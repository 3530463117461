import React from "react";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { MessagesGroupTab, MessagesTab } from "components/Global/Messages";

const ClubMessage = props => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box display="flex" gap={2}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: 50,
            padding: 2,
            width: 220,
            color: "white",
          }}
          onClick={() => {
            setIsGroupModalOpen(true);
          }}
        >
          Message Group
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: 50,
            padding: 2,
            width: 220,
            color: "white",
          }}
          onClick={() => {
            setIsMessageModalOpen(true);
          }}
        >
          Message Followers
        </Button>
      </Box>
      <FollowersMessage
        club={props.club}
        isMessageModalOpen={isMessageModalOpen}
        setIsMessageModalOpen={setIsMessageModalOpen}
      />
      <GroupsMessage
        club={props.club}
        isMessageModalOpen={isGroupModalOpen}
        setIsMessageModalOpen={setIsGroupModalOpen}
      />
    </React.Fragment>
  );
};

const FollowersMessage = ({ isMessageModalOpen, setIsMessageModalOpen, club }) => {
  return (
    <Modal
      open={isMessageModalOpen}
      onClose={() => setIsMessageModalOpen(false)}
      aria-labelledby="Messages"
      aria-describedby="Messages"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <MessagesTab isNew isModal club={club} />
      </Box>
    </Modal>
  );
};

const GroupsMessage = ({ isMessageModalOpen, setIsMessageModalOpen, club }) => {
  return (
    <Modal
      open={isMessageModalOpen}
      onClose={() => setIsMessageModalOpen(false)}
      aria-labelledby="Messages"
      aria-describedby="Messages"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <MessagesGroupTab isNew isModal club={club} />
      </Box>
    </Modal>
  );
};

export default ClubMessage;
