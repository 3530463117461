import { Typography } from "@mui/material";
import React, { useRef } from "react";
import Container from "@mui/system/Container";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/system/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import COLORS from "../../../../../../../constants/colors";
import Grid from "@mui/material/Grid";
import apiRequest from "../../../../../../../api/apiRequest";
import { PdfCard } from "../../../../../../../core/PdfViewer/PdfCard";

export const FilesUpload = ({ files, onChange, onRemoveFile }) => {
  const fileRef = useRef();
  const theme = useTheme();

  const handleImageChange = async e => {
    if (e.target.files[0]) {
      const response = await uploadFile(e.target.files[0]);

      if (response.data) {
        onChange(response.data[0]);
        fileRef.current.value = null;
      }
    }
  };

  const uploadFile = async file => {
    try {
      const formData = new FormData();
      formData.append("file[]", file);

      return await apiRequest({
        method: "post",
        url: "upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {}
  };

  return (
    <Container maxWidth="xl" className="club-page-leaders">
      <Typography
        variant="h5"
        color={theme.think12.colors.black.dark}
        fontSize={{ md: 30 }}
        fontWeight={700}
        mb={"20px"}
      >
        Files
      </Typography>

      <Grid container spacing={3} mb={4} mt={4} gap={2}>
        {files.map((file, index) => (
          <PdfCard onRemove={() => onRemoveFile(index)} key={file.url} url={file.url} />
        ))}
      </Grid>

      <input
        type="file"
        id="pdfsInputFile"
        className="fileElem"
        ref={fileRef}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Box onClick={() => fileRef.current.click()} display="flex" alignItems="center" ml={"35%"}>
          <Fab
            color="primary"
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": { backgroundColor: theme.palette.secondary.main },
            }}
          >
            <AddIcon />
          </Fab>
          <Typography ml={1} variant="body2" fontWeight="bold" color={COLORS.primary.main}>
            Add a new file
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};
