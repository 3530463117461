import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import { Card } from "../../../../components/Card/Card";

export const GraduatesList = ({ graduates, isLoading }) => {
  return (
    <Box mt={15} mb={10} marginX={5}>
      {isLoading ? (
        <Box justifyContent="center" mt={6}>
          <LoadingIndicator />
        </Box>
      ) : (
        <Box justifyContent="center" mt={6} px={{ sm: 2, md: 20 }}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            container
            rowSpacing={3}
            columnSpacing={3}
            alignItems="center"
            justifyContent="center"
          >
            {graduates &&
              graduates.map((item, index) => (
                <Grid item md={3} key={index}>
                  <Link to={`/graduates/${item.id}`}>
                    <Card
                      image={item?.profile_image?.path}
                      title={`${item?.first_name} ${item?.last_name[0]}.`}
                      educations={item?.educations?.[0]?.outcome_id}
                      outcome_id={item?.educations[0]?.outcome_id}
                    />
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default GraduatesList;
