import React from "react";
import { useHistory } from "react-router-dom";

import COLORS from "constants/colors";
import { ROUTES } from "constants/routes";
import AuthSignUpForm from "pages/ManageAuth/components/AuthSignUpForm";
import AuthLandingScreen from "pages/ManageAuth/components/AuthLandingScreen";

export const GraduateProfileSignUp = () => {
  const history = useHistory();
  return (
    <AuthLandingScreen
      height="950px"
      bgColor={COLORS.common.graduate}
      headerTitle="Graduate Gallery Profile"
      image="assets/images/GraduateImage.png"
    >
      <AuthSignUpForm
        buttonsArea
        title="Welcome!"
        subTitle="Create a Profile and start sharing your story!"
        authButtonText="Sign Up"
        navigationButtonText="Log In Here"
        navigationButtonTextAction={() => history.push(ROUTES.SIGN_IN)}
      />
    </AuthLandingScreen>
  );
};

export default GraduateProfileSignUp;
