import React from "react";
import { SideBar } from "./components/SideBar";
import Grid from "@mui/material/Grid";

export const Layout = ({ setActiveStep, activeStep, children, tabs, profileRoute, avatar }) => {
  return (
    <Grid container>
      <Grid item md={2} mt={8} xs={12} sm={12}>
        <SideBar
          tabs={tabs}
          profileRoute={profileRoute}
          avatar={avatar}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      </Grid>
      <Grid item md={8} mt={8}>
        {children}
      </Grid>
    </Grid>
  );
};
