import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, { useRef } from "react";
import { useGroups } from "../GroupsProvider";

export const CreateGroupModal = ({ open, setOpen, clubId }) => {
  const formRef = useRef(null);
  const { createGroup } = useGroups();

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(formRef.current);
    const name = formData.get("name");
    await createGroup({ name, clubId });
    setOpen(false);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={() => setOpen(false)}>
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit} ref={formRef}>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className="MuiInputLabel-Profile-label" shrink>
              Group Name:
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                name="name"
                size="small"
                InputProps={{ disableUnderline: "false" }}
                hiddenLabel
                variant="filled"
                fullWidth
                color="secondary"
                placeholder="Team A..."
                sx={{ mb: 2 }}
              />
            </FormControl>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              type="submit"
              size="large"
              sx={{ p: 8, height: 40 }}
              className="MuiButton-profile"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
