import React from "react";
import {getElementSize} from "../utils/numToMeasurement";

export const TEXT = ({
  text,
  style,
}) => (
  <div
    dangerouslySetInnerHTML={{ __html: text }}
    style={{
      margin: getElementSize(style?.margin),
      height: getElementSize(style?.height),
      width: getElementSize(style?.width),
      background: style?.background,
      color: style?.color,
      fontFamily: style.fontFamily,
      fontSize: getElementSize(style?.fontSize),
      fontWeight: style.fontWeight,
      textDecoration: style.textDecoration,
      textAlign: style.textAlign,
    }}
  />
);
