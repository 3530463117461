import React from 'react';
import SideBar from './components/SideBar';
import Grid from '@mui/material/Grid';

const Layout = (props) => {
  const { setActiveStep, activeStep, children } = props || {};

  return (
    <Grid container>
      <Grid item md={2} mt={8} xs={12} sm={12}>
        <SideBar setActiveStep={setActiveStep} activeStep={activeStep} />
      </Grid>
      <Grid item md={8} mt={8}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
