import React, { useState } from 'react';
import Box from '@mui/system/Box';
import Tab from '@mui/material/Tab';
import Modal from '@mui/material/Modal';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import UploadControl from 'components/UploadControl';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TakeSelfie } from 'components/Layout/TakePhoto';
import { AvatarCanvas } from 'components/AvatarCanvas';
import ProfileContext from 'contexts/ProfileContext';

export const EditProfile = (props) => {
  const { setIsOpen, isOpen } = props || {};
  const { updateImage, familyProfile } = React.useContext(ProfileContext);

  const [selectedTab, setSelectedTabb] = useState('1');
  const [uploadFile, setUploadFile] = useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTabb(newValue);
  };

  const style = {
    position: 'absolute',
    top: { xs: '45%', sm: '42%', md: '40%', lg: '40%' },
    left: { xs: '50%', sm: '50%', md: '50%', lg: '50%' },
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '55%', md: '55%', lg: '50%' },
    // height: { xs: '60%', sm: '70%', md: '72%', lg: '62%' },
    bgcolor: 'background.paper',
    boxShadow: 10,
    borderRadius: '8px',
    marginTop: '80px',
  };
  const tabStyle = {
    display: 'flex',
    color: '#929292', // font Color Fix
    fontWeight: 600, // font weight Fix
    alignItem: 'center',
    justifyContent: 'center',
    width: { xs: '10px', sm: '14%', md: '18%', lg: '20%' },
    textTransform: 'capitalize',
    marginRight: { lg: '9%', md: '8%', sm: '3%', xs: '0px' },
    fontSize: { xs: '8px', sm: '10px', md: '12px', lg: '16px' },
  };

  React.useEffect(() => {
    if (uploadFile) {
      uploadImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const uploadImage = async () => {
    await updateImage({
      userId: familyProfile.id,
      imageFile: uploadFile,
      imageId: familyProfile?.profile_image ? familyProfile?.profile_image.id : null,
      ownerType: 'Family',
    });
    setIsOpen(false);
  };

  return (
    <div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={style}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: '#D3D3D3' }}>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                // sx={{ marginTop: "30px", paddingLeft: "50px", color: "black" }}
                sx={{
                  marginTop: '30px',
                  paddingLeft: {
                    xs: '0px',
                    sm: '15px',
                    md: '30px',
                    lg: '40px',
                  },
                  color: 'black',
                  display: { xs: 'flex', flexDirection: 'column' },
                }}
              >
                <img src='/assets/icons/modalIcon1.svg' alt='' />
                <Tab label='Upload Image' value='1' sx={tabStyle} />
                <img src='/assets/icons/modalIcon3.svg' alt='' />
                <Tab label='Camera' value='2' sx={tabStyle} />
                <img src='/assets/icons/modalIcon2.svg' alt='' />
                <Tab label='Create Avatar' value='3' sx={tabStyle} />
                <span
                  style={{
                    // fix cancel icon with background color,
                    marginRight: { sm: '50px' },
                    color: '#2E75FF',
                    backgroundColor: '#F1F1F1',
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    lineHeight: '3.3',
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontWeight: 800,
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  <CloseOutlinedIcon color='blue' />
                </span>
              </TabList>
            </Box>
            <TabPanel value='1'>
              <UploadControl
                title='Upload a profile image.'
                icon='/assets/icons/modalFileIcon.svg'
                title1='Drop your file(s) here or'
                title2='browse'
                height='350px'
                setUploadFile={setUploadFile}
              />
            </TabPanel>
            <TabPanel value='2'>
              <TakeSelfie height='350px' setUploadFile={setUploadFile} />
            </TabPanel>
            <TabPanel value='3'>
              <AvatarCanvas setUploadFile={setUploadFile} />
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </div>
  );
};
export default EditProfile;
