import React from "react";
import Box from "@mui/system/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import COLORS from "constants/colors";

export const ConnectLinkedIn = (props) => {
  const { isConnectLinkedIn, setIsConnectLinkedIn, title, text } = props || {};

  return (
    <React.Fragment>
      <Modal
        open={isConnectLinkedIn}
        onClose={() => setIsConnectLinkedIn(false)}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              padding: "30px",
              justifyContent: "space-between",
            }}
          >
            <Box position="relative">
              <img
                src="/assets/images/user-profile.svg"
                className="logo"
                style={{ borderRadius: "50px" }}
                alt=""
                width="100px"
                height="100px"
              />
              <img
                src="/assets/icons/linkedin-contained.svg"
                className="logo"
                alt="logo"
                style={{ position: "absolute", top: "60%", right: "0%" }}
                width="20px"
                height="20px"
              />
            </Box>
            <Box display={{xs:'none',sm:'flex',md:'flex',lg:'flex'}}>
              <img
                src="/assets/images/logo.svg"
                className="logo"
                alt="logo"
                style={{ marginLeft: "30px" }}
                width='80%'
              />
            </Box>
            <Box
              onClick={() => setIsConnectLinkedIn(false)}
              sx={{
                cursor: "pointer",
                background: "#F2F2F2",
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloseOutlinedIcon sx={{ color: COLORS.primary.main }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "30px",
              borderBottom: "1px solid gray",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={"body1"} fontWeight={600}>
              {title}
            </Typography>
          </Box>
          <Box p={5}>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{ lineHeight: "28px" }}
            >
              {text}
            </Typography>
            <Box mt={5} display="flex" justifyContent="flex-end">
              <Button
                sx={{ width: "160px", height: "60px", mr: 2 }}
                className="MuiButton-profile"
                variant="outlined"
                onClick={() => setIsConnectLinkedIn(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: "160px", height: "60px" }}
                className="MuiButton-profile"
                variant="contained"
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ConnectLinkedIn;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "40%", lg: "40%",sm:'100%',xs:'100%' },
  bgcolor: "background.paper",
  boxShadow: 10,
  borderRadius: "8px",
  marginTop: "80px",
};
