import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

function Loader({ height = '100vh' }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ color: theme.palette.grey[600] }} />
    </Box>
  );
}

export default Loader;
