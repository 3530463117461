import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { COLORS } from 'constants/colors';
import { useTheme } from '@mui/material/styles';
import urls from 'api/urls';
import { PROGRAM_CONTENT } from 'constants/program_content';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const AuthLandingScreen = (props) => {
  const { children, height, bgColor, headerTitle, staffAdminIcon, staffBgcolor } = props || {};
  const theme = useTheme();
  const { configurations } = React.useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Box
        bgcolor={bgColor || staffBgcolor || `${theme.palette.primary.main}40`}
        sx={{ position: 'relative' }}
        py={{ xs: 2, md: headerTitle ? 10 : 15 }}
        height={staffAdminIcon ? '1200px' : 'auto'}
      >
        <Container maxWidth='xl' disableGutters>
          {headerTitle && (
            <Box px={16} py={2}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} color={COLORS.secondary.main}>
                {headerTitle}
              </Typography>
            </Box>
          )}
          <Box sx={{ position: 'absolute', right: 80, top: 50 }} display={{ xs: 'none', md: 'flex' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='154' height='154' viewBox='0 0 154 154' fill='none'>
              <circle
                cx='77'
                cy='77'
                r='62'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.3'
                stroke-width='30'
              />
            </svg>
          </Box>
          <Grid container justifyContent='center'>
            <Grid item container md={10} p={2} justifyContent='center'>
              <Paper
                sx={{
                  zIndex: 1,
                  width: '1200px',
                  display: 'flex',
                  borderRadius: 5,
                  height: { xs: 'auto', md: height || '800px' },
                  flexDirection: { xs: 'column', md: 'row' },
                  boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Grid item md={6} xs={12} sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Box style={{ width: '100%', height: height || '800px' }}>
                    <img
                      src={
                        configurations?.guest_welcome_image?.path || PROGRAM_CONTENT[urls.programId()].auth_screen_image
                      }
                      alt='LoginImage'
                      width='100%'
                      height='100%'
                      style={{
                        objectFit: 'cover',
                        borderRadius: '20px 0px 0px 20px',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  {children}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Box
            sx={{
              left: 110,
              position: 'absolute',
              bottom: staffAdminIcon ? 0 : 130,
            }}
            display={{ xs: 'none', md: 'flex' }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='154' height='154' viewBox='0 0 154 154' fill='none'>
              <circle
                cx='77'
                cy='77'
                r='62'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.3'
                stroke-width='30'
              />
            </svg>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default AuthLandingScreen;
