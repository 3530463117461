import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//imports
import { COLORS } from "constants/colors";

export const LeaderClubCard = (props) => {
  const { image, title, subTitle, description, sx } = props;

  return (
    <React.Fragment>
      <Box bgcolor={COLORS.blue.light} sx={sx} mt={2}>
        <Box width={{ xs: "100%", md: "100%" }}>
          <img src={image} alt="" width="100%" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          ml={{ md: 2, xs: 2 }}
          mt={{ md: 2, xs: 2 }}
        >
          <Typography fontSize={{ md: 22 }} fontWeight={900} align="left">
            {title}
          </Typography>
          <Typography fontSize={{ md: 18 }} align="left">
            {subTitle}
          </Typography>
        </Box>
        <Box mt={{ md: 4 }} ml={{ md: 2, xs: 2 }}>
          <Typography
            warp
            align="left"
            lineHeight={2}
            fontSize={{ md: 15 }}
            width={{ xs: 320, md: 370 }}
            fontWeight={400}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
