import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { FAMILY_MEMBER_ICONS } from "../../../../constants/family_member_icons";
import ConfigurationContext from 'contexts/ConfigurationContext';

export const ClubFamilyCard = (props) => {
  const theme = useTheme();

  const { configurations } = React.useContext(ConfigurationContext);

  const getLink = () => {
    if (props.family.is_standard) {
      return `/family-network/families/${props.family.id}`;
    } else {
      return `/family-network/ambassador/${props.family.id}`;
    }
  }

  const socialMedias = [];
  const smTwitter = socialMedias?.find(
    (sm) => sm.type.toLowerCase().trim() === "twitter"
  );
  const smLinkedin = socialMedias?.find(
    (sm) => sm.type.toLowerCase().trim() === "linkedin"
  );
  const smFacebook = socialMedias?.find(
    (sm) => sm.type.toLowerCase().trim() === "facebook"
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        marginBottom: "4em",
        background: theme.palette.background.default,
        boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    > 
      <Link to={getLink()}>
        <Box
          sx={{
            width: "100%",
            height: "300px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              objectFit: "contain"
            }}
            src={
              props.family.profile_image
                ? props.family.profile_image.path
                : configurations?.default_user_profile_image?.path || "/assets/images/family-profile.svg"
            }
            alt=""
            width="100%"
          />
        </Box>
      </Link>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Link to={getLink()}>
          <Typography fontWeight={900} fontSize={22} ml={4} mt={2}>
            {(props.family.is_standard) ? props.family.last_name + " Family" : null}
            {(!props.family.is_standard) ? props.family.first_name || props.family.head?.last_name : null}
          </Typography>
        </Link>

      </Box>
      <Box px={4} pt={1} pb={3}>
      {props.family.members?.length ? (
          props.family.members?.map((fm, i) => {
            if (!fm.icon_id) return <React.Fragment key={i}></React.Fragment>;

            return (
              <img key={i}
                src={FAMILY_MEMBER_ICONS.find((f) => f.id === fm.icon_id).icon}
                alt=''
                style={{ marginRight: '10px' }}
              />
            );
          })
        ) : (<img
          src={FAMILY_MEMBER_ICONS[0].icon}
          alt=''
          style={{ transform:'scale(0)' }}
        />)}
        <Grid item container justifyContent="space-between" mt={3}>
          <Box>
            <Link to={getLink()}>
              <Button
                variant="outlined"
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                  borderRadius: "100px",
                  paddingX: "2.5em",
                }}
              >
                Contact
              </Button>
            </Link>
          </Box>
          {socialMedias && socialMedias.length ? (
            <Box>
              {smTwitter && (
                <Link to={smTwitter.url} target="_blank">
                  <IconButton>
                    <img src={"/assets/icons/twitter.png"} alt="twitter" />
                  </IconButton>
                </Link>
              )}
              {smFacebook && (
                <Link to={smFacebook.url} target="_blank">
                  <IconButton>
                    <img src={"/assets/icons/facebook.png"} alt="facebook" />
                  </IconButton>
                </Link>
              )}
              {smLinkedin && (
                <Link to={smLinkedin.url} target="_blank">
                  <IconButton>
                    <img src={"/assets/icons/linkedIn.png"} alt="linkedin" />
                  </IconButton>
                </Link>
              )}
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ClubFamilyCard;
