import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Divider from "@mui/material/Divider";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LaunchIcon from "@mui/icons-material/Launch";
import { Document, Page } from "react-pdf";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxHeight: "calc(100% - 64px)",
  },
  pdfContainer: {
    overflowY: "auto",
    maxHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    background: "gray",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const PdfDialog = ({ url, open, handleClose }) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);
  const pdfWrapperRef = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };

  const fitToWidth = () => {
    setTimeout(() => {
      if (pdfWrapperRef.current) {
        const containerWidth = pdfWrapperRef.current.offsetWidth;
        const scaleFactor = containerWidth / 595;
        setScale(scaleFactor);
      }
    }, 100);
  };

  const zoomIn = () => setScale(scale * 1.1);
  const zoomOut = () => setScale(scale / 1.1);

  const openInNewTab = () => {
    window.open(url, "_blank").focus();
  };

  const shrinkToFit = () => setScale(1.5);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <Toolbar className={classes.toolbar}>
        <Box>
          <Typography variant="body1">Pages: {pageNumber}</Typography>
        </Box>
        <Box display="flex">
          <IconButton onClick={zoomIn}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={zoomOut}>
            <ZoomOutIcon />
          </IconButton>
          <Divider flexItem orientation="vertical" />
          <IconButton onClick={fitToWidth}>
            <FullscreenIcon />
          </IconButton>
          <IconButton onClick={shrinkToFit}>
            <FullscreenExitIcon />
          </IconButton>
        </Box>
        <IconButton onClick={openInNewTab}>
          <LaunchIcon />
        </IconButton>
      </Toolbar>
      <DialogContent
        sx={{ paddingTop: 0 }}
        dividers
        className={classes.pdfContainer}
        ref={pdfWrapperRef}
      >
        <Document file={url} renderMode="canvas" onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(pageNumber), (el, index) => (
            <Page
              renderAnnotationLayer={false}
              renderTextLayer={false}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
            />
          ))}
        </Document>
      </DialogContent>
    </Dialog>
  );
};
