import React from 'react';
import COLORS from 'constants/colors';
import ArticleCard from '../ArticleCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const Topics = ({ articleContent }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box bgcolor={theme.palette.secondary.main} mt={10} pb={{ md: 30, sm: 20, xs: 10 }}>
        <Container maxWidth='false'>
          <Box ml={{ md: 5 }} py={{ xs: 5, md: 10 }}>
            <Typography variant='h3' fontWeight='bolder' sx={{ color: COLORS.white.lighter }}>
              More Topics to Explore
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {articleContent.length
              ? articleContent.map((article, idx) => (
                  <Grid xs={12} key={idx} item md={idx === 0 ? 8 : 4}>
                    <ArticleCard
                      article={article}
                      isWhite
                      height='500'
                      image={article.featuredImageUrl}
                      fallbackImage={'assets/images/noImage.jpg'}
                      primaryText={article.articleTitle}
                      secondaryText={article.contentDescription ? article.contentDescription : 'lorem ipsum sit amet'}
                    />
                  </Grid>
                ))
              : new Array(2).fill('').map((article, idx) => (
                  <Grid xs={12} key={idx} item md={idx === 0 ? 8 : 4}>
                    <ArticleCard
                      isWhite
                      height='500'
                      image={'assets/images/noImage.jpg'}
                      fallbackImage={'assets/images/noImage.jpg'}
                      primaryText={'Not Available'}
                      secondaryText={article.contentDescription ? article.contentDescription : 'lorem ipsum sit amet'}
                    />
                  </Grid>
                ))}
            {/* <Grid xs={12} item md={4}>
              <ArticleCard
                isWhite
                height="300"
                image={"assets/images/article-banner7.png"}
                primaryText=" Quality of teaching staff"
                secondaryText="Understanding the Effectiveness of Teacher Attributes."
              />
            </Grid> */}
          </Grid>
        </Container>
        <Box position={'relative'}>
          <Box
            sx={{
              top: '0',
              right: { md: '30%', lg: '30%', xs: '0%' },
              position: 'absolute',
            }}
          >
            <svg width='340' height='340' viewBox='0 0 340 340' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle
                cx='170'
                cy='170'
                r='150'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.1'
                stroke-width='40'
              />
            </svg>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Topics;
