import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { COLORS } from "constants/colors";
export const Meeting = (props) => {
  const [numStudents, setNumStudents] = React.useState("");
  const [grades, setGrades] = React.useState("");
  const [meetingDay, setMeetingDay] = React.useState("");
  const [meetingHours, setMeetingHours] = React.useState("");

  useEffect(() => {
    if (props.meeting) {
      //check if all fields are empty
      if (numStudents === "" && grades === "" && meetingDay === "" && meetingHours === "") {
        setNumStudents(props.meeting.number_of_students);
        setGrades(props.meeting.student_grades);
        setMeetingDay(props.meeting.meeting_day);
        setMeetingHours(props.meeting.meeting_hours);
      }
    }
  }, [props.meeting]);

  useEffect(() => {
    if (props.onMeetingUpdate) {
      props.onMeetingUpdate({
        number_of_students: numStudents,
        student_grades: grades,
        meeting_day: meetingDay,
        meeting_hours: meetingHours,
      });
    }
  }, [numStudents, grades, meetingDay, meetingHours]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            Number of Participants
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              onChange={(event) => setNumStudents(event.target.value)}
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              fullWidth
              value={numStudents}
              id="number-of-students"
              name="number-of-students"
              placeholder=""
            />
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            Participants
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              onChange={(event) => setGrades(event.target.value)}
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              fullWidth
              value={grades}
              id="student-grades"
              name="student-grades"
              placeholder=""
            />
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            Meeting Day
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              onChange={(event) => setMeetingDay(event.target.value)}
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              fullWidth
              value={meetingDay}
              id="meeting-day"
              name="meeting-day"
              placeholder=""
            />
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            Meeting Hours
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              onChange={(event) => setMeetingHours(event.target.value)}
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              fullWidth
              value={meetingHours}
              id="meeting-hours"
              name="meeting-hours"
              placeholder=""
            />
          </FormControl>
        </Grid>
        <Grid item md={1} xs={12} sm={12}>
        {(props.disabled) ? <IconButton style={{marginTop:"1.4em"}}
                color="primary"
                onClick={() => { if(props.onDelete) props.onDelete(); }}
                sx={{ bgcolor: COLORS.white.dark }}
            >
                <CloseRoundedIcon />
            </IconButton> : []}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Meeting;
