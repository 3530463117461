import React from 'react';
import { Grid, Box, InputLabel, Typography, FormControl, TextField } from '@mui/material';
import COLORS from 'constants/colors';

function QuoteRow({ formData, updateFormData }) {
  return (
    <Grid container mt={2} spacing={3}>
      <Grid item md={12} xs={12}>
        <Box display='flex' justifyContent='space-between'>
          <InputLabel className='MuiInputLabel-Profile-label' shrink>
            Add a quote
          </InputLabel>
          <Typography color={COLORS.grey.thinner} variant='caption'>
            75
          </Typography>
        </Box>
        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: 'false' }}
            variant='filled'
            required
            multiline
            fullWidth
            rows={5}
            id='name'
            name='name'
            sx={{ mb: 2 }}
            value={formData.quote}
            onChange={(e) => updateFormData('quote', e.target.value)}
            placeholder='A good education can change anyone. A good teacher can change everything!'
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default QuoteRow;
