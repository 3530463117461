import React, { useEffect } from "react";
import AddMeeting from "../AddMeeting";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import useTheme from "@mui/material/styles/useTheme";
import debounce from "lodash/debounce";
import { useStyles } from "./ClubEditorAbout.style";
import {Editor} from "@tinymce/tinymce-react";

export const About = props => {
  const styles = useStyles();
  const [club, setClub] = React.useState(props.club ? props.club : {});
  const [about, setAbout] = React.useState(props.club ? props.club.about : "");
  const [benefits, setBenefits] = React.useState(props.club ? props.club.benefits : "");
  const [meetings, setMeetings] = React.useState(props.club ? props.club.meetings : []);
  const [category, setCategory] = React.useState(props.club ? props.club.category : "");
  const [requirements, setRequirements] = React.useState(props.requirements ? props.requirements : "");

  const theme = useTheme();

  const addMeeting = meeting => {
    setMeetings([...meetings, meeting]);
  };

  useEffect(
    debounce(() => {
      club.about = about;
      club.requirements = requirements;
      club.benefits = benefits;
      if (props.onClubUpdate) {
        props.onClubUpdate(club);
      }
      setClub(club);
    }, 300),
    [about, category, benefits, requirements]
  );

  useEffect(() => {
    if (props.onMeetingsUpdate) {
      props.onMeetingsUpdate(meetings);
    }
  }, [meetings]);

  useEffect(() => {
    if (JSON.stringify(props.club) !== JSON.stringify(club)) {
      setClub(props.club);
      setMeetings(props.club.meetings);
      setAbout(prevAbout => prevAbout || props.club.about);
      setRequirements(prevState => prevState || props.club.requirements);
      setBenefits(prevBenefits => prevBenefits || props.club.benefits);
      setCategory(prevCategory => prevCategory || props.club.category);
    }
  }, [props.club]);

  const [isAddMeeting, setIsAddMeeting] = React.useState(false);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={9}>
          <Box
            px={{ md: 0, lg: 0, sm: 3, xs: 3 }}
            mt={5}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="800">
              About Us
            </Typography>
          </Box>

          <FormControl fullWidth>
            <TextField
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              multiline
              fullWidth
              rows={5}
              id="name"
              name="name"
              value={about}
              sx={{ mb: 2, px: { md: 0, lg: 0, sm: 3, xs: 3 } }}
              placeholder="Write a short description of the club"
              onChange={e => setAbout(e.target.value)}
            />
          </FormControl>

          <Box
            px={{ md: 0, lg: 0, sm: 3, xs: 3 }}
            mt={5}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="800">
              Requirements
            </Typography>
          </Box>

          <FormControl fullWidth>
            <TextField
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              fullWidth
              id="name"
              name="name"
              value={requirements}
              sx={{ mb: 2, px: { md: 0, lg: 0, sm: 3, xs: 3 } }}
              placeholder="Please indicate any participation requirements"
              onChange={e => setRequirements(e.target.value)}
            />
          </FormControl>

          <Box
            px={{ md: 0, lg: 0, sm: 3, xs: 3 }}
            mt={5}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="800">
              Benefits of Participating
            </Typography>
          </Box>
          <FormControl sx={{ mb: 4 }} fullWidth>
            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              value={benefits}
              init={{
                height: 200,
                menubar: false,
                plugins: "link image",
                toolbar:
                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright | image link",
              }}
              onEditorChange={e => setBenefits(e)}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel style={{ marginTop: ".5em" }} id="category-label">
              Category
            </InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              {props.club.categories &&
                props.club.categories.map((cat, index) => (
                  <MenuItem value={index} key={index}>
                    {cat}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box ml={{ xs: 2, sm: 2, md: 0, lg: 0 }} mt={3} display="flex" alignItems="center">
            <Fab
              onClick={() => setIsAddMeeting(!isAddMeeting)}
              color="primary"
              sx={{
                backgroundColor: theme.palette.primary.main,
                "&:hover": { backgroundColor: theme.palette.secondary.main },
              }}
            >
              <AddIcon className={styles.AddMeet} />
            </Fab>
            <Typography ml={3} variant="body1" fontWeight="bold" color={theme.palette.primary.main}>
              Add a new meeting time
            </Typography>
          </Box>
          {(isAddMeeting || meetings?.length > 0) && (
            <Box mt={4}>
              <AddMeeting
                meetings={meetings}
                setIsAddMeeting={setIsAddMeeting}
                setMeetings={meetings => setMeetings(meetings)}
                onConfirm={data => addMeeting(data)}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default About;
