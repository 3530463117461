import { createContext, useEffect, useState } from "react";
import apiRequest from "../api/apiRequest";
import urls from "../api/urls";

const ProgramContext = createContext(null);

export const ProgramProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        await apiRequest({
          method: "get",
          url: urls.programAccess,
        });
      } catch (e) {
        setIsActive(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAccess();
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isActive) throw new Error();

  return <ProgramContext.Provider value={null}>{children}</ProgramContext.Provider>;
};
