import { useState } from "react";

export const useStateFilter = () => {
  return useState({
    prefix: "",
    classFilter: "",
    isStandard: 1,
  });
};

export const useFilter = () => {
  return {
    query: (prefix, classFilter, isStandard = 1, sort = "asc") => {
      let query = [];

      if (prefix) {
        query.push(`filter[last_name][prefix]=${prefix}`);
      }
      if (classFilter) {
        query.push(`class=${classFilter}`);
      }
      if (isStandard) {
        query.push(`filter[is_standard][is]=${isStandard}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }

      return query.join("&");
    },
  };
};
