import React, { useEffect, useRef, useState } from "react";
import { ClubPageCover } from "../ClubPageCover";
import { LeadersContact } from "../LeadersContact";
import { ClubPageHeading } from "../ClubPageHeading";
import { FamiliesCardsSection } from "../ClubPageFamilies";
import ClubHubUpComingEvents from "../ClubHubUpcomingEvents";
import { GallerySection } from "../Gallery";
import AuthContext from "contexts/AuthContext";
import apiRequest from "api/apiRequest";
import { NavigationToolbar } from "../../../../components/NavigationToolbar/ToolbarNavigation";
import { FilesListing } from "../FilesSection/FilesListing";

export const ClubPage = props => {
  const [club, setClub] = React.useState();
  const { user } = React.useContext(AuthContext);
  const tabs = useTabOptions(club);

  const [isSticky, setIsSticky] = useState(false);
  const elementRef = useRef(null); // This ref is attached to the element we're monitoring

  const checkIfSticky = () => {
    if (elementRef.current) {
      const elementTop = elementRef.current.getBoundingClientRect().top - 50;
      setIsSticky(elementTop <= 0); // Set sticky based on element's top position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfSticky);

    return () => {
      window.removeEventListener("scroll", checkIfSticky); // Clean up the event listener on component unmount
    };
  }, []);

  useEffect(() => {
    apiRequest({
      url: `/clubs/${props.match.params.clubId}`,
    }).then(res => {
      setClub(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <NavigationToolbar isSticky={isSticky} tabs={tabs} />
      <div ref={elementRef} />
      <ClubPageHeading club={club} />
      <ClubPageCover club={club} />
      {club?.leaders && club?.leaders?.length > 0 && <LeadersContact club={club} />}
      {club?.files?.length > 0 && <FilesListing files={club?.files} />}
      <ClubHubUpComingEvents club={club} />
      {user &&
      user?.roles?.find(r => ["Verified Family", "Club Leader", "Staff"].indexOf(r.name) !== -1) ? (
        <FamiliesCardsSection club={club} />
      ) : (
        []
      )}
      <GallerySection club={club} />
    </React.Fragment>
  );
};

const useTabOptions = club => {
  if (!club) return [];

  const tabs = [];

  if (club.about) {
    tabs.push({ label: "About us", value: "about" });
  }
  if (club.benefits) {
    tabs.push({ label: "Benefits", value: "benefits" });
  }
  if (club.leaders && club?.leaders?.length > 0) {
    tabs.push({ label: "Leaders", value: "contacts" });
  }
  if (club.files && club?.file?.length > 0) {
    tabs.push({ label: "Files", value: "clubFiles" });
  }
  if (club.events && club?.events?.length > 0) {
    tabs.push({ label: "Events", value: "events" });
  }
  if (club.announcements) {
    tabs.push({ label: "Announcements", value: "announcements" });
  }
  if (club.families && club?.families?.length > 0) {
    tabs.push({ label: "Followers", value: "families" });
  }
  if (club.gallery && club?.gallery?.length > 0) {
    tabs.push({ label: "Gallery", value: "gallery" });
  }

  return tabs;
};

export default ClubPage;
