import { GraduatesHeader } from "./Components/Header/AlumniHeader";
import { SearchInput } from "../../components/SearchBar/SearchInput";
import { PillFilter } from "../../components/Filters/PillFilter";
import { AlphabeticFilter } from "../../components/Filters/AlphabeticFilter";
import React, { useState } from "react";
import { AlumniList } from "./Components/AlumniNetworkList/AlumniList";
import { Pagination } from "../../components/Pagination/Pagination";
import apiRequest from "../../api/apiRequest";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import { toast } from "react-toastify";

export const AlumniProfile = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  const [alumniList, setAlumniList] = React.useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [classes, setClasses] = useState([]);

  const [filter, setFilter] = useState({
    classYear: null,
    query: "",
    prefix: null,
  });

  React.useEffect(() => {
    const fetchClasses = async () => {
      const resp = await apiRequest({
        method: "get",
        url: `/programs/${configurations?.id}/class-years/alumni`,
      });
      setClasses(resp.data);
    };
    fetchClasses();
  }, []);

  const fetchAlumniProfiles = async (pageNumber = 1) => {
    try {
      setIsLoading(true);
      const { classYear, query, prefix } = filter;

      const resp = await apiRequest({
        method: "get",
        url: `/alumni?page=${pageNumber}${classYear ? `&filter[attending_school_since][is]=${classYear}` : ""}${
          prefix ? `&filter[first_name][prefix]=${prefix}` : ""
        }${query !== "" ? `&search[query]=${encodeURIComponent(query)}` : ""}`,
      });

      setAlumniList(resp.data);
      setLinks(resp.links);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAlumniProfiles();
  }, [filter]);

  return (
    <div>
      <GraduatesHeader />
      <SearchInput
        placeholder="Search by alumni network name, subject, club"
        onSearch={query => setFilter({ ...filter, query })}
      />
      <PillFilter
        pills={classes}
        selectedPill={filter.classYear}
        setSelectedPill={classYear => setFilter({ ...filter, classYear })}
        label="Select Class"
      />
      <AlphabeticFilter onSearch={prefix => setFilter({ ...filter, prefix })} />
      <AlumniList alumniList={alumniList} isLoading={isLoading} />
      {links.length > 3 && <Pagination links={links} fetch={fetchAlumniProfiles} />}
    </div>
  );
};
