export const backgroundColors = [
  '#FFF9E3',
  '#F8F5AF',
  '#FFEFE3',
  '#FFDDDD',
  '#F2E5F2',
  '#D8FCEB',
  '#DEF4AD',
  '#D0F4F4',
  '#CDECF9',
];

export const faceColors = [
  '#FFF1DB',
  '#FFDEB6',
  '#F4B162',
  '#FFB8A1',
  '#D39471',
  '#EA9986',
  '#D58977',
  '#D38171',
  '#C08C6F',
];

export const hairColors = [
  '#EFB126',
  '#6F4833',
  '#E9356E',
  '#FF4F4E',
  '#F55B5D',
  '#5A184A',
  '#3F2037',
  '#19274B',
  '#060606',
];

export const clothsColors = [
  '#EFB126',
  '#6F4833',
  '#F4B162',
  '#FF4F4E',
  '#F55B5D',
  '#5A184A',
  '#D58977',
  '#19274B',
  '#060606',
];
