import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Container from "@mui/material/Container";
import React, { useState } from "react";

export const SearchInput = ({ onSearch, placeholder }) => {
  const [query, setQuery] = useState("");

  return (
    <Container>
      <Box display={{ xs: "none", sm: "flex", md: "flex" }} mt={5}>
        <Box component="form" width="100%">
          <Paper
            component="form"
            sx={{
              p: 2,
              width: "auto",
              display: "flex",
              bgcolor: "white",
              alignItems: "center",
              borderRadius: "26px",
              boxShadow: "0px 4px 10px 7px rgba(0, 0, 0, 0.05)",
            }}
          >
            <IconButton sx={{ p: "10px" }} onClick={() => onSearch(query)}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={placeholder}
              value={query}
              onChange={e => setQuery(e.target.value)}
              fullWidth={true}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSearch(query);
                }
              }}
            />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};
