import React from "react";
import Hero from "./components/Hero";
import General from "./components/General";
import AuthContext from "../../../../contexts/AuthContext";
import ProfileContext from "../../../../contexts/ProfileContext";
import LoadingIndicator from "../../../../components/Utils/LoadingIndicator";
import { Typography } from "@mui/material";

export const Profile = () => {
  const { profile, isLoading, fetchProfile, error } =
    React.useContext(ProfileContext);
  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!profile && user) {
      fetchProfile(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, profile]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error !== "") {
    return <Typography>{error}</Typography>;
  }
  return (
    <React.Fragment>
      <Hero />
      <General />
    </React.Fragment>
  );
};

export default Profile;
