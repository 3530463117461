import React from "react";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export const SideBar = ({ setActiveStep, activeStep, tabs, profileRoute, avatar }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(activeStep);

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={{ md: "200px", lg: "200px" }}
      >
        <Avatar
          alt=""
          src={avatar}
          sx={{
            width: 70,
            height: 70,
            background: COLORS.grey.secondary,
            marginBottom: 2,
          }}
        />
        <Box textAlign="left">
          <Link to={profileRoute}>
            <Typography
              className={"MuiTypography-profile-sidebar"}
              fontWeight={700}
              color="primary"
            >
              View Profile
            </Typography>
          </Link>
          {tabs.map((item, index) => (
            <Box mt={2} key={index}>
              <Typography
                className={"MuiTypography-profile-sidebar"}
                onClick={() => {
                  setSelectedIndex(index);
                  setActiveStep(index);
                }}
                fontWeight={index === selectedIndex ? 700 : null}
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};
