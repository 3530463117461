import React from 'react';
import { Link } from 'react-router-dom';
import COLORS from 'constants/colors';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import Check from './components/Check';
import { useEffect } from 'react';
import { useState } from 'react';
import AuthContext from 'contexts/AuthContext';
import ProfileContext from 'contexts/ProfileContext';
import LoadingIndicator from 'components/Utils/LoadingIndicator';
import apiRequest from 'api/apiRequest';
import urls from 'api/urls';
import { CircularProgress } from '@mui/material';
import { ROUTES } from 'constants/routes';
import { useTheme } from '@mui/material/styles';

export const Preferences = (props) => {
  const [preferences, setPreferences] = useState([]);
  const [userPreferences, setUserPreferences] = useState([]);
  const { user } = React.useContext(AuthContext);
  const { savePreferences, loadingButton } = React.useContext(ProfileContext);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [checkboxState, setCheckboxState] = useState({});

  const handleOnChange = (id) => {
    setCheckboxState({ ...checkboxState, [id]: !checkboxState[id] });
  };

  const saveChanges = () => {
    let preferencesArray = [];
    Object.keys(checkboxState).forEach((key) => {
      if (checkboxState[key]) {
        preferencesArray.push(parseInt(key));
      }
    });
    savePreferences(user, preferencesArray);
  };

  const fetchAllProfilePreferences = async () => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: `${urls.preferences}?filter[category][is]=Contact`,
      });
      setPreferences(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchUsersEnabledPreferences = async () => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: `${urls.userEnabledPreferences}/me${urls.preferences}`,
      });
      setUserPreferences(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (user) fetchAllProfilePreferences();
  }, [user]);

  useEffect(() => {
    if (preferences.length) fetchUsersEnabledPreferences();
  }, [preferences]);

  useEffect(() => {
    if (userPreferences.length) {
      const newCheckBoxState = {};
      for (let i = 0; i < userPreferences.length; i++) {
        const up = userPreferences[i];
        console.log(up);
        newCheckBoxState[up.id] = true;
      }
      setCheckboxState(newCheckBoxState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences, preferences]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Container>
        <Box>
          <Typography variant='h5' fontWeight='bold'>
            Preferences
          </Typography>
        </Box>
        <Box mt={3} mb={2}>
          <Typography fontWeight='bold'>Contact preferences</Typography>
        </Box>
        {preferences.map((pref) => (
          <Check
            id={pref.id}
            disabled={pref.is_verified_only ? 'disabled' : null}
            text={pref.description}
            onChange={() => handleOnChange(pref.id)}
            checked={checkboxState[pref.id]}
          />
        ))}
        <Box my={3}>
          <Divider />
        </Box>

        {/* {isUserProfile && (
          <>
            {" "}
            <Box mb={2}>
              <Typography fontWeight="bold">Privacy preferences</Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body2">
                Allow my profile to be visible to:
              </Typography>
            </Box>
            {preferences.map((pref) =>
              pref.category === "Privacy" && pref.id < 7 ? (
                <Check
                  id={pref.id}
                  disabled={pref.is_verified_only ? "disabled" : null}
                  text={pref.description}
                  onChange={() => handleOnChange(pref.id)}
                  checked={checked[pref.id]}
                />
              ) : (
                console.log("")
              )
            )}
          </>
        )} */}
        {/* <Box mb={2} mt={2}>
          <Typography variant="body2">Allow contact from:</Typography>
        </Box>
        {preferences.map((pref) =>
          pref.category === "Privacy" && pref.id >= 7 ? (
            <Check
              id={pref.id}
              disabled={pref.is_verified_only ? "disabled" : null}
              text={pref.description}
              onChange={() => handleOnChange(pref.id)}
              checked={checked[pref.id]}
            />
          ) : (
            console.log("")
          )
        )} */}

        <Box mt={2} sx={{ background: `${theme.palette.primary.main}40`, borderRadius: '10px' }}>
          <Typography color={COLORS.secondary.main} sx={{ p: 3 }} variant='body1'>
            *Note: Only verified enrolled families may connect with enrolled families on this platform. Learn more about
            our privacy policy and verification for enrolled families{' '}
            <Link style={{ color: COLORS.primary.main }} to={ROUTES.PRIVACY_POLICY}>
              here.
            </Link>
          </Typography>
        </Box>
        <Box mb={10} mt={5} display='flex' justifyContent='flex-end'>
          <Button
            sx={{ width: '233px', height: '60px' }}
            className='MuiButton-profile'
            variant='contained'
            color='secondary'
            disabled={loadingButton ? true : false}
            onClick={saveChanges}
          >
            {loadingButton ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Preferences;
