import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import COLORS from 'constants/colors';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'constants/routes';
import apiRequest from 'api/apiRequest';
import { useContext } from 'react';
import ProfileContext from 'contexts/ProfileContext';
import LoadingIndicator from 'components/Utils/LoadingIndicator';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Preferences = () => {
  const { profile } = useContext(ProfileContext);
  const [staffPreferences, setStaffPreferences] = useState([]);
  const [staffEnabled, setStaffEnabled] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const handleOnChange = (id) => {
    setStaffEnabled({ ...staffEnabled, [id]: !staffEnabled[id] });
  };

  const fetchStaffPreferences = async () => {
    setIsLoading(true);
    const response = await apiRequest({
      method: 'get',
      url: `/preferences?filter[category][is]=Privacy`,
    });
    setStaffPreferences(response);
    setIsLoading(false);
  };

  const fetchStaffEnabledPreference = async () => {
    setIsLoading(true);
    const response = await apiRequest({
      method: 'get',
      url: `/staff/${profile.id}/preferences`,
    });
    let enabled = {};
    response.data.forEach((item) => {
      enabled[item.id] = true;
    });
    setStaffEnabled({ ...enabled });
    setIsLoading(false);
  };

  const saveChanges = async () => {
    setLoadingButton(true);
    let preference_ids = [];
    for (const key in staffEnabled) {
      if (staffEnabled[key] === true) preference_ids.push(parseInt(key));
    }
    await apiRequest({
      method: 'put',
      url: `/families/${profile.family_id}/preferences`,
      data: { preference_ids },
    });
    setLoadingButton(false);
  };

  useEffect(() => {
    if (profile) {
      fetchStaffPreferences();
    }
  }, [profile]);

  useEffect(() => {
    if (staffPreferences.length) fetchStaffEnabledPreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffPreferences]);

  return (
    <React.Fragment>
      <Container>
        <Box>
          <Typography variant='h5' fontWeight='bold'>
            Preferences
          </Typography>
        </Box>
        <Box mt={3} mb={2}>
          <Typography fontWeight='bold'>Privacy preferences</Typography>
        </Box>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          staffPreferences?.map((fam) => (
            <Box item display='flex' alignItems='center'>
              <Checkbox
                size='small'
                checked={staffEnabled[fam.id] || false}
                onChange={() => handleOnChange(fam.id)}
                color='primary'
              />
              <Typography variant='body2'>{fam.description}</Typography>
            </Box>
          ))
        )}
        <Box mt={2} sx={{ background: `${theme.palette.primary.main}40`, borderRadius: '10px' }}>
          <Typography color={COLORS.secondary.main} sx={{ p: 3 }} variant='body1'>
            *Note: Only verified enrolled families may participate in this platform. Learn more about our privacy policy
            and verification for enrolled families{' '}
            <Link style={{ color: COLORS.primary.main }} to={ROUTES.PRIVACY_POLICY}>
              here.
            </Link>
          </Typography>
        </Box>
        <Box mb={10} mt={2} display='flex' justifyContent='flex-end'>
          <Button
            sx={{ width: '233px', height: '60px' }}
            className='MuiButton-profile'
            variant='contained'
            color='secondary'
            disabled={loadingButton ? true : false}
            onClick={saveChanges}
          >
            {loadingButton ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}{' '}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Preferences;
