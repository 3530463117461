import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import EditProfile from "../EditProfile";
import ProfileContext from "contexts/ProfileContext";
import useTheme from "@mui/material/styles/useTheme";
import ConfigurationContext from "contexts/ConfigurationContext";
import apiRequest from "api/apiRequest";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Close";

export const Hero = () => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = React.useState(false);
  const { familyProfile } = React.useContext(ProfileContext);
  const { configurations } = React.useContext(ConfigurationContext);

  const removeProfileImage = () => {
    apiRequest({
      method: "delete",
      url: "/family/profile-image",
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12} sm={12}>
            <Box>
              <Box pt={3} style={{position:'relative'}}>
                <Fab
                  color="primary"
                  onClick={() => removeProfileImage()}
                  sx={{
                    transform:'scale(0.5)',
                    position:'absolute',
                    right:'-2em',
                    top:'-1em',
                    backgroundColor: "#FFF",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                >
                  <AddIcon />
                </Fab>
                <img
                  src={
                    familyProfile?.profile_image?.path
                      ? familyProfile?.profile_image?.path
                      : configurations?.default_user_profile_image?.path
                      ? configurations?.default_user_profile_image?.path
                      : "/assets/images/family-profile.svg"
                  }
                  alt=""
                  width="100%"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            px={5}
            display="flex"
            justifyContent="center"
            alignItems={{ xs: "center", sm: "normal" }}
            flexDirection="column"
            xs={12}
            sm={12}
          >
            <Button
              sx={{ width: "208px", height: "50px" }}
              className="MuiButton-profile"
              variant="outlined"
              onClick={() => setIsOpen(true)}
            >
              Edit Profile Image
            </Button>
          </Grid>
        </Grid>
      </Container>
      <EditProfile setIsOpen={setIsOpen} isOpen={isOpen} />
    </React.Fragment>
  );
};

export default Hero;
