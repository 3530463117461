import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";
import LoadingIndicator from "../../components/Utils/LoadingIndicator";
import Grid from "@mui/material/Grid";
import { CraftJsonRenderer } from "../../components/PageBuilder";
import { ROUTES } from "../../constants/routes";

export const CustomPage = () => {
  const params = useParams();
  const [page, setPage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchPage();
  }, [window.location.pathname]);

  const fetchPage = async () => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: "GET",
        url: `${urls.pages}${params.pageUrl}`,
      });
      setPage(response.data);
    } catch (error) {
      if (error?.status === 401) {
        history.push(`${ROUTES.SIGN_IN}?redirect=page/${params.pageUrl}`);
      }

      if (error?.status === 404) {
        history.push("/not-found");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <LoadingIndicator />
      </Grid>
    );
  }

  return <CraftJsonRenderer craftJson={page?.pageContent} />;
};
