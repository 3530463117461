import React from "react";
// import COLORS from 'constants/colors';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import EditProfile from "../EditProfile";
import ProfileContext from "contexts/ProfileContext";

export const Hero = () => {
  const { profile } = React.useContext(ProfileContext);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12} sm={12}>
            <Box>
              <Box pt={3}>
                <img
                  src={
                    profile?.profile_image?.path ||
                    "/assets/images/profiles-admin.svg"
                  }
                  alt=""
                  width="100%"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            md={3}
            px={5}
            display="flex"
            justifyContent="center"
            alignItems={{ xs: "center", sm: "normal" }}
            flexDirection="column"
            xs={12}
            sm={12}
          >
            <Button
              sx={{ width: "208px", height: "50px" }}
              className="MuiButton-profile"
              variant="outlined"
              onClick={() => setIsOpen(true)}
            >
              Edit Profile Image
            </Button>
          </Grid>
        </Grid>
      </Container>
      <EditProfile setIsOpen={setIsOpen} isOpen={isOpen} />
    </React.Fragment>
  );
};

export default Hero;
