import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import { Link } from "react-router-dom";
import { MessagesTab } from "components/Global/Messages";

import { COLORS } from "constants/colors";
import { toast } from "react-toastify";

export const AmbassadorsProfileDetails = ({ profile, type }) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const theme = useTheme();
  const { configurations, schools, isFeatureEnabled } =
    useContext(ConfigurationContext);
  const linkedIn = profile.socialMedias?.find(
    (sm) => sm.media.toLowerCase().trim() === "linkedin"
  );
  const facebook = profile.socialMedias?.find(
    (sm) => sm.media.toLowerCase().trim() === "facebook"
  );
  const twitter = profile.socialMedias?.find(
    (sm) => sm.media.toLowerCase().trim() === "twitter"
  );

  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Box sx={{ position: "relative" }} display={{ xs: "none", md: "flex" }}>
          <Box sx={{ position: "absolute", top: 410, right: 20, zIndex: -1 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="216"
              height="216"
              viewBox="0 0 216 216"
              fill="none"
            >
              <circle
                r="93"
                transform="matrix(-1 0 0 1 108 108)"
                stroke={theme.palette.primary.main}
                stroke-opacity="0.3"
                stroke-width="30"
              />
            </svg>
          </Box>
        </Box>
        <Grid item container md={10} sm={12} mt={10}>
          {type === "ambassador" ? (
            <Box sx={{ position: "relative" }}>
              <Box sx={{ position: "absolute" }}>
                <img src="/assets/images/tag.png" alt="" />
              </Box>
              <Box sx={{ position: "absolute", top: 5, left: 15 }}>
                <Typography
                  fontSize={{ md: 17 }}
                  fontWeight={600}
                  color={COLORS.white.main}
                >
                  ·Ambassador·
                </Typography>
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Grid item md={4} sm={6} xs={12}>
            <Box
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.03)" }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  profile.profile_image
                    ? profile.profile_image.path
                    : configurations?.default_user_profile_image?.path ||
                      "/assets/images/family-profile.svg"
                }
                style={{
                  objectFit: "contain",
                  width: "320px",
                  height: "280px",
                }}
                alt={profile.lastName}
                title={profile.lastName}
                width="100%"
                height="auto"
              />
            </Box>
          </Grid>

          <Grid
            item
            container
            md={8}
            sm={6}
            justifyContent="center"
            paddingX={5}
            mt={4}
          >
            <Grid item md={6}>
              {profile?.lastName ? (
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  {profile.lastName}
                </Typography>
              ) : (
                ""
              )}
              <Box>
                <Typography
                  fontSize={{ md: 18 }}
                  fontWeight={600}
                  mt={2}
                  width={260}
                  wrap
                >
                  {profile?.email ? (
                    <a
                      href={`mailto:${profile.email}`}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        textDecoration: "none",
                      }}
                    >
                      {profile.email}
                    </a>
                  ) : (
                    ""
                  )}
                  {profile?.otherEmail ? (
                    <>
                      <br />
                      <a
                        href={`mailto:${profile.otherEmail}`}
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          textDecoration: "none",
                        }}
                      >
                        {profile.otherEmail}
                      </a>
                    </>
                  ) : (
                    ""
                  )}

                  <br />
                  {profile?.attending_school_since &&
                  configurations?.schoolShortName
                    ? `Member of the ${configurations?.schoolShortName} community since ${profile?.attending_school_since}`
                    : ""}

                  {profile?.class ? <div>{profile.class}</div> : []}
                </Typography>
                {profile?.members?.map((mem) =>
                  mem?.educations?.map((ed) => {
                    const foundSchool = schools.find(
                      (s) => s.id === ed.outcome_id
                    );
                    if (!foundSchool) return <React.Fragment></React.Fragment>;
                    return (
                      <>
                        <Typography
                          fontSize={{ md: 18 }}
                          fontWeight={400}
                          mt={4}
                        >
                          {mem?.first_name} went to{" "}
                          {foundSchool?.outcomeTitle || "School Not Found"}
                        </Typography>
                        {foundSchool?.url && (
                          <Box mt={2}>
                            <img
                              src={foundSchool?.url}
                              alt={foundSchool?.outcomeTitle}
                              height="30px"
                            />
                          </Box>
                        )}
                      </>
                    );
                  })
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: 2, md: 0 }}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isFeatureEnabled("family_message") && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: 50,
                    padding: 2,
                    width: 220,
                    color: "white",
                  }}
                  onClick={() => {
                    if (profile?.is_contactable_by_current_user) {
                      setIsMessageModalOpen(true);
                    } else {
                      toast.error(
                        "Please log in or sign up to send a message."
                      );
                    }
                  }}
                >
                  Message
                </Button>
              )}

              <Box display="flex" justifyContent="flex-end" marginX={2} mt={2}>
                {facebook ? (
                  <Link to={`//${facebook.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <img src="/assets/icons/circle.png" alt="" />
                        <img
                          src="/assets/icons/facebook.svg"
                          alt=""
                          style={{ position: "absolute", top: 8, left: 12 }}
                        />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ""
                )}

                {twitter ? (
                  <Link to={`//${twitter.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <img src="/assets/icons/circle.png" alt="" />
                        <img
                          src="/assets/icons/twitter.svg"
                          alt=""
                          style={{ position: "absolute", top: 8, left: 8 }}
                        />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ""
                )}

                {linkedIn ? (
                  <Link to={`//${linkedIn.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <img src="/assets/icons/circle.png" alt="" />
                        <img
                          src="/assets/icons/linkedIn.svg"
                          alt=""
                          style={{ position: "absolute", top: 8, left: 8 }}
                        />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby="Messages"
        aria-describedby="Messages"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <MessagesTab
            isNew={true}
            isModal={true}
            members={profile?.members ? profile?.members : []}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default AmbassadorsProfileDetails;
