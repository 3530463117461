const delbartonContent = {
  programName: "delbarton",
  logo: "/assets/images/logo-delbarton.png",
  home_hero_image: "assets/images/home-hero-delbarton.jpg",
  home_hero_subtitle: schoolName =>
    `He is called to excellence. At ${schoolName}, we will answer his call.`,
  default_family_profile_image: "/assets/images/family-profile-delbarton.jpg",
  auth_screen_image: "/assets/images/auth-screen-delbarton.jpg",
};

const sawgrassContent = {
  programName: "sawgrass",
  logo: "/assets/images/logo-sawgrass.png",
  home_hero_image: "assets/images/home-hero-sawgrass.jpg",
  home_hero_subtitle: null,
  auth_screen_image: "/assets/images/auth-screen-sawgrass.jpg",
};

const rtecContent = {
  programName: "rtec",
  home_hero_image: "assets/images/RTEC-Hero.png",
  home_hero_title: "Explore How River Terrace Helps Exceptional Children Soar!",
};

export const PROGRAM_CONTENT = {
  "https://localhost:4443/api": delbartonContent,
  "delbarton.think-12.com": delbartonContent,
  "app.dev.think-12.com": delbartonContent,
  "app.staging.think-12.com": delbartonContent,
  "devdemo.dev.think-12.com": delbartonContent,
  "sawgrass.think-12.com": sawgrassContent,
  "rtec.dev.think-12.com": rtecContent,
  "rtec.think-12.com": rtecContent,
};
