import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingIndicator() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2em', marginBottom: '2em' }}>
      <CircularProgress />
    </Box>
  );
}

export default LoadingIndicator;
