import React from "react";
import { useHistory } from "react-router-dom";

import COLORS from "constants/colors";
import { ROUTES } from "constants/routes";
import AuthLandingScreen from "pages/ManageAuth/components/AuthLandingScreen";
import AuthSignInForm from "pages/ManageAuth/components/AuthSignInForm";

export const ClubProfileSignIn = () => {
  const history = useHistory();
  return (
    <AuthLandingScreen
      height="850px"
      headerTitle="ClubHub Profile"
      bgColor={COLORS.common.graduate}
      image="assets/images/GraduateImage.png"
    >
      <AuthSignInForm
        OR
        googleButton
        title="Welcome Back!"
        authButtonText="Sign In"
        navigationButtonText="Sign Up Here"
        navigationButtonTextAction={() => history.push(ROUTES.SIGN_UP)}
      />
    </AuthLandingScreen>
  );
};

export default ClubProfileSignIn;
