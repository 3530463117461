// import { Grid } from "@mui/material";
import React, { useRef } from 'react';
import Avatar, { genConfig } from 'react-nice-avatar';
import { Button, CircularProgress } from '@mui/material';
import { toBlob } from 'html-to-image';
import { toast } from 'react-toastify';

export const NiceAvatar = (props) => {
  const {
    bgColor,
    faceColor,
    hairColor,
    hairStyle,
    // hatColor,
    showHat,
    showGlasses,
    shirtStyle,
    shirtColor,
    setUploadFile,
  } = props;
  const avatarRef = useRef();
  const [showProgress, setShowProgress] = React.useState(false);

  const config = {
    sex: 'man',
    hairStyle,
    faceColor,
    eyeStyle: 'circle',
    earSize: 'small',
    noseStyle: 'round',
    mouthStyle: 'peace',
    shirtStyle,
    glassesStyle: showGlasses ? 'round' : 'none',
    hairColor,
    hatStyle: showHat ? 'beanie' : 'none',
    hatColor: '#C42C31',
    eyeBrowStyle: 'up',
    shirtColor,
    bgColor,
  };
  const myConfig = genConfig(config);

  const createImage = (e) => {
    e.preventDefault();
    toBlob(avatarRef.current)
      .then(async (blob) => {
        setShowProgress(true);
        setUploadFile(blob);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div>
      <div ref={avatarRef}>
        <Avatar
          shape='square'
          {...myConfig}
          style={{ objectFit: 'cover', width: '100%', height: '250px' }}
          id='created-avatar'
        />
      </div>
      <Button
        disabled={showProgress}
        variant='contained'
        sx={{
          p: 2,
          width: '100%',
          borderRadius: 47,
          bgcolor: 'secondary.main',
          marginTop: '10px',
        }}
        onClick={createImage}
      >
        {showProgress ? <CircularProgress /> : 'Confirm'}
      </Button>
    </div>
  );
};
