export const PROFILE_ACTIVITIES = [
  {
    name: 'Biking',
    icon: '/assets/icons/activity-icon1.svg',
  },
  {
    name: 'Running',
    icon: '/assets/icons/activity-icon2.svg',
  },
  {
    name: 'Yoga',
    icon: '/assets/icons/activity-icon3.svg',
  },
  {
    name: 'Volleyball',
    icon: '/assets/icons/activity-icon4.svg',
  },
  {
    name: 'Hiking',
    icon: '/assets/icons/activity-icon5.svg',
  },
  {
    name: 'Tennis',
    icon: '/assets/icons/activity-icon6.svg',
  },
  {
    name: 'Ping Pong',
    icon: '/assets/icons/activity-icon7.svg',
  },
  {
    name: 'Ski',
    icon: '/assets/icons/activity-icon8.svg',
  },
  {
    name: 'Ice Skating',
    icon: '/assets/icons/activity-icon9.svg',
  },
  {
    name: 'Swimming',
    icon: '/assets/icons/activity-icon10.svg',
  },
  {
    name: 'Basketball',
    icon: '/assets/icons/activity-icon11.svg',
  },
  {
    name: 'Gym',
    icon: '/assets/icons/activity-icon12.svg',
  },
  {
    name: 'Football',
    icon: '/assets/icons/activity-icon13.svg',
  },
  {
    name: 'Baseball',
    icon: '/assets/icons/activity-icon14.svg',
  },
  {
    name: 'Gymnastics',
    icon: '/assets/icons/activity-icon15.svg',
  },
  {
    name: 'Boxing',
    icon: '/assets/icons/activity-icon16.svg',
  },
  {
    name: 'Badminton',
    icon: '/assets/icons/activity-icon17.svg',
  },
  {
    name: 'Boot Camp',
    icon: '/assets/icons/activity-icon18.svg',
  },
  {
    name: 'Jump Rope',
    icon: '/assets/icons/activity-icon19.svg',
  },
];

export const PROFILE_CLUB = [
  {
    name: 'Book Club',
    date: 'Updated on Jan 13 · 2022',
    image: '/assets/images/profile-club-image1.png',
  },
  {
    name: 'Skateboarding Club',
    date: 'Updated on Dec 5 · 2022',
    image: '/assets/images/profile-club-image2.png',
  },
  {
    name: 'School Magazine Club',
    date: 'Updated on Jan 20 · 2022',
    image: '/assets/images/profile-club-image3.png',
  },
];

export const CONTACT_PREFERENCES = {
  TEXT1: `I would like to receive messages from other verified enrolled families.`,
  TEXT2: `I would like to receive messages`,
  TEXT3: `We would like to receive messages with general tips and advice for parents from X Program`,
};

export const HELP_TEXT = [
  {
    title: `Can I edit my profile sections?`,
    text: `Yes, you can edit your profile sections (including the Coalition Essay section)
     after starting college-specific application questions.
   Edits will affect all in-progress/unsubmitted applications.`,
  },
  {
    title: `Additional Information Section`,
    text: `The Additional Information section is a required page on your profile asking you
     to self-report anything else you would like to add to your application. `,
  },
  {
    title: `About The Profile Sections`,
    text: `The Profile Sections were designed by members of the school to make it easy for you to enter
     and organize basic information about yourself,
      your family, your interests, and your academic record in a single place.`,
  },
];

export const SCHOOL_TABLE = [
  {
    school: 'Alaska Universiy',
    state: 'AK',
  },
  {
    school: 'Alaska Fairbanks Universiy',
    state: 'AK',
  },
  {
    school: 'Arkansas Fort Smith Universiy',
    state: 'AK',
  },
  {
    school: 'Alabama A&M school',
    state: 'AL',
  },
  {
    school: 'Arkansas Fort Smith Universiy',
    state: 'AL',
  },
  {
    school: 'Auburn University',
    state: 'AL',
  },
];
export const FAMILIES_TABLE = [
  {
    id: 1,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '11th ,', '12th'],
  },
  {
    id: 2,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,'],
    grads: ['9th ,', '10th ,', '11th ,', '12th'],
  },
  {
    id: 3,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '10th ,', '12th'],
  },
  {
    id: 4,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '10th ,', '11th ,'],
  },
  {
    id: 5,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '11th ,', '12th'],
  },
  {
    id: 6,
    family_name: 'tmantle0',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '10th ,', '11th ,', '12th'],
  },
  {
    id: 7,
    family_name: 'ktomasicchio6',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '10th ,', '11th ,', '12th'],
  },
  {
    id: 8,
    family_name: 'rketton7',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Jim'],
    grads: ['9th ,', '10th ,', '12th'],
  },
  {
    id: 9,
    family_name: 'bstainburn8',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,'],
  },
  {
    id: 10,
    family_name: 'ktenney9',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,'],
    grads: ['9th ,', '10th ,', '12th'],
  },
  {
    id: 11,
    family_name: 'gmacguinessa',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Jim'],
    grads: ['9th ,', '10th ,', '11th ,'],
  },
  {
    id: 12,
    family_name: 'mdibbsb',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '11th ,', '12th'],
  },
  {
    id: 13,
    family_name: 'shalec',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '11th ,', '12th'],
  },
  {
    id: 14,
    family_name: 'frikkardd',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '10th ,', '11th ,', '12th'],
  },
  {
    id: 15,
    family_name: 'tanderse',
    cartoons: 'assets/icons/threeCartoons.jpg',
    family_members: ['Josh ,', 'Anna ,', 'Lisa ,', 'Jim'],
    grads: ['9th ,', '12th'],
  },
];
export const GRADUATE_PROFILE_LABELS = [
  {
    id: 1,
    label: 'Profile',
  },
  { id: 2, label: 'Password' },
  { id: 3, label: 'Messages' },
  { id: 4, label: 'Preferences' },
  { id: 5, label: 'Sign Out' },
];
export const STAFF_PROFILE_LABELS = [
  { id: 1, label: 'Staff Profile' },
  // { id: 1, label: 'Password' },
  // { id: 2, label: 'Messages' },
  // { id: 2, label: 'My Clubs' },
  { id: 2, label: 'Preferences' },
  // { id: 4, label: 'Help' },
  // { id: 5, label: 'Sign Out' },
];

export const FAMILY_PROFILE_LABELS = [
  { id: 1, label: 'Family Profile' },
  { id: 2, label: 'Preferences' },
  // { id: 3, label: 'Messages' },
  // {
  //   id: 4,
  //   label: 'Liked Articles (22)',
  // },
  // { id: 5, label: 'My Clubs' },
  // { id: 6, label: 'Preferences' },
  // { id: 6, label: 'Help' },
  // { id: 7, label: 'Sign Out' },
];

export const CLUB_PROFILE_LABELS = [
  { id: 1, label: 'My Clubs' },
  { id: 1, label: 'Messages' },
  { id: 2, label: 'Settings' },
  { id: 3, label: 'Preferences' },
  { id: 4, label: 'Sign Out' },
];
export const USER_PROFILE_LABELS = [
  { id: 1, label: 'Profile' },
  { id: 1, label: 'Password' },
  { id: 2, label: 'Messages' },
  { id: 3, label: 'My Clubs' },
  { id: 4, label: 'Preferences' },
  { id: 5, label: 'Log Out' },
];

export const PASSWORD_REQUIREMENTS = [
  `Minimum 8 characters`,
  ` At least 1 capital letter`,
  `At least 1 number`,
  `Can not be the same as previous`,
];

export const MESSAGE_PLACEHOLDER = {
  TEXT: `Hello Jane, We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He’s learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He’s learning so much in your class this year.`,
};

export const SELECT_ICONS = [
  `/assets/icons/cartoon-icon1.svg`,
  `/assets/icons/cartoon-icon2.svg`,
  `/assets/icons/cartoon-icon3.svg`,
  `/assets/icons/cartoon-icon4.svg`,
  `/assets/icons/cartoon-icon5.svg`,
  `/assets/icons/cartoon-icon6.svg`,
  `/assets/icons/cartoon-icon7.svg`,
  `/assets/icons/cartoon-icon8.svg`,
  `/assets/icons/cartoon-icon9.svg`,
];
