import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import ConfigurationContext from "contexts/ConfigurationContext";

export const About = ({
  formData,
  updateFormData,
  withQuote = true,
  withAbout = true,
  withAdvise = true,
  withFavExperience = true,
}) => {
  const { configurations } = React.useContext(ConfigurationContext);
  const updateAbout = (aboutKey, aboutValue) => {
    const abouts = formData.about ? [...formData.about] : [];
    const foundAbout = abouts.find(a => a.name === aboutKey);
    if (foundAbout) foundAbout.value = aboutValue;
    else abouts.push({ name: aboutKey, value: aboutValue });

    updateFormData("about", abouts);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            About
          </Typography>
        </Box>
        <Grid container mt={2} spacing={3}>
          {withAbout && (
            <Grid item md={12} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                About me
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  placeholder="Tell us a little about yourself"
                  multiline={true}
                  rows={5}
                  InputProps={{ disableUnderline: false }}
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  id="name"
                  name="name"
                  className="family-input-fields"
                  sx={{ mb: 2 }}
                  value={formData?.about?.find(a => a?.name === "about_me")?.value}
                  onChange={e => updateAbout("about_me", e.target.value)}
                ></TextField>
              </FormControl>
            </Grid>
          )}
          {withFavExperience && (
            <Grid item md={12} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Favorite Experience at {configurations?.schoolShortName || ""}
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  placeholder={`Tell us about your experience at ${configurations?.schoolShortName || ""}`}
                  multiline={true}
                  rows={5}
                  InputProps={{ disableUnderline: false }}
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  id="name"
                  name="name"
                  className="family-input-fields"
                  sx={{ mb: 2 }}
                  value={formData?.about?.find(a => a?.name === "favorite_experience")?.value}
                  onChange={e => updateAbout("favorite_experience", e.target.value)}
                ></TextField>
              </FormControl>
            </Grid>
          )}
          {withAdvise && (
            <Grid item md={12} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Advice for Future {configurations?.schoolShortName || ""} Students
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  placeholder={`What advice would you share with future ${
                    configurations?.schoolShortName || ""
                  } students?`}
                  multiline={true}
                  rows={5}
                  InputProps={{ disableUnderline: false }}
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  id="name"
                  name="name"
                  className="family-input-fields"
                  sx={{ mb: 2 }}
                  value={formData?.about?.find(a => a?.name === "advice")?.value}
                  onChange={e => updateAbout("advice", e.target.value)}
                ></TextField>
              </FormControl>
            </Grid>
          )}
          {withQuote && (
            <Grid item md={12} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Add a quote
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  multiline={true}
                  rows={5}
                  InputProps={{ disableUnderline: false }}
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  id="name"
                  name="name"
                  className="family-input-fields"
                  sx={{ mb: 2 }}
                  value={formData?.about?.find(a => a?.name === "quote")?.value}
                  onChange={e => updateAbout("quote", e.target.value)}
                ></TextField>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default About;
