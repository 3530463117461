import React from "react";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const ProfileDetailsActivities = ({ profile }) => {
  const { activities, fetchActivities } = React.useContext(ConfigurationContext);

  React.useEffect(() => {
    if (activities.length === 0) fetchActivities();
  }, [activities, fetchActivities]);

  if (profile?.activities?.length === 0) return;

  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid item container md={10}>
          <Typography fontSize={{ md: 40 }} fontWeight={700} mt={1} p={{ xs: 4, md: 0 }}>
            Activities I Like
          </Typography>
          <Grid item container md={12} mt={4}>
            {profile?.activities?.map(item => (
              <>
                <Grid item md={1.5} xs={6}>
                  <Box display="flex" alignItems="center" flexDirection="column" mb={4}>
                    {/* <img src={`/assets/icons/activity-icon${item.id}.svg`} alt='' height='50px' /> */}
                    <img
                      className="activity-icon"
                      src={activities.find(a => a.id === item.id)?.icon.path}
                      alt={item?.name}
                    />
                    <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2}>
                      {item?.name}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
