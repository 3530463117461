import _ from 'lodash';

export const getPrime = (min, max) => {
  const result = Array(max + 1)
    .fill(0)
    .map((_, i) => i);
  for (let i = 2; i <= Math.sqrt(max + 1); i++) {
    for (let j = i ** 2; j < max + 1; j += i) delete result[j];
  }
  return _.sample(Object.values(result.slice(Math.max(min, 2))));
};
