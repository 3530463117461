import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const StaffAboutAndCommitment = ({ profile, type }) => {
  const foundAbout = profile?.staff_profile?.about?.fields?.find(a => a.name === "about_me");
  const foundCommitment = profile?.staff_profile?.about?.fields?.find(
    a => a.name === "commitment_to_child"
  );
  if (!foundAbout && !foundCommitment) return;

  return (
    <Box>
      {foundAbout ? (
        <>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                About Me
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {foundAbout.value}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}

      {foundCommitment ? (
        <>
          <Grid container maxWidth="xl" justifyContent="center" style={{ marginBottom: "20px" }}>
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                My Commitment to Your Child
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {foundCommitment.value}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default StaffAboutAndCommitment;
