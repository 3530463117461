import React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import ConfigurationContext from 'contexts/ConfigurationContext';
import moment from 'moment';
import { FormControlLabel } from '@mui/material';

export const General = ({ formData, updateFormData }) => {
  const { configurations } = React.useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            General
          </Typography>
          {/* <img src='/assets/icons/profile-edit-icon.svg' alt='' style={{ cursor: 'pointer' }} /> */}
        </Box>
        <Grid container mt={2} spacing={3}>
          <Grid item md={8} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Family Last Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                fullWidth
                id='last-name'
                name='last-name'
                className='family-input-fields'
                sx={{ mb: 2 }}
                value={formData.last_name}
                onChange={(e) => updateFormData('last_name', e.target.value)}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Member of the {configurations.schoolShortName ? configurations.schoolShortName : ''} community since*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                select
                value={formData.attending_school_since}
                onChange={(e) => updateFormData('attending_school_since', e.target.value)}
              >
                <MenuItem>
                  <em>Select Year</em>
                </MenuItem>
                {Array.from({ length: 50 }, (_, i) => String(i + (Number(moment().format('YYYY')) - 49)))
                  .reverse()
                  .map((item = '') => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Primary Email Address*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                required
                fullWidth
                id='work-email'
                name='work-email'
                className='family-input-fields'
                value={formData.primary_email}
                onChange={(e) => updateFormData('primary_email', e.target.value)}
              ></TextField>
            </FormControl>
            {/* preference_ids/ */}
            {/* <Box display="flex" alignItems="center">
              <Checkbox size="small" value="check" color="primary" />
              <Typography variant="caption" color="text.secondary">
                Allow this email addresses to be visible in our profile
              </Typography>
            </Box>*/}
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color='primary' />}
                label='Allow this email address to be visible in our profile'
                checked={formData.is_primary_email_visible}
                onChange={(e) => updateFormData('is_primary_email_visible', e.target.checked)}
              />
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Other Email Address
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                required
                fullWidth
                id='other-email'
                name='other-email'
                className='family-input-fields'
                value={formData.secondary_email}
                onChange={(e) => updateFormData('secondary_email', e.target.value)}
              ></TextField>
            </FormControl>
            {/* <Box display="flex" alignItems="center">
              <Checkbox size="small" value="check" color="primary" />
              <Typography variant="caption" color="text.secondary">
                Allow this email addresses to be visible in our profile
              </Typography>
            </Box> */}
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color='primary' />}
                label='Allow this email address to be visible in our profile'
                checked={formData.is_secondary_email_visible}
                onChange={(e) => updateFormData('is_secondary_email_visible', e.target.checked)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default General;
