import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export const AlphabeticFilter = ({ onSearch }) => {
  const theme = useTheme();
  const [prefix, setPrefix] = useState(null);

  useEffect(() => {
    if (prefix) {
      onSearch(prefix);
    }
  }, [prefix]);

  return (
    <Container>
      <Box
        display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }}
        alignItems={"center"}
        mt={3}
        sx={{ justifyContent: "center" }}
      >
        {ALPHABETS.map(data => (
          <Box key={data} ml={1}>
            <Typography
              sx={{ px: 2, cursor: "pointer", fontWeight: "bold" }}
              label={data}
              variant={data === prefix ? "filled" : "outlined"}
              color="secondary"
              onClick={() => setPrefix(data)}
            >
              {data}
            </Typography>
            <Box
              sx={{
                width: "10px",
                height: "3px",
                backgroundColor: data === prefix ? theme.palette.secondary.main : "transparent",
                marginTop: "5px",
                marginX: "auto",
                borderRadius: "10px",
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const ALPHABETS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
