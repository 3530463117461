import React, { useContext, useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { PasswordStorage } from "../../constants/password";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Alert, Dialog, DialogContent, DialogContentText } from "@mui/material";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";

export const PasswordNotSetDialog = () => {
  const { user, isLoading, setAuthToken, setToken } = useAuthContext();
  const { configurations } = useContext(ConfigurationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    loadPasswordReminder();
  }, [user, JSON.parse(localStorage.getItem(PasswordStorage.PASSWORD_NOT_SET_FLAG))]);

  const loadPasswordReminder = () => {
    if (user && !user.is_password_set && localStorage) {
      const sessionValue = JSON.parse(localStorage.getItem(PasswordStorage.PASSWORD_NOT_SET_FLAG));

      if (sessionValue !== false) {
        setTimeout(() => {
          setIsOpen(true);
        }, 3000);
      }
    }

    if (!user) {
      setIsOpen(false);
    }
  };

  if ((!user && isLoading) || !isOpen) {
    return null;
  }

  const handleCloseDialog = e => {
    e.preventDefault();
    localStorage.setItem(PasswordStorage.PASSWORD_NOT_SET_FLAG, JSON.stringify(false));
    setIsOpen(false);
  };

  const handleChangePassword = async e => {
    e.preventDefault();
    e.stopPropagation();
    setError("");
    const formData = new FormData(formRef.current);
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    if (password !== confirmPassword) {
      setError("Passwords do NOT match!");
      return;
    }

    const response = await apiRequest({
      method: "put",
      url: urls.changePassword,
      data: {
        password_current: password,
        password,
        password_confirmation: confirmPassword,
      },
    });
    setIsModalOpen(false);
    localStorage.setItem(PasswordStorage.PASSWORD_NOT_SET_FLAG, JSON.stringify(false));
    setIsOpen(false);
    const token = `Bearer ${response.data.access_token}`;
    localStorage.setItem("token", token);
    setAuthToken(token);
    setToken(token);
  };

  return (
    <>
      <Box
        onClick={e => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        sx={{
          position: "fixed",
          bottom: "13px",
          "@media (max-width:680px)": {
            bottom: "85px",
          },
          left: "2rem",
          background: "#fff",
          padding: 2,
          borderRadius: 2,
          paddingTop: 3,
          paddingRight: 4,
          boxShadow: "0px 0px 11px -1px rgba(0,0,0,0.75)",
          cursor: "pointer",
        }}
        display="flex"
      >
        <Box>
          <Typography>Reminder: Set your password!</Typography>
        </Box>
        <Box
          onClick={handleCloseDialog}
          position="absolute"
          sx={{ right: 4, top: 4, cursor: "pointer" }}
        >
          <CloseIcon fontSize="small" />
        </Box>
      </Box>
      <Dialog maxWidth="xs" fullWidth open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <IconButton
          onClick={() => setIsModalOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText>
            Set a password so you can easily access {configurations?.programName} again in the
            future.
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert sx={{ mb: 2 }} severity="error">
                Passwords do NOT match!
              </Alert>
            )}
            <form onSubmit={handleChangePassword} ref={formRef}>
              <Grid item md={6} xs={12} sm={6}>
                <InputLabel className="MuiInputLabel-Profile-label" shrink>
                  Password
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    name="password"
                    size="small"
                    InputProps={{ disableUnderline: "false" }}
                    hiddenLabel
                    variant="filled"
                    fullWidth
                    color="secondary"
                    sx={{ mb: 2 }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} sm={6}>
                <InputLabel className="MuiInputLabel-Profile-label" shrink>
                  Confirm Password
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    size="small"
                    InputProps={{ disableUnderline: "false" }}
                    hiddenLabel
                    variant="filled"
                    fullWidth
                    color="secondary"
                    sx={{ mb: 2 }}
                  />
                </FormControl>
              </Grid>
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  type="submit"
                  size="large"
                  sx={{ p: 8, height: 40 }}
                  className="MuiButton-profile"
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
