import React, { useEffect } from "react";
import UploadControl from "components/UploadControl";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import COLORS from "constants/colors";
import {
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  TextField,
} from "@mui/material";
import apiRequest from 'api/apiRequest';
import ImageUpload from "components/ImageUpload";

export const AddStaffModal = (props) => {
  const { isShowStaffModal, setIsShowStaffModal } = props || {};
  const [search, setSearch] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedStaff, setSelectedStaff] = React.useState({});
  const [image, setImage] = React.useState(null);
  const [title, setTitle] = React.useState("");

  useEffect(() => {
    apiRequest({
      method: 'get',
      url: '/admin/users/search?page=0&limit=10&searchKey=' + search + '&roles=Club',
    }).then((res) => {
      setSearchResults(res.data);
    });
  }, [search]);

  const confirm = () => {
    setIsShowStaffModal(false);
    if (props.onConfirm) {
      props.onConfirm({
        user: selectedStaff,
        user_id: selectedStaff.id,
        image: image,
        image_id: (image) ? image.id : null,
        title: title,
    });
    }
  };

  return (
    <Modal open={isShowStaffModal} onClose={() => setIsShowStaffModal(false)}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            borderBottom: "1px solid gray",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            New Club Leader
          </Typography>
          <Box
            onClick={() => setIsShowStaffModal(false)}
            sx={{
              cursor: "pointer",
              display: "flex",
              background: "#F2F2F2",
              width: "30px",
              height: "30px",
              borderRadius: "15px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseOutlinedIcon color="blue" />
          </Box>
        </Box>
        <Grid container spacing={3} p={5}>
          <Grid item md={6} sm={6} xs={12} p={2}>
          <ImageUpload image={null} ownerType="Club" ownerId={props.clubId}
                title=""
                icon="/assets/icons/modalFileIcon.svg"
                title1="Drop your file(s) here or"
                title2="browse"
                bgColor="#F2F2F2"
                height={"300px"}
                padding={5}
                onChange={(image) => (image) ? setImage(image) : []}
              />
          </Grid>
          <Grid item md={6} sm={6} xs={12} pr={4}>
            <Box
              component="form"
              sx={{
                display: "flex",
                bgcolor: COLORS.white.light,
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon sx={{ color: COLORS.blue.secondary }} />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            <Box mt={2}>
                {searchResults.slice(0,5).map((user) => (
                  <Box
                    key={user.id}
                    sx={{
                      paddingLeft: '1em',
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                  <InputBase name="staffId" type="radio" value={user.id} onClick={() => {
                    setSelectedStaff(user);
                  }} />
                  <Typography variant="body2" fontWeight={600} textAlign="left" width={"80%"}>
                    {user.first_name} {user.last_name}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    
                  </Typography>
                  </Box>
                ))}
            </Box>
            <Grid item md={12} mt={5}>
              <Box display="flex">
                <Box>
                  <InputLabel className="MuiInputLabel-Profile-label" shrink>
                    First Name*
                  </InputLabel>
                  <FormControl>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="filled"
                      required
                      value={selectedStaff.first_name}
                      id="first-name"
                      name="first-name"
                      sx={{ mb: 2 }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{ ml: 3 }}
                    className="MuiInputLabel-Profile-label"
                    shrink
                  >
                    Last Name*
                  </InputLabel>
                  <FormControl>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="filled"
                      required
                      value={selectedStaff.last_name}
                      id="last-name"
                      name="last-name"
                      sx={{ mb: 2, ml: 3 }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mt={5}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Title*
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  InputProps={{ disableUnderline: "false" }}
                  onChange={(e) => setTitle(e.target.value)}
                  variant="filled"
                  required
                  id="last-name"
                  name="last-name"
                  sx={{ mb: 2 }}
                  placeholder="English Teacher"
                />
              </FormControl>
            </Grid>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Button
                sx={{ width: "160px", height: "50px", mr: 2 }}
                className="MuiButton-profile"
                variant="outlined"
                onClick={() => setIsShowStaffModal(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: "160px", height: "50px", px: 5 }}
                className="MuiButton-profile"
                variant="contained"
                onClick={() => confirm()}
              >
                Confirm
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default AddStaffModal;

const style = {
  position: "absolute",
  top: '50%',
  left: "50%",
  width: { xs: "100%", sm: "80%", md: "auto", lg: "auto" },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 10,
  borderRadius: "8px",
  marginTop: { md: "80px", lg: "80px", sm: "0px", xs: "0px" },
};
