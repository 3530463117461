import React from 'react';
import COLORS from 'constants/colors';
import {
  Box,
  Grid,
  Button,
  Container,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { PASSWORD_REQUIREMENTS } from 'constants/profiles';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { changePasswordSchema } from 'validations/schemas';
import AuthContext from 'contexts/AuthContext';
import { useTheme } from '@mui/material/styles';

export const Password = ({ profile }) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { changePassword } = React.useContext(AuthContext);
  const theme = useTheme();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(changePasswordSchema),
  });

  const onSubmitHandle = async (values) => {
    if (!isUpdating) {
      try {
        setIsUpdating(true);
        try {
          await changePassword(values.currentPassword, values.password, values.confirmPassword);
          toast.success('Password Successfully Changed!');
        } catch (error) {
          toast.error(error);
        }
        setIsUpdating(false);
      } catch (error) {
        toast.error('Something went wrong!', {});
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Typography variant='h5' fontWeight='bold'>
          Password
        </Typography>
        <Grid container mt={5}>
          <Grid item md={12} xs={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Current Password*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                required
                fullWidth
                type='password'
                sx={{ mb: 2 }}
                error={!!errors['currentPassword']}
                helperText={errors['currentPassword'] ? errors['currentPassword'].message : ''}
                {...register('currentPassword')}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              New Password*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                required
                fullWidth
                type='password'
                sx={{ mb: 2 }}
                error={!!errors['password']}
                helperText={errors['password'] ? errors['password'].message : ''}
                {...register('password')}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Confirm Password*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                required
                fullWidth
                type='password'
                sx={{ mb: 2 }}
                error={!!errors['confirmPassword']}
                helperText={errors['confirmPassword'] ? errors['confirmPassword'].message : ''}
                {...register('confirmPassword')}
              />
            </FormControl>
          </Grid>
          <Box
            width='100%'
            mt={2}
            p={3}
            sx={{
              borderRadius: '10px',
              background: `${theme.palette.primary.main}40`,
            }}
          >
            <Typography mb={3} color={COLORS.secondary.main} variant='body2'>
              *Rules for passwords: To create a new password, you have to meet all of the following requirements:
            </Typography>
            {PASSWORD_REQUIREMENTS.map((data) => (
              <Typography color={COLORS.secondary.main} variant='body2'>
                {data}
              </Typography>
            ))}
          </Box>

          <Grid item md={12} xs={12} mb={10} mt={4}>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                sx={{ width: '233px', height: '60px' }}
                className='MuiButton-profile'
                variant='contained'
                color='secondary'
                onClick={handleSubmit(onSubmitHandle)}
                disabled={isUpdating}
              >
                {isUpdating ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Password;
