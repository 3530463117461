import { useEffect } from "react";
import { FAMILY_FILTER } from "../../constants/StorageKeys";

export const useBrowserUnLoad = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", ev => {
      localStorage.removeItem(FAMILY_FILTER);
    });

    return () => window.removeEventListener("beforeunload", null);
  }, []);
};
