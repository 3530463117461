import React from "react";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import { Divider, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";

const Activities = ({ formData, updateFormData, allActivities }) => {
  const theme = useTheme();
  const [selectedActivities, setSelectedActivities] = React.useState(formData.activity_ids || []);

  const handleUpdateActivities = activityId => {
    let newSA;
    if (selectedActivities.includes(activityId)) {
      newSA = selectedActivities.filter(a => a !== activityId);
    } else {
      newSA = [...selectedActivities, activityId];
    }
    updateFormData("activity_ids", newSA);
    setSelectedActivities(newSA);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography mt={5} variant="body2">
          Select at least 3
        </Typography>
        <Typography mb={4} variant="h5" fontWeight="bold">
          Activities I Like
        </Typography>
        <Grid container spacing={2}>
          {allActivities?.map((data, index) => (
            <Grid key={data.id} item md={2}>
              <Badge
                className="MuiBadge-Profile-badge"
                badgeContent={
                  (formData.activity_ids || selectedActivities || []).includes(data.id) ? (
                    <DoneIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: "pointer",
                        backgroundColor: `#15DA7B`,
                        color: `white`,
                        borderRadius: "15px",
                      }}
                    />
                  ) : (
                    <AddIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: "pointer",
                        backgroundColor: `${theme.palette.primary.main}`,
                        color: `white`,
                        borderRadius: "15px",
                      }}
                    />
                  )
                }
              >
                <Box
                  bgcolor={COLORS.white.secondary}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  alignItems="center"
                  borderRadius={"100%"}
                  width="100px"
                  height="100px"
                >
                  <img src={data.icon.path} alt="" width="30px" />
                  <Typography color={COLORS.dark.lighter} variant="caption">
                    {data.name}
                  </Typography>
                </Box>
              </Badge>
            </Grid>
          ))}
        </Grid>
        <Box my={5}>
          <Divider />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Activities;
