import React, { useState } from "react";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";

const AddSocialLinks = ({ setIsAddSocialLinks, updateFormData, formData }) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");
  const [profileLink, setProfileLink] = useState("");

  const addProfile = () => {
    if (selectedSocialMedia.trim() !== "" && profileLink.trim() !== "") {
      setIsAddSocialLinks(false);
      const newSM = [
        ...formData.social_media_accounts,
        { provider: selectedSocialMedia, url: profileLink },
      ];
      updateFormData("social_media_accounts", newSM);
    }
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          borderRadius: "5px",
          border: `1px solid ${COLORS.white.secondary}`,
          p: 5,
          borderTop: `5px solid ${COLORS.primary.main}`,
          position: "relative",
        }}
        elevation={0}
      >
        <img
          src="/assets/icons/modal-arrow-up.svg"
          alt=""
          width="25px"
          style={{ position: "absolute", top: "-5%", left: "1%" }}
        />
        <Box>
          <Typography variant="h6">Add a new social network link</Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item md={5}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Social Network
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedSocialMedia}
                  onChange={(e) => setSelectedSocialMedia(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select Social Network</em>
                  </MenuItem>
                  <MenuItem value={"Instagram"}>
                    <Box sx={{ display: "flex" }}>
                      <InstagramIcon sx={{ mr: 2 }} /> Instagram
                    </Box>
                  </MenuItem>
                  <MenuItem value={"Youtube"}>
                    <Box sx={{ display: "flex" }}>
                      <YouTubeIcon sx={{ mr: 2 }} /> YouTube
                    </Box>
                  </MenuItem>

                  <MenuItem value={"Pinterest"}>
                    <Box sx={{ display: "flex" }}>
                      <PinterestIcon sx={{ mr: 2 }} /> Pinterest
                    </Box>
                  </MenuItem>
                  <MenuItem value={"Tiktok"}>
                    <Box sx={{ display: "flex" }}>
                      <img
                        src="/assets/icons/tiktok-icon.svg"
                        alt=""
                        style={{ marginRight: "22px" }}
                      />{" "}
                      TikTok
                    </Box>
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={7}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Link
              </InputLabel>
              <FormControl>
                <TextField
                  InputProps={{ disableUnderline: "false" }}
                  variant="filled"
                  fullWidth
                  id="link"
                  name="link"
                  sx={{ mb: 2 }}
                  value={profileLink}
                  onChange={(e) => setProfileLink(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box mt={12} display="flex" justifyContent="flex-end">
          <Button
            sx={{ width: "145px", height: "40px", mr: 2 }}
            className="MuiButton-profile"
            variant="outlined"
            onClick={() => setIsAddSocialLinks(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: "145px", height: "40px" }}
            className="MuiButton-profile"
            variant="contained"
            onClick={() => {
              addProfile();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default AddSocialLinks;
