import React from "react";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const ProfileDetailsAbout = ({ profile }) => {
  const { configurations } = React.useContext(ConfigurationContext);
  const foundAbout = profile?.about?.fields?.find(a => a.name === "about_me");
  const fouundExperience = profile?.about?.fields?.find(a => a.name === "favorite_experience");
  const fouundAdvice = profile?.about?.fields?.find(a => a.name === "advice");
  if (!foundAbout && !fouundExperience && !fouundAdvice) return;

  return (
    <Box>
      {foundAbout ? (
        <>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                About Me
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {foundAbout.value}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}

      {fouundExperience ? (
        <>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                My Experience at {configurations?.schoolShortName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {fouundExperience.value}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}

      {fouundAdvice ? (
        <>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                Advice for Future Students
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {fouundAdvice.value}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
