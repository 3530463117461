import React from 'react';
import { Box } from '@mui/material';

export const ColorCircle = ({ onClick, ml, color }) => {
  return (
    <Box
      ml={ml}
      onClick={onClick}
      sx={{
        width: 47,
        borderRadius: 50,
        height: 47,
        backgroundColor: color,
        '&:hover': {
          opacity: [0.9, 0.8, 0.7],
          cursor: 'pointer',
        },
      }}
    />
  );
};
