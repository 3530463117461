import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import ImageUpload from "components/ImageUpload";
import { getImageListItemBarUtilityClass } from "@mui/material";
import urls from "api/urls";

export const Gallery = (props) => {
  const [images, setImages] = React.useState(props.gallery || []);
  const [lastImage, setLastImage] = React.useState(null);
  const [reset, setReset] = React.useState(null);
  const imageUploadRef = React.useRef(null);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  const updateImage = (image) => {
    if (props.onChange) {
      props.onChange(image);
    }
  };

  const addImage = (image) => {
    if (image) {
      if (images.indexOf(image) > -1) {
        return;
      }
      setLastImage(null);
      image = {
        club_id: props.club ? props.club.id : null,
        image_id: image.id,
        image: image,
      };
      const newImages = [...images, image];
      setImages(newImages);
      if (props.onChange) {
        props.onChange(newImages);
      }
    }
  };

  const removeImage = (image) => {
    const newImages = images.filter((galleryImg) => galleryImg.image.id !== image.image.id);
    setImages(newImages);
    if (props.onChange) {
      props.onChange(newImages);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box mb={5} mt={7}>
          <Grid container>
            <Grid md={5}>
              <Typography variant="h4" fontWeight="bold">
                Gallery
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} mb={4}>
          {images &&
            images.map((image, i) => (
              <Grid
                item
                md={4}
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  className="club-leader-remove"
                  src="/assets/icons/cancel-icon.svg"
                  alt="remove"
                  style={{
                    position: "absolute",
                    top: "1em",
                    left: "95%",
                    cursor: "pointer",
                  }}
                  onClick={() => removeImage(image)}
                />
                <img
                  src={image.image.path}
                  alt="club"
                  style={{ width: "100%" }}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <ImageUpload
              image={lastImage}
              ownerType="Club"
              ownerId={props.club ? props.club.id : null}
              title="Add more images of your club."
              icon="/assets/icons/modalFileIcon.svg"
              title1="Drop your file(s) here or"
              title2="browse"
              bgColor="#F2F2F2"
              height={"400px"}
              padding={8}
              clearOnChange={true}
              onChange={(image) => addImage(image)}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Gallery;
