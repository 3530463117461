import React, { useEffect, useState } from "react";
import apiRequest from "../api/apiRequest";
import urls from "../api/urls";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

export const AnnouncementProvider = () => {
  const [open, setOpen] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      fetchAnnouncements();
    }, 2000);
  }, []);

  useEffect(() => {
    if (announcements.length > 0) {
      setOpen(true);
    }
  }, [announcements.length]);

  const fetchAnnouncements = async () => {
    try {
      const response = await apiRequest({
        method: "GET",
        url: urls.announcements,
      });
      setAnnouncements(response);
    } catch {}
  };

  const onClose = () => {
    //announcements/2/seen
    try {
      if (index !== announcements.length - 1) {
        setOpen(false);

        setTimeout(() => {
          setIndex(index + 1);
          setTimeout(() => {
            setOpen(true);
          }, 500);
        }, 100);
      } else {
        setOpen(false);
      }
    } catch {}
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: announcements[index]?.content }} />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              size="small"
              variant="contained"
              // sx={{ width: "208px", height: "50px" }}
              className="MuiButton-profile"
              onClick={onClose}
            >
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
