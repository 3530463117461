import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./videoJs.css";

export const VideoJS = props => {
  const videoReference = React.useRef(null);
  const playerReference = React.useRef(null);
  const { options, onReady, poster, preload, track } = props;

  const initVideoPlayer = async () => {
    const localOptions = JSON.parse(JSON.stringify(options));

    if (track && track !== "0") {
      localOptions["tracks"] = [
        {
          src: track,
          kind: "subtitles",
          srclang: "en",
          label: "English",
        },
      ];
    }

    if (!playerReference.current) {
      const videoElement = videoReference.current;
      if (!videoElement) return;

      const player = (playerReference.current = videojs(
        videoElement,
        {
          ...localOptions,
          html5: {
            nativeTextTracks: false,
          },
        },
        () => {
          videojs.log("Video player is ready");
          onReady && onReady(player);
        }
      ));
    }
  };

  React.useEffect(() => {
    initVideoPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoReference]);

  // Destroy video.js player on component unmount
  React.useEffect(() => {
    const player = playerReference.current;
    return () => {
      if (player) {
        player.dispose();
        playerReference.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerReference]);
  // wrap player with data-vjs-player` attribute
  // so no additional wrapper are created in the DOM
  return (
    <div className="video-js-container" data-vjs-player>
      <video
        ref={videoReference}
        className="video-js vjs-big-playcentered qavideos"
        preload={preload ? preload : "none"}
        autoPlay={false}
        width="100%"
        height="90%"
        poster={poster}
      />
    </div>
  );
};
export default VideoJS;
