import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LeaderCard from "../LeaderCard";
import { Link } from "react-router-dom";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import ConfigurationContext from "contexts/ConfigurationContext";

const Leadership = ({ leaders, isLoading }) => {
  const { configurations } = React.useContext(ConfigurationContext);
  if (!isLoading && leaders.length === 0) return;

  return (
    <Box mt={15} marginX={5}>
      <Container>
        <Box display="flex" justifyContent="center">
          {!isLoading && (
            <Grid item container md={12}>
              <Grid item>
                <Typography color="#131416" fontSize={{ md: 40 }} fontWeight={700}>
                  {/* Leadership */}
                  {configurations.staff_spotlight_title}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
        {isLoading ? (
          <Box justifyContent="center" mt={6}>
            <LoadingIndicator />
          </Box>
        ) : (
          <Box justifyContent="center" mt={6}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              rowSpacing={3}
              columnSpacing={3}
              alignItems="center"
              justifyContent="center"
            >
              {leaders.map((item, index) => (
                <Grid item md={4}>
                  <Link to={`/staff-directory/${item.id}`}>
                    <LeaderCard
                      image={item?.profile_image?.path}
                      userName={item.full_name}
                      subject={item.subject}
                      title={item.title}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* <Box justifyContent='center' mt={6}>
          <div style={{ display: 'flex' }}>
            <LeaderCard
              image={'assets/images/cardImage.jpg'}
              userName={'Judy Thompson'}
              subject={'Superintendent'}
              width='500px'
              height='400px'
            />
            <div style={{ marginLeft: '20px' }}>
              <LeaderCard
                image={'assets/images/cardImage.jpg'}
                userName={'Judy Thompson'}
                subject={'Superintendent'}
                width='300px'
                height='300px'
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-80px',
            }}
          >
            <LeaderCard
              image={'assets/images/cardImage.jpg'}
              userName={'Judy Thompson'}
              subject={'Superintendent'}
              width='300px'
              height='300px'
            />
            <div style={{ marginLeft: '30px' }}>
              <LeaderCard
                image={'assets/images/cardImage.jpg'}
                userName={'Judy Thompson'}
                subject={'Superintendent'}
                width='300px'
                height='300px'
              />
            </div>
          </div>
          <Box p={2}></Box>
        </Box> */}
      </Container>
    </Box>
  );
};

export default Leadership;
