import { Grid, Typography } from "@mui/material";
import React from "react";

export const ClubPageMeetings = props => {
  const { meetings = [] } = props;

  return (
    <Grid
      mt={2}
      container
      xs={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
      }}
    >
      {meetings.map(meeting => (
        <Grid
          container
          display="flex"
          sx={{
            display: "flex",
            borderBottom: "2px solid gray",
            padding: "20px 0",
          }}
        >
          {meeting.number_of_students && (
            <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                Number of Participants: {meeting.number_of_students}
              </Typography>
            </Grid>
          )}
          {meeting.student_grades && (
            <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                Participants: {meeting.student_grades}
              </Typography>
            </Grid>
          )}
          {meeting.meeting_day && (
            <Grid xs={12} sm={12} md={6} lg={2} xl={3}>
              <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                Day: {meeting.meeting_day}
              </Typography>
            </Grid>
          )}
          {meeting.meeting_hours && (
            <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
              <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                Time: {meeting.meeting_hours}
              </Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
