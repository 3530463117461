import React, { useState } from "react";
import { familyNetworkAlphabetsData } from "constants";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import COLORS from "constants/colors";
import Divider from "@mui/material/Divider";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SchoolTable from "./component/SchoolTable";
import { TextField, useMediaQuery } from "@mui/material";
import ConfigurationContext from "contexts/ConfigurationContext";
import { useTheme } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Grid from "@mui/material/Grid";

const SelectSchool = props => {
  const { setIsSelectSchool, setSchool } = props || {};
  const { schools } = React.useContext(ConfigurationContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSchools, setFilteredSchools] = useState(schools);
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const filterByCharacter = character => {
    const filteredList = schools.filter(
      school => school.outcomeTitle.charAt(0).toLowerCase() === character.toLowerCase()
    );
    setFilteredSchools(filteredList);
  };

  const filterBySearch = value => {
    setSearchTerm(value);
    const filteredList = schools.filter(school =>
      school.outcomeTitle.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSchools(filteredList);
  };

  return (
    <Container>
      <Paper
        sx={{
          borderRadius: "5px",
          border: `1px solid ${COLORS.white.secondary}`,
          borderTop: `5px solid ${theme.palette.primary.main}`,
          p: isMatch ? 0.5 : 5,
          position: "relative",
        }}
        elevation={0}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Select Your School</Typography>
          <a
            href="/public"
            onClick={e => {
              e.preventDefault();
              setIsSelectSchool(false);
            }}
          >
            <HighlightOffIcon fontSize="large" color="primary" />
          </a>
        </Box>

        <Box mt={3} justifyContent="space-between" display="flex">
          {familyNetworkAlphabetsData.map(item => (
            <Box display="flex" justifyContent="space-between">
              <Typography
                fontSize={16}
                color="primary"
                fontWeight="bold"
                onClick={() => filterByCharacter(item)}
                sx={{ cursor: "pointer" }}
              >
                {item}
              </Typography>
              <Divider
                style={{
                  width: "100%",
                }}
                variant="middle"
                flexItem
                orientation="vertical"
              />
            </Box>
          ))}
        </Box>

        <Box>
          <Box
            component="form"
            mt={5}
            sx={{
              display: "flex",
              bgcolor: COLORS.white.light,
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <SearchIcon color="primary" />
            </IconButton>
            <TextField
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by school name"
              value={searchTerm}
              onChange={e => {
                e.preventDefault();
                filterBySearch(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <SchoolTable
            filteredSchools={filteredSchools}
            setSchool={setSchool}
            setIsSelectSchool={setIsSelectSchool}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => setIsSelectSchool(false)}
            sx={{ px: 5, mr: 2 }}
            className="MuiButton-profile"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{ px: 5 }}
            className="MuiButton-profile"
            variant="contained"
            onClick={() => setIsSelectSchool(false)}
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default SelectSchool;
