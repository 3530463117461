import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LeaderCard from '../LeaderCardOld';
import { leadersCardData } from 'constants/content';
import SearchArea from '../SearchArea';
import { ROUTES } from 'constants/routes';

const AllLeaders = () => {
  return (
    <Box mt={18} marginX={5}>
      <SearchArea />
      <Container>
        <Box display='flex' justifyContent='center'>
          <Grid item container md={12}>
            <Typography
              color='#2E75FF'
              fontSize={{ md: '16.5px' }}
              fontWeight={600}
              lineHeight='22px'
              letterSpacing='5px'
              marginBottom={'50px'}
            >
              A | B | C | D | E | F | G | H | I | J | K | L | M | N | O | P | Q | R | S | T | U | V | W | X | Y | Z
            </Typography>
            <Grid item>
              <Typography color='#131416' fontSize={{ md: 40 }} fontWeight={700}>
                Meet and Connect With Our Staff
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box justifyContent='center' mt={6}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            container
            rowSpacing={2}
            columnSpacing={2}
            alignItems='center'
            justifyContent='center'
          >
            {leadersCardData.map((item) => (
              <Grid item md={4}>
                <Link to={ROUTES.STAFF_PROFILE}>
                  <LeaderCard image={item.image} userName={item.userName} subject={item.subject} />
                </Link>
              </Grid>
            ))}
          </Grid>
          <Box p={2}></Box>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Stack spacing={2}>
              <Pagination count={10} variant='outlined' color='primary' />
            </Stack>
          </Box>
          <Box p={2}></Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AllLeaders;
