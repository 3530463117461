import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import Preferences from "../GraduateProfile/components/Preferences";
import { AlumniProfileForm } from "./AlumniProfileForm";
import { AlumniLayout } from "./Layout";

export const AlumniProfileUpdate = () => {
  const { search } = useLocation();
  const history = useHistory();
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), []);
  }

  const query = useQuery();
  const tab = query.get("tab") ? query.get("tab").toLowerCase() : "profile";
  const [activeStep, setActiveStep] = React.useState(tab === "preferences" ? 1 : 0);
  const changeActiveStep = stepIndex => {
    const newQueryParam =
      stepIndex === 0 ? "graduate-profile" : stepIndex === 1 ? "preferences" : "";
    const queryParams = qs.parse(search);
    const newQueries = { ...queryParams, tab: newQueryParam };
    history.push({ search: qs.stringify(newQueries) });
    setActiveStep(stepIndex);
  };

  switch (activeStep) {
    case 0:
      return (
        <AlumniLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <AlumniProfileForm />
        </AlumniLayout>
      );

    case 1:
      return (
        <AlumniLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <Preferences />
        </AlumniLayout>
      );

    default:
      throw new Error("Invalid route access");
  }
};
