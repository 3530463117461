import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import './navigation-button.css';

export const NAV_OPTIONS = {
  NEXT: 'next',
  PREV: 'prev',
};

export const NavigationButton = ({ icon: IconComponent, label, color }) => {
  const customColor = color ? { backgroundColor: color } : {};
  return (
    <Box className='navigation-button-wrapper'>
      <Box className={`navigation-button`} style={customColor}>
        {IconComponent && <IconComponent />}
      </Box>
      {label && (
        <Typography
          color='inherit'
          variant='body2'
          fontWeight='bold'
          className='nav-label'
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};
