import Box from "@mui/system/Box";
import { CircularProgress, FormControl, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { toast } from "react-toastify";
import MessagingContext from "../../../contexts/MessagingContext";
import AuthContext from "../../../contexts/AuthContext";

export const MessageForm = ({ selectedMembers, club, setNewMessage }) => {
  const [newMessageBody, setNewMessageBody] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { sendMessage, sendMessageMulti } = React.useContext(MessagingContext);
  const { user } = React.useContext(AuthContext);

  const handleSubmit = async () => {
    if (selectedMembers.length === 0) {
      toast.error("Please select a family member");
      return;
    }
    if (newMessageBody.trim() === "") {
      toast.error("Please type a message");
      return;
    }

    try {
      setIsLoading(true);
      if (club) {
        await sendMessageMulti(selectedMembers, newMessageBody);
      } else {
        for (let i = 0; i < selectedMembers.length; i++) {
          const m = selectedMembers[i];
          await sendMessage([user.id, m], newMessageBody);
        }
      }

      setIsLoading(false);
      setNewMessage(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box my={5}>
        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: "false" }}
            variant="standard"
            required
            multiline
            fullWidth
            rows={5}
            id="message"
            name="message"
            sx={{ pr: 8 }}
            placeholder="Type your message here"
            value={newMessageBody}
            onChange={e => {
              setNewMessageBody(e.target.value);
            }}
          />
        </FormControl>
      </Box>
      <Button
        sx={{
          width: "153px",
          height: "45px",
          position: "absolute",
          right: "0",
          bottom: "30px",
        }}
        className="MuiButton-profile"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress /> : "Send"}
      </Button>
    </>
  );
};
