import { sortListByValue } from "../utils/Sort";

export const IndustryList = sortListByValue([
  { key: "agriculture", value: "Agriculture" },
  { key: "arts", value: "Arts" },
  { key: "automotive", value: "Automotive" },
  { key: "construction", value: "Construction" },
  { key: "consumer_goods", value: "Consumer Goods" },
  { key: "corporate_services", value: "Corporate Services" },
  { key: "design", value: "Design" },
  { key: "education", value: "Education" },
  { key: "energy_mining", value: "Energy & Mining" },
  { key: "entertainment", value: "Entertainment" },
  { key: "finance", value: "Finance" },
  { key: "government", value: "Government" },
  { key: "hardware_networking", value: "Hardware & Networking" },
  { key: "health_care", value: "Health Care" },
  { key: "legal", value: "Legal" },
  { key: "manufacturing", value: "Manufacturing" },
  { key: "media_communications", value: "Media & Communications" },
  { key: "military", value: "Military" },
  { key: "nonprofit", value: "Nonprofit" },
  { key: "public_administration", value: "Public Administration" },
  { key: "public_safety", value: "Public Safety" },
  { key: "real_estate", value: "Real Estate" },
  { key: "recreation_travel", value: "Recreation & Travel" },
  { key: "retail", value: "Retail" },
  { key: "software_it_services", value: "Software & IT Services" },
  { key: "technology", value: "Technology" },
  { key: "transportation_logistics", value: "Transportation & Logistics" },
  { key: "wellness_fitness", value: "Wellness & Fitness" },
]);
