import React from "react";

import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CustomSelect = props => {
  const handleClick = () => {
    if (props.isSelectSchool === false) {
      props.setIsSelectSchool(true);
    } else {
      props.setIsSelectSchool(false);
    }
  };
  return (
    <Box>
      <Button
        variant="outline"
        sx={{
          padding: "10px 12px 8px",
          width: "100%",
          height: "55px",
          borderRadius: "1px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          backgroundColor: "white",
          color: "black",
          font: "inherit",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <span>{props.value}</span>
      </Button>
    </Box>
  );
};
export default CustomSelect;
