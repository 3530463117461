import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AmbassadorCard from "../AmbassadorCard";
// import { ambassadorsCardData } from 'constants/content';
import { ROUTES } from "constants/routes";
import { useTheme } from "@mui/material";
import ConfigurationContext from "contexts/ConfigurationContext";
import LoadingIndicator from "../../../../components/Utils/LoadingIndicator";
import AuthContext from "../../../../contexts/AuthContext";
import { useState } from "react";

const FamilyAmbassadorsArea = ({ ambassadors, isLoading }) => {
  const history = useHistory();
  const theme = useTheme();
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);
  const { isAuthenticated, user, logout } = React.useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const familiesConfigurator = configurations?.pages_configuration?.families?.configuration;

  const handleBrowseAll = () => {
    if (
      isAuthenticated &&
      user?.roles?.find(r => ["Verified Family", "Club Leader", "Staff"].indexOf(r.name) !== -1)
    ) {
      history.push(ROUTES.FAMILY_NETWORK_DETAILS);
    } else if (isAuthenticated) {
      setShowError(true);
    } else {
      history.push(ROUTES.SIGN_IN);
    }
  };
  const handleLogout = () => {
    logout();
    history.push(ROUTES.SIGN_IN);
  };
  return (
    <Box mt={12}>
      <Container>
        <Box display="flex" justifyContent="center">
          <Grid item container md={12}>
            <Grid item>
              <Typography color={theme.palette.common.black} fontSize={{ md: 40 }} fontWeight={700}>
                {familiesConfigurator?.title ? (
                  familiesConfigurator.title
                ) : (
                  <>Meet Your {configurations.family_ambassador_name}</>
                )}{" "}
              </Typography>
              <Typography mt={2}>
                {familiesConfigurator?.subTitle ? (
                  familiesConfigurator.subTitle
                ) : (
                  <>
                    We’re here to welcome you to the{" "}
                    {configurations.schoolShortName ? configurations.schoolShortName : ""} Family.
                  </>
                )}
              </Typography>
            </Grid>

            {isAuthenticated && !user?.is_verified_family && showError ? (
              <Typography>
                Only verified enrolled families may connect with other enrolled families on this
                platform. Please &nbsp;
                <Link onClick={handleLogout}>Login</Link>
                &nbsp; as an enrolled family to continue.
              </Typography>
            ) : (
              ""
            )}
            {isFeatureEnabled("family_browse_all") && (
              <Grid item container justifyContent="center" mt={6}>
                <Button
                  role="button"
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: 80,
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  size="large"
                  onClick={handleBrowseAll}
                >
                  Browse all {configurations.schoolShortName ? configurations.schoolShortName : ""}{" "}
                  Families
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box justifyContent="center" mt={6}>
          <Grid container spacing={2}>
            {isLoading ? (
              <Grid container justifyContent="center">
                <LoadingIndicator />
              </Grid>
            ) : (
              ambassadors?.map(item => (
                <Grid lg={4} md={6} sm={12} item width="100%">
                  <AmbassadorCard
                    image={item.profile_image?.path}
                    grades={item?.user?.gradeBands && []}
                    userName={item.lastName}
                    familyMembers={item.members ? item.members : []}
                    socialMedias={item.socialMedias ? item.socialMedias : []}
                    userId={item.id}
                  />
                </Grid>
              ))
            )}
          </Grid>
          {isFeatureEnabled("family_browse_all") && (
            <Grid item container justifyContent="center" mt={6}>
              <Button
                role="button"
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: 80,
                  textTransform: "capitalize",
                  color: "white",
                }}
                size="large"
                onClick={handleBrowseAll}
              >
                Browse all {configurations.schoolShortName ? configurations.schoolShortName : ""}{" "}
                Families
              </Button>
            </Grid>
          )}
          <Box p={2}></Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FamilyAmbassadorsArea;
