import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const ProfileDetailsQuote = ({ profile }) => {
  const foundQuote = profile?.about?.fields?.find(a => a.name === "quote");
  if (!foundQuote) return;

  return (
    <Box>
      <Grid container justifyContent="center" maxWidth="xl">
        <Grid item container md={10} spacing={3} alignItems="center">
          <Grid item md={6} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            {foundQuote && (
              <Box>
                <div>
                  <svg
                    width="64"
                    height="51"
                    viewBox="0 0 64 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M0 33.4076C0 44.6203 7.15408 50.9999 15.0816 50.9999C22.2357 50.9999 28.2296 45.0069 28.2296 37.6607C28.2296 30.5078 23.2024 25.4814 16.6284 25.4814C15.4683 25.4814 13.9215 25.8681 13.3414 26.0614C14.6949 19.4885 21.0755 11.5623 27.2628 7.88915L18.1752 0.349609C7.54079 7.88915 0 20.0684 0 33.4076ZM35.7704 33.4076C35.7704 44.6203 42.9245 50.9999 50.852 50.9999C58.006 50.9999 64 45.0069 64 37.6607C64 30.5078 58.9728 25.4814 52.3988 25.4814C51.2387 25.4814 49.6918 25.8681 49.1118 26.0614C50.4653 19.4885 56.8459 11.5623 63.0332 7.88915L53.9456 0.349609C43.3112 7.88915 35.7704 20.0684 35.7704 33.4076Z"
                      fill="#302F68"
                    />
                  </svg>
                </div>
                <Typography sx={{ fontFamily: "Proxima-Nova-Black", fontSize: "50px" }}>
                  {foundQuote.value}
                </Typography>
                <div>
                  <svg
                    width="65"
                    height="51"
                    viewBox="0 0 65 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M64.4434 17.7137C64.4434 6.42367 57.2399 0 49.2577 0C42.0542 0 36.0189 6.03436 36.0189 13.4313C36.0189 20.6336 41.0807 25.6947 47.7002 25.6947C48.8683 25.6947 50.4258 25.3053 51.0099 25.1107C49.647 31.729 43.2223 39.7099 36.9923 43.4084L46.1427 51C56.8505 43.4084 64.4434 31.145 64.4434 17.7137ZM28.426 17.7137C28.426 6.42367 21.2225 0 13.2403 0C6.03683 0 0.00149536 6.03436 0.00149536 13.4313C0.00149536 20.6336 5.0634 25.6947 11.6828 25.6947C12.8509 25.6947 14.4084 25.3053 14.9925 25.1107C13.6297 31.729 7.20497 39.7099 0.974941 43.4084L10.1253 51C20.8332 43.4084 28.426 31.145 28.426 17.7137Z"
                      fill="#302F68"
                    />
                  </svg>
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
