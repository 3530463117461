import React from 'react';
import AuthLandingScreen from 'pages/ManageAuth/components/AuthLandingScreen';
import AuthSignInForm from 'pages/ManageAuth/components/AuthSignInForm';
import { COLORS } from 'constants/colors';

export const StaffAdminSignIn = () => {
  return (
    <AuthLandingScreen
      headerTitle='Think12 Staff'
      height='680px'
      image='assets/images/beautiful-female.png'
      staffBgcolor={COLORS.common.staff}
      staffAdminIcon
    >
      <AuthSignInForm
        title='Welcome Back!'
        authButtonText='Sign In'
        staffAdmin
        subTitle='Log into your account and start exploring!'
      />
    </AuthLandingScreen>
  );
};

export default StaffAdminSignIn;
