import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { COLORS } from 'constants/colors';
import { useTheme } from '@mui/material/styles';

export const SignUpSnackBar = (props) => {
  const { open, close, width, status, message, familyLogin, borderColor } = props || {};
  const theme = useTheme();

  setTimeout(() => {
    close();
  }, 3000);

  return (
    <div>
      <Button onClick={open}>Open modal</Button>
      <Modal open={open} onClose={close} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Container>
          <Paper
            open={open}
            sx={{
              left: '50%',
              height: 'auto',
              width: width || 470,
              position: 'absolute',
              bgcolor: 'background.paper',
              top: familyLogin ? '27%' : '10%',
              transform: 'translate(-50%, -50%)',
              borderRadius: status === 'Success' && '0px 0px 10px 10px',
            }}
          >
            <Box>
              <Divider
                variant='fullWidth'
                sx={{
                  '&.MuiDivider-fullWidth': {
                    border: `2px solid ${borderColor || COLORS.warning.dark}`,
                  },
                }}
              />
            </Box>
            <Grid container p={3}>
              <Grid item md={2} xs={2}>
                {status === 'Success' ? (
                  <img src='assets/icons/successIcon.png' alt='' />
                ) : (
                  <img src='assets/icons/exclamationIcon.png' alt='' />
                )}
              </Grid>
              <Grid item md={10} xs={10}>
                <Typography color={status === 'Success' ? theme.palette.primary.main : COLORS.warning.dark}>
                  {status || 'Error'}
                </Typography>
                <Typography component='paragraph' wrap>
                  {message ||
                    'Oops. The code you entered does not match our records. Please click here to continue as an unverified user or try again below.'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Modal>
    </div>
  );
};

export default SignUpSnackBar;
