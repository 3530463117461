import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";

import Hero from "./components/Hero";
import "./manage-outcomes.css";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";

import { PROGRAM_CONTENT } from "constants/program_content";
import DelbartonOutcomes from "./components/DelbartonOutcomes";
import { LogosScroller } from "../../components/LogosScroller/LogosScroller";

export const ManageOutcomes = () => {
  const [outcomesContent, setOutcomesContent] = useState([]);

  const fetchOutcomesContent = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `outcome-content`,
      });
      setOutcomesContent(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchOutcomesContent();
  }, []);

  return (
    <Box className="manage-outcomes-page">
      <Hero />
      {(() => {
        const programContent = PROGRAM_CONTENT[urls.programId()];
        let programName = null;
        if (programContent) {
          programName = PROGRAM_CONTENT[urls.programId()].programName;
        }

        if (programName === "delbarton") {
          return <DelbartonOutcomes />;
        } else {
          return outcomesContent.map((content, index) => {
            if (content.content_type === "logo") {
              return <LogosScroller id={content.content} />;
            }

            return <div key={index} dangerouslySetInnerHTML={{ __html: content.content }}></div>;
          });
        }
      })()}
    </Box>
  );
};

export default ManageOutcomes;
