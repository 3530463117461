import { createTheme } from "@mui/material/styles";
import { COLORS } from "constants/colors";
import { THEME_NAME, getThink12Theme } from "../models";

const colors = {
  primary: {
    // main: '#2E75FF',
    main: "#046A38",
    light: "#87AFFF",
  },
  secondary: {
    // main: "#08296B",
    main: "#18381E",
  },
  warning: {
    // main: "#EFAC00",
    main: "#fff",
    dark: "#ED282F",
  },
  success: {
    main: "#4CBC24",
  },
  white: {
    main: "#FFFFFF",
    dark: "#F1F1F1",
    secondary: "#F3F4F4",
    light: "#F7F8F8",
    lighter: "#F3F4F4",
  },
  black: {
    main: "#000000",
    dark: "#131416",
    light: "#353535",
  },
  dark: {
    main: "#000000",
    light: "#34333B",
    lighter: "#20212F",
    lightest: "#979797",
  },
  blue: {
    // main: "#10265B",
    main: "#18381E",
    secondary: "#2E75FF",
    dark: " #302F68",
    light: "#D3D2FF",
  },
  grey: {
    main: "#C0C7D5",
    secondary: "#EDEDED",
    light: "#F6F8FA",
    lighter: "#FBFBFD",
    lightest: "#F6F6F6",
    dark: "#20212F",
    lightGrey: "#F1F5FD",
    thinner: "#B8B8B8",
    greyOnGrey: "#8b8e95",
  },
  common: {
    sky: "#DCE8FF",
    purple: "#F2EFFF",
    graduate: "#FFF3EB",
    staff: "#E8F9E3",
  },
  pink: {
    main: "#BF38FF",
  },
};

const think12Theme = getThink12Theme(colors);

export let theme = createTheme({
  palette: {
    primary: {
      // main: COLORS.primary.main,
      main: "#fff",
      light: COLORS.primary.light,
    },

    secondary: {
      // main: COLORS.secondary.main,
      main: "#fff",
    },

    dark: {
      main: COLORS.dark.main,
      light: COLORS.dark.light,
      contrastText: COLORS.white.main,
    },

    warning: {
      // main: COLORS.warning.main,
      main: "#fff",
    },
  },
  name: THEME_NAME,
  think12: think12Theme,
});

theme = {
  ...theme,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "& .MuiButton-navbar": {
            fontSize: 15,
            display: "flex",
            alignItems: "center",
            color: COLORS.white.main,
            textTransform: "capitalize",
            padding: theme.spacing(2, 3, 3, 3),
            // '&.MuiSelected': {
            //   borderTopLeftRadius: 10,
            //   borderTopRightRadius: 10,
            //   background: COLORS.secondary.main,
            // },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            paddingTop: theme.spacing(1.2),
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-profile-menu": {
            overflow: "visible",
            marginTop: theme.spacing(1.5),
            borderRadius: 10,
            // filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
            boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.1)",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              marginLeft: theme.spacing(-0.5),
              marginRight: theme.spacing(1),
            },
          },
          "&.MuiPaper-card-layer": {
            background:
              "rgba(32, 33, 47, 0) linear-gradient(to bottom, rgba(32, 33, 47, 0) 10%, rgba(32, 33, 47, .8) 40%, rgba(32, 33, 47, .9) 75%, rgba(32, 33, 47, 1) 100%) repeat scroll 0 0",
            position: "relative",
            marginTop: "-150px",
            height: "150px",
            boxShadow: "0 !important",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-enroll": {
            fontSize: 18,
            boxShadow: "none",
            borderRadius: 100,
            padding: theme.spacing(0.7, 7),
            lineHeight: "5px",
            textTransform: "capitalize",
            display: "flex",
            flexDirection: "column",
          },
        },
        outlined: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            boxShadow: "none",
          },
          "&.MuiButton-tips-filter": {
            borderRadius: 50,
            borderColor: COLORS.dark.main,
            color: COLORS.dark.main,
            fontWeight: "bold",
          },
          "&.MuiButton-profile": {
            borderRadius: 50,
            borderColor: COLORS.secondary.main,
            color: COLORS.secondary.main,
            fontWeight: "bold",
            textTransform: "capitalize",
          },
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            boxShadow: "none",
          },
          "&.MuiButton-profile": {
            borderRadius: 50,
            color: COLORS.white.main,
            fontWeight: "bold",
            paddingTop: "5px",
            paddingBottom: "5px",
            fontSize: "12px",
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-profile-sidebar": {
            fontSize: "14px",
            // marginTop: '20px',
            // marginBottom: '20px',
            cursor: "pointer",
          },
          "&.MuiTypography-profile-sidebar-selected": {
            fontSize: "14px",
            // marginTop: '20px',
            // marginBottom: '20px',
            cursor: "pointer",
            fontWeight: 900,
          },
        },
        h1: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "64px",
          fontWeight: 900,
        },
        h2: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "56px",
          fontWeight: 900,
        },
        h3: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "36px",
          fontWeight: 900,
        },
        h4: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "30px",
          fontWeight: 900,
        },
        h5: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "24px",
          fontWeight: 900,
        },
        h6: {
          fontFamily: '"Proxima-Nova-Black", sans-serif',
          fontSize: "20px",
          fontWeight: 900,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white.secondary,
          "&:hover": {
            backgroundColor: COLORS.white.secondary,
          },
          "&.Mui-focused": {
            backgroundColor: COLORS.white.secondary,
          },
        },
      },
    },

    MuiCardMedia: {
      styleOverrides: {
        root: {
          "&.MuiCardMedia-tips": {},
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          "&.Mui-Grid-image-silder": {
            height: 500,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-Profile-label": {
            color: COLORS.grey.dark,
            fontSize: "18px",
            fontWeight: "400",
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-sidebar": {
            color: COLORS.white.main,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            padding: theme.spacing(2, 4),
            // '&.Mui-selected': {
            //   background: COLORS.secondary.main,
            // },
          },
        },
      },
    },
  },
};

// export default theme;
