import React from 'react';
import TipsCard from '../TipsCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

export const Tips = ({ articleContent }) => {
  return (
    <Container maxWidth={false} sx={{ marginTop: { xs: '50px', md: '100px' }, marginBottom: '50px' }}>
      <Grid container spacing={3}>
        {articleContent[0] ? (
          <Grid item md={5} xs={12}>
            <TipsCard article={articleContent[0]} isMainCard height='580px' image='assets/images/noImage.jpg' />
          </Grid>
        ) : (
          ''
        )}

        <Grid item md={7} xs={12}>
          <Grid container spacing={3} direction={'column'}>
            {articleContent[1] ? (
              <Grid item md={6} xs={6}>
                <TipsCard article={articleContent[1]} height='305px' image='assets/images/noImage.jpg' />
              </Grid>
            ) : (
              ''
            )}
            <Grid item md={6} xs={12}>
              <Grid item container spacing={3}>
                {articleContent[2] ? (
                  <Grid item md={6} xs={6}>
                    <TipsCard article={articleContent[2]} height='250px' image='assets/images/noImage.jpg' />
                  </Grid>
                ) : (
                  ''
                )}

                {articleContent[3] ? (
                  <Grid item md={6} xs={6}>
                    <TipsCard article={articleContent[3]} height='250px' image='assets/images/noImage.jpg' />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12}>
          <Grid container spacing={3}>
            {articleContent[4] ? (
              <Grid item md={6} xs={6}>
                <TipsCard article={articleContent[4]} height='300px' image='assets/images/noImage.jpg' />
              </Grid>
            ) : (
              ''
            )}
            {articleContent[5] ? (
              <Grid item md={6} xs={6}>
                <TipsCard article={articleContent[5]} height='300px' image='assets/images/noImage.jpg' />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        {articleContent[6] ? (
          <Grid item md={6} xs={12}>
            <TipsCard article={articleContent[6]} height='300px' image='assets/images/noImage.jpg' />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Container>
  );
};

export default Tips;
