import React from 'react';

export const ManagePrivacyPolicy = () => {
  return (
    <div>
      <div name='termly-embed' data-id='195b0c3c-cf48-4d76-acfb-9792c2eeec99' data-type='iframe'></div>
      {(function (d, s, id) {
        var js,
          tjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://app.termly.io/embed-policy.min.js';
        tjs.parentNode.insertBefore(js, tjs);
      })(document, 'script', 'termly-jssdk')}
    </div>
  );
};

export default ManagePrivacyPolicy;
