import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const BottomHeading = () => {
  return (
    <Box sx={{ width: "100%", marginTop: "-50px", position: "relative" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <img
            alt=""
            width="80px"
            height="63px"
            style={{
              position: "absolute",
              color: "#081E90",
              right: "470px",
              top: "380px",
            }}
            src="/assets/images/commas.png"
          />
          <img
            alt=""
            width="80px"
            height="63px"
            style={{
              position: "absolute",
              color: "#081E90",
              right: "470px",
              top: "-70px",
              transform: "rotate(180deg)",
            }}
            src="/assets/images/commas.png"
          />
          <Typography
            variant="h2"
            style={{
              color: "#302F68",
              fontWeight: "900",
              lineHeight: "58px",
              fontStyle: "normal",
              marginBottom: "15px",
            }}
          >
            A good education can change anyone. A good teacher can change
            everything!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomHeading;
