import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

export const ProfileEducation = ({ profile }) => {
  const { schools, configurations } = useContext(ConfigurationContext);

  return (
    <>
      <Box>
        <>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
                Education
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth="xl" justifyContent="center">
            <Grid flexDirection="column" item container md={10}>
              {profile?.educations?.map((ed, index) => {
                const foundSchool = schools.find(s => s.id === ed.outcome_id);
                if (!foundSchool) return null;

                return (
                  <>
                    <Box sx={{ ml: 2 }}>
                      <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                        {ed.degree} - {foundSchool.outcomeTitle}
                      </Typography>
                      {foundSchool?.url && (
                        <Box mt={2}>
                          <img
                            src={foundSchool?.url}
                            alt={foundSchool?.outcomeTitle}
                            height="30px"
                          />
                        </Box>
                      )}
                    </Box>
                    {index + 1 !== profile?.educations?.length && (
                      <Divider
                        sx={{
                          mt: 2,
                          borderWidth: "2px",
                          borderColor: configurations.color1,
                        }}
                      />
                    )}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </>
      </Box>
    </>
  );
};
