import { Grid, CircularProgress, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import ProfileContext from 'contexts/ProfileContext';

const videoConstraints = {
  width: { max: 300 },
  height: { max: 400 },
  aspectRatio: 1.5,
  facingMode: 'user',
};

export const TakeSelfie = (props) => {
  const { height, setIsOpen, setUploadFile } = props;
  const webcamRef = useRef();
  const { updateImage, profile } = React.useContext(ProfileContext);
  const [showProgress, setShowProgress] = React.useState(false);

  const capture = useCallback(async () => {
    const imageb64 = webcamRef.current.getScreenshot();
    const response = await fetch(imageb64);
    const blob = await response.blob();
    setShowProgress(true);
    setUploadFile(blob);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, setIsOpen, updateImage, profile?.id, profile?.profileImage]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        md={12}
        sm={12}
        xs={12}
        sx={{
          border: '1px dashed #A3A3A3',
          height: { height },
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'relative',
            width: '100%',
          }}
        >
          <Button
            sx={{
              zIndex: 1000,
              backgroundColor: '#fff',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: '10px',
              '&:hover': { backgroundColor: '#fff' },
            }}
            onClick={capture}
            disabled={showProgress}
          >
            {showProgress ? <CircularProgress /> : <img src='/assets/icons/take-picture.svg' alt='' />}
          </Button>
          <Box className='take-selfie-box' height='100%' width='100%' sx={{ position: 'absolute', top: 0 }}>
            <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' videoConstraints={videoConstraints} />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
