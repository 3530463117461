import React, { useEffect } from "react";
import ImageUpload from "components/ImageUpload";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";

export const Hero = props => {
  const [name, setName] = React.useState(props.club ? props.club.name : "");
  const [image, setImage] = React.useState(props.club.image ? props.club.image : null);
  const [club, setClub] = React.useState(props.club ? props.club : {});
  const [clubs, setClubs] = React.useState(props.clubs ? props.clubs : []);
  const [editName, setEditName] = React.useState(false);
  const [isImage] = React.useState(false);

  useEffect(() => {
    if (props.onClubUpdate) {
      club.clubName = name;
      props.onClubUpdate(club);
    }
  }, [name]);

  useEffect(() => {
    if (props.onClubUpdate) {
      club.image = image;
      club.image_id = image ? image.id : null;
      setClub(club);
      props.onClubUpdate(club);
    }
  }, [image]);

  useEffect(() => {
    if (props.club) {
      setName(props.club.name);
      setClub(props.club);
    }
  }, [props.club]);

  useEffect(() => {
    setClubs(props.clubs);
  }, [props.clubs]);

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box my={8} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" px={2}>
            {!editName ? (
              <Typography
                variant="h4"
                fontSize={{ md: "35px", lg: "35px", sm: "35px", xs: "25px" }}
              >
                {name}
              </Typography>
            ) : (
              <TextField
                id="outlined-basic"
                label="Club Name"
                variant="outlined"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
              />
            )}
            <img
              src="/assets/icons/profile-edit-icon.svg"
              alt=""
              style={{ marginLeft: "30px" }}
              onClick={() => setEditName(!editName)}
            />
          </Box>
          <Box>
            <FormControl>
              {club.id && clubs.length ? (
                <TextField select value={club.id}>
                  {clubs.map(club => (
                    <MenuItem key={club.id} value={club.id}>
                      {club.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                []
              )}
            </FormControl>
          </Box>
        </Box>
      </Container>
      <Box my={5}>
        <Grid container>
          <Grid item md={2}></Grid>
          <Grid item md={9} sm={12} xs={12} p={{ xs: 2, md: 0, sm: 0, lg: 0 }}>
            {!isImage ? (
              <ImageUpload
                ownerType="Club"
                ownerId={club.id}
                image={club.image}
                title="Add a representative image of your club."
                icon="/assets/icons/modalFileIcon.svg"
                title1="Drop your file(s) here or"
                title2="browse"
                bgColor="#F2F2F2"
                showProgress="showProgress"
                height={"400px"}
                padding={8}
                onChange={image => setImage(image)}
              />
            ) : (
              <Box width="100%" height="500px">
                <img src="/assets/icons/modalFileIcon.svg" alt="" width="100%" height="500px" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Hero;
