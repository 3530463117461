import * as React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { AuthLandingScreen } from "../AuthLandingScreen";
import AuthSignUpForm from "../AuthSignUpForm";
import { SignUpModal } from "./components/SignUpModal/index";
import { SignUpSnackBar } from "./components/SignUpSnackBar/index";
import { useState } from "react";
import GreetingScreen from "../GreetingScreen";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import { Roles } from "../../../../utils/Roles";

export const CreateAccount = () => {
  const history = useHistory();
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const [values, setValues] = React.useState({
    showModal: false,
    showAlert: false,
  });
  const [userRole, setuserRole] = useState(0);

  let modalRoles = [1, 3, 4, 5, 12];
  if (isFeatureEnabled("program_verification_code")) {
    modalRoles = [4, 5];
  }

  const handleuserRoleChange = e => {
    if (modalRoles.includes(e) && e !== Roles.alumni) {
      handleShowModal();
    }
    setuserRole(e);
  };
  const { showModal, showAlert } = values;

  const handleShowModal = () => {
    setValues({ ...values, showModal: true });
  };
  const handleCloseModal = () => {
    setValues({ ...values, showModal: false });
  };
  const handleCloseAlert = () => {
    setValues({ ...values, showAlert: false });
  };

  const handleSignup = () => {
    handleCloseModal();
    setuserRole(4);
  };

  return (
    <React.Fragment>
      <AuthLandingScreen height="1120px">
        {userRole === 0 ? (
          <GreetingScreen handleuserRoleChange={handleuserRoleChange} />
        ) : (
          <AuthSignUpForm
            title="Welcome!"
            authButtonText="Sign Up"
            navigationButtonText="Log In Here"
            authButtonAction={handleShowModal}
            navigationButtonTextAction={() => history.push(ROUTES.SIGN_IN)}
            userRole={userRole}
            returnToSignUpAction={() => setuserRole(0)}
          />
        )}
        {showModal && (
          <SignUpModal
            open={showModal}
            close={handleCloseModal}
            confirmAction={() => setValues({ ...values, showAlert: true })}
            handleSignup={handleSignup}
          />
        )}
        {showAlert && (
          <SignUpSnackBar width={{ xs: 320, md: 570 }} open={showAlert} close={handleCloseAlert} />
        )}
      </AuthLandingScreen>
    </React.Fragment>
  );
};

export default CreateAccount;
