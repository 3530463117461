import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

import COLORS from "constants/colors";

export const StaffAdminSignUp = () => {
  return (
    <React.Fragment>
      <Box bgcolor={COLORS.common.staff} height="100vh">
        <Box
          sx={{ position: "absolute", top: "10%", right: "10%" }}
          display={{ xs: "none", md: "flex" }}
        >
          <img src="assets/icons/staff-admin-top.png" alt="staff-admin" />
        </Box>

        <Grid container justifyContent="center">
          <Grid item md={6} textAlign="center">
            <img src="assets/images/Think12Logo.png" alt="" />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={4}>
          <Grid item md={6} xs={12} px={{ xs: 2, md: 0 }}>
            <Paper
              sx={{
                width: "100%",
                height: 600,
                boxShadow: "0px 4px 20px 15px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Box textAlign="center" py={5}>
                <Typography
                  fontSize={{ md: 56, xs: 35 }}
                  fontWeight={900}
                  color={COLORS.secondary.main}
                >
                  Welcome back!
                </Typography>
                <Box mt={1}>
                  <Typography
                    fontSize={{ md: 22 }}
                    fontWeight={400}
                    color={COLORS.secondary.main}
                  >
                    Log into your account and start editing content
                  </Typography>
                </Box>
              </Box>
              <Grid
                px={5}
                mt={4}
                spacing={2}
                container
                justifyContent={{ xs: "center", md: "start" }}
              >
                <Grid item md={12} xs={12} sm={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField fullWidth placeholder="Enter" margin="normal" />
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <Box display="flex" justifyContent="space-between">
                    <InputLabel>Password</InputLabel>
                    <InputLabel sx={{ color: COLORS.primary.main }}>
                      Forgot Password?
                    </InputLabel>
                  </Box>
                  <TextField
                    fullWidth
                    type="password"
                    placeholder="Enter"
                    margin="normal"
                  />
                </Grid>

                <Grid item md={12} xs={12} textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      bgcolor: "secondary.main",
                      borderRadius: 50,
                      width: { xs: 200, md: 250 },
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Box
          sx={{ position: "absolute", bottom: "1%", left: "1%" }}
          display={{ xs: "none", md: "flex" }}
        >
          <img src="assets/icons/staff-admin-bottom.png" alt="staff-admin" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default StaffAdminSignUp;
