import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const LeaderCard = (props) => {
  const { image, userName, subject, width, height } = props || {};

  return (
    <Box
      sx={{
        width: width ? width : 350,
        height: height ? height : 'auto',
        background: '#ffff',
        boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <img src={image} alt='' width='100%' height='auto' />
      </Box>
      <Box p={4}>
        <Typography fontWeight={900} fontSize={22}>
          {userName}
        </Typography>
        <Typography fontSize={14}>{subject}</Typography>
      </Box>
    </Box>
  );
};

export default LeaderCard;
