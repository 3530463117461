import { CSSStyleDeclaration } from "cssstyle";

const isCSSProperty = (property, cssDeclaration) => {
  return property in cssDeclaration;
};

// Function to extract CSS style properties from an object
export const extractCSSProperties = (obj) => {
  const cssDeclaration = new CSSStyleDeclaration();
  const styleObj = {};

  Object.keys(obj).forEach(key => {
    if (isCSSProperty(key, cssDeclaration)) {
      styleObj[key] = obj[key];
    }
  });

  return styleObj;
};
