import React, { useEffect, useState, useContext } from "react";
import ConfigurationContext from "../../contexts/ConfigurationContext";

import apiRequest from "api/apiRequest";

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

function htmlEncode(input) {
  const el = document.createElement("div");
  el.innerText = input;
  return el.innerHTML;
}

const TranslationWrapper = ({ children }) => {
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);
  const storedTargetLanguage = localStorage.getItem("targetLanguage") || "en";
  const [targetLanguage, setTargetLanguage] = useState(storedTargetLanguage);
  const [isTranslating, setIsTranslating] = useState(false);
  let loading = false;

  const filterStrings = strings => {
    //remove any non-latin1 chars from strings
    //eslint-disable-next-line
    strings = strings.map(string => string.replace(/[^\x00-\x7F]/g, ""));

    //check if a valid strings for translation
    strings = strings.filter(string => {
      let cpy = string;
      //check if string would be empty if we removed whitespace and newlines
      cpy = cpy.replace(/\s/g, ""); //remove whitespace
      cpy = cpy.replace(/\n/g, ""); //remove newlines

      if (cpy.length > 1) {
        return string;
      }
      return null;
    });
    return strings;
  };

  // Add targetLanguage parameter to translateStrings function
  const translateStrings = (nodesData, targetLanguage) => {
    let strings = nodesData.map(({ string }) => string);
    strings = filterStrings(strings);

    setIsTranslating(true);

    const translationsCacheKey = `translations_cache_${targetLanguage}`;
    const translationCache = JSON.parse(localStorage.getItem(translationsCacheKey)) || {};

    const uncachedStrings = strings.filter(string => translationCache[string] === undefined);

    if (uncachedStrings.length > 0) {
      loading = true;
      return apiRequest({
        url: `translation/${targetLanguage}`,
        method: "POST",
        data: {
          strings: uncachedStrings,
        },
      }).then(response => {
        const translations = response;
        uncachedStrings.forEach((string, index) => {
          translationCache[string] = translations[index];
        });

        localStorage.setItem(translationsCacheKey, JSON.stringify(translationCache));

        //combine cached and uncached translations
        loading = false;
        return strings.map(string => translationCache[string]);
      });
    } else {
      return Promise.resolve(strings.map(string => translationCache[string]));
    }
  };

  const handleLanguageChange = e => {
    const newTargetLanguage = e.target.value;
    setTargetLanguage(newTargetLanguage);
    localStorage.setItem("targetLanguage", newTargetLanguage);
    window.location.reload();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (loading || isTranslating || targetLanguage === "en") {
        return;
      }
      // Collect nodes to translate
      const textNodes = getTextNodes(document.body);
      const imgAltNodes = getImgAltNodes(document.body);
      const inputPlaceholderNodes = getAttributeNodes(document.body, "input", "placeholder");

      let nodes = [].concat(textNodes, imgAltNodes, inputPlaceholderNodes);
      nodes = nodes.filter(node => {
        const alreadyTranslated = node.parentNode.getAttribute("data-translated");
        const shouldTranslate = node.parentNode.matches(".translate-ignore") === false;
        return alreadyTranslated !== "1" && shouldTranslate;
      });
      let nodesData = nodes.map(node => {
        let originalString;
        if (node.nodeType === Node.TEXT_NODE) {
          originalString = node.textContent;
        } else if (node.tagName === "IMG") {
          originalString = node.alt;
        } else if (node.tagName === "INPUT") {
          originalString = node.placeholder;
        }
        if (filterStrings([originalString]).length === 0) {
          return null;
        }
        return { string: originalString, node };
      });
      //remove nulls
      nodesData = nodesData.filter(nodeData => nodeData !== null);

      if (nodesData.length > 0) {
        translateStrings(nodesData, targetLanguage).then(translatedStrings => {
          translatedStrings.forEach((translatedString, index) => {
            const originalString = nodesData[index].string;
            const nodeData = nodesData.find(nodeData => nodeData.string === originalString);
            const { node } = nodeData;
            if (node.nodeType === Node.TEXT_NODE) {
              node.textContent = htmlDecode(translatedString);
              node.innerHTML = htmlEncode(translatedString);
              node.parentNode.setAttribute("data-translated", 1);
            } else if (node.tagName === "IMG") {
              node.alt = htmlDecode(translatedString);
              node.parentNode.setAttribute("data-translated", 1);
            } else if (node.tagName === "INPUT") {
              node.placeholder = htmlDecode(translatedString);
              node.parentNode.setAttribute("data-translated", 1);
            }
          });
        });
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [targetLanguage]);

  const getImgAltNodes = node => {
    let imgAltNodes = [];
    if (node.tagName === "IMG" && node.hasAttribute("alt")) {
      imgAltNodes.push(node);
    } else {
      node.childNodes.forEach(child => {
        imgAltNodes = imgAltNodes.concat(getImgAltNodes(child));
      });
    }
    return imgAltNodes;
  };

  const getTextNodes = node => {
    let textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      node.childNodes.forEach(child => {
        textNodes = textNodes.concat(getTextNodes(child));
      });
    }
    return textNodes;
  };

  const getAttributeNodes = (node, tagName, attributeName) => {
    let attributeNodes = [];
    if (node.tagName === tagName.toUpperCase() && node.hasAttribute(attributeName)) {
      attributeNodes.push(node);
    } else {
      node.childNodes.forEach(child => {
        attributeNodes = attributeNodes.concat(getAttributeNodes(child, tagName, attributeName));
      });
    }
    return attributeNodes;
  };

  let languages = [
    { code: "en", name: "English" },
    { code: "zh", name: "Chinese" },
    { code: "ht", name: "Haitian Creole" },
    { code: "es", name: "Spanish" },
    { code: "", name: "---------" },
    { code: "af", name: "Afrikaans" },
    { code: "sq", name: "Albanian" },
    { code: "am", name: "Amharic" },
    { code: "ar", name: "Arabic" },
    { code: "hy", name: "Armenian" },
    { code: "az", name: "Azerbaijani" },
    { code: "eu", name: "Basque" },
    { code: "be", name: "Belarusian" },
    { code: "bn", name: "Bengali" },
    { code: "bs", name: "Bosnian" },
    { code: "bg", name: "Bulgarian" },
    { code: "ca", name: "Catalan" },
    { code: "ceb", name: "Cebuano" },
    { code: "ny", name: "Chichewa" },
    { code: "zh-TW", name: "Chinese (Traditional)" },
    { code: "co", name: "Corsican" },
    { code: "hr", name: "Croatian" },
    { code: "cs", name: "Czech" },
    { code: "da", name: "Danish" },
    { code: "nl", name: "Dutch" },
    { code: "eo", name: "Esperanto" },
    { code: "et", name: "Estonian" },
    { code: "tl", name: "Filipino" },
    { code: "fi", name: "Finnish" },
    { code: "fr", name: "French" },
    { code: "fy", name: "Frisian" },
    { code: "gl", name: "Galician" },
    { code: "ka", name: "Georgian" },
    { code: "de", name: "German" },
    { code: "el", name: "Greek" },
    { code: "gu", name: "Gujarati" },
    { code: "ha", name: "Hausa" },
    { code: "haw", name: "Hawaiian" },
    { code: "iw", name: "Hebrew" },
    { code: "hi", name: "Hindi" },
    { code: "hmn", name: "Hmong" },
    { code: "hu", name: "Hungarian" },
    { code: "is", name: "Icelandic" },
    { code: "ig", name: "Igbo" },
    { code: "id", name: "Indonesian" },
    { code: "ga", name: "Irish" },
    { code: "it", name: "Italian" },
    { code: "ja", name: "Japanese" },
    { code: "jw", name: "Javanese" },
    { code: "kn", name: "Kannada" },
    { code: "kk", name: "Kazakh" },
    { code: "km", name: "Khmer" },
    { code: "ko", name: "Korean" },
    { code: "ku", name: "Kurdish (Kurmanji)" },
    { code: "ky", name: "Kyrgyz" },
    { code: "lo", name: "Lao" },
    { code: "la", name: "Latin" },
    { code: "lv", name: "Latvian" },
    { code: "lt", name: "Lithuanian" },
    { code: "lb", name: "Luxembourgish" },
    { code: "mk", name: "Macedonian" },
    { code: "mg", name: "Malagasy" },
    { code: "ms", name: "Malay" },
    { code: "ml", name: "Malayalam" },
    { code: "mt", name: "Maltese" },
    { code: "mi", name: "Maori" },
    { code: "mr", name: "Marathi" },
    { code: "mn", name: "Mongolian" },
    { code: "my", name: "Myanmar (Burmese)" },
    { code: "ne", name: "Nepali" },
    { code: "no", name: "Norwegian" },
    { code: "ps", name: "Pashto" },
    { code: "fa", name: "Persian" },
    { code: "pl", name: "Polish" },
    { code: "pt", name: "Portuguese" },
    { code: "pa", name: "Punjabi" },
    { code: "ro", name: "Romanian" },
    { code: "ru", name: "Russian" },
    { code: "sm", name: "Samoan" },
    { code: "gd", name: "Scots Gaelic" },
    { code: "sr", name: "Serbian" },
    { code: "st", name: "Sesotho" },
    { code: "sn", name: "Shona" },
    { code: "sd", name: "Sindhi" },
    { code: "si", name: "Sinhalese" },
    { code: "sk", name: "Slovak" },
    { code: "sl", name: "Slovenian" },
    { code: "so", name: "Somali" },

    { code: "su", name: "Sundanese" },
    { code: "sw", name: "Swahili" },
    { code: "sv", name: "Swedish" },
    { code: "tg", name: "Tajik" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "th", name: "Thai" },
    { code: "tr", name: "Turkish" },
    { code: "uk", name: "Ukrainian" },
    { code: "ur", name: "Urdu" },
    { code: "uz", name: "Uzbek" },
    { code: "vi", name: "Vietnamese" },
    { code: "cy", name: "Welsh" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "zu", name: "Zulu" },
  ];
  return (
    <>
      {children}

      <div
        style={{
          position: "fixed",
          bottom: "13px",
          right: "6rem",
          zIndex: 1,
        }}
      >
        {isFeatureEnabled("translation") && (
          <div
            style={{
              background: "#FFF",
              borderRadius: ".3em",
              border: "solid 4px",
              borderColor: configurations.color2,
              padding: "1em",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span className="translate-ignore">Translate</span>
            <br />
            <select
              style={{ marginLeft: "1em" }}
              value={targetLanguage}
              onChange={handleLanguageChange}
            >
              {languages.map(language => (
                <option key={language.code} className="translate-ignore" value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default TranslationWrapper;
