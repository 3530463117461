import React from 'react';
import { Grid, Box, Container, Typography, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReviewRow from './ReviewRow';

export const Reviews = ({ formData, updateFormData }) => {
  const addNewReview = () => {
    const newReviews = [
      ...formData.reviews,
      {
        body: '',
        is_approved: false,
        reviewer: {
          first_name: '',
          title: 'None',
        },
      },
    ];
    updateFormData('reviews', newReviews);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5}>
          <Typography variant='h5' fontWeight='bold'>
            Reviews
          </Typography>
        </Box>

        {formData?.reviews?.map((r, idx) => (
          <ReviewRow rev={r} idx={idx} updateFormData={updateFormData} formData={formData} />
        ))}

        <Grid container spacing={3} mt={2}>
          <Grid item md={12} xs={12} sm={12}>
            <Box display='flex' alignItems='center' onClick={addNewReview} sx={{ cursor: 'pointer' }}>
              <Fab color='secondary'>
                <AddIcon style={{ color: '#fff' }} />
              </Fab>
              <Typography ml={3} variant='body1' fontWeight='bold'>
                Add new review
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Reviews;
