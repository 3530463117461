import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@mui/material';
import { ProgressBar } from 'components/ProgressBar';
import { toast } from 'react-toastify';

export const UploadControl = (props) => {
  const { icon, title1, title2, bgColor, title, height, padding, setUploadFile } = props || {};
  const [image, setImage] = useState(null);
  const [showProgress, setShowProgress] = useState(false);

  const isFirefox = () => {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  };

  React.useEffect(() => {
    console.log('Upload control loaded');
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles[0].type.toLowerCase().startsWith('image')) {
      toast.error('Please provide an image file!');
      return;
    }
    if (acceptedFiles[0].size > 5242880) {
      toast.error('Image size must be less than 5 MB!');
      return;
    }
    setImage(acceptedFiles[0]);
    setUploadFile(acceptedFiles[0]);
    setShowProgress(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    // accept:{'image/*':[]}
  });

  const imageRef = useRef();

  //Handle Image change

  const handleImageChange = async (e) => {
    console.log('Image changed');
    if (!e.target.files[0].type.toLowerCase().startsWith('image')) {
      toast.error('Please provide an image file!');
      return;
    }
    if (e.target.files[0].size > 5242880) {
      toast.error('Image size must be less than 5 MB!');
      return;
    }
    setImage(e.target.files[0]);
    setUploadFile(e.target.files[0]);
    setShowProgress(true);
  };

  //Linear Progress
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box onClick={() => isFirefox() ? imageRef.current.click() : null}>
      <Box p={padding} bgcolor={bgColor} sx={{ width: '100%' }}>
        <Grid
          {...getRootProps()}
          md={12}
          sm={12}
          xs={12}
          sx={{
            border: '1px dashed #A3A3A3',
            height: { height },
            borderRadius: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          {!image ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img id='img' src={icon} alt='' width={'15%'} height={'15%'} />
              <Grid
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <Typography textAlign='center' id='label' mt={1} variant='body2'>
                    {title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography id='label' variant='body2'>
                    {title1} <span style={{ color: '#2E75FF', cursor: 'pointer' }}>{title2}</span>
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ) : (
            <img src={URL.createObjectURL(image)} alt='profile' style={ImageStyle} />
          )}
          <input
            type='file'
            id='fileInput'
            className='fileElem'
            accept='image/*'
            ref={imageRef}
            onChange={handleImageChange}
            style={{ width: 0, height: 0 }}
          />
        </Grid>

        {showProgress ? (
          <Box sx={{ width: '100%', marginTop: '30px' }}>
            <ProgressBar value={progress} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
export default UploadControl;

const ImageStyle = {
  width: '95%',
  height: '95%',
  display: 'flex',
  borderRadius: '10px',
};
