export const HOME_VIDEO_CARD = [
  'assets/images/new-home-video1.png',
  'assets/images/home-video2.png',
  'assets/images/home-video3.png',
  'assets/images/home-video4.png',
];

export const CURRICULAR_TEXT = [
  // "Search and Connect with other families",
  // "Explore and Join Family Groups",
  // "Create and Update your Family Page",

  'Connect with Current Families',
  'Explore Shared Interests',
  'Get Your Questions Answered',
];
