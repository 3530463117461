import { SettingsInputAntennaTwoTone } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { ROUTES } from "constants/routes";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import COLORS from "constants/colors";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FollowButton } from "./FollowButton";

export const ClubPageHeading = props => {
  const [club, setClub] = useState(props.club || {});
  const { push } = useHistory();
  const goBack = () => {
    push({ pathname: ROUTES.CLUB_HUB });
    sessionStorage.setItem("backButtonClick", true);
  };
  useEffect(() => {
    if (props.club) setClub(props.club);
  }, [props.club]);

  return (
    <Container maxWidth="xl">
      <Grid
        sx={{ marginBottom: { md: "unset", lg: "32px", xl: "32px" } }}
        container
        alignItems="center"
      >
        <Grid item lg={10} md={12} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontStyle: "normal",
              color: "#131416",
              fontWeight: "900",
              lineHeight: "58px",
              fontSize: "50px",
              marginTop: "50px",
            }}
          >
            {club.name}
          </Typography>
          <Grid
            item
            md={12}
            xs={12}
            display={{ xs: "flex", md: "flex", sm: "flex" }}
            mt={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Box display="flex">
              <ArrowBackIcon
                sx={{
                  fontSize: "23px",
                  cursor: "pointer",
                  "padding-bottom": "3px",
                }}
                onClick={() => goBack()}
              />
              <Typography
                ml={1}
                fontWeight={"bold"}
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => goBack()}
              >
                See more
              </Typography>
            </Box>
            <FollowButton club={club} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
