import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
// import { PROGRAM_CONTENT } from 'constants/program_content';
// import urls from 'api/urls';
import { FAMILY_MEMBER_ICONS } from '../../../../constants/family_member_icons';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const AmbassadorCard = (props) => {
  const { image, userName, grades, onDelete, socialMedias, familyMembers, userId } = props || {};
  const theme = useTheme();
  const { configurations } = React.useContext(ConfigurationContext);

  const smTwitter = socialMedias?.find((sm) => sm.type.toLowerCase().trim() === 'twitter');
  const smLinkedin = socialMedias?.find((sm) => sm.type.toLowerCase().trim() === 'linkedin');
  const smFacebook = socialMedias?.find((sm) => sm.type.toLowerCase().trim() === 'facebook');

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        background: theme.palette.background.default,
        boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)',
        position: 'relative',
      }}
    >
      {onDelete && (
        <Box
          sx={{
            position: 'absolute',
            right: '5%',
            top: '-5%',
            cursor: 'pointer',
          }}
        >
          <img src='/assets/icons/cancel-icon.svg' alt='' width={'40px'} height={'40px'} onClick={onDelete} />
        </Box>
      )}
      <Link to={`/family-network/ambassador/${userId}`}>
        <Box
          sx={{
            width: '100%',
            height: '300px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            // src={image ? image : "/assets/images/profiles-admin.svg"}
            src={
              // image
              //   ? image
              //   : PROGRAM_CONTENT[urls.programId()].default_family_profile_image
              //   ? PROGRAM_CONTENT[urls.programId()].default_family_profile_image
              //   : '/assets/images/family-profile.svg'

              image
                ? image
                : configurations?.default_user_profile_image?.path || '/assets/images/family-profile.svg'
            }
            alt={userName}
            title={userName}
            width='auto'
            height='100%'
          />
        </Box>
      </Link>
      <Box display='flex' justifyContent='space-between'>
        <Link to={`/family-network/ambassador/${userId}`}>
          <Typography fontWeight={900} fontSize={22} ml={4}>
            {userName}
          </Typography>
        </Link>
        {grades && grades.length ? (
          <Box
            className='family_members_grade_bands'
            sx={{
              background: `${theme.palette.primary.main}`,
              width: 100,
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '0.25em 0.25em',
            }}
          >
            {grades.map((g) => (
              <Typography color='white' align='center' fontSize={15}>
                {g.gradeBand}
              </Typography>
            ))}
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box px={4} pt={1} pb={3}>
        {familyMembers && familyMembers.length ? (
          familyMembers.map((fm) => (
            <img
              src={FAMILY_MEMBER_ICONS.find((f) => f.id === fm.icon_id)?.icon}
              alt=''
              style={{ marginRight: '10px' }}
            />
          ))
        ) : (
          <Box height='26px'></Box>
        )}
        <Grid item container justifyContent='space-between' mt={3}>
          <Box>
            <Link to={`/family-network/ambassador/${userId}`}>
              <Button
                variant='outlined'
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                  borderRadius: '100px',
                }}
              >
                Contact
              </Button>
            </Link>
          </Box>
          {socialMedias && socialMedias.length ? (
            <Box>
              {smTwitter && (
                <Link to={smTwitter.url} target='_blank'>
                  <IconButton>
                    <img src={'/assets/icons/twitter.png'} alt='twitter' />
                  </IconButton>
                </Link>
              )}
              {smFacebook && (
                <Link to={smFacebook.url} target='_blank'>
                  <IconButton>
                    <img src={'/assets/icons/facebook.png'} alt='facebook' />
                  </IconButton>
                </Link>
              )}
              {smLinkedin && (
                <Link to={smLinkedin.url} target='_blank'>
                  <IconButton>
                    <img src={'/assets/icons/linkedIn.png'} alt='linkedin' />
                  </IconButton>
                </Link>
              )}
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AmbassadorCard;
