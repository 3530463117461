import React from "react";
import {getElementSize} from "../utils/numToMeasurement";

export const VIDEO = ({
  src,
  style,
}) => (
  <video
    style={{
      borderRadius: getElementSize(style?.borderRadius),
      margin: getElementSize(style?.margin),
      height: getElementSize(style?.height),
      width: getElementSize(style?.width),
    }}
    src={src}
  >
    <source src={src} type="video/mp4" />
    <source src={src} type="video/ogg" />
  </video>
);
