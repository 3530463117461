import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const Explore = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Box
        // bgcolor=' #5A92FF'
        // bgcolor="#046A38"
        bgcolor={theme.palette.primary.main}
      >
        <Box position='relative'>
          <Container maxWidth='xl'>
            <Grid className='beyondacd' container py={10} px={10}>
              <Grid className='beyondacd-flexdir' item md={6}>
                <Grid className='flex-horz' container spacing={5}>
                  {/* <Grid item md={2}></Grid> */}
                  <Grid item md={1}></Grid>
                  <Grid item md={4}>
                    <img
                      // src="/assets/images/home-explore1.png"
                      src='/assets/images/new-home-explore1.png'
                      alt=''
                      width='100%'
                      // style={{ borderRadius: 10 }}
                      style={{ zIndex: '10', position: 'relative' }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <img
                      // src="/assets/images/home-explore2.png"
                      src='/assets/images/new-home-explore2.png'
                      alt=''
                      width='100%'
                      // style={{ borderRadius: 10 }}
                    />
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
                <Grid
                  container
                  // mt={3}
                  mt={0}
                  spacing={4}
                >
                  <Grid item md={6} sm={6} xs={6} position='relative'>
                    <img
                      // src="/assets/images/home-explore3.png"
                      src='/assets/images/new-home-explore3.png'
                      alt=''
                      width='100%'
                      // style={{ borderRadius: 10 }}
                      style={{ zIndex: '10', position: 'relative' }}
                    />

                    <img
                      className='small-circle'
                      alt=''
                      src='/assets/images/circle.png'
                      // style={{ position: "absolute", top: "-4%", left: "-4%" }}
                      style={{
                        position: 'absolute',
                        top: '-17%',
                        left: '-14%',
                        opacity: '50%',
                      }}
                    />
                    <img
                      className='small-circle-2'
                      alt=''
                      src='/assets/images/circle2.svg'
                      style={{
                        position: 'absolute',
                        top: '40%',
                        left: '75%',
                        opacity: '98%',
                        zIndex: 1,
                      }}
                    />
                  </Grid>
                  <Grid position='relative' item md={6} sm={6} xs={6}>
                    <Box direction='column' display='flex' flexDirection='column'>
                      <Box zIndex={2}>
                        <img
                          // src="/assets/images/home-explore4.png"
                          src='/assets/images/new-home-explore4.png'
                          alt=''
                          width={'50%'}
                        />
                      </Box>

                      <Box mt={5} zIndex={2}>
                        <img
                          // src="/assets/images/home-explore5.png"
                          src='/assets/images/new-home-explore5.png'
                          alt=''
                          width={'50%'}
                          // style={{ borderRadius: 10 }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <img
                        src='/assets/images/new-home-explore6.png'
                        alt=''
                        width={'50%'}
                        // style={{ borderRadius: 10 }}
                        style={{
                          position: 'absolute',
                          bottom: '25%',
                          right: '-13%',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className='clubhub-mob' item md={6}>
                <Box mt={10}>
                  <Typography sx={{ textAlign: 'end' }} variant='h6' color='white' fontWeight='bold' fontSize={16}>
                    CLUBHUB™
                  </Typography>
                </Box>
                <Box
                  // mt={4}
                  mt={0}
                >
                  <Typography
                    sx={{ textAlign: 'end' }}
                    variant='h2'
                    lineHeight={1}
                    // color="black"
                    color='white'
                    fontWeight={'800'}
                    fontSize={'50px'}
                  >
                    {/* Beyond academics there’s a world of activity for your child
                    to explore. */}
                    Beyond academics there’s a world of activity for your son to explore.
                  </Typography>
                </Box>
                <Box mt={3} sx={{ textAlign: 'end' }}>
                  <Box>
                    <Button
                      variant='contained'
                      color={'secondary'}
                      sx={{
                        alignSelf: 'end',
                        borderRadius: 10,
                        paddingX: 7,
                        paddingY: 2,
                        marginTop: 4,
                        // backgroundColor: "#EFAC00",
                        // backgroundColor: '#18381E',
                        backgroundColor: theme.palette.secondary.main,
                      }}
                    >
                      <Typography
                        textTransform={'none'}
                        fontWeight={'600'}
                        fontSize={{ xs: 10, md: 13, sm: 10 }}
                        color='#fff'
                      >
                        {/* Explore Everything Our <br />
                        School Has to Offer */}
                        Explore Clubs And <br /> Student Activities
                      </Typography>
                    </Button>
                    <Box>
                      <Typography
                        marginTop={4}
                        variant='body2'
                        // fontWeight="bold"
                        fontWeight='500'
                        color='white'
                      >
                        {/* Find the best extra-curricular activities for your child */}
                        We have more than 50 student organizations to choose from! <br /> If we don't already have an
                        organization, start your own!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Explore;
