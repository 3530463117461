import { Box } from "@mui/system";
import Container from "@mui/system/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";

export const SectionCarousel = ({
  sectionTitle,
  canGoForward,
  handleNext,
  handlePrevious,
  children,
}) => {
  const [offset, setOffset] = useState(0);

  const handleRightClick = () => {
    if (canGoForward) {
      handleNext(offset + 3);
      setOffset(offset + 3);
    }
  };

  const handleLeftClick = () => {
    if (offset > 0) {
      handlePrevious(offset - 3);
      setOffset(offset - 3);
    }
  };

  const hasMoreItems = canGoForward || offset > 0;

  return (
    <Box mt={5} mb={5}>
      <Container maxWidth="xl">
        {sectionTitle && (
          <Box display="flex" justifyContent="center">
            <Grid item container>
              <Grid item md={12} sm={12} lg={12}>
                <Typography color="#131416" fontSize={{ md: 40 }} fontWeight={700}>
                  {sectionTitle}
                </Typography>
                <Grid item md={12}></Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box
          mt={6}
          sx={{
            padding: {
              xs: hasMoreItems ? "0 4em" : "unset",
              sm: hasMoreItems ? "0 4em" : "unset",
              md: "0 4em",
            },
            position: "relative",
          }}
        >
          {offset > 0 && (
            <IconButton
              style={{ position: "absolute", top: "50%", left: "0" }}
              onClick={handleLeftClick}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Grid container spacing={3} justifyContent="center">
            {children}
          </Grid>
          {canGoForward && (
            <IconButton
              style={{ position: "absolute", top: "50%", right: "0" }}
              onClick={handleRightClick}
            >
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};
