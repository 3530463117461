import React from 'react';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import COLORS from 'constants/colors';

export const SearchArea = () => {
  return (
    <React.Fragment>
      <Box>
        <Container
          sx={{ maxWidth: { xl: 'xl', lg: 'lg' } }}
          position='relative'
        >
          <Box>
            <Box
              component='form'
              mt={{ md: 22, sm: 53, xs: 28 }}
              sx={{
                p: 2,
                my: 5,
                width: 'auto',
                bgcolor: 'white',
                alignItems: 'center',
                borderRadius: '26px',
                display: { lg: 'flex', md: 'none' },
                boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
                marginBottom: '100px',
              }}
              display={{ xs: 'none', md: 'none', lg: 'flex' }}
            >
              <IconButton sx={{ p: '10px' }} aria-label='menu'>
                <SearchIcon sx={{ color: COLORS.blue.secondary }} />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder='Search by staff name, subject, club'
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
                <FilterIcon sx={{ color: COLORS.blue.secondary }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                left: '6%',
                top: '42%',
                zIndex: -1,
                position: 'absolute',
              }}
            >
              <img src='assets/images/background-circle.svg' alt='' />
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default SearchArea;
