import React from "react";
import FormControl from "@mui/material/FormControl";
import SelectSchool from "./SelectSchool";
import CustomSelect from "./CustomSelect";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/system/Box";
import ConfigurationContext from "contexts/ConfigurationContext";
import RemoveIcon from "@mui/icons-material/Remove";
import { Fab } from "@mui/material";

function EducationRow({ education, formData, updateFormData, removeEducation }) {
  const [isSelectSchool, setIsSelectSchool] = React.useState(false);
  const { schools } = React.useContext(ConfigurationContext);
  const schoolName = schools.find(s => s.id === education?.outcome_id)?.outcomeTitle;

  const updateEducationDegree = value => {
    const newEducations = formData.educations.map(e =>
      e.ourId === education.ourId ? { ...e, degree: value } : e
    );
    updateFormData("educations", newEducations);
  };

  const updateEducationSchool = value => {
    const newEducations = formData.educations.map(e =>
      e.ourId === education.ourId ? { ...e, outcome_id: value } : e
    );
    updateFormData("educations", newEducations);
  };

  return (
    <div>
      <Grid container mt={2} spacing={3}>
        <Grid item lg={5} md={6} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            Degree and Area of Study
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              hiddenLabel
              fullWidth
              id="last-name"
              className="family-input-fields"
              sx={{ mb: 2 }}
              value={education?.degree}
              onChange={e => updateEducationDegree(e.target.value)}
            ></TextField>
          </FormControl>
        </Grid>

        <Grid item lg={6} md={6} xs={12} sm={12}>
          <InputLabel className="MuiInputLabel-Profile-label" shrink>
            School
          </InputLabel>
          <FormControl fullWidth>
            <CustomSelect
              InputProps={{ disableUnderline: "false" }}
              isSelectSchool={isSelectSchool}
              setIsSelectSchool={setIsSelectSchool}
              value={schoolName}
            ></CustomSelect>
          </FormControl>
        </Grid>

        <Grid item lg={1} md={2} xs={12} sm={6}>
          <Fab color="primary" onClick={e => removeEducation(education.ourId)} sx={{ mt: 2 }}>
            <RemoveIcon style={{ color: "#fff" }} />
          </Fab>
        </Grid>
      </Grid>
      {!isSelectSchool && (
        <Box my={5}>
          <Divider />
        </Box>
      )}
      {isSelectSchool && (
        <SelectSchool
          setSchool={updateEducationSchool}
          setIsSelectSchool={setIsSelectSchool}
          school={education?.outcome_id}
        />
      )}
    </div>
  );
}

export default EducationRow;
