import React from 'react';
import Box from '@mui/material/Box';

import './quote.css';

export const Quote = ({ quoteText, quoteUser }) => {
  return (
    <Box className='quote-container'>
      {!!quoteText && (
        <Box className='quote-content'>
          <img
            className='left-quote'
            alt=''
            src='/assets/icons/quote-start.svg'
          />
          <Box className='quote-text'>{quoteText}</Box>
          <Box className='bottom-row'>
            <span className='quote-user'>{quoteUser}</span>
            <img
              className='right-quote'
              alt=''
              src='/assets/icons/quote-end.svg'
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Quote;
