import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { NiceAvatar } from 'components/NiceAvatar';
import { backgroundColors, clothsColors, faceColors, hairColors } from './configs';
import { ColorCircle } from './ColorCircle';

export const AvatarCanvas = ({ setUploadFile }) => {
  const [state, setState] = useState({
    bgColor: '#FFEFE3',
    faceColor: '#FFEFE3',
    hairStyle: 'normal',
    hairColor: '#506AF4',
    hatColor: 'none',
    showHat: false,
    showGlasses: false,
    shirtStyle: 'hoody',
    shirtColor: '#CDECF9',
  });

  const getHairStyle = (hairName, hairStyle, ml, mb) => {
    return (
      <Box
        ml={ml}
        mb={mb}
        onClick={() => setState({ ...state, hairStyle })}
        sx={{
          width: 47,
          height: 47,
          '&:hover': {
            opacity: [0.9, 0.8, 0.7],
            cursor: 'pointer',
          },
        }}
      >
        <img src={`/assets/images/${hairName}.svg`} alt={hairStyle} />
      </Box>
    );
  };

  const getShirtStyle = (shirtName, shirtStyle, ml, mb) => {
    return (
      <Box
        ml={ml}
        mb={mb}
        onClick={() => setState({ ...state, shirtStyle })}
        sx={{
          width: 47,
          height: 47,
          '&:hover': {
            opacity: [0.9, 0.8, 0.7],
            cursor: 'pointer',
          },
        }}
      >
        <img src={`/assets/images/${shirtName}.svg`} alt={shirtStyle} />
      </Box>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        maxHeight={{ lg: '400px', md: '475px', sm: '460px', xs: '445px' }}
      >
        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} order={{ xs: 2, sm: 2, md: 1, lg: 2 }}>
          <Typography variant='h6' component='div' fontWeight='bold'>
            Color Background
          </Typography>
          <Grid display='flex' mt={2} mb={2}>
            {backgroundColors.map((bc, idx) => (
              <ColorCircle
                color={bc}
                onClick={() => setState({ ...state, bgColor: bc })}
                ml={idx === 0 ? 0 : 1}
                key={idx}
              />
            ))}
          </Grid>
          <Divider />
          <Typography variant='h6' component='div' fontWeight='bold' mt={2}>
            Character
          </Typography>
          <Typography variant='h7' component='div'>
            Color Skin
          </Typography>
          <Grid display='flex' mt={2} mb={2}>
            {faceColors.map((fc, idx) => (
              <ColorCircle
                color={fc}
                onClick={() => setState({ ...state, faceColor: fc })}
                ml={idx === 0 ? 0 : 1}
                key={idx}
              />
            ))}
          </Grid>
          <Divider />
          <Typography variant='h7' component='div' mt={2}>
            Hair Style
          </Typography>
          <Grid display='flex' mt={2} mb={2} sx={{ overflowY: 'hidden', overflowX: 'auto' }}>
            {getHairStyle('manLongHair', 'normal')}
            {getHairStyle('manhair', 'thick', 3)}
            {getHairStyle('manShort', 'mohawk', 3)}
            {getHairStyle('womanLong', 'womanLong', 3, 2)}
            {getHairStyle('womanShort', 'womanShort', 8)}
          </Grid>
          <Divider />
          <Typography variant='h7' component='div' mt={2}>
            Hair Color
          </Typography>
          <Grid display='flex' mt={2} mb={2}>
            {hairColors.map((hc, idx) => (
              <ColorCircle
                color={hc}
                onClick={() => setState({ ...state, hairColor: hc })}
                ml={idx === 0 ? 0 : 1}
                key={idx}
              />
            ))}
          </Grid>
          <Divider />
          <Typography variant='h7' component='div' mt={2}>
            Clothes
          </Typography>
          <Grid display='flex' mt={2} sx={{ overflowY: 'hidden', overflowX: 'auto' }}>
            {getShirtStyle('redHoody', 'hoody')}
            {getShirtStyle('redPolo', 'polo', 7)}
            {getShirtStyle('redShort', 'short', 7)}
          </Grid>
          <Divider />
          <Typography variant='h7' component='div' mt={2}>
            Clothes Color
          </Typography>
          <Grid display='flex' mt={2} mb={2}>
            {clothsColors.map((cc, idx) => (
              <ColorCircle
                color={cc}
                onClick={() => setState({ ...state, shirtColor: cc })}
                ml={idx === 0 ? 0 : 1}
                key={idx}
              />
            ))}
          </Grid>
          <Divider />
          <Typography variant='h7' component='div' mt={2}>
            Accesories
          </Typography>
          <Grid item xs={6} md={12} display='flex' mt={2} mb={2}>
            <Box
              onClick={() =>
                setState({
                  ...state,
                  showHat: !state.showHat,
                })
              }
              sx={{
                width: 47,
                height: 47,
                '&:hover': {
                  opacity: [0.9, 0.8, 0.7],
                  cursor: 'pointer',
                },
              }}
            >
              <img src='/assets/images/hat.svg' alt='hat' />
            </Box>
            <Box
              ml={2}
              onClick={() =>
                setState({
                  ...state,
                  showGlasses: !state.showGlasses,
                })
              }
              sx={{
                width: 47,
                height: 47,
                '&:hover': {
                  opacity: [0.9, 0.8, 0.7],
                  cursor: 'pointer',
                },
              }}
            >
              <img src='/assets/images/glasses.svg' alt='glasses' />
            </Box>
          </Grid>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          ml={{ xs: '0px', sm: '12%', md: '0px', lg: '0px' }}
        >
          <NiceAvatar
            bgColor={state.bgColor}
            faceColor={state.faceColor}
            hairStyle={state.hairStyle}
            hairColor={state.hairColor}
            hatColor={state.hatColor}
            showHat={state.showHat}
            showGlasses={state.showGlasses}
            shirtStyle={state.shirtStyle}
            shirtColor={state.shirtColor}
            setUploadFile={setUploadFile}
          />
        </Grid>
      </Grid>
    </>
  );
};
