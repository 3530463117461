import React from "react";
import COLORS from "constants/colors";
import Fab from "@mui/material/Fab";
import Box from "@mui/system/Box";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

export const AddStaffCard = (props) => {
  const { height,onOpenModal } = props || {};
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        bgcolor={COLORS.white.lighter}
        width="100%"
        height={height}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" onClick={onOpenModal}>
          <Fab color="primary" sx={{ backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: theme.palette.secondary.main }}}>
            <AddIcon />
          </Fab>
          <Typography
            ml={1}
            variant="body1"
            fontWeight="bold"
            color={theme.palette.primary.main}
          >
            Add Club Leader
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AddStaffCard;
