import Layout from './components/Layout';
import React from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import OurProfile from './components/OurProfile';
import Preferences from './components/Preferences';
const FamilyProfile = () => {
  const { search } = useLocation();
  const history = useHistory();
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), []);
  }

  const query = useQuery();
  const tab = query.get('tab') ? query.get('tab').toLowerCase() : 'profile';
  const [activeStep, setActiveStep] = React.useState(tab === 'preferences' ? 1 : 0);
  const changeActiveStep = (stepIndex) => {
    const newQueryParam = stepIndex === 0 ? 'families-profile' : stepIndex === 1 ? 'preferences' : '';
    const queryParams = qs.parse(search);
    const newQueries = { ...queryParams, tab: newQueryParam };
    history.push({ search: qs.stringify(newQueries) });
    setActiveStep(stepIndex);
  };

  switch (activeStep) {
    case 0:
      return (
        <Layout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <OurProfile />
        </Layout>
      );

    case 1:
      return (
        <Layout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <Preferences />
        </Layout>
      );

    default:
      throw new Error('Invalid route access');
  }
};

export default FamilyProfile;
