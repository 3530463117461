import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext } from "react";
import Divider from "@mui/material/Divider";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import { IndustryList } from "../../constants/Industry";

export const ProfessionalExperience = ({ profile }) => {
  const { configurations } = useContext(ConfigurationContext);

  const professions = profile?.professions || [];

  if (!professions.length) return;

  const getProfessionIndustry = value => {
    return IndustryList.find(({ key }) => key === value)?.value;
  };

  return (
    <Box>
      <>
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid item container md={10}>
            <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
              Professional Experience
            </Typography>
          </Grid>
        </Grid>
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid flexDirection="column" item container md={10}>
            {professions.map((profession, index) => (
              <>
                <Box sx={{ ml: 2 }} display="flex" alignItems="center" gap={8}>
                  <Grid>
                    {profession.industry && (
                      <Typography>{getProfessionIndustry(profession.industry)} Industry</Typography>
                    )}
                    {profession.company && <Typography>{profession.company} Company</Typography>}
                    {profession.job_title && (
                      <Typography>{profession.job_title} Job Title</Typography>
                    )}
                  </Grid>
                </Box>
                {index + 1 !== professions.length && (
                  <Divider
                    sx={{ mb: 2, mt: 2, borderWidth: "2px", borderColor: configurations.color1 }}
                  />
                )}
              </>
            ))}
          </Grid>
        </Grid>
      </>
    </Box>
  );
};
