import React from "react";
import ConfigurationContext from "contexts/ConfigurationContext";
import { NetworkHeader } from "../../../../components/Header/NetworkHeader";

export const GraduatesHeader = () => {
  const { configurations } = React.useContext(ConfigurationContext);

  const subTitle = `
    Use the ${configurations?.schoolShortName} alumni network to stay connected 
      with your classmates and remain an active member of the ${configurations?.schoolName} \n
     community!
  `;

  return (
    <NetworkHeader
      schoolName={configurations?.schoolName}
      subTitle={subTitle}
      schoolType={"Alumni Network"}
    />
  );
};
