import React from 'react';
import { useHistory } from 'react-router-dom';
import AuthSignUpForm from 'pages/ManageAuth/components/AuthSignUpForm';
import AuthLandingScreen from 'pages/ManageAuth/components/AuthLandingScreen';
import { ROUTES } from 'constants/routes';

export const ClubProfileSignUp = () => {
  const history = useHistory();

  return (
    <AuthLandingScreen
      height='950px'
      headerTitle='ClubHub Profile'
      image='assets/images/beautiful-female.png'
    >
      <AuthSignUpForm
        buttonsArea
        title='Welcome!'
        authButtonText='Sign Up'
        navigationButtonText='Log In Here'
        subTitle='Create a Profile and start sharing your story!'
        navigationButtonTextAction={() => history.push(ROUTES.SIGN_IN)}
      />
    </AuthLandingScreen>
  );
};

export default ClubProfileSignUp;
