import React from "react";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const SchoolActivities = ({ profile }) => {
  const foundAbout = profile?.school_activities_participation || [];

  if (!foundAbout.length) return;

  return (
    <Box>
      <>
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid item container md={10}>
            <Typography fontSize={{ md: 40 }} fontWeight={700} mt={3} p={{ xs: 4, md: 0 }}>
              School Activities I Participated in
            </Typography>
          </Grid>
        </Grid>
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid item container md={10}>
            <Typography fontSize={{ md: 18 }} ml={2}>
              {profile?.school_activities_participation?.join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};
