import React from 'react';
import COLORS from 'constants/colors';
import Box from '@mui/system/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ProfileContext from 'contexts/ProfileContext';
import ConfigurationContext from 'contexts/ConfigurationContext';
import MessagingContext from 'contexts/MessagingContext';
import { Badge } from '@mui/material';

export const SideBar = (props) => {
  const { setActiveStep, activeStep } = props || {};
  const [selectedIndex, setSelectedIndex] = React.useState(activeStep);
  const profileInfo = React.useContext(ProfileContext);
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const { unreadCount } = React.useContext(MessagingContext);
  const { configurations } = React.useContext(ConfigurationContext);

  const USER_PROFILE_LABELS = isFeatureEnabled('family_message')
    ? [
        { id: 1, label: 'Profile' },
        { id: 2, label: 'Password' },
        { id: 3, label: 'Messages' },
        { id: 4, label: 'My Clubs' },
        { id: 5, label: 'Preferences' },
        { id: 6, label: 'Log Out' },
      ]
    : [
        { id: 1, label: 'Profile' },
        { id: 2, label: 'Password' },
        { id: 4, label: 'My Clubs' },
        { id: 5, label: 'Preferences' },
        { id: 6, label: 'Log Out' },
      ];

  return (
    <React.Fragment>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        marginBottom={{ md: '200px', lg: '200px' }}
      >
        <Avatar
          alt=''
          src={
            profileInfo.profile?.profile_image?.path
              ? profileInfo.profile?.profile_image?.path
              : configurations?.default_user_profile_image?.path
              ? configurations?.default_user_profile_image?.path
              : '/assets/images/family-profile.svg'
          }
          sx={{
            width: 70,
            height: 70,
            background: COLORS.grey.secondary,
            marginBottom: 2,
          }}
        />
        <Box textAlign='left'>
          {USER_PROFILE_LABELS.map((item, index) => (
            <Box mt={2}>
              <Badge badgeContent={item.label === 'Messages' ? unreadCount : 0} color='primary'>
                <Typography
                  className={'MuiTypography-profile-sidebar'}
                  onClick={() => {
                    setSelectedIndex(item.id);
                    setActiveStep(item.id);
                  }}
                  fontWeight={item.id === selectedIndex ? 700 : null}
                >
                  {item.label}
                </Typography>
              </Badge>
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SideBar;
