import React, { useEffect, useRef, useState } from "react";
import { AppBar, Toolbar, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useSmoothScroll } from "./useSectionScroll";
import { useTheme } from "@mui/material/styles";

const useStyles = () => {
  const theme = useTheme();

  return (() =>
    makeStyles(() => ({
      appBar: {
        justifyContent: "center",
        padding: "0 1.5rem",
        background: theme.palette.secondary.main,
      },
      tab: {
        minWidth: "auto",
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 20,
        color: theme.think12.colors.white.main,
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.think12.colors.white.main,
          borderBottom: "unset",
        },
        minHeight: "unset",
        padding: "8px 8px",
      },
      iconButton: {
        color: "#fff",
      },
      tabsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      tabsComponent: {
        alignItems: "center",
        display: "flex",
        minHeight: 48,
        "& .MuiTabs-scroller": {
          overflow: "unset!important",
          overflowX: "unset",
          "& .MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    })))();
};

export const NavigationToolbar = ({ isSticky, tabs = [] }) => {
  const classes = useStyles()();
  const scrollContainerRef = useRef(null);
  const [value, setValue] = useState(0);
  const scrollToSection = useSmoothScroll(50);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scroll = amount => {
    const container = scrollContainerRef.current;
    container.scrollBy({ left: amount, behavior: "smooth" });
  };

  const scrollLeft = () => {
    scroll(-200);
  };

  const scrollRight = () => {
    scroll(200);
  };

  return (
    <AppBar position={isSticky ? "fixed" : "static"} className={classes.appBar}>
      <Toolbar sx={{ justifyContent: "center", paddingTop: "0!important" }}>
        <IconButton onClick={scrollLeft} className={classes.iconButton}>
          <ArrowBackIos />
        </IconButton>
        <div className={classes.tabsContainer}>
          <Tabs
            ref={scrollContainerRef}
            sx={{ minHeight: "unset" }}
            value={value}
            onChange={handleChange}
            variant="scroll, able"
            scrollButtons="auto"
            allowScrollButtonsMobile
            className={classes.tabsComponent}
            indicatorColor="secondary"
          >
            {tabs.map(({ label, value }) => (
              <Tab onClick={() => scrollToSection(value)} label={label} className={classes.tab} />
            ))}
          </Tabs>
        </div>
        <IconButton onClick={scrollRight} className={classes.iconButton}>
          <ArrowForwardIos />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
