import { usePaginationApi } from './usePaginationApi';
import urls from 'api/urls';

export const OutcomesContext = () => {
  const logoPagination = usePaginationApi(
    { method: 'get', url: urls.outcomeLogos },
    { returnFullResponse: true, limit: 11 }
  );

  const groupOutcomeLogos = (data, startIndex) => {
    if (!data) return;

    return {
      topRow: data.slice(startIndex, startIndex + 4),
      middleRow: data.slice(startIndex + 4, startIndex + 8),
      bottomRow: data.slice(startIndex + 8, startIndex + 11),
    };
  };

  // TODO: Retain page map of outcome logos
  // const generatedPagedGroups = () => {
  //   const outcomeLogos = logoPagination.data;
  //   if (!outcomeLogos) return {};

  //   const grouped = {};
  //   for (let i = 0; i < logoPagination.page; i++) {
  //     const startIndex = i * 11;
  //     grouped[i + 1] = groupOutcomeLogos(outcomeLogos, startIndex);
  //   }

  //   return grouped[logoPagination.page];
  // };

  return {
    ...logoPagination,
    limit: 11,
    grouped: groupOutcomeLogos(logoPagination.data, 0),
  };
};

export default OutcomesContext;
