import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment/moment";
import Grid from "@mui/material/Grid";
import React from "react";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IndustryList } from "../../../constants/Industry";

export const ProfessionalExperience = ({ formData, updateFormData }) => {
  const addNewProfessions = () => {
    const newProfessions = [
      ...(formData?.professions || []),
      { industry: "", company: null, job_title: "", ourId: Number(moment().format("x")) },
    ];
    updateFormData("professions", newProfessions);
  };

  const removeProfessions = ourId => {
    const newProfessions = formData.professions.filter(e => e.ourId !== ourId);
    updateFormData("professions", newProfessions);
  };

  return (
    <Container>
      <Box mt={5} display="flex" justifyContent="space-between">
        <Typography variant="h5" fontWeight="bold">
          Professional Experience
        </Typography>
      </Box>

      {formData?.professions?.map((profession, index) => (
        <ProfessionalExperienceRow
          key={index}
          profession={profession}
          formData={formData}
          updateFormData={updateFormData}
          removeProfession={removeProfessions}
        />
      ))}

      <Grid item md={6} xs={12} sm={12}>
        <Box
          display="flex"
          alignItems="center"
          onClick={addNewProfessions}
          sx={{ cursor: "pointer" }}
        >
          <Fab color="secondary">
            <AddIcon style={{ color: "#fff" }} />
          </Fab>
          <Typography ml={3} variant="body1" fontWeight="bold">
            Add more
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};

const ProfessionalExperienceRow = ({ profession, removeProfession, formData, updateFormData }) => {
  const updateProfession = (key, value) => {
    const newProfessions = formData.professions.map(e =>
      e.ourId === profession.ourId ? { ...e, [key]: String(value) } : e
    );
    updateFormData("professions", newProfessions);
  };

  return (
    <Grid container mt={2} spacing={3}>
      <Grid item md={4} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          Industry
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            select
            value={profession?.industry}
            onChange={e => updateProfession("industry", e.target.value)}
          >
            <MenuItem key={"emptyState"}>
              <em>Select Industry</em>
            </MenuItem>
            {IndustryList.map(({ key, value } = "") => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          Company
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: "false" }}
            variant="filled"
            hiddenLabel
            fullWidth
            id="company"
            name="company"
            className="family-input-fields"
            sx={{ mb: 2 }}
            value={profession?.company}
            onChange={e => updateProfession("company", e.target.value)}
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          Job/Title
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: "false" }}
            variant="filled"
            hiddenLabel
            fullWidth
            id="job_title"
            name="job_title"
            className="family-input-fields"
            sx={{ mb: 2 }}
            value={profession?.job_title}
            onChange={e => updateProfession("job_title", e.target.value)}
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <Fab color="primary" onClick={e => removeProfession(profession.ourId)} sx={{ mt: 2 }}>
          <RemoveIcon style={{ color: "#fff" }} />
        </Fab>
      </Grid>
    </Grid>
  );
};
