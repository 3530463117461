import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import useOutcomes from "hooks/useOutcomes";
import { NavigationButton } from "components/Global/NavigationButton";
import { theme } from "uikit/theme";
import "./university-grid.css";

export const UniversityGrid = () => {
  const paginationData = useOutcomes();
  const { page, limit, totalCount, grouped = [] } = paginationData;

  return (
    <>
      <Grid id="outcome-logo-container" sx={{ mt: 8 }}>
        <Container
          id={`outcome-logo-page`}
          className={`outcome-logo-page-${+page}`}
        >
          <Container maxWidth="xl">
            <Box
              display="flex"
              justifyContent="space-between"
              mb={{ xs: 2, md: 0 }}
            >
              <Box pl="4em">
                <Typography fontWeight="bold" variant="h4">
                  He is Called to Excellence
                </Typography>
              </Box>
            </Box>
            <Grid container py={5} className="outcome-logo-row odd">
              {grouped?.topRow.map((data) => {
                return (
                  <Grid className="outcome-logo-wrapper" item md={3} xs={6}>
                    <Box
                      className="logo-container"
                      display="flex"
                      justifyContent="center"
                    >
                      <img
                        className="outcome-logo-img"
                        src={data.logo?.url}
                        alt={data.logo?.altText || ""}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Grid container mt={2} className="outcome-logo-row even">
              {grouped?.middleRow.map((data) => (
                <Grid className="outcome-logo-wrapper" item md={3} xs={6}>
                  <Box display="flex" justifyContent="center">
                    <img
                      className="outcome-logo-img"
                      src={data.logo?.url}
                      alt={data.logo?.altText || ""}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Grid container mt={2} className="outcome-logo-row odd">
              {grouped?.bottomRow.map((data) => {
                return (
                  <Grid className="outcome-logo-wrapper" item md={3} xs={6}>
                    <Box display="flex" justifyContent="center">
                      <img
                        className="outcome-logo-img"
                        src={data.logo?.url}
                        alt={data.logo?.altText || ""}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Container>
      </Grid>
      <Grid container className="action-section" justifyContent="center">
        {paginationData.page > 1 && (
          <Grid item className="pagination-button-previous" xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              mt={4}
              onClick={paginationData.prevPage}
            >
              <NavigationButton
                icon={ExpandLess}
                label="Previous"
                color={theme.palette.primary.main}
              />
            </Box>
          </Grid>
        )}
        {page * limit < totalCount && (
          <Grid item className="pagination-button-next" xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              mt={4}
              onClick={paginationData.nextPage}
            >
              <NavigationButton
                icon={ExpandMore}
                label="Next"
                color={theme.palette.primary.main}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UniversityGrid;
