import React, { useContext, useState } from 'react';
import { AuthLandingScreen } from 'pages/ManageAuth/components/AuthLandingScreen/index';
import AuthContext from '../../../../contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import { Box, Typography, TextField, Grid, InputLabel, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { forgotPasswordSchema } from '../../../../validations/schemas';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {
  const { isAuthenticated, sendResetPasswordLink } = useContext(AuthContext);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: zodResolver(forgotPasswordSchema) });

  const onSubmitHandler = async (values) => {
    try {
      setIsLoading(true);
      await sendResetPasswordLink(values.email);
      toast.success('You will receive a password reset link on the provided email shortly');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <AuthLandingScreen height='1070px'>
      <>
        <Box textAlign='center' mt={4}>
          <Typography fontWeight={900} fontSize={{ xs: 20, md: 56 }} color={theme.palette.secondary.main}>
            Forgot Password
          </Typography>
        </Box>
        <Grid px={5} mt={12} spacing={2} container justifyContent={{ xs: 'center', md: 'start' }}>
          <Grid item xs={12}>
            <InputLabel>Email Address</InputLabel>
            <TextField
              fullWidth
              placeholder='Enter Email'
              margin='normal'
              error={!!errors['email']}
              helperText={errors['email'] ? errors['email'].message : ''}
              {...register('email')}
              // onKeyPress={handleKeyPress}
            />
          </Grid>

          <Grid item md={12} xs={12} textAlign='center' mt={2}>
            <Button
              size='large'
              variant='contained'
              disabled={isLoading ? true : false}
              sx={{
                p: 2,
                width: 250,
                borderRadius: 50,
                bgcolor: 'secondary.main',
                color: '#fff',
              }}
              onClick={handleSubmit(onSubmitHandler)}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  handleSubmit(onSubmitHandler);
                }
              }}
            >
              {isLoading ? <CircularProgress size='1.7em' color='primary' /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </>
    </AuthLandingScreen>
  );
};

export default ForgotPassword;
