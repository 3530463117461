
import apiRequest from "api/apiRequest";

export const sendPageReportingEvent = () => {
    let session = localStorage.getItem('session');
    apiRequest({
        url: '/reporting/events',
        method: 'post',
        data: {
          event: 'pageview',
          uri: window.location.pathname,
          admin: false,
          session: session,
        },
      });
}