import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ConfigurationContext from 'contexts/ConfigurationContext';

const useStyles = makeStyles(theme => ({
  imgStyle: {
    position: 'absolute',
    top: '0%',
    left: '35%',
    [theme.breakpoints.down('md')]: {
      top: '5%',
      left:'50%',
      transform:'rotate(10deg)',
    },
    [theme.breakpoints.down('sm')]: {
      top: '33%',
      right: '-10%',
      left:'auto',
      transform:'rotate(60deg)',
    },
  },
}));
export const Hero = () => {
  const { configurations } = useContext(ConfigurationContext);

  const theme = useTheme();
  const classes = useStyles();

  const [imagesColored, setImagesColored] = React.useState(0);
  
  let targetColor = "#FFFFFF";
  if (configurations.color2) {
    targetColor = configurations.color2;
  }

  const onImageLoad = async (event) => {
    if (imagesColored > 3) {
      return;
    }
    const targetHex = targetColor;

    // Convert the target hex color to its RGB components
    const targetRGB = {
      r: parseInt(targetHex.substring(1, 3), 16),
      g: parseInt(targetHex.substring(3, 5), 16),
      b: parseInt(targetHex.substring(5, 7), 16)
    };

    // Create a reference to the image element
    const imgElement = event.target;

    // Create an offscreen canvas and get its context
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = imgElement.width;
    offscreenCanvas.height = imgElement.height;
    const ctx = offscreenCanvas.getContext('2d');

    ctx.drawImage(imgElement, 0, 0);

    const imageData = ctx.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
    const data = imageData.data;

    // Loop through the image data to find and change white pixels
    for(let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const a = data[i + 3];

      data[i] = targetRGB.r;
      data[i + 1] = targetRGB.g;
      data[i + 2] = targetRGB.b;
    }

    // Apply the updated image data back to the offscreen canvas
    ctx.putImageData(imageData, 0, 0);

    // Update the image element with the modified data
    imgElement.src = offscreenCanvas.toDataURL();

    setImagesColored(imagesColored + 1);
  };

  return (
    <Box bgcolor={theme.palette.secondary.main} position='relative'>
      <img
        alt=''
        src='/assets/images/circle.png'
        style={{ position: 'absolute', top: '0%', left: '0%', opacity: '10%' }}
      />
      <img
        alt=''
        src='/assets/images/circle2.svg'
        style={{
          position: 'absolute',
          top: '0%',
          right: '0%',
          opacity: '10%',
        }}
      />
      <Container maxWidth='xl'>
        <Box position='relative'>
          <Grid container py={10} ml={{ lg: 20 }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography color='white' variant='h2' fontWeight='bold'>
                From
                <br /> {configurations?.schoolName}
              </Typography>
            </Grid>
            <div style={{ position: 'relative' }}>
            <img
                alt=''
                src="/assets/images/outcomes_arrow.png"
                onLoad={onImageLoad}
                style={{ marginLeft: '-50px', position:'absolute', transform:'translateX(-100%)', '@media (min-width: 960px)': { marginLeft: '0' } }}
              />
              <img
                alt=''
                src="/assets/images/outcomes_arrow.png"
                onLoad={onImageLoad}
                style={{ marginLeft: '-50px', '@media (min-width: 960px)': { marginLeft: '0' } }}
              />
              <img
                alt=''
                src="/assets/images/outcomes_arrow.png"
                onLoad={onImageLoad}
                style={{ marginLeft: '0px', position:'absolute', transform:'translateX(0%)', '@media (min-width: 960px)': { marginLeft: '0' } }}
              />
            </div>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Box display='flex' justifyContent={{ md: 'flex-end', sm: 'flex-end', xs: 'flex-end' }}>
                <Typography mt={{ md: 2, sm: 0, xs: 0 }} color='white' variant='h2' fontWeight='bold' style={{textAlign:'right'}}>
                  to Anywhere
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
