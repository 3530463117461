import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";

import { COLORS } from "constants/colors";
import { ROUTES } from "constants/routes";

export const GreetingScreen = props => {
  const theme = useTheme();
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);
  const history = useHistory();
  const [value, setValue] = React.useState("");

  const handleChange = e => {
    props.handleuserRoleChange(e.target.value);
    setValue(e.target.value);
  };

  const getDropdownData = () => {
    let data = [];

    if (isFeatureEnabled("signup_current_family")) {
      data.push({
        id: 1,
        label: `Current enrolled family at ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    if (isFeatureEnabled("signup_family")) {
      data.push({
        id: 2,
        label: `Family considering enrolling at ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    if (isFeatureEnabled("signup_enrolled_student")) {
      data.push({
        id: 3,
        label: `Student enrolled at ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    if (isFeatureEnabled("signup_staff")) {
      data.push({
        id: 4,
        label: `Staff member at ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    if (isFeatureEnabled("signup_graduate")) {
      data.push({
        id: 5,
        label: `Graduate of ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    if (isFeatureEnabled("signup_alumni")) {
      data.push({
        id: 12,
        label: `Alumni of ${configurations.schoolName ? configurations.schoolName : ""}`,
      });
    }

    return data;
  };

  return (
    <React.Fragment>
      <Grid item container maxWidth="xl" direction="column" mt={{ xs: 5, md: 20 }}>
        <Grid item md={6}>
          <Box textAlign="center" mt={5}>
            <Typography
              fontWeight={900}
              fontSize={{ xs: 20, md: 56 }}
              color={theme.palette.secondary.main}
            >
              Welcome!
            </Typography>
          </Box>
          <Box px={{ md: 5, xs: 2 }}>
            <Typography
              mt={2}
              fontWeight={400}
              fontSize={{ xs: 15, md: 18 }}
              color={COLORS.grey.dark}
            >
              {configurations.programName ? configurations.programName : ""} was developed for you!
              Register for the full program experience.
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} px={{ md: 5, xs: 2 }} mt={{ xs: 4, md: 10 }}>
          <FormControl fullWidth sx={{ pt: 2 }}>
            <Typography fontWeight={400} fontSize={{ xs: 15, md: 18 }} color={COLORS.grey.dark}>
              I am a...
            </Typography>
            <Select value={value} margin="normal" onChange={handleChange}>
              {getDropdownData().map(item => (
                <MenuItem
                  style={{ whiteSpace: "normal" }}
                  value={item.id}
                  sx={{
                    borderRadius: "0px 0px 10px 10px",
                    padding: 2,
                    color: COLORS.grey.dark,
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} mt={{ xs: 10, md: 25 }} px={{ md: 5, xs: 2 }}>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Typography fontSize={15} fontWeight={700}>
              Have an account already?
            </Typography>
            <Button
              variant="text"
              color="primary"
              sx={{ fontSize: 15, fontWeight: 400 }}
              onClick={() => history.push(ROUTES.SIGN_IN)}
            >
              Log In Here
            </Button>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GreetingScreen;
