import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BackgroundCircle } from "components/Global/BackgroundCircle";
import useTheme from "@mui/material/styles/useTheme";
import { Card, CardActionArea, CardMedia, Typography, Container, Box } from "@mui/material";
import urls from "api/urls";

export const GallerySection = props => {
  const theme = useTheme();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      {props.club && props.club.gallery.length ? (
        <div id="gallery" style={{ marginBottom: "50px", marginTop: "50px" }}>
          <Container maxWidth="xl" sx={{ marginBottom: "50px" }}>
            <Typography variant="h4" fontWeight={900}>
              Gallery
            </Typography>
          </Container>
          <Box style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "650px", left: "15%" }}>
              <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={20} />
            </div>
          </Box>
          <Container>
            <Slider
              {...settings}
              infinite={props.club.gallery.length > 1}
              style={{ width: "100% !important" }}
            >
              {props.club.gallery.map(image => (
                <div>
                  <Box
                    ml={2}
                    width={{ xs: "100%", md: "100%" }}
                    justifyContent={{ xs: "center", md: "start" }}
                  >
                    <Card sx={{ margin: "0px 10px" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="380"
                          src={image.image.path}
                          alt=""
                          style={{ objectFit: "contain" }}
                        />
                      </CardActionArea>
                    </Card>
                  </Box>
                </div>
              ))}
            </Slider>
          </Container>
        </div>
      ) : (
        []
      )}
    </React.Fragment>
  );
};
