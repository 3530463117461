import React, { useEffect } from "react";
import { Grid, Typography, Box, Container, colors } from "@mui/material";
import ConfigurationContext from "contexts/ConfigurationContext";
import moment from "moment";
import urls from "api/urls";
import { useTheme } from "@mui/material/styles";
import { BackgroundCircle } from "components/Global/BackgroundCircle";

export const ClubHubUpComingEvents = props => {
  const { configurations } = React.useContext(ConfigurationContext);
  const [events, setEvents] = React.useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (props.club) {
      let ev = [...props.club.events];
      ev.sort((a, b) => {
        return moment(a.event_date).diff(moment(b.event_date));
      });
      setEvents(ev);
    }
  }, [props.club]);

  const getImage = () => {
    if (events.length === 0 || !events[0].image) {
      return null;
    }
    return events[0].image?.path;
  };

  return (
    <section id="events">
      {events.length ? (
        <Box
          bgcolor={theme.palette.secondary.main}
          sx={{ position: "relative", paddingBottom: "20px" }}
        >
          <Container maxWidth="xl">
            <Grid container py={10} sx={{ position: "relative", flex: "1 1 auto" }}>
              <Grid item sm={12} md={4} mb={10}>
                <Typography color="white" variant="h4" fontWeight="bold">
                  Upcoming Events
                </Typography>
                <Box mt={5}>
                  <Box display={{ xs: "none", sm: "block", md: "block" }}>
                    <BackgroundCircle
                      bottom="50px"
                      left="-20%"
                      zIndex="1"
                      styles={{ image: { width: 340, height: 340 } }}
                      color={theme.palette.primary.main}
                      strokeWidth={20}
                    />
                  </Box>
                  {getImage() ? (
                    <img
                      src={getImage()}
                      alt=""
                      width="55%"
                      height="55%"
                      style={{ borderRadius: 10, zIndex: "2", position: "relative" }}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid sm={12} md={8} className="club-events-table">
                <Box mt={5} sx={{ flex: "1 1 auto" }}>
                  {events.map((item, i) => (
                    <Box mt={5} key={i}>
                      <Box display="flex">
                        <Box sx={{ borderBottom: "1px solid white" }}>
                          <Typography
                            pb={2}
                            display="block"
                            fontWeight="bold"
                            textAlign="center"
                            variant="body4"
                            color="white"
                          >
                            <Typography mb={2} fontWeight="100" fontSize={"1.1em"}>
                              {moment(item.event_date).format("MMMM").toUpperCase()}
                              {item.event_time}
                              <br />
                            </Typography>
                            {moment(item.event_date).format("ddd")}
                            <br />
                            {moment(item.event_date).format("DD")}
                          </Typography>
                        </Box>
                        <Box py={2} ml={5} sx={{ borderBottom: "1px solid white" }} width="100%">
                          <Typography fontWeight="bold" variant="h5" color="white">
                            {item.name}
                          </Typography>
                          <Typography mt={2} variant="body2" color="white">
                            {item.location}
                          </Typography>
                          <Typography mt={2} variant="body2" color="white">
                            {item.time}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : null}
      {props.club && props.club.announcements ? (
        <Container id="announcements" maxWidth="xl">
          <Box sx={{ position: "relative", flex: "1 1 auto" }} pb={4}>
            <Typography color="black" variant="h4" fontWeight="bold" mt={4}>
              Announcements
            </Typography>
            <Typography mt={2} variant="body2" color="black">
              {props.club
                ? props.club.announcements.split("\n").map(line => {
                    return <p>{line}</p>;
                  })
                : ""}
            </Typography>
          </Box>
        </Container>
      ) : null}
    </section>
  );
};

export default ClubHubUpComingEvents;
