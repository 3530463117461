import React from "react";
import {getElementSize} from "../utils/numToMeasurement";

export const IMG = ({
  src,
  alt,
  style,
}) => (
  <img
    style={{
      borderRadius: getElementSize(style?.borderRadius),
      margin: getElementSize(style?.margin),
      height: getElementSize(style?.height),
      width: getElementSize(style?.width),
      borderWidth: getElementSize(style?.borderWidth),
      borderStyle: style?.borderStyle,
      borderColor: style?.borderColor,
      boxSizing: style?.boxSizing,
      objectFit: style?.objectFit,
      overflow: style?.overflow,
    }}
    alt={alt}
    src={src}
  />
);
