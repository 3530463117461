import React from "react";
import Box from "@mui/system/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import COLORS from "constants/colors";

export const Check = (props) => {
  const { text, disabled, checked, onChange, id } = props || {};

  return (
    <Box item display="flex" alignItems="center">
      <Checkbox
        disabled={disabled ? true : false}
        size="small"
        value="check"
        color="primary"
        checked={checked}
        onChange={() => onChange(id)}
        id={id}
      />
      <Typography color={disabled ? COLORS.dark.lightest : ""} variant="body2">
        {text}
      </Typography>
    </Box>
  );
};

export default Check;
