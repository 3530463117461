import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Chip, ListItemText, OutlinedInput, Select } from "@mui/material";
import Box from "@mui/system/Box";
import COLORS from "../../../constants/colors";
import moment from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { SchoolActivitiesList, VolunteerActivitiesList } from "../../../constants/School";

export const OpenVolunteering = ({ formData, updateFormData }) => {
  const updateData = e => {
    const newData = { ...formData?.open_volunteering, name: e.target.value };
    updateFormData("open_volunteering", { ...newData });
  };

  return (
    <Grid item md={4} xs={12} sm={6}>
      <InputLabel className="MuiInputLabel-Profile-label" shrink>
        Open to volunteering
      </InputLabel>
      <FormControl fullWidth>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={formData?.open_volunteering?.name}
          onChange={updateData}
          input={<OutlinedInput />}
          fullWidth
          renderValue={selected => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  minHeight: "1.6em",
                  maxHeight: "5em",
                  overflowY: "scroll",
                }}
              >
                {selected.map(value => {
                  const label = VolunteerActivitiesList.find(({ key }) => value === key);
                  return (
                    <Chip
                      key={value}
                      label={label.value}
                      color="primary"
                      style={{ color: COLORS.white.secondary }}
                      size="small"
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {VolunteerActivitiesList.map(({ key, value }, idx) => {
            return (
              <MenuItem key={idx} value={key} dense={true}>
                <Checkbox checked={formData?.open_volunteering?.name.includes(key)} />
                <ListItemText primary={value} sx={{ fontSize: "0.8em" }} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};
