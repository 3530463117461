import React from 'react';
import { Divider, InputLabel, Grid, FormControl, TextField, MenuItem, Checkbox, Typography, Fab } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

function ReviewRow({ rev, idx, updateFormData, formData }) {
  const updateReviewKey = (keyName, value) => {
    const newReviews = [...formData?.reviews];
    newReviews[idx][keyName] = value;
    updateFormData('reviews', newReviews);
  };

  const removeReview = (id) => {
    const newReviews = formData.reviews.filter((r) => r.id !== id);
    updateFormData('reviews', newReviews);
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid item container md={10} xs={10} sm={10} spacing={3}>
          <Grid item md={12} xs={12} sm={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Review
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={4}
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                fullWidth
                id='review'
                name='review'
                placeholder='Share review'
                sx={{ mb: 2 }}
                value={rev?.body}
                onChange={(e) => updateReviewKey('body', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              First Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                required
                hiddenLabel
                fullWidth
                id='first-name'
                name='first-name'
                value={rev?.reviewer?.first_name}
                onChange={(e) =>
                  updateReviewKey('reviewer', {
                    ...rev.reviewer,
                    first_name: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Tile*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                value={rev?.reviewer?.title}
                onChange={(e) =>
                  updateReviewKey('reviewer', {
                    ...rev.reviewer,
                    title: e.target.value,
                  })
                }
                select
              >
                <MenuItem value='None'>
                  <em>None</em>
                </MenuItem>
                <MenuItem value='Student'>Student</MenuItem>
                <MenuItem value='Parent'>Parent</MenuItem>
                <MenuItem value='Staff Member'>Staff Member</MenuItem>
                <MenuItem value='Alumni'>Alumni</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item md={6} display='flex' alignItems='center'>
            <Checkbox
              size='small'
              checked={rev?.is_approved === undefined ? false : rev.is_approved}
              color='primary'
              onChange={(e) => updateReviewKey('is_approved', e.target.checked)}
            />
            <Typography variant='caption' color='text.secondary'>
              I have received approval to publish this review
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item md={2} xs={2} sm={2} textAlign='center'>
          <Fab color='primary' onClick={(e) => removeReview(rev.id)} sx={{ mt: 5 }}>
            <RemoveIcon style={{ color: '#fff' }} />
          </Fab>
        </Grid>
      </Grid>
    </>
  );
}

export default ReviewRow;
