import React from 'react';
import { Box } from '@mui/system';

import LoadingIndicator from 'components/Utils/LoadingIndicator';
import './section.css';

const Section = ({ loading, children }) => {
  return (
    <Box className='think12-section'>
      {loading && <LoadingIndicator />}
      {!loading && children}
    </Box>
  );
};

export default Section;
