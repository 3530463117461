import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const LeaderCard = (props) => {
  const { image, userName, socialMedias, title } = props || {};
  const theme = useTheme();
  const { configurations } = React.useContext(ConfigurationContext);
  const smLinkedin = socialMedias?.find((sm) => sm.type.toLowerCase().trim() === 'linkedin');

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        background: theme.palette.background.default,
        boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '300px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={
            image
              ? image
              : configurations?.default_user_profile_image?.path
              ? configurations?.default_user_profile_image?.path
              : '/assets/images/family-profile.svg'
          }
          alt={userName}
          title={userName}
          width='auto'
          height='100%'
        />
      </Box>
      {/* </Link> */}
      <Box>
        <Typography fontWeight={900} fontSize={22} ml={4}>
          {userName}
        </Typography>
      </Box>
      <Box minHeight={20}>
        <Typography fontWeight={900} fontSize={16} ml={4}>
          {title ? title : ''}
        </Typography>
      </Box>
      <Box px={4} pb={3}>
        <Grid item container justifyContent='space-between' mt={3}>
          <Box>
            <Button
              variant='outlined'
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                borderRadius: '100px',
              }}
            >
              Contact
            </Button>
          </Box>
          {socialMedias?.length ? (
            <Box>
              {smLinkedin && (
                <Link to={smLinkedin.url} target='_blank'>
                  <IconButton>
                    <img src={'../../../../assets/icons/linkedIn.png'} alt='linkedin' />
                  </IconButton>
                </Link>
              )}
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default LeaderCard;
