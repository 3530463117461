import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export const SearchInputTextField = (props) => {
  const { colorIcon } = props;
  return (
    <Container>
      <Box sx={{ position: 'relative' }} display={{ sm: 'flex', md: 'flex' }}>
        <Box mt={82} component='form' sx={{ position: 'absolute', left: 40, right: 40 }}>
          <Paper
            component='form'
            sx={{
              p: 2,
              width: 'auto',
              display: 'flex',
              bgcolor: 'white',
              alignItems: 'center',
              borderRadius: '26px',
              boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconButton sx={{ p: '8px' }}>
              <SearchIcon sx={{ color: colorIcon }} />
            </IconButton>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder='Search for anything (families, clubs, groups and more)' />
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ p: '10px' }} aria-label='search'>
                <FilterAltOutlinedIcon sx={{ color: colorIcon }} />
              </IconButton>
              <Typography ml={1} color='gray'>
                My Group
              </Typography>
              <IconButton
                // color='primary'
                sx={{ p: '10px', ml: 1 }}
                aria-label='directions'
              >
                <ArrowDownIcon />
              </IconButton>
            </Box> */}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default SearchInputTextField;
