import Box from "@mui/material/Box";
import React from "react";
import { useTheme } from "@mui/material/styles";

export const ProfileCircle = () => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }} display={{ xs: "none", md: "flex" }}>
      <Box sx={{ position: "absolute", top: 410, right: -80, zIndex: -1 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="216"
          height="216"
          viewBox="0 0 216 216"
          fill="none"
        >
          <circle
            r="93"
            transform="matrix(-1 0 0 1 108 108)"
            stroke={theme.palette.primary.main}
            strokeOpacity="0.3"
            strokeWidth="30"
          />
        </svg>
      </Box>
    </Box>
  );
};

export const ProfileCircle2 = () => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ position: "absolute", zIndex: -1, bottom: 350, left: 250 }}
        display={{ xs: "none", md: "flex", sm: "none" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="154"
          height="154"
          viewBox="0 0 154 154"
          fill="none"
        >
          <circle
            r="62"
            transform="matrix(-1 0 0 1 77 77)"
            stroke={theme.palette.primary.main}
            strokeOpacity="0.3"
            strokeWidth="30"
          />
        </svg>
      </Box>
    </Box>
  );
};
