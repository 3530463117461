import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LeaderProfileCard from '../LeaderProfileCard/LeaderProfileCard';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { COLORS } from 'constants/colors';
import { leaderClubCardData } from 'constants';
import { LeaderClubCard } from 'pages/ManageStaffDirectory/LeaderClubCard';
import { leadersAboutUsData } from 'constants';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const LeaderProfile = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  return (
    <React.Fragment>
      <Box style={{ marginTop: '80px' }}>
        <Grid container maxWidth='xl' justifyContent='center'>
          <Box sx={{ position: 'relative' }} display={{ xs: 'none', md: 'flex' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 460,
                right: -80,
                zIndex: -1,
              }}
            >
              <img src='/assets/icons/Ellipse.png' alt='' width='180' />
            </Box>
          </Box>
          <Grid item container md={10} sm={12} mt={10}>
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: -1,
                  bottom: 400,
                  left: 250,
                }}
                display={{ xs: 'none', md: 'flex', sm: 'none' }}
              >
                <img src='/assets/icons/Ellipse.png' alt='' />
              </Box>
            </Box>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)' }}
            >
              <img src='/assets/images/CardImage.png' alt='' width='100%' height='100%' />
            </Grid>

            <Grid item container md={8} sm={6} justifyContent='center' paddingX={5} mt={4}>
              <Grid item md={6}>
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  Jane Anderson
                </Typography>
                <Box>
                  <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2} width={260} wrap>
                    English Teacher 10th and 11th grades jane.anderson@millerhs.com Member of{' '}
                    {configurations.schoolShortName ? configurations.schoolShortName : ''} since 2015
                  </Typography>
                  <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                    B.A English and History · Princeton University
                  </Typography>
                  <Box mt={2}>
                    <img src='/assets/images/harvardLogo.png' alt='' />
                  </Box>
                  <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                    PhD in Philosophy · Harvard University
                  </Typography>
                  <Box mt={2}>
                    <img src='/assets/images/pennLogo.png' alt='' />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                mt={{ xs: 2, md: 0 }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button variant='contained' color='secondary' sx={{ borderRadius: 50, padding: 2, width: 220 }}>
                  Message
                </Button>
                <Box display='flex' justifyContent='flex-end' marginX={2} mt={2}>
                  <IconButton disableRipple>
                    <Box sx={{ position: 'relative' }}>
                      <img src='assets/icons/circle.png' alt='' />
                      <img src='/assets/icons/linkedIn.svg' alt='' style={{ position: 'absolute', top: 5, left: 10 }} />
                    </Box>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box display={{ xs: 'none', md: 'none', lg: 'flex' }}>
        <Container sx={{ maxWidth: { xl: 'xl', lg: 'lg' } }} position='relative'>
          <Box>
            <Box
              component='form'
              mt={{ md: 22, sm: 53, xs: 48 }}
              sx={{
                p: 2,
                my: 5,
                width: 'auto',
                bgcolor: 'white',
                alignItems: 'center',
                borderRadius: '26px',
                display: { lg: 'flex', md: 'none' },
              }}
            >
              <LeaderProfileCard image='/assets/images/cardImage.jpg' />
            </Box>
          </Box>
        </Container>
      </Box>
      <Grid item md={12} justifyContent='center' sx={{ margin: '100px', padding: '20px' }}>
        <Box bgcolor={COLORS.white.dark}>
          <Grid container justifyContent='center' sx={{ padding: '20px' }}>
            <Grid item container>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={5} p={{ xs: 4, md: 0 }}>
                More About Me
              </Typography>
              {leadersAboutUsData.map((item, index) => (
                <Grid item container paddingX={{ xs: 8, md: 0 }}>
                  <Grid item md={6}>
                    <Box
                      display='flex'
                      flexDirection={{ xs: 'column', md: 'row' }}
                      justifyContent={{ xs: 'center', md: 'start' }}
                    >
                      <Box display='flex' flexDirection={{ xs: 'row' }} p={{ md: 2, xs: 0 }}>
                        <img src={item.icon} alt='' />
                        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} p={1} ml={{ xs: 1, md: 4 }}>
                          <Typography fontSize={{ md: 18 }} fontWeight={700}>
                            {item.title}
                          </Typography>
                          <Typography fontSize={{ md: 18 }} fontWeight={400} ml={{ md: 4, xs: 0 }}>
                            {item.subTitle}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      display='flex'
                      flexDirection={{ xs: 'column', md: 'row' }}
                      justifyContent={{ xs: 'center', md: 'start' }}
                    >
                      <Box ml={{ xs: 0, md: 6 }} display='flex' flexDirection={{ xs: 'row' }} p={{ md: 2, xs: 0 }}>
                        <img src={item.icon2} alt='' />
                        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} p={1} ml={{ xs: 1, md: 4 }}>
                          <Typography fontSize={{ md: 18 }} fontWeight={700}>
                            {item.title2}
                          </Typography>
                          <Typography fontSize={{ md: 18 }} fontWeight={400} ml={{ md: 4, xs: 0 }}>
                            {item.subTitle2}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}

              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={5} p={{ xs: 4, md: 0 }}>
                Clubs
              </Typography>
              <Grid container>
                <Grid item container md={12} gap={2} justifyContent={{ xs: 'center', md: 'start' }}>
                  {leaderClubCardData.map((item, index) => (
                    <>
                      <LeaderClubCard
                        image={item.image}
                        title={item.title}
                        subTitle={item.subTitle}
                        description={item.description}
                      />
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default LeaderProfile;
