import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { BackgroundCircle } from "components/Global/BackgroundCircle";

import { toast } from "react-toastify";
import { MessagesTab } from "components/Global/Messages";

import Modal from "@mui/material/Modal";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import AuthContext from "../../../../contexts/AuthContext";
import { SectionCarousel } from "../../../../components/SectionCarousel/SectionCarousel";
import { LeaderCard } from "./LeaderCard";

export const LeadersContact = props => {
  const theme = useTheme();

  const { isFeatureEnabled } = useContext(ConfigurationContext);
  const { user } = useContext(AuthContext);

  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const [messageUser, setMessageUser] = React.useState(null);

  const onClickMessage = leader => {
    const isVerifiedFamily =
      user?.roles?.find(r => ["Verified Family", "Club Leader", "Staff"].indexOf(r.name) !== -1) &&
      isFeatureEnabled("family");
    if (isVerifiedFamily) {
      setIsMessageModalOpen(true);
      setMessageUser(leader.user);
    } else {
      toast.error("Please log in or sign up to send a message.");
    }
  };

  return (
    <Container component="section" id="contacts" maxWidth="xl" className="club-page-leaders">
      <Typography variant="h5" color="#131416" fontSize={{ md: 30 }} fontWeight={700} mb={"20px"}>
        Contact us to learn more
      </Typography>

      <div style={{ position: "relative" }}>
        <Box style={{ position: "absolute", right: "5%", top: "600px" }}>
          <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={20} />
        </Box>
      </div>

      {props.club?.leaders && (
        <SectionCarousel>
          {props.club.leaders.map((leader, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
              <LeaderCard onClickMessage={onClickMessage} leader={leader} />
            </Grid>
          ))}
        </SectionCarousel>
      )}

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby="Messages"
        aria-describedby="Messages"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <MessagesTab isNew={true} isModal={true} members={[messageUser]} />
        </Box>
      </Modal>
    </Container>
  );
};
