import AuthContext from "contexts/AuthContext";
import React from "react";
import { Messages } from "./Messages";
import { NewMessage } from "./NewMessage";
import { useHistory } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { NewMessageGroup } from "./NewMessageGroup";

export const MessagesTab = ({ club = null, isNew = false, isModal = false, members = [] }) => {
  const [newMessage, setNewMessage] = React.useState(isNew);
  const { isAuthenticated } = React.useContext(AuthContext);
  const history = useHistory();

  if (!isAuthenticated) history.push(ROUTES.SIGN_IN);

  return newMessage ? (
    <NewMessage
      club={club}
      setNewMessage={setNewMessage}
      isModal={isModal}
      isUserProfile="isUserProfile"
      members={members}
    />
  ) : (
    <Messages setNewMessage={setNewMessage} isModal={isModal} isUserProfile="isUserProfile" />
  );
};

export const MessagesGroupTab = ({ club = null, isNew = false, isModal = false, members = [] }) => {
  const [newMessage, setNewMessage] = React.useState(isNew);
  const { isAuthenticated } = React.useContext(AuthContext);
  const history = useHistory();

  if (!isAuthenticated) history.push(ROUTES.SIGN_IN);

  return newMessage ? (
    <NewMessageGroup
      club={club}
      setNewMessage={setNewMessage}
      isModal={isModal}
      isUserProfile="isUserProfile"
      members={members}
    />
  ) : (
    <Messages setNewMessage={setNewMessage} isModal={isModal} isUserProfile="isUserProfile" />
  );
};
