import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import AuthContext from "contexts/AuthContext";
import React, { useState, useEffect, useRef, useContext } from "react";

const SsoLoginModal = ({ programId }) => {
  const [open, setOpen] = useState(false);
  const iframeRef = useRef(null);
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_BASE_PATH) return; // Update with your backend app URL

      if (event.data && event.data.token) {
        const tokenData = JSON.parse(event.data.token);
        const identityData = JSON.parse(event.data.identity);
        const userData = JSON.parse(event.data.user);

        setAuth(userData, `Bearer ${tokenData?.value}`);
        setOpen(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const style = {
    width: "100%",
    height: "408px",
  };

  return (
      <React.Fragment>
        <Grid px={5} mt={1} spacing={2} container justifyContent={{ xs: "center", md: "start" }}>
          <Box sx={style}>
            <iframe
              ref={iframeRef}
              title="SSO Login Frame"
              src={`${process.env.REACT_APP_SSO_LOGIN_PATH}?program=${programId}`}
              style={{ width: "100%", height: "100%", border: "none" }}
            ></iframe>
          </Box>
        </Grid>
      </React.Fragment>
  );
};

export default SsoLoginModal;
