import React from 'react';
import COLORS from 'constants/colors';
import { ROUTES } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const Header = ({ title }) => {
  const { push } = useHistory();
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Container maxWidth='xl'>
        <Grid container mt={5}>
          <Grid item md={12} xs={12} display={{ xs: 'flex', md: 'none', sm: 'none' }} alignItems='center'>
            <ArrowBackIosIcon
              sx={{
                color: COLORS.secondary.main,
                fontSize: '25px',
                cursor: 'pointer',
              }}
              onClick={() => push({ pathname: ROUTES.TIPS })}
            />
            <Typography ml={2} variant='h6' fontWeight={'bold'} sx={{ color: COLORS.secondary.main }}>
              Back to Tips for Parents
            </Typography>
          </Grid>
          <Grid item md={6} my={6} xs={12}>
            <Typography lineHeight={1} variant="h2">
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            my={{ lg: 8, md: 8, sm: 0, xs: 0 }}
            display='flex'
            justifyContent={{ md: 'flex-end', xs: 'flex-start' }}
          >
            {isFeatureEnabled('article_share') && (
              <Box mr={4}>
                <ShareOutlinedIcon sx={{ color: COLORS.primary.main, fontSize: '30px' }} />
                <Typography display={{ xs: 'none', md: 'flex' }} variant='body2' color='primary.main'>
                  Share
                </Typography>
              </Box>
            )}
            {isFeatureEnabled('article_forward') && (
              <Box mr={4}>
                <FastForwardOutlinedIcon sx={{ color: COLORS.primary.main, fontSize: '30px' }} />
                <Typography display={{ xs: 'none', md: 'flex' }} variant='body2' color='primary.main'>
                  Forward
                </Typography>
              </Box>
            )}
            {isFeatureEnabled('article_save') && (
              <Box>
                <FavoriteIcon sx={{ color: COLORS.primary.main, fontSize: '30px' }} />
                <Typography display={{ xs: 'none', md: 'flex' }} variant='body2' color='primary.main'>
                  Save
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Header;
