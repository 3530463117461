import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

export const PillFilter = ({ label, selectedPill, setSelectedPill, pills }) => {
  const theme = useTheme();

  return (
    <Container>
      <Box
        display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }}
        alignItems={"center"}
        mt={8}
        sx={{ justifyContent: "center" }}
      >
        <Typography mr={2} variant="body1" color={theme.palette.secondary.main} fontWeight="bold">
          {label}
        </Typography>
        {pills.map(data => (
          <Box key={data} ml={1}>
            <Chip
              sx={{ px: 2, color: data === selectedPill ? "#fff" : "secondary" }}
              label={data}
              variant={data === selectedPill ? "filled" : "outlined"}
              color="secondary"
              onClick={() => setSelectedPill(data)}
            />
            <Box
              sx={{
                width: "30px",
                height: "3px",
                backgroundColor:
                  data === selectedPill ? theme.palette.secondary.main : "transparent",
                marginTop: "5px",
                marginX: "auto",
                borderRadius: "10px",
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};
