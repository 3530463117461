import React from "react";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// import ConfigurationContext from "contexts/ConfigurationContext";
import { SELECT_ICONS } from "constants/profiles";
// import apiRequest from "api/apiRequest";

const AddFamilyMember = ({ setIsAddFamilyMember, addMember }) => {
  // const { configurations } = React.useContext(ConfigurationContext);
  const [name, setName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [age, setAge] = React.useState("");
  const [iconId, setIconId] = React.useState("");

  const handleClick = () => {
    if (name !== "" && gender !== "" && age !== "" && iconId !== "") {
      addMember(name, gender, age, iconId);
      setIsAddFamilyMember(false);
    }
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          borderRadius: "5px",
          border: `1px solid ${COLORS.white.secondary}`,
          p: 5,
          borderTop: `5px solid ${COLORS.primary.main}`,
          position: "relative",
        }}
        elevation={0}
      >
        <img
          src="/assets/icons/modal-arrow-up.svg"
          alt=""
          width="25px"
          style={{ position: "absolute", top: "-3%", left: "1%" }}
        />
        <Box>
          <Typography variant="h6">Add a new family member</Typography>
        </Box>
        <Box mt={4}>
          <Grid container mt={2} spacing={3}>
            <Grid item md={3} xs={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Name
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  InputProps={{ disableUnderline: "false" }}
                  variant="filled"
                  required
                  fullWidth
                  id="last-name"
                  name="name"
                  sx={{ mb: 2 }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Gender
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  name="gender"
                  select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Age
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    disableUnderline: "false",
                    inputProps: { min: 0, max: 100 },
                  }}
                  name="age"
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>11</MenuItem>
                  <MenuItem value={20}>12</MenuItem>
                  <MenuItem value={20}>13</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} sm={12}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Icon
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    disableUnderline: "false",
                  }}
                  select
                  name="icon_id"
                  value={iconId}
                  onChange={(e) => setIconId(e.target.value)}
                >
                  <MenuItem value=""></MenuItem>
                  {SELECT_ICONS.map((data, index) => (
                    <MenuItem value={index}>
                      <img src={data} alt="" />
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box mt={10} display="flex" justifyContent="flex-end">
          <Button
            sx={{ width: "145px", height: "40px", mr: 2 }}
            className="MuiButton-profile"
            variant="outlined"
            onClick={() => setIsAddFamilyMember(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: "145px", height: "40px" }}
            className="MuiButton-profile"
            variant="contained"
            onClick={() =>
              handleClick(
                `newForm0.name`,
                `newForm0.gender`,
                `newForm0.age`,
                `newForm0.icon_id`
              )
            }
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default AddFamilyMember;
