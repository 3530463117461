import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export const SearchInputTextField = ({ query, setQuery, onSearch }) => {
  return (
    <Container>
      <Box sx={{ position: 'relative' }} display={{ xs: 'none', sm: 'flex', md: 'flex' }}>
        <Box mt={82} component='form' sx={{ position: 'absolute', left: 40, right: 40 }}>
          <Paper
            component='form'
            sx={{
              p: 2,
              width: 'auto',
              display: 'flex',
              bgcolor: 'white',
              alignItems: 'center',
              borderRadius: '26px',
              boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconButton sx={{ p: '10px' }} onClick={onSearch}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Search by staff name, subject, club'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSearch();
                }
              }}
            />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default SearchInputTextField;
