import React, { useEffect } from "react";
// import { PROFILE_CLUB } from "constants/profiles";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";
import apiRequest from '../../../../api/apiRequest';
import { CardSection } from "pages/ManageClubHub/components/CardSection";

import AuthContext from "contexts/AuthContext";

export const MyClubs = (props) => {
  const [clubs, setClubs] = React.useState([]);

  const { user } = React.useContext(AuthContext);


  const history = useHistory();

  const loadClubs = () => {
      apiRequest({
        method: 'get',
        url: "clubs?currentUser=1",
      }).then((response) => {
        setClubs(response.data);
      })
  }

  useEffect(() => {
    loadClubs();
  }, []);

  const canEditClub = (club) => {
    let result = false;
    if (!user) {
      return false;
    }
    club.members.forEach((member) => {
      if (member.user_id === user.id) {
        result = true;
      }
    });
    return result;
  };

  return (
    <React.Fragment>
      <Container>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            My Clubs
          </Typography>
        </Box>
        <Grid container spacing={3} mb={10} mt={5}>
          {clubs.map((data) => (
            <CardSection club={data} showEdit={canEditClub(data)}/>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default MyClubs;
