import apiRequest from "../../../../api/apiRequest";
import { Redirect, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import { CircularProgress, Box } from "@mui/material";

export const EmailAuthentication = () => {
  const { setAuth, isAuthenticated } = useContext(AuthContext);
  let location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const emailToken = queryParameters.get("email_token");
  const redirectTo = queryParameters.get("redirect_to");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      authenticateUser();
    }
  }, []);

  const authenticateUser = () => {
    apiRequest({
      method: "post",
      url: "/email-auth",
      data: {
        emailToken,
      },
    })
      .then(response => {
        setAuth(response.user, `Bearer ${response.token.value}`);
        window.location.href = redirectTo ? redirectTo : "/";
      })
      .catch(error => {
        if (error.includes("401")) {
          window.location.href = "/";
        }
      });
  };

  if (!emailToken || isAuthenticated) {
    return <Redirect exact to={redirectTo ? redirectTo : ROUTES.HOME} />;
  }

  if (isLoading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2em", marginBottom: "2em" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return <></>;
};
