import React from 'react';
// import COLORS from 'constants/colors';
import ArticleCard from 'pages/ManageTips/components/ArticleCard';
import { Box, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Cards = (props) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        bgcolor={theme.palette.primary.main}
        mt={{ ld: 8, md: 8, sm: 5, xs: 4 }}
        pt={{ md: 15, lg: 15, sm: 10, xs: 5 }}
        pb={{ md: 25, sm: 20, xs: 5 }}
      >
        <Container maxWidth='false'>
          <Grid container spacing={3}>
            {props.articleOne === undefined ? (
              console.log('does not exist')
            ) : (
              <Grid xs={12} item md={4}>
                <Box>
                  <ArticleCard
                    article={props.articleOne}
                    isWhite
                    height='300'
                    image={props.articleOne?.mediaUrl}
                    primaryText={props.articleOne?.article_title}
                    secondaryText={props.articleOne?.content_description}
                  />
                </Box>
              </Grid>
            )}
            {props.articleTwo === undefined ? (
              console.log('does not exist')
            ) : (
              <Grid xs={12} item md={4}>
                <Box>
                  <ArticleCard
                    article={props.articleTwo}
                    isWhite
                    height='300'
                    image={props.articleTwo?.mediaUrl}
                    primaryText={props.articleTwo?.article_title}
                    secondaryText={props.articleOne?.content_description}
                  />
                </Box>
              </Grid>
            )}
            {props.articleThree === undefined ? (
              console.log('does not exist')
            ) : (
              <Grid xs={12} item md={4}>
                <Box>
                  <ArticleCard
                    article={props.articleThree}
                    isWhite
                    height='300'
                    image={props.articleThree?.mediaUrl}
                    primaryText={props.articleThree?.article_title}
                    secondaryText={props.articleThree?.content_description}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Cards;
