import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LeaderCard from "../LeaderCard";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";

const ManageStaffLeadersArea = ({ leaders, staff, isLoading }) => {
  const { configurations } = React.useContext(ConfigurationContext);
  if (!isLoading && staff.length === 0) return;

  return (
    <Box mt={leaders.length > 0 ? 5 : 15} mb={10} marginX={5}>
      <Container>
        <Box display="flex" justifyContent="center">
          {!isLoading && (
            <Grid item container md={12}>
              <Grid item>
                <Typography color="#131416" fontSize={{ md: 40 }} fontWeight={700}>
                  {configurations.staff_spotlight_title_2 ||
                    (leaders.length > 0 ? "More Members of Our Staff" : "Meet Our Staff")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
        {isLoading ? (
          <Box justifyContent="center" mt={6}>
            <LoadingIndicator />
          </Box>
        ) : (
          <Box justifyContent="center" mt={6}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              rowSpacing={3}
              columnSpacing={3}
              alignItems="center"
              justifyContent="center"
            >
              {staff.map((item, index) => (
                <Grid item md={4}>
                  <Link to={`/staff-directory/${item.id}`}>
                    <LeaderCard
                      image={item?.profile_image?.path}
                      userName={item.full_name}
                      subject={item.subject}
                      title={item.title}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ManageStaffLeadersArea;
