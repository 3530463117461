import { Button,  Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import useTheme from "@mui/material/styles/useTheme";

export const DeleteModal = (props) => {
  const theme = useTheme();
  const { isDeleteModal, setIsDeleteModal, title, text } = props || {};

  const confirm = () => {
    setIsDeleteModal(false);
    if (props.onConfirm) props.onConfirm();
  };

  return (
    <div>
      <Modal open={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              padding: "30px",
              borderBottom: "1px solid gray",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={"body1"} fontWeight={600}>
              {title}
            </Typography>
            <Box
              onClick={() => setIsDeleteModal(false)}
              sx={{
                cursor: "pointer",
                display: "flex",

                background: "#F2F2F2",
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloseOutlinedIcon color="blue" />
            </Box>
          </Box>
          <Box p={5}>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{ lineHeight: "28px" }}
            >
              {text}
            </Typography>
            <Box mt={10} display="flex" justifyContent="flex-end">
              <Button
                style={{borderColor: theme.palette.primary.main, color: theme.palette.primary.main}}
                sx={{ px: 5, mr: 2 }}
                className="MuiButton-profile"
                variant="outlined"
                onClick={() => setIsDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ px: 5 }}
                style={{ background: theme.palette.primary.main, color: "white", '&:hover': { background: theme.palette.primary.main }, borderColor: theme.palette.primary.main}}
                className="MuiButton-profile"
                variant="contained"
                onClick={() => confirm()}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  boxShadow: 10,
  borderRadius: "8px",
  marginTop: "80px",
};
