import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { BackgroundCircle } from "components/Global/BackgroundCircle";
import { useTheme } from "@mui/material/styles";

import apiRequest from "../../../../api/apiRequest";
import urls from "../../../../api/urls";

import Hero from "./components/Hero";
import Cards from "./components/Cards";
import Header from "./components/Header";
// import ArticleCard from '../ArticleCard';
import LoadingIndicator from "../../../../components/Utils/LoadingIndicator";

export const ArticleDetail = (props) => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    apiRequest({
      url: `${urls.articleDetails}/${articleId}?param=article_url`,
    }).then((resp) => {
      setArticle(resp.data);
      setIsLoading(false);
    });
  }, [articleId]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box mx={"7%"}>
            <Header title={article?.articleTitle} />
          </Box>

          <Box
            sx={{ position: "relative" }}
            display={{ xs: "none", md: "flex" }}
          >
            <Box sx={{ position: "absolute", top: -300, right: 150, zIndex: -1 }}>
              <BackgroundCircle color={theme.palette.primary.main} />
            </Box>
            <Box sx={{ position: "absolute", top: 400, left: -20, zIndex: -1 }}>
              <BackgroundCircle color={theme.palette.primary.main} />
            </Box>

            <Box sx={{ position: "absolute", top: 1200, right: 100, zIndex: -1 }}>
              <BackgroundCircle color={theme.palette.primary.main} />
            </Box>
          </Box>

          <Hero
            image={article?.featuredImageUrl}
            content={article?.articleContent}
            title={article?.articleTitle}
          />

          {/* related articles */}
          {article?.relatedArticles.length ? (
            <Cards
              articleOne={article?.relatedArticles[0]}
              articleTwo={article?.relatedArticles[1]}
              articleThree={article?.relatedArticles[2]}
            />
          ) : (
            ""
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ArticleDetail;
