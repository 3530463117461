import React, { useContext } from "react";
import { Layout } from "../UpdateProfileForm/Layout/Layout";
import ProfileContext from "../../contexts/ProfileContext";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import { ROUTES } from "../../constants/routes";

export const ALUMNI_PROFILE_LABELS = [
  { id: 1, label: "Alumni Profile" },
  { id: 2, label: "Preferences" },
];

export const AlumniLayout = props => {
  const { setActiveStep, activeStep, children } = props || {};
  const { profile } = useContext(ProfileContext);
  const { configurations } = useContext(ConfigurationContext);

  return (
    <Layout
      avatar={
        profile?.profile_image?.path
          ? profile?.profile_image?.path
          : configurations?.default_user_profile_image?.path
            ? configurations?.default_user_profile_image?.path
            : "/assets/images/family-profile.svg"
      }
      profileRoute={`${ROUTES.ALUMNI_NETWORK}/${profile?.id}`}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      tabs={ALUMNI_PROFILE_LABELS}
    >
      {children}
    </Layout>
  );
};
