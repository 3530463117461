import { Route } from "react-router-dom";
import FamilyNetworkDetails from "./FamilyNetwork";
import FamilyNetworkProfileDetails from "./FamilyNetworkProfileDetails/FamiliesNetworkProfileDetails";
import React, { useEffect } from "react";
import { FAMILY_FILTER } from "../../constants/StorageKeys";
import { ROUTES } from "../../constants/routes";

export const FamilyNetworkProvider = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem(FAMILY_FILTER);
    };
  }, []);

  return (
    <>
      <Route exact path={ROUTES.FAMILY_NETWORK_DETAILS} component={FamilyNetworkDetails} />
      <Route
        exact
        path={ROUTES.FAMILY_NETWORK_DETAILS_INDIVIDUAL}
        component={FamilyNetworkProfileDetails}
      />
    </>
  );
};
