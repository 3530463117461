import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { useHistory } from "react-router-dom";
import urls from "api/urls";
import { useTheme } from "@mui/material/styles";

export const ClubCard = props => {
  const theme = useTheme();
  const history = useHistory();
  const navigate = path => {
    return history.push({
      pathname: path,
    });
  };

  const club = props.club || {};
  console.log(club);

  return (
    <div style={{ width: "100%" }}>
      <Grid sx={{ gridRow: "1", gridColumn: "span 3" }} spacing={3}>
        <Link to={"/hub-page/" + club.id}>
          <Card style={{ height: "100%", marginBottom: "30px" }} elevation={0}>
            <CardActionArea>
              <CardMedia
                title={club.name}
                component="img"
                height="262"
                image={club.image ? club.image.path : "/assets/images/noImage.jpg"}
                alt={club.name}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    color: "#20212F",
                    fontSize: "28px",
                    fontWeight: "900",
                    lineHeight: "28px",
                    fontStyle: "normal",
                  }}
                >
                  {club.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {club.about}
                </Typography>
              </CardContent>
              <Button
                style={{
                  width: "170px",
                  height: "47px",
                  marginLeft: "15px",
                  borderRadius: "25px",
                  border: "1px solid " + theme.palette.primary.main,
                  color: theme.palette.primary.main,
                }}
                onClick={() => navigate("/hub-page/" + club.id)}
              >
                Learn More
              </Button>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>
    </div>
  );
};

export default ClubCard;
