import React from 'react';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';

export const Layout = (props) => {
  const { children } = props || {};

  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  const handleSidebarToggle = () => setSidebarOpen(!isSidebarOpen);

  return (
    <React.Fragment>
      <Header onToggle={handleSidebarToggle} />
      <Navbar />
      <main>{children}</main>
      <Footer />
      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
    </React.Fragment>
  );
};

export default Layout;
