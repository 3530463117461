import { useAuthContext } from "../contexts/AuthContext";

export const Roles = {
  thinkAdmin: 1,
  serviceAdmin: 2,
  staff: 3,
  graduates: 4,
  family: 5,
  club: 6,
  verifiedFamily: 8,
  programAdmin: 9,
  programOwner: 10,
  alumni: 12,
};

export const useHasRoles = requiredRoles => {
  const { user } = useAuthContext();
  if (!user && !user?.roles) return false;

  return requiredRoles.every(role => user.roles.some(uRole => role === uRole.id));
};

export const useAnyRoles = requiredRoles => {
  const { user } = useAuthContext();
  if (!user && !user?.roles) return false;

  return requiredRoles.some(role => user.roles.some(uRole => role === uRole.id));
};
