import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import { OutlinedInput, Checkbox, ListItemText, Chip } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import COLORS from "constants/colors";
import apiRequest from "api/apiRequest";
import { NewMessageTemplate } from "./NewMessageTemplate";

export const NewMessage = props => {
  const { isUserProfile, isModal, setNewMessage, members, club } = props || {};
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [names, setNames] = React.useState([]);

  useEffect(() => {
    setNames(members);
    if (club) {
      apiRequest({
        method: "get",
        url: `/clubs/${club.id}/family?members=1`,
      }).then(res => {
        setNames(res.data);
      });
    }
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const toggleSelectAll = () => {
    if (selectedMembers.length === names.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(names.map(n => n.id));
    }
  };

  return (
    <NewMessageTemplate
      setNewMessage={setNewMessage}
      club={club}
      isModal={isModal}
      isUserProfile={isUserProfile}
      toggleSelectAll={toggleSelectAll}
      selectedMembers={selectedMembers}
    >
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={selectedMembers}
        onChange={e => {
          setSelectedMembers(e.target.value);
        }}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        fullWidth
        size="small"
        renderValue={selected => {
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                minHeight: "1.6em",
                maxHeight: "5em",
                overflowY: "scroll",
              }}
            >
              {selected.map(value => {
                const found = names.find(n => n.id === value);
                const fullName = `${found.first_name} ${found?.last_name || ""}`;
                return (
                  <Chip
                    key={value}
                    label={fullName}
                    color="primary"
                    style={{ color: COLORS.white.secondary }}
                    size="small"
                  />
                );
              })}
            </Box>
          );
        }}
      >
        {names &&
          names.map((name, idx) => {
            const fullName = `${name.first_name} ${name?.last_name ? name.last_name : ""}`;
            return (
              <MenuItem key={idx} value={name.id} dense={true}>
                <Checkbox checked={selectedMembers.includes(name.id)} />
                <ListItemText primary={fullName} sx={{ fontSize: "0.8em" }} />
              </MenuItem>
            );
          })}
      </Select>
    </NewMessageTemplate>
  );
};
