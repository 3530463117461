import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//imports
// import { COLORS } from 'constants/colors';
import FamilyClubCard from './../FamilyClubCard';
import { familyClubCardData } from 'constants/content';
// import { useContext } from 'react';
// import ConfigurationContext from 'contexts/ConfigurationContext';
import { useTheme } from '@mui/material';
import apiRequest from 'api/apiRequest';

export const AmabassadorFamilyClub = () => {
  const theme = useTheme();
  // const { configurations } = useContext(ConfigurationContext);

  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    apiRequest({
      method: 'get',
      url: '/clubs',
    }).then((res) => {
      setClubs(res.data.slice(0,4));
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box bgcolor={theme.palette.primary.main}>
      <Box mt={5} ml={{ xs: 0, md: 4 }} height={{ xs: 'auto', md: 900 }} paddingX={{ xs: 10, md: 0 }}>
        <Container>
          <Grid item container justifyContent={{ md: 'start', xs: 'center' }}>
            <Typography mt={4} fontSize={{ md: 56 }} fontWeight={900} color={theme.palette.common.white}>
              Get Involved
            </Typography>
          </Grid>

          <Slider {...settings}>
            {clubs.map((club, index) => (
              <div>
                <Box ml={2} width={{ xs: '100%', md: '100%' }} justifyContent={{ xs: 'center', md: 'start' }}>
                  <FamilyClubCard
                    image={club.image}
                    title={club.name}
                    club={club}
                    // subTitle={item.subTitle}
                    description={club.about}
                    buttonColor={theme?.palette}
                  />
                </Box>
              </div>
            ))}
          </Slider>
        </Container>
      </Box>
    </Box>
  );
};

export default AmabassadorFamilyClub;
