import React, { useEffect, useId } from "react";
import Box from "@mui/system/Box";
import { MenuItem, Select } from "@mui/material";
import { OutlinedInput, Checkbox, ListItemText, Chip } from "@mui/material";
import COLORS from "constants/colors";
import apiRequest from "api/apiRequest";
import urls from "../../../api/urls";
import { NewMessageTemplate } from "./NewMessageTemplate";

export const NewMessageGroup = ({ isUserProfile, isModal, setNewMessage, members, club }) => {
  const id = useId();
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const selectedMembers = useSelectedMembers(groups, selectedGroups);

  useEffect(() => {
    if (club) {
      apiRequest({
        method: "get",
        url: `${urls.groups}?clubId=${club.id}`,
      }).then(res => {
        setGroups(res.data);
      });
    }
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const toggleSelectAll = () => {
    if (selectedGroups.length === groups.length) {
      selectedGroups([]);
    } else {
      setSelectedGroups(groups.map(n => n.id));
    }
  };

  return (
    <>
      <NewMessageTemplate
        selectedMembers={selectedMembers}
        isUserProfile={isUserProfile}
        isModal={isModal}
        setNewMessage={setNewMessage}
        toggleSelectAll={toggleSelectAll}
      >
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedGroups}
          onChange={e => {
            setSelectedGroups(e.target.value);
          }}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          fullWidth
          size="small"
          renderValue={selected => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  minHeight: "1.6em",
                  maxHeight: "5em",
                  overflowY: "scroll",
                }}
              >
                {selected.map(value => {
                  const found = groups.find(n => n.id === value);
                  return (
                    <Chip
                      key={value}
                      label={found.name}
                      color="primary"
                      style={{ color: COLORS.white.secondary }}
                      size="small"
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {groups &&
            groups.map((group, idx) => (
              <MenuItem key={id + idx} value={group.id} dense={true}>
                <Checkbox checked={selectedGroups.includes(group.id)} />
                <ListItemText primary={group.name} sx={{ fontSize: "0.8em" }} />
              </MenuItem>
            ))}
        </Select>
      </NewMessageTemplate>
    </>
  );
};

const useSelectedMembers = (groups, selectedGroups) => {
  const selectedGroupsObject = groups.filter(group => selectedGroups.includes(group.id));
  const selectedMembers = [];
  selectedGroupsObject.forEach(group => {
    group.users.forEach(user => {
      if (!selectedMembers.includes(user.id) && group.creator.id !== user.id) {
        selectedMembers.push(user.id);
      }
    });
  });

  return selectedMembers;
};
