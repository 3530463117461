import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { ROUTES } from "constants/routes";
import ConfigurationContext from "contexts/ConfigurationContext";
import { useTheme } from "@mui/material/styles";

const Tags = [
  "home",
  "article",
  "qa",
  "outcome",
  "family",
  "staff",
  "club",
  "graduate",
  "alumni_network",
];

const pagesAll = [
  {
    title: "Home",
    urls: [ROUTES.HOME],
    icon: "/assets/icons/home-icon.png",
    tag: "home",
  },
  {
    title: "ThinKTank",
    urls: [ROUTES.TIPS, ROUTES.TIPS_ARTICLE],
    icon: "/assets/icons/tips.svg",
    tag: "article",
  },
  {
    title: "Q&A Videos",
    urls: [ROUTES.QA],
    icon: "/assets/icons/qa.svg",
    tag: "qa",
  },
  {
    title: "Outcomes",
    urls: [ROUTES.OUTCOMES],
    icon: "/assets/icons/outcomes.svg",
    tag: "outcome",
  },
  {
    title: "Family Network",
    urls: [
      ROUTES.FAMILY_NETWORK,
      ROUTES.PROFILE_DETAILS,
      ROUTES.FAMILY_AMBASSADORS_DETAILS,
      ROUTES.FAMILY_AMBASSADORS_PROFILE_DETAILS,
    ],
    icon: "/assets/icons/family.svg",
    tag: "family",
  },
  {
    title: "Staff Directory",
    urls: [ROUTES.STAFF_DIRECTORY, ROUTES.STAFF_DIRECTORY_DETAILS],
    icon: "/assets/icons/staff.svg",
    tag: "staff",
  },
  {
    title: "Hub",
    urls: [ROUTES.CLUB_HUB, ROUTES.CLUB_PAGE, "/hub-page"],
    icon: "/assets/icons/clubhub.svg",
    tag: "club",
  },
  {
    title: "Graduates",
    urls: [ROUTES.GRADUATES, ROUTES.GRADUATE_DETAILS],
    icon: "/assets/icons/graduates.svg",
    tag: "graduate",
  },
  {
    title: "Alumni Network",
    urls: [ROUTES.ALUMNI_NETWORK],
    icon: "/assets/icons/graduates.svg",
    tag: "alumni_network",
  },
];

const useOrderNavigationItems = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  const features = configurations.features;
  const orderMap = new Map(
    features
      .filter(feature => Tags.includes(feature.tag) && feature.type !== "admin")
      .map(order => {
        return [order.tag, order.order_number];
      })
  );
  const sortedNavigationItems = pagesAll.slice().sort((a, b) => {
    const orderA = orderMap.get(a.tag);
    const orderB = orderMap.get(b.tag);

    if (orderA === null || orderA === undefined) return 1; // Push 'a' to the end
    if (orderB === null || orderB === undefined) return -1; // Push 'b' to the end

    return orderA - orderB;
  });

  return sortedNavigationItems;
};

const Navbar = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { isFeatureEnabled, getFeatureName, getFeatureRedirect } =
    React.useContext(ConfigurationContext);
  const pagesAll = useOrderNavigationItems();
  const pages = pagesAll.filter(p => isFeatureEnabled(p.tag));

  const history = useHistory();

  const navigateTo = url => {
    if (url.includes("https") || url.includes("http")) {
      return window.open(url, "_blank");
    }
    return history.push(url);
  };

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{ display: { xs: "none", md: "block" }, overflow: "hidden" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            mx={7}
            flexGrow={1}
            justifyContent={pages.length === pagesAll.length ? "space-between" : "start"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "stretch",
              width: "100%",
              "@media (max-width: 1100px)": {
                marginLeft: "-32px",
              },
            }}
          >
            {pages.map(({ title, urls, icon, tag }, index) => {
              const baseRoute = `/${pathname.split("/")[1]}`;
              const foundUrl = urls.find(url => url === baseRoute);

              let titleOverride = getFeatureName(tag);
              const redirectTo = getFeatureRedirect(tag) ? getFeatureRedirect(tag) : urls[0];

              if (titleOverride) {
                title = titleOverride;
              }
              return (
                <Link
                  onClick={() => navigateTo(redirectTo)}
                  role="button"
                  key={`menu-${index}`}
                  style={{ display: "flex", flexGrow: 10 }}
                  to={undefined}
                >
                  <Button
                    tabIndex={-1}
                    className="MuiButton-navbar"
                    sx={{
                      borderTopRightRadius: foundUrl ? 10 : 0,
                      borderTopLeftRadius: foundUrl ? 10 : 0,
                      backgroundColor: foundUrl
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                      width: "100%",
                      justifyContent: "center",
                      borderBottomLeftRadius: "0",
                      borderBottomRightRadius: "0",
                    }}
                  >
                    {icon && <img src={icon} width={20} alt={title} />}
                    <Box ml={2}>{title}</Box>
                  </Button>
                </Link>
              );
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
