import React from "react";
import { getElementSize } from "../utils/numToMeasurement";
import { useHistory } from "react-router-dom";

export const BTN = ({ text, url, target, style }) => {
  const history = useHistory();

  const onNavigate = () => {
    if (target === "_blank") {
      const path = url?.includes("http") ? url : `https://${url}`;
      window.open(path, target);
      return;
    }

    url && history.push(url);
  };

  return (
    <button
      onClick={onNavigate}
      style={{
        padding: getElementSize(style?.padding),
        margin: getElementSize(style?.margin),
        background: style?.background,
        color: style?.color,
        borderWidth: getElementSize(style?.borderWidth),
        borderStyle: style?.borderStyle,
        borderColor: style?.borderColor,
        boxSizing: style?.boxSizing,
        fontFamily: style?.fontFamily,
        fontSize: getElementSize(style?.fontSize),
        fontWeight: style?.fontWeight,
        textDecoration: style?.textDecoration,
        textAlign: style?.textAlign,
        height: getElementSize(style?.height),
        width: getElementSize(style?.width),
        minHeight: getElementSize(style?.minHeight),
        minWidth: getElementSize(style?.minWidth),
        maxHeight: getElementSize(style?.maxHeight),
        maxWidth: getElementSize(style?.maxWidth),
        borderRadius: getElementSize(style?.borderRadius),
      }}
    >
      {text}
    </button>
  );
};
