import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React from "react";

export const Pagination = ({ links, fetch }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Grid style={{ paddingBottom: "1em" }}>
        {links.map(link => (
          <Button
            key={link.label}
            onClick={() => fetch(link.url.slice(link.url.lastIndexOf("=") + 1))}
            style={{ fontSize: "20px" }}
          >
            <span
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
                borderRadius: "2em",
                padding: ".5em .8em",
                display: "block",
              }}
              dangerouslySetInnerHTML={{ __html: link.label }}
            />
          </Button>
        ))}
      </Grid>
    </Box>
  );
};
