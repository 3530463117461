import { useCallback } from "react";

export function useSmoothScroll(offsetHeight) {
  return useCallback(
    sectionId => {
      const yOffset = -offsetHeight;
      const element = document.getElementById(sectionId);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
    [offsetHeight]
  );
}
