import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Chip, ListItemText, OutlinedInput, Select } from "@mui/material";
import Box from "@mui/system/Box";
import COLORS from "../../../constants/colors";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { SchoolActivitiesList } from "../../../constants/School";

export const SchoolActivities = ({ formData, updateFormData }) => {
  const updateData = e => {
    const newData = { ...formData?.school_activities, name: e.target.value };
    updateFormData("school_activities", { ...newData });
  };

  return (
    <Grid item md={4} xs={12} sm={6}>
      <InputLabel className="MuiInputLabel-Profile-label" shrink>
        School activities I participated in
      </InputLabel>
      <FormControl fullWidth>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={formData.school_activities.name}
          onChange={updateData}
          input={<OutlinedInput />}
          fullWidth
          renderValue={() => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  minHeight: "1.6em",
                  maxHeight: "5em",
                  overflowY: "scroll",
                }}
              >
                {formData.school_activities.name.map(value => {
                  const label = SchoolActivitiesList.find(({ key }) => value === key);
                  return (
                    <Chip
                      key={value}
                      label={label.value}
                      color="primary"
                      style={{ color: COLORS.white.secondary }}
                      size="small"
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {SchoolActivitiesList.map(({ key, value }, idx) => {
            return (
              <MenuItem key={idx} value={key} dense={true}>
                <Checkbox checked={formData.school_activities.name.includes(key)} />
                <ListItemText primary={value} sx={{ fontSize: "0.8em" }} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};
