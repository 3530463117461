export const FAMILY_MEMBER_ICONS = [
  {
    id: 0,
    icon: '/assets/icons/cartoon-icon1.svg',
  },
  {
    id: 1,
    icon: '/assets/icons/cartoon-icon2.svg',
  },
  {
    id: 2,
    icon: '/assets/icons/cartoon-icon3.svg',
  },
  {
    id: 3,
    icon: '/assets/icons/cartoon-icon4.svg',
  },
  {
    id: 4,
    icon: '/assets/icons/cartoon-icon5.svg',
  },
  {
    id: 5,
    icon: '/assets/icons/cartoon-icon6.svg',
  },
  {
    id: 6,
    icon: '/assets/icons/cartoon-icon7.svg',
  },
  {
    id: 7,
    icon: '/assets/icons/cartoon-icon8.svg',
  },
  {
    id: 8,
    icon: '/assets/icons/cartoon-icon8.svg',
  },
  {
    id: 9,
    icon: '/assets/icons/cartoon-icon9.svg',
  },
];
