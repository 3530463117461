import React from "react";
import { extractCSSProperties } from "./utils/extractCss";
import { BTN, IMG, TEXT, VIDEO, Col, Container, Row, GridBuilder } from "./Components";

export const useRenderNode = () => {
  const renderMapper = {
    Container: Container,
    GridItem: GridBuilder,
    Row: Row,
    Col: Col,
    Text: TEXT,
    Image: IMG,
    Video: VIDEO,
    Button: BTN,
  };

  const renderNode = (node, nodesMap) => {
    if (!node) return null;

    const Component = renderMapper[node.type.resolvedName];
    if (!Component) {
      console.error(`No component defined for type "${node.type}"`);
      return null;
    }
    const children = node?.nodes?.map(childId => renderNode(nodesMap[childId], nodesMap));

    const componentStyles = extractCSSProperties(node.props);
    const restProps = JSON.parse(JSON.stringify(node.props));
    Object.keys(node.props).forEach(key => {
      if ((componentStyles[key] || ["shadow", "fillSpace"].includes(key)) && key !== "src") {
        delete restProps[key];
      }
    });

    restProps.style = componentStyles;
    return <Component {...restProps}>{children}</Component>;
  };

  return renderNode;
};
