import LoadingIndicator from "../../components/Utils/LoadingIndicator";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiRequest from "../../api/apiRequest";
import { ProfilePage } from "../../components/ProfileDetails/ProfilePage";

export const AlumniDetails = () => {
  const { alumniId } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await apiRequest({ url: `/alumni/${alumniId}` });
        setProfile(response.data);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return <ProfilePage withLocation profile={profile} withEducationSection withEducation={false} />;
};
