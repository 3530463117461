import React, { useContext } from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import EditProfile from "../../GraduateProfile/components/OurProfile/components/EditProfile";
import ConfigurationContext from "contexts/ConfigurationContext";
import ProfileContext from "../../../contexts/ProfileContext";

export const Hero = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { configurations } = React.useContext(ConfigurationContext);
  const { profile } = useContext(ProfileContext);

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12} sm={12}>
            <Box>
              <Box pt={3}>
                <img
                  src={
                    profile?.profile_image?.path
                      ? profile?.profile_image?.path
                      : configurations?.default_user_profile_image?.path
                        ? configurations?.default_user_profile_image?.path
                        : "/assets/images/family-profile.svg"
                  }
                  alt=""
                  width="100%"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            px={5}
            display="flex"
            justifyContent="center"
            alignItems={{ xs: "center", sm: "normal" }}
            flexDirection="column"
          >
            <Button
              sx={{ width: "208px", height: "50px" }}
              className="MuiButton-profile"
              variant="outlined"
              onClick={() => setIsOpen(true)}
            >
              Edit Profile Image
            </Button>
          </Grid>
        </Grid>
      </Container>
      <EditProfile setIsOpen={setIsOpen} isOpen={isOpen} />
    </React.Fragment>
  );
};

export default Hero;
