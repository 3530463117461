import React from 'react';
import AddFamilyMember from '../AddFamilyMember';
import Box from '@mui/system/Box';
import Fab from '@mui/material/Fab';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { SELECT_ICONS } from 'constants/profiles';

export const FamilyMembers = ({ formData, updateFormData }) => {
  const [isAddFamilyMember, setIsAddFamilyMember] = React.useState(false);

  const updateMember = (idx, key, value) => {
    const newMembers = [...formData.members];
    newMembers[idx][key] = value;
    updateFormData('members', newMembers);
  };

  const removeMember = (idx) => {
    const newMembers = [...formData.members];
    newMembers.splice(idx, 1);
    updateFormData('members', newMembers);
  };

  const addMember = (name, gender, age, iconId, family_title) => {
    const newMembers = formData?.members ? [...formData?.members] : [];
    newMembers.push({
      name,
      gender,
      age,
      icon_id: iconId,
      family_title: family_title,
      education: { outcome_id: '', degree: '' },
    });
    updateFormData('members', newMembers);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            Family Members*
          </Typography>
          {/* <img src='/assets/icons/profile-edit-icon.svg' alt='' style={{ cursor: 'pointer' }} /> */}
        </Box>
        {formData?.members?.map((member, idx) => (
          <div key={idx}>
            <Grid container mt={2} spacing={3} alignItems='center'>
              <Grid item md={2} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  Name
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{ disableUnderline: 'false' }}
                    variant='filled'
                    hiddenLabel
                    fullWidth
                    id='last-name'
                    name='name'
                    className='family-input-fields'
                    value={member?.name}
                    onChange={(e) => updateMember(idx, 'name', e.target.value)}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  Gender
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      disableUnderline: 'false',
                    }}
                    fullWidth
                    select
                    name='gender'
                    value={member?.gender}
                    onChange={(e) => updateMember(idx, 'gender', e.target.value)}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='Male'>Male</MenuItem>
                    <MenuItem value='Female'>Female</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  Age
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      disableUnderline: 'false',
                      inputProps: { min: 0, max: 100 },
                    }}
                    fullWidth
                    type='number'
                    name='age'
                    value={member?.age}
                    onChange={(e) => updateMember(idx, 'age', e.target.value)}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  Icon
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      disableUnderline: 'false',
                    }}
                    fullWidth
                    select
                    name='icon_id'
                    value={member?.icon_id}
                    onChange={(e) => updateMember(idx, 'icon_id', e.target.value)}
                  >
                    <MenuItem value=''>{/* <em>Select Age</em> */}</MenuItem>
                    {SELECT_ICONS.map((data, index) => (
                      <MenuItem value={index} key={index}>
                        <img src={data} alt='' />
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  Family Title
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{ disableUnderline: 'false' }}
                    variant='filled'
                    hiddenLabel
                    fullWidth
                    id='family-title'
                    name='family_title'
                    className='family-input-fields'
                    value={member?.family_title}
                    onChange={(e) => updateMember(idx, 'family_title', e.target.value)}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Fab color='primary' onClick={(e) => removeMember(idx)} sx={{ mt: 2 }}>
                  <RemoveIcon style={{ color: '#fff' }} />
                </Fab>
              </Grid>
            </Grid>
          </div>
        ))}
        <Box my={2}>
          <Box display='flex' alignItems='center'>
            <Fab color='secondary' onClick={() => setIsAddFamilyMember(!isAddFamilyMember)}>
              <AddIcon style={{ color: '#fff' }} />
            </Fab>
            <Typography ml={3} variant='body1' fontWeight='bold'>
              Add a new family member
            </Typography>
          </Box>
        </Box>
        {isAddFamilyMember && (
          <Box mt={2}>
            <AddFamilyMember
              setIsAddFamilyMember={setIsAddFamilyMember}
              addMember={addMember}
              // addMember={addMember}
              // addMemberManually={addMemberManually}
              // addExtraMember={addExtraMember}
            />
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
};

export default FamilyMembers;
