import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ConfigurationContext from "contexts/ConfigurationContext";

export const Heading = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  return (
    <Container maxWidth="xl" style={{ marginTop: "30px" }}>
      <Grid container xs={12} sm={10} md={10}>
        <Grid item>
          <Typography
            gutterBottom
            variant="h2"
            sx={{
              color: "#131416",
              fontWeight: "900",
              lineHeight: "58px",
              marginTop: "25px",
            }}
          >
            {configurations.club_header_copy ?? "Hub"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontWeight: "400",
              lineHeight: "28px",
              fontStyle: "normal",
              marginBottom: "20px",
            }}
          >
            {configurations.club_subheader_copy ?? ""}
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}></Grid>
      </Grid>
    </Container>
  );
};
