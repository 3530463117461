import React from 'react';

import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomSelect = (props) => {
  const handleClick = () => {
    if (props.isSelectSchool === false) {
      props.setIsSelectSchool(true);
    } else {
      props.setIsSelectSchool(false);
    }
  };
  return (
    <Box>
      <Input
        sx={{
          display: 'none',
          flex: '1',
          borderRadius: '1px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '10px',
        }}
      />
      <Button
        variant='outline'
        sx={{
          padding: '10px 12px 8px',
          width: '434px',
          height: '55px',
          borderRadius: '1px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: 'white',
          color: 'black',
          font: 'inherit',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        onClick={handleClick}
      >
        {props.value}
      </Button>
      <ArrowDropDownIcon sx={{ position: 'absolute', top: '18px', right: '35px' }} />
    </Box>
  );
};
export default CustomSelect;
