import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ConfigurationContext from 'contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';
import Logo from '../../../../../../components/Global/Logo';
import AuthContext from '../../../../../../contexts/AuthContext';
import { COLORS } from 'constants/colors';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { createPasswordSchema } from 'validations/schemas';
import { ROUTES } from 'constants/routes';

export const SignUpModal = (props) => {
  const { open, close, handleSignup } = props || {};
  const { configurations } = React.useContext(ConfigurationContext);
  const [email, setEmail] = React.useState('');
  const [passwordModel, setPasswordModel] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState('');
  const { isAuthenticated, handleVerification, isLoading, error, createPassword } = useContext(AuthContext);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: zodResolver(createPasswordSchema),
  });

  const handleActionAndSignup = async () => {
    try {
      await handleVerification(email, verificationCode);
      setValue('password', '');
      setValue('confirmPassword', '');
      setPasswordModel(true);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (error !== '') {
      if (error === 'verification.invalid-code') toast.error('Invalid Verification code!');
      else if (error === 'identity.not-found') toast.error('No user found with that email!');
      else if (error === 'verification.already') toast.error('Verification Code is already used!');
    }
  }, [error]);


  const onSubmitHandle = async (values) => {
    if (!isUpdating) {
      setIsUpdating(true);
      try {
        await createPassword(values.password, values.confirmPassword);
        toast.success('Password Successfully Created!');
        history.push(ROUTES.HOME);
      } catch (error) {
        toast.error(error);
      }
      setIsUpdating(false);
    }
  };

  if (passwordModel) {
    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Paper
            sx={{
              p: 2,
              top: '58%',
              left: '50%',
              boxShadow: 24,
              position: 'absolute',
              justifyContent: 'center',
              width: { xs: '95%', md: 570 },
              bgcolor: 'background.paper',
              transform: 'translate(-50%, -50%)',
              height: { xs: 'auto', md: '700px' },
            }}
            open={open}
          >
            <Box textAlign='center'>
              <Logo />
            </Box>
            <Divider variant='fullWidth' sx={{ py: 1 }} />
            <Grid container p={2} spacing={3}>
              <Grid item md={12} xs={12}>
                <Box>
                  <Typography fontSize={18} fontWeight={400}>
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    type='text'
                    placeholder='Enter New Password'
                    sx={{ mt: 1 }}
                    error={!!errors['password']}
                    helperText={errors['password'] ? errors['password'].message : ''}
                    {...register('password')}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={3}>
              <Grid item md={12} xs={12}>
                <Box>
                  <Typography fontSize={18} fontWeight={400}>
                    Confirm Password
                  </Typography>
                  <TextField
                    fullWidth
                    type='text'
                    placeholder='Enter Confirm Password'
                    sx={{ mt: 1 }}
                    error={!!errors['confirmPassword']}
                    helperText={errors['confirmPassword'] ? errors['confirmPassword'].message : ''}
                    {...register('confirmPassword')}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xs={12} textAlign='center'>
                <Button
                  size='large'
                  variant='contained'
                  sx={{
                    p: 2,
                    width: 160,
                    fontSize: 16,
                    fontWeight: 900,
                    borderRadius: 50,
                    textTransform: 'capitalize',
                    background: theme.palette.secondary.main,
                    color: '#fff',
                  }}
                  onClick={handleSubmit(onSubmitHandle)}
                  disabled={isUpdating}
                >
                  {isUpdating ? <CircularProgress style={{ color: 'white' }} /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Paper
            sx={{
              p: 2,
              top: '58%',
              left: '50%',
              boxShadow: 24,
              position: 'absolute',
              justifyContent: 'center',
              width: { xs: '95%', md: 570 },
              bgcolor: 'background.paper',
              transform: 'translate(-50%, -50%)',
              height: { xs: 'auto', md: '700px' },
            }}
            open={open}
          >
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <IconButton color='primary' onClick={close} sx={{ bgcolor: COLORS.white.dark }}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <Box textAlign='center'>
              <Logo />
            </Box>
            <Divider variant='fullWidth' sx={{ py: 1 }} />
            <Grid container p={2} spacing={3}>
              <Grid item md={12} xs={12}>
                <Box>
                  <Typography fontSize={18} fontWeight={400}>
                    Email Address (the one you use for {configurations.schoolName ? configurations.schoolName : ''})
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder='Enter here...'
                    sx={{ mt: 1 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box>
                  <Typography fontSize={18} fontWeight={400}>
                    You should have received a verification code from{' '}
                    {configurations.schoolName ? configurations.schoolName : ''}. Please enter it here.
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder='Enter here...'
                    sx={{ mt: 1 }}
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12} textAlign='center'>
                <Button
                  size='large'
                  variant='contained'
                  onClick={handleActionAndSignup}
                  sx={{
                    p: 2,
                    width: 160,
                    fontSize: 16,
                    fontWeight: 900,
                    borderRadius: 50,
                    textTransform: 'capitalize',
                    background: theme.palette.secondary.main,
                    color: '#fff',
                  }}
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Confirm'}
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box>
                  <Typography fontSize={18} fontWeight={400}>
                    Don’t have a verification code? No problem! Click below and we will let you know when your account
                    is verified.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12} textAlign='center'>
                <Button
                  size='large'
                  variant='contained'
                  sx={{
                    fontWeight: 900,
                    borderRadius: 50,
                    py: { xs: 2, md: 2 },
                    padding: { xs: 1, md: 2 },
                    textTransform: 'capitalize',
                    fontSize: { xs: 12, md: 16 },
                    background: theme.palette.secondary.main,
                    color: '#fff',
                  }}
                  onClick={handleSignup}
                >
                  Continue without a verification code
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  }
};

export default SignUpModal;
