import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import "./Slider.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import QAQuestionBox from "../QAQuestionBox";
import { useTheme } from "@mui/material/styles";
import { theme } from "uikit/theme";
import VideoJS from "components/VideoJS";

export const MultiSlider = ({
  qaData,
  data,
  selectedResponse,
  setSelectedResponse,
  onChangeCurrentSlide,
}) => {
  const theme = useTheme();
  const videoSliderRef = useRef(null);
  const questionsSliderRef = useRef(null);
  const thumnailsSliderRef = useRef(null);
  const selectedResponseRef = useRef();
  selectedResponseRef.current = selectedResponse;

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (onChangeCurrentSlide) {
      onChangeCurrentSlide(currentSlide);
    }
  }, [selectedResponse]);

  useEffect(() => {
    if (videoSliderRef.current?.slickGoTo) videoSliderRef.current.slickGoTo(selectedResponse);
    if (thumnailsSliderRef.current?.slickGoTo)
      thumnailsSliderRef.current.slickGoTo(selectedResponse);
    if (questionsSliderRef.current?.slickGoTo)
      questionsSliderRef.current.slickGoTo(
        data.findIndex(e => e.id === qaData[selectedResponse].id)
      );
  }, [selectedResponse, data, qaData]);

  const questionsSliderSettings = {
    infinite: data.length !== 1,
    slidesToShow: data.length >= 5 ? 4.5 : data.length,
    slidesToScroll: 1,
    dots: false,
    speed: 300,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: data.length >= 5 ? 5 : data.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data.length >= 3 ? 3 : data.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.length >= 2 ? 2 : data.length,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const thumnailsSliderSettings = {
    infinite: data.length !== 1,
    dots: null,
    slidesToShow: qaData.length > 5 ? 6 : qaData.length,
    slidesToScroll: 5,
    arrows: null,
    appendDots: null,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const videosSliderSettings = {
    infinite: false,
    arrows: qaData.length !== 1,
    slidesToShow: 1,
    centerMode: true,
    lazyLoad: true,
    afterChange: indexOfNextSlide => {
      setCurrentSlide(indexOfNextSlide);

      if (indexOfNextSlide !== selectedResponse) {
        if (indexOfNextSlide < qaData.length) {
          setSelectedResponse(indexOfNextSlide);
        } else {
          setSelectedResponse(0);
        }
      }
    },
    beforeChange: () => {
      const vidElement = document.querySelector(".slick-current .qavideos > video");
      vidElement.pause();
    },
  };

  console.log("3", data, "4", qaData);

  return (
    <>
      {/* Questions Slider */}
      <Grid className={data.length === 1 ? "remove-duplicates-single" : ""}>
        <Slider
          {...questionsSliderSettings}
          ref={questionsSliderRef}
          nextArrow={<NextArrowQA />}
          prevArrow={<PrevArrowQA />}
          className="questions-slider"
        >
          {data?.map((item, index) => {
            return (
              <Box ml={2} mr={2} mt={{ xs: 5, md: 0 }} key={index}>
                <QAQuestionBox
                  selected={qaData[selectedResponse].id === data[index].id ? true : false}
                  topic={item?.topic}
                  onClick={() => {
                    setSelectedResponse(qaData.findIndex(e => e.id === data[index].id));
                  }}
                />
              </Box>
            );
          })}
        </Slider>
      </Grid>

      <div
        className={qaData.length === 1 ? "remove-single-response" : ""}
        style={{ margin: "-30px 30px 0px 30px", position: "relative" }}
      >
        {/* Video Slider */}
        <Slider
          {...videosSliderSettings}
          autoplaySpeed={2000}
          slidesToShow={1}
          centerMode={true}
          dots={null}
          ref={videoSliderRef}
          infinite
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          dotsClass="slick-dots custom-indicator"
          className="qa-video-slider"
        >
          {qaData?.map((item, index) => {
            return (
              <Box
                className={"video-js-container"}
                height="auto"
                position={"relative"}
                sx={{
                  padding: "0 10px",
                }}
                key={index}
              >
                <div
                  style={{
                    width: "100%",
                    height: "80%",
                  }}
                >
                  <VideoJS
                    track={item.uploadFileUrl}
                    options={{
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,

                      sources: [
                        {
                          src: item?.responseUrl,
                        },
                      ],
                    }}
                    poster={
                      item.thumbnail
                        ? item.thumbnail
                        : item?.thumbnails["100%"]
                          ? item?.thumbnails["100%"]
                          : ""
                    }
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <Typography variant="h3" sx={{ fontSize: "1.5rem", fontWeight: "900" }}>
                    {item?.responseName}
                  </Typography>
                  <Typography variant="body" sx={{ fontSize: "1rem" }}>
                    {item?.responseTitle}
                  </Typography>
                </div>
              </Box>
            );
          })}
        </Slider>

        {/* Thumnails Slider */}
        <Slider ref={thumnailsSliderRef} {...thumnailsSliderSettings}>
          {qaData?.map((item, index) => {
            return (
              <Box
                sx={{ cursor: "pointer", marginTop: "2rem" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap={"wrap"}
                width={"100%"}
                height="100%"
                gap={"1rem"}
                paddingY={"1rem"}
                px={".5rem"}
                onClick={() => {
                  setSelectedResponse(index);
                }}
                key={index}
              >
                <Typography>{item?.quoteTitle}</Typography>
                <img
                  src={
                    item.thumbnail
                      ? item.thumbnail
                      : item?.thumbnails["25%"]
                        ? item?.thumbnails["25%"]
                        : "/assets/images/noImage.jpg"
                  }
                  alt={item?.responseTitle}
                  width={"100%"}
                  height={"112px"}
                  style={{ objectFit: "cover" }}
                />

                {selectedResponse === index ? (
                  <Box
                    sx={{
                      width: "111px",
                      height: "7px",
                      borderRadius: "5px",
                      bgcolor: theme.palette.primary.main,
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default MultiSlider;

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow"}
      style={{
        position: "absolute",
        top: "0",
        left: "100px",
        cursor: "pointer",
        zIndex: "1",
        height: "calc(100% - 74px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0.9), transparent)",
        width: "100px",
        marginLeft: "3em",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          width: "50px",
          height: "50px",
          background: "white",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <NavigateNextIcon
          sx={{
            color: theme.palette.secondary.main,
            fontSize: "2rem",
            transform: "rotate(180deg)",
          }}
        />
      </div>
    </div>
  );
}
function PrevArrowQA(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow1"}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon
        sx={{
          color: theme.palette.secondary.main,
          fontSize: "2rem",
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow"}
      style={{
        position: "absolute",
        top: "0",
        right: "-1em",
        cursor: "pointer",
        zIndex: "1",
        height: "calc(100% - 74px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(90deg, transparent, rgba(255,255,255, 0.9))",
        width: "100px",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          width: "50px",
          height: "50px",
          background: "white",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <NavigateNextIcon sx={{ color: theme.palette.secondary.main, fontSize: "2rem" }} />
      </div>
    </div>
  );
}

function NextArrowQA(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow1"}
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon sx={{ color: theme.palette.secondary.main, fontSize: "2rem" }} />
    </div>
  );
}
