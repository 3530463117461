export const COLORS = {
  primary: {
    // main: '#2E75FF',
    main: '#046A38',
    light: '#87AFFF',
  },
  secondary: {
    // main: "#08296B",
    main: '#18381E',
  },
  warning: {
    // main: "#EFAC00",
    main: '#fff',
    dark: '#ED282F',
  },
  success: {
    main: '#4CBC24',
  },
  white: {
    main: '#FFFFFF',
    dark: '#F1F1F1',
    secondary: '#F3F4F4',
    light: '#F7F8F8',
    lighter: '#F3F4F4',
  },
  black: {
    main: '#000000',
    dark: '#131416',
    light: '#353535',
  },
  dark: {
    main: '#000000',
    light: '#34333B',
    lighter: '#20212F',
    lightest: '#979797',
  },
  blue: {
    // main: "#10265B",
    main: '#18381E',
    secondary: '#2E75FF',
    dark: ' #302F68',
    light: '#D3D2FF',
  },
  grey: {
    main: '#C0C7D5',
    secondary: '#EDEDED',
    light: '#F6F8FA',
    lighter: '#FBFBFD',
    lightest: '#F6F6F6',
    dark: '#20212F',
    lightGrey: '#F1F5FD',
    thinner: '#B8B8B8',
    greyOnGrey: '#8b8e95',
  },
  common: {
    sky: '#DCE8FF',
    purple: '#F2EFFF',
    graduate: '#FFF3EB',
    staff: '#E8F9E3',
  },
  pink: {
    main: '#BF38FF',
  },
};

export default COLORS;
