import React, { useContext, useEffect } from "react";
import FamilyNetworkCard from "./components/FamilyNetworkCard";
import FamilyAmbassadorsArea from "./components/FamilyAmbassadorsArea";
import AmbassadorFamilyClub from "./components/AmbassadorFamilyClub";
import FamilyAmbassadorsDetails from "./components/FamilyAmbassadorsDetails";
import FamilyAmbassadorsProfileDetails from "./components/FamilyAmbassadorsProfileDetails";
import AmbassadorContext from "../../contexts/AmbassadorContext";
import ConfigurationContext from "contexts/ConfigurationContext";

export const ManageFamilyNetwork = () => {
  const { ambassadors, fetchAmbassadors, isLoading } =
    useContext(AmbassadorContext);
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  useEffect(() => {
    fetchAmbassadors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <FamilyNetworkCard />
      <FamilyAmbassadorsArea ambassadors={ambassadors} isLoading={isLoading} />
      {isFeatureEnabled("family_familyclubs") && <AmbassadorFamilyClub />}
    </React.Fragment>
  );
};

export const ManageFamilyAmbassadorsDetails = FamilyAmbassadorsDetails;

export const ManageFamilyAmbassadorrsProfile = FamilyAmbassadorsProfileDetails;

export default ManageFamilyNetwork;
