import React from 'react';
import SideBar from './Sidebar';
import Grid from '@mui/material/Grid';

export const ProfileLayout = (props) => {
  const { setActiveStep, activeStep, children } = props || {};

  return (
    <Grid container>
      <Grid item md={2} mt={8} xs={12} sm={12}>
        <SideBar setActiveStep={setActiveStep} activeStep={activeStep} />
      </Grid>
      <Grid item md={8} mt={8} xs={12} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
};
