import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import { ROUTES } from "../../../../constants/routes";

export const Footer = () => {
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);

  return (
    <footer>
      <Grid container p={5} spacing={4} sx={{ bgcolor: "#F2F2F2" }} pb={8}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="div" fontWeight="bold">
            {configurations.schoolName ? configurations.schoolName : ""}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <a href="https://think-12.com/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/footer-logo.svg" alt="logo" />
            </a>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="body2" component="div">
            {/* 10397 OH-155, <br /> Corning, OH 43730 <br /> +1 740-721-0521 */}
            {/* 230 Mendham Road <br /> Morristown, NJ 07960 <br /> +1 973-538-3231 */}
            {configurations.school_address ? configurations.school_address : ""}
            <br />
            {`${configurations.school_city ? configurations.school_city : ""}, ${
              configurations.school_state ? configurations.school_state : ""
            } ${configurations.school_zip_code ? configurations.school_zip_code : ""}`}
            <br />
            {configurations?.school?.phone_number ? configurations?.school?.phone_number : ""}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box display="flex" flexDirection="column">
            {isFeatureEnabled("footer_contact_link") && (
              <Box mb={1}>
                <Link to="/">Contact</Link>
              </Box>
            )}
            <Box>
              <Link to={ROUTES.TERMS_OF_USE}>Terms of Use</Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box display="flex" flexDirection="column">
            {isFeatureEnabled("footer_share_link") && (
              <Box mb={1} display="flex" alignItems="center">
                <Link to="/">Share</Link> &nbsp;
                <ShareIcon color="secondary" />
              </Box>
            )}
            <Box>
              <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: "block", sm: "block", md: "none" } }}
          md={3}
          mt={1.5}
        >
          <a href="https://think-12.com/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/footer-logo.svg" alt="logo" />
          </a>
        </Grid>
        {configurations?.custom_footer ? (
          <Grid item xs={12} md={8}>
            <Box display="flex" flexDirection="column">
              <Box>{configurations?.custom_footer ? configurations?.custom_footer : ""}</Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </footer>
  );
};

export default Footer;
