import React from 'react';
import * as Routes from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { LoginAccount } from './components/LoginAccount/index';
import { ForgotPassword } from './components/ForgotPassword/index';
import { ResetPassword } from './components/ResetPassword/index';
import { CreateAccount } from './components/CreateAccount/index';
import { GraduateProfileSignUp, StaffAdminSignUp } from './components/CreateAccount/components';
import ClubProfileSignUp from './components/CreateAccount/components/ClubProfileSignUp';
import { GraduateProfileSignIn, StaffAdminSignIn } from './components/LoginAccount/components';
import ClubProfileSignIn from './components/LoginAccount/components/ClubProfileSignIn';

export const ManageAuth = () => {
  const { pathname } = Routes.useLocation();

  //signin routes
  if (pathname === ROUTES.SIGN_IN) return <LoginAccount />;
  if (pathname === ROUTES.FORGOT_PASSWORD) return <ForgotPassword />;
  if (pathname === ROUTES.RESET_PASSWORD) return <ResetPassword />;
  if (pathname === ROUTES.AUTH_GRADUATE_SIGNIN) return <GraduateProfileSignIn />;
  if (pathname === ROUTES.AUTH_CLUB_HUB_SIGNIN) return <ClubProfileSignIn />;
  if (pathname === ROUTES.AUTH_ADMIN_STAFF_SIGNIN) return <StaffAdminSignIn />;

  //signup routes
  if (pathname === ROUTES.SIGN_UP) return <CreateAccount />;
  if (pathname === ROUTES.AUTH_GRADUATE_SIGNUP) return <GraduateProfileSignUp />;
  if (pathname === ROUTES.AUTH_CLUB_HUB_SIGNUP) return <ClubProfileSignUp />;
  if (pathname === ROUTES.AUTH_ADMIN_STAFF_SIGNUP) return <StaffAdminSignUp />;
};

export default ManageAuth;
