import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RealAnswers } from "./components/RealAnswers/index";
import { ThanksBox } from "./components/ThanksBox/index";
import MultiSlider from "./components/MultiSlider";
import QAContext from "../../contexts/QAContext";
import LoadingIndicator from "../../components/Utils/LoadingIndicator";
import { sendPageReportingEvent } from "helpers/reporting";

export const ManageQA = () => {
  const [selectedResponse, setSelectedResponse] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { qas, isLoading, error, fetchQas } = useContext(QAContext);
  const { id } = useParams();

  const getInitialData = async () => {
    await fetchQas();
  };

  const flatten = (arr) => {
    return arr
      .map(({ id, responses }) =>
        responses.map((video) => {
          return { ...video, id };
        })
      )
      .flat(Infinity);
  };

  React.useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (id) {
      let flatList = flatten(qas);
      let index = -1;
      for (let i = 0; i < flatList.length; i++) {
        if (flatList[i].id === parseInt(id)) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        setSelectedResponse(index);
      }
    }
  }, [qas]);

  const finalQA = qas;

  let flatList = flatten(qas);

  const updateSelectedResponse = (index) => {
    setSelectedResponse(index);
  };

  React.useEffect(() => {
    // Update the path when the selected response changes using react router
    if (qas.length > 0 && selectedResponse !== -1) {
        let newPath = "/qa/" + flatList[selectedResponse].id;
        window.history.pushState(null, null, newPath);
        setTimeout(() => {
          sendPageReportingEvent();
        }, 200);
      }
  }, [selectedResponse, currentSlide]);

  return (
    <React.Fragment>
      <RealAnswers data={finalQA} isLoading={isLoading} />
      {isLoading ? (
        <LoadingIndicator />
      ) : error !== "" ? (
        error
      ) : (
        <MultiSlider
          onChangeCurrentSlide={setCurrentSlide}
          qaData={flatList}
          data={finalQA}
          selectedResponse={selectedResponse}
          setSelectedResponse={updateSelectedResponse}
        />
      )}
      <ThanksBox
        qaData={finalQA.find(({ id }) => id === flatList[selectedResponse].id)}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default ManageQA;
