import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import ConfigurationContext from "contexts/ConfigurationContext";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { MessagesTab } from "components/Global/Messages";

import { COLORS } from "constants/colors";
import { ProfileCircle, ProfileCircle2 } from "./ProfileCircle";
import { COUNTRIES, STATES } from "../../constants/countries";

export const ProfileDetails = ({ profile, withEducation = true, withLocation }) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const theme = useTheme();
  const { configurations, schools, isFeatureEnabled } = useContext(ConfigurationContext);
  const linkedIn = profile.social_media_accounts?.find(
    sm => sm.provider.toLowerCase().trim() === "linkedin"
  );
  const facebook = profile.social_media_accounts?.find(
    sm => sm.provider.toLowerCase().trim() === "facebook"
  );
  const twitter = profile.social_media_accounts?.find(
    sm => sm.provider.toLowerCase().trim() === "twitter"
  );

  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <ProfileCircle />
        <Grid item container md={10} sm={12} mt={10}>
          <ProfileCircle2 />

          <Grid item md={4} sm={6} xs={12}>
            <Box
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.03)" }}
            >
              <img
                src={
                  profile?.profile_image?.path
                    ? profile?.profile_image?.path
                    : configurations?.default_user_profile_image?.path
                      ? configurations?.default_user_profile_image?.path
                      : "/assets/images/family-profile.svg"
                }
                alt={profile.full_name}
                title={profile.full_name}
                width="100%"
                height="auto"
              />
            </Box>
          </Grid>

          <Grid item container md={8} sm={6} justifyContent="center" paddingX={5} mt={4}>
            <Grid item md={6}>
              {profile?.last_name ? (
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  {profile.full_name}
                </Typography>
              ) : (
                ""
              )}
              <Box>
                <Typography fontSize={{ md: 18 }} fontWeight={600} mt={1} width={260} wrap>
                  {/* {profile?.head?.first_name || ''} */}
                  {profile?.members &&
                    profile.members.map(
                      (mem, i) => `${mem.first_name}${i < profile.members.length - 1 ? ", " : ""}`
                    )}
                </Typography>

                <Typography fontSize={{ md: 18 }} fontWeight={600} mt={1} width={260} wrap>
                  {profile?.attending_school_since && `Class ${profile.attending_school_since}`}
                  <br />
                  {profile?.email?.value && profile?.is_primary_email_visible ? (
                    <a
                      href={`mailto:${profile?.email?.value}`}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        textDecoration: "none",
                      }}
                    >
                      {profile?.email?.value}
                    </a>
                  ) : (
                    []
                  )}
                </Typography>
                {withEducation && (
                  <>
                    {profile?.educations?.map(ed => {
                      const foundSchool = schools.find(s => s.id === ed.outcome_id);
                      if (!foundSchool) return <React.Fragment></React.Fragment>;
                      return (
                        <>
                          <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                            {ed.degree} - {foundSchool.outcomeTitle}
                          </Typography>
                          {foundSchool?.url && (
                            <Box mt={2}>
                              <img
                                src={foundSchool?.url}
                                alt={foundSchool?.outcomeTitle}
                                height="30px"
                              />
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {withLocation && (
                  <>
                    {(profile?.location?.city ||
                      profile?.location?.abbreviation ||
                      profile?.location?.country) && (
                      <Box mt={2}>
                        <Typography fontSize={{ md: 40 }} fontWeight={700}>
                          Location
                        </Typography>
                        {profile?.location.city && (
                          <Typography fontSize={{ md: 18 }} fontWeight={400}>
                            {profile?.location?.city} City
                          </Typography>
                        )}
                        {profile?.location?.subdivision && (
                          <Typography fontSize={{ md: 18 }} fontWeight={400}>
                            {STATES.find(
                              state => state.abbreviation === profile?.location.subdivision
                            )?.name || ""}{" "}
                            State
                          </Typography>
                        )}
                        {profile?.location?.country && (
                          <Typography fontSize={{ md: 18 }} fontWeight={400}>
                            {COUNTRIES.find(state => state.code === profile?.location.country)
                              ?.name || ""}{" "}
                            Country
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: 2, md: 0 }}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isFeatureEnabled("family_message") && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 50, padding: 2, width: 220, color: "white" }}
                  onClick={() => setIsMessageModalOpen(true)}
                >
                  Message
                </Button>
              )}
              <Box display="flex" justifyContent="flex-end" marginX={2} mt={2}>
                {facebook ? (
                  <a
                    href={
                      facebook.url.toLowerCase().startsWith("http")
                        ? facebook.url
                        : `//${facebook?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <FacebookIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}

                {twitter ? (
                  <a
                    href={
                      twitter.url.toLowerCase().startsWith("http")
                        ? twitter.url
                        : `//${twitter?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <TwitterIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}

                {linkedIn ? (
                  <a
                    href={
                      linkedIn.url.toLowerCase().startsWith("http")
                        ? linkedIn.url
                        : `//${linkedIn?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <LinkedInIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby="Messages"
        aria-describedby="Messages"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // boxShadow: 24,
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <MessagesTab isNew={true} isModal={true} members={profile ? [profile] : []} />
        </Box>
      </Modal>
    </Box>
  );
};

export default ProfileDetails;
