import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ArticleCard from '../ArticleCard';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const Article = ({ articleContent }) => {
  const history = useHistory();
  const theme = useTheme();

  const navigate = (article) => {
    return history.push({
      pathname: `/article/${article?.articleUrl.split('/').pop()}`,
    });
  };

  return (
    <>
      <Box position={'relative'}>
        <Box
          sx={{
            top: '0',
            zIndex: -1,
            right: '30%',
            marginTop: '-8%',
            position: 'absolute',
          }}
        >
          <svg width='340' height='340' viewBox='0 0 340 340' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle
              cx='170'
              cy='170'
              r='150'
              stroke={theme.palette.primary.main}
              stroke-opacity='0.1'
              stroke-width='40'
            />
          </svg>
        </Box>
      </Box>

      <Container maxWidth='false'>
        <Grid container spacing={3} mt={{ md: 5, sm: 10, xs: 5 }}>
          {articleContent.map((article, idx) => (
            <Grid xs={12} item md={idx === 0 ? 5 : idx === 1 ? 7 : 4} key={idx}>
              <Box onClick={() => navigate(article)}>
                <ArticleCard
                  height={idx === 1 ? '500' : '300'}
                  image={article.featuredImageUrl}
                  fallbackImage={'assets/images/noImage.jpg'}
                  primaryText={article.articleTitle}
                  secondaryText={
                    article.contentDescription
                      ? article.contentDescription
                      : 'lorem ipsum sit amet. lorem ipsum sit amet'
                  }
                  article={article}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* <Grid container spacing={3} mt={5}>
          <>
            <Grid xs={12} item md={5}>
              <Link to={ROUTES.TIPS_ARTICLE}>
                <ArticleCard
                  height="300"
                  image={"assets/images/article-banner1.png"}
                  primaryText={" Benefits of a public school education"}
                  secondaryText="Public schools generally have a range of children from the whole gamut
              of socioeconomic classes and a wide variety of backgrounds."
                />
              </Link>
            </Grid>
            <Grid item xs={12} md={7}>
              <Link to={ROUTES.TIPS_ARTICLE}>
                <ArticleCard
                  height="500"
                  image={"assets/images/article-banner2.png"}
                  primaryText={" Mental health support services"}
                  secondaryText="It’s a resource more and more students (and families) are taking advantage of."
                />
              </Link>
            </Grid>
          </>
          <Grid item xs={12} md={4}>
            <Link to={ROUTES.TIPS_ARTICLE}>
              <ArticleCard
                height="300"
                image={"assets/images/article-banner3.png"}
                primaryText={" Community relationships"}
                secondaryText={
                  "Getting involved in community service shapes your student for the real world."
                }
              />
            </Link>
          </Grid>
          <Grid xs={12} item md={4}>
            <Link to={ROUTES.TIPS_ARTICLE}>
              <ArticleCard
                height="300"
                image={"assets/images/article-banner4.png"}
                primaryText=" The value of diversity in schools"
                secondaryText="
              Students learn how to use their own strengths and points of view to contribute in a diverse working environment."
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link to={ROUTES.TIPS_ARTICLE}>
              <ArticleCard
                height="300"
                image={"assets/images/article-banner5.png"}
                primaryText=" School spirit"
                secondaryText="
              Pride connects students to one another and supports community well-being."
              />
            </Link>
          </Grid>
        </Grid> */}
      </Container>
    </>
  );
};

export default Article;
