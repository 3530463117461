import React from 'react';
import { Box } from '@mui/system';
import { LinearProgress, Typography } from '@mui/material';

export const ProgressBar = (props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <Box sx={{ width: '100%', mr: 1, mb: 1, color: '#52D8C0' }}>
        <LinearProgress sx={{ background: 'gray' }} color='inherit' variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography fontWeight={700}>Uploading</Typography>
        {/* <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography> */}
      </Box>
    </Box>
  );
};
