import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export const ArticleCard = (props) => {
  const { primaryText, secondaryText, height, image, isWhite, fallbackImage, article } = props || {};

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Link
        to={`/article/${
          article.articleUrl ? article.articleUrl.split('/').pop() : article.article_url.split('/').pop()
        }`}
      >
        <CardMedia
          image={image}
          height={height}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackImage;
          }}
          component='img'
          alt={primaryText ? primaryText : 'No Title'}
        />
        <Typography mt={2} variant='h5' gutterBottom fontWeight='bold' sx={{ color: isWhite ? 'white' : '' }}>
          {primaryText}
        </Typography>
        <Typography variant='body1' color='text.secondary' sx={{ color: isWhite ? 'white' : '' }}>
          {secondaryText}
        </Typography>
      </Link>
    </Box>
  );
};

export default ArticleCard;
