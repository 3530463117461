import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SearchInputTextField from "../SearchInputTextField";
import ConfigurationContext from "contexts/ConfigurationContext";

export const ManageStaffHeader = ({ query, setQuery, onSearch }) => {
  const { configurations } = React.useContext(ConfigurationContext);
  const staffConfiguration = configurations?.pages_configuration?.staff?.configuration;

  return (
    <Box
      height={700}
      className="header-image-overlay"
      sx={{
        maxWidth: "700vh",
        objectFit: "cover",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${configurations?.staff_hero_image?.path})`,
      }}
    >
      {staffConfiguration?.isSearchVisible && (
        <SearchInputTextField query={query} setQuery={setQuery} onSearch={onSearch} />
      )}

      <Container maxWidth="false" disableGutters>
        <Grid item container>
          <Grid
            item
            md={5}
            sm={8}
            xs={12}
            mt={{ xs: 20, md: 20, sm: 20 }}
            sx={{ position: "absolute" }}
          >
            <Typography wrap ml={{ xs: 5, md: 15, sm: 4 }} variant="h2" color="white">
              {/* Meet and connect with our staff at {configurations.schoolShortName ? configurations.schoolShortName : ''}. */}
              {configurations?.staff_header_copy}
            </Typography>
            <Box
              ml={{ xs: 5, md: 15, sm: 4 }}
              mt={2}
              sx={{ display: "flex", flexWrap: "wrap" }}
              width={{ xs: 300, md: "auto", sm: 350 }}
            >
              <Typography color="white" fontSize={{ xs: 18, md: 18, sm: 18 }}>
                {/* We’re here to serve your student and your family. So let’s get to know each other. */}
                {configurations?.staff_subheader_copy}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ManageStaffHeader;
