import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import ProfileContext from "contexts/ProfileContext";
import apiRequest from "api/apiRequest";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import ConfigurationContext from "contexts/ConfigurationContext";
import AuthContext from "contexts/AuthContext";
import Hero from "../UpdateProfileForm/Hero";
import Education from "../UpdateProfileForm/Education";
import About from "../UpdateProfileForm/About";
import Activities from "../UpdateProfileForm/Activities";
import { ProfessionalExperience } from "../UpdateProfileForm/ProfessionalExperience/ProfessionalExperience";
import { GeneralComplex } from "../UpdateProfileForm/General/GeneralComplex";

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  attending_school_since: new Date().getFullYear(),
  social_media_accounts: [],
  is_primary_email_visible: 0,
  about: [],
  activity_ids: [],
  educations: [],
  profile_image: null,
  location: {
    city: "",
    country: "",
    subdivision: "",
  },
  professions: [],
  school_activities: {
    name: [],
  },
  open_volunteering: {
    name: [],
  },
};

export const AlumniProfileForm = () => {
  const { isLoading, alumniProfile, fetchProfileWithAlumni } = React.useContext(ProfileContext);
  const { schools, fetchSchools, activities, fetchActivities } =
    React.useContext(ConfigurationContext);
  const { user, setAuth } = React.useContext(AuthContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    if (alumniProfile) {
      setFormData(alumniProfile);
    }
  }, [alumniProfile]);

  React.useEffect(() => {
    fetchProfileWithAlumni(user.id);
    if (schools.length === 0) fetchSchools();
    if (activities.length === 0) fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormData = (formDataKey, formDataValue) => {
    const newFormData = { ...formData };
    newFormData[formDataKey] = formDataValue;
    setFormData(newFormData);
  };

  const handleSubmit = async () => {
    if (!isUpdating) {
      try {
        setIsUpdating(true);

        // Prepare Final Data To Submit
        const dataToSubmit = { ...formData };
        // delete dataToSubmit.email;

        // dataToSubmit.about = dataToSubmit.about.map((d) => (d.value === '' ? { name: d.name, value: null } : d));
        dataToSubmit.social_media_accounts = dataToSubmit.social_media_accounts.filter(
          d => d.url !== ""
        );
        dataToSubmit.educations = dataToSubmit.educations.filter(
          e => e.outcome_id !== null && e.degree.trim() !== ""
        );
        for (let i = 0; i < dataToSubmit.educations.length; i++) {
          const element = dataToSubmit.educations[i];
          delete element.ourId;
        }
        // delete dataToSubmit.about_media;

        // Prepare Data that needs to be deleted
        dataToSubmit.dissociate = {};

        // Prepare social media links that needs to be deleted
        const detachedSocialMedia = alumniProfile?.social_media_accounts.filter(
          sm => !dataToSubmit.social_media_accounts.some(s => s.provider === sm.provider)
        );
        const detachedSocialMediaIds = detachedSocialMedia.map(d => ({ id: d.id }));
        if (detachedSocialMediaIds.length)
          dataToSubmit.dissociate.social_media_accounts = detachedSocialMediaIds;

        // Prepare educations that needs to be deleted
        const detachedEducations = alumniProfile?.educations.filter(
          ed => !dataToSubmit.educations.some(m => m.id === ed.id)
        );
        const detachedEducationsIds = detachedEducations.map(d => ({ id: d.id }));
        if (detachedEducationsIds.length)
          dataToSubmit.dissociate.educations = detachedEducationsIds;

        const detachedProfessions = alumniProfile?.professions.filter(
          ed => !dataToSubmit.professions.some(m => m.id === ed.id)
        );
        const detachedProfessionsIds = detachedProfessions.map(d => ({ id: d.id }));
        if (detachedProfessionsIds.length)
          dataToSubmit.dissociate.professions = detachedProfessionsIds;

        const response = await apiRequest({
          method: "PUT",
          url: `/alumni/me`,
          data: dataToSubmit,
        });
        if (response?.data?.token?.value) {
          const authToken = `Bearer ${response?.data?.token?.value}`;
          setAuth(user, authToken);
        }
        setIsUpdating(false);
        toast.success("Graduate Profile Updated!");
      } catch (error) {
        toast.error(error);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Hero />
      {formData && <GeneralComplex formData={formData} updateFormData={updateFormData} />}
      <ProfessionalExperience formData={formData} updateFormData={updateFormData} />
      <Education formData={formData} updateFormData={updateFormData} />
      <About
        withAdvise={false}
        withQuote={false}
        formData={formData}
        updateFormData={updateFormData}
      />
      <Activities formData={formData} updateFormData={updateFormData} allActivities={activities} />
      <Container>
        <Box my={10} display="flex" justifyContent="flex-end">
          <Button
            sx={{ width: "233px", height: "60px" }}
            className="MuiButton-profile"
            variant="contained"
            color="secondary"
            disabled={isUpdating}
            onClick={handleSubmit}
          >
            {isUpdating ? <CircularProgress style={{ color: "white" }} /> : "Save Changes"}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};
