import { Checkbox, Chip, ListItemText, OutlinedInput, Select } from "@mui/material";
import { Box } from "@mui/system";
import COLORS from "../../../../../../../../../constants/colors";
import MenuItem from "@mui/material/MenuItem";
import React, { memo, useEffect, useId, useState } from "react";
import { useGroups } from "../../GroupsProvider";

export const UserGroupCellComponent = ({ userId, user }) => {
  const id = useId();
  const { groups, setGroups } = useGroups();
  const [localGroups, setLocalGroups] = useState(groups || []);
  const userGroups = useUserGroups(localGroups, userId);
  const ids = userGroups.map(group => group.id) || [];

  useEffect(() => {
    setLocalGroups(groups);
  }, [groups]);

  const checkedGroup = group => {
    return !!group.users.find(user => user.id === userId);
  };

  const onChange = group => {
    let newGroup = Object.assign({}, group);
    const foundUser = newGroup.users.find(userGroup => userGroup.id === userId);

    if (!foundUser) {
      const newUsers = [
        ...group.users,
        { id: userId, first_name: user?.first_name, last_name: user?.last_name },
      ];
      newGroup = { ...newGroup, users: newUsers };
    } else {
      const newUsers = newGroup.users.filter(ug => ug.id !== userId);
      newGroup = { ...newGroup, users: newUsers };
    }

    setLocalGroups(
      localGroups.map(gr => {
        if (gr.id === newGroup.id) return newGroup;
        return gr;
      })
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Select
        onClose={() => setGroups(localGroups)}
        key={id + userId}
        size="small"
        multiple
        value={ids}
        input={<OutlinedInput />}
        fullWidth
        renderValue={() => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              minHeight: "1.6em",
              maxHeight: "5em",
              overflowY: "scroll",
            }}
          >
            {userGroups.map((group, idx) => (
              <Chip
                key={id + idx}
                label={group.name}
                color="primary"
                style={{ color: COLORS.white.secondary }}
                size="small"
              />
            ))}
          </Box>
        )}
      >
        {localGroups.map((group, idx) => (
          <MenuItem
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onChange(group);
            }}
            key={id + idx}
            value={group.id}
            dense={true}
          >
            <Checkbox checked={checkedGroup(group)} />
            <ListItemText primary={group.name} sx={{ fontSize: "0.8em" }} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const UserGroupCell = memo(UserGroupCellComponent);

const useUserGroups = (groups, userId) => {
  const userGroups = [];
  groups.forEach(group => {
    const foundUser = group.users.find(user => user.id === userId);
    if (foundUser) {
      userGroups.push(group);
    }
  });

  return userGroups;
};
