import { useEffect, useState, useCallback, useRef } from 'react';

import apiRequest from 'api/apiRequest';

const DEFAULT_LIMIT = 10;

function formatQueryString(params, page, limit) {
  let apiParams = Object.keys(params)
    .reduce((qStr, param) => {
      qStr.append(param, `${params[param]}`);
      return qStr;
    }, new URLSearchParams(''))
    .toString();
  apiParams = apiParams ? apiParams + '&' : '';

  return `${apiParams}page=${page}&limit=${limit}`;
}

export const usePaginationApi = (options, queryOptions = {}) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  /**
   * Query Options
   *  - params - Params to be passed to the Api
   *  - limit - Determine how many results to return at a time
   */
  const queryOptionsRef = useRef(queryOptions);

  const callApi = useCallback(
    async (url) => {
      setLoading(true);

      try {
        const queryString = formatQueryString(
          queryOptionsRef.current.params || {},
          page,
          queryOptionsRef.current.limit || DEFAULT_LIMIT
        );
        const response = await apiRequest(
          {
            method: 'get',
            url: `${url}?${queryString}`,
          },
          queryOptionsRef.current
        );
        response.data && setData(response.data);
        response.totalCount && setTotalCount(response.totalCount);
      } catch (err) {
        console.error('Error retrieving paginated data: ', err);
        setErrors(err);
      }

      setLoading(false);
    },
    [queryOptionsRef, page]
  );

  useEffect(() => {
    callApi(options.url);
  }, [options.url, callApi, page]);

  const nextPage = () => {
    const limit = queryOptionsRef.current.limit || DEFAULT_LIMIT;
    if (page * limit < totalCount) {
      setPage((prevNum) => prevNum + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prevNum) => prevNum - 1);
    }
  };

  return {
    callApi,
    data,
    totalCount,
    page,
    nextPage,
    prevPage,
    loading,
    errors,
  };
};
