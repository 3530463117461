import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

import { ProfileLayout } from './components/ProfileLayout/Layout';
import Profile from './components/Profile';
import MyClubs from './components/MyClubs';
import { MessagesTab } from 'components/Global/Messages';
import Password from './components/Password';
import Preferences from './components/Preferences';
import AuthContext from '../../contexts/AuthContext';
import { ROUTES } from '../../constants/routes';

export const MyAccount = () => {
  const { search } = useLocation();
  const history = useHistory();
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), []);
  }

  const query = useQuery();
  const tab = query.get('tab') ? query.get('tab').toLowerCase() : 'profile';

  const [activeStep, setActiveStep] = React.useState(
    tab === 'password' ? 2 : tab === 'messages' ? 3 : tab === 'my-clubs' ? 4 : tab === 'preferences' ? 5 : 1
  );
  const { logout } = React.useContext(AuthContext);

  const isUserProfile = 'isUserProfile';

  const changeActiveStep = (stepIndex) => {
    const newQueryParam =
      stepIndex === 1
        ? 'profile'
        : stepIndex === 2
        ? 'password'
        : stepIndex === 3
        ? 'messages'
        : stepIndex === 4
        ? 'my-clubs'
        : stepIndex === 5
        ? 'preferences'
        : '';
    const queryParams = qs.parse(search);
    const newQueries = { ...queryParams, tab: newQueryParam };
    history.push({ search: qs.stringify(newQueries) });
    setActiveStep(stepIndex);
  };

  switch (activeStep) {
    case 1:
      return (
        <ProfileLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <Profile />
        </ProfileLayout>
      );

    case 2:
      return (
        <ProfileLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <Password />
        </ProfileLayout>
      );

    case 3:
      return (
        <ProfileLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <MessagesTab />
        </ProfileLayout>
      );

    case 4:
      return (
        <ProfileLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <MyClubs />
        </ProfileLayout>
      );

    case 5:
      return (
        <ProfileLayout setActiveStep={changeActiveStep} activeStep={activeStep}>
          <Preferences isUserProfile={isUserProfile} />
        </ProfileLayout>
      );

    case 6:
      window.location.href = ROUTES.HOME;
      logout();
      return;

    default:
      throw new Error('Invalid route access');
  }
};
