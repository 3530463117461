import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";
import { Tooltip } from "@mui/material";
import "./LogoScroller.css";

const CarouselContainer = styled.div`
  width: 96%;
  margin: 0 auto;
  .slick-slide img {
    margin: auto;
  }
`;

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const LogosScroller = ({ id }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(defaultSettings);
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    fetchCarousel();
  }, [id]);

  const fetchCarousel = async () => {
    try {
      const response = await apiRequest({
        method: "GET",
        url: id ? `${urls.carouselById}${id}` : `${urls.carousel}?type=homepage`,
      });

      if (response.data) {
        const conf = JSON.parse(JSON.stringify(response.data));
        delete conf.images;
        setSettings({
          ...conf,
          carouselConfigurator: {
            ...conf.carouselConfigurator,
            speed: Number(conf.carouselConfigurator.speed) * 1000,
            autoPlaySpeed: Number(conf.carouselConfigurator.autoPlaySpeed) * 1000,
            slidesToScroll: Number(conf.carouselConfigurator.slidesToScroll),
            slidesToShow: Number(conf.carouselConfigurator.slidesToShow),
          },
        });
        setLogos(response.data.images);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateTo = url => {
    if (!url) return;

    if (url.includes("http") || url.includes("https")) {
      return window.open(url, "_blank");
    }

    return history.push(url);
  };

  if (isLoading || !Boolean(settings?.isActive)) {
    return null;
  }
  const combinedSettings = { ...defaultSettings, ...settings.carouselConfigurator };

  return (
    <CarouselContainer>
      <Slider className="logo-scroller-parent" pauseOnHover {...combinedSettings}>
        {logos.map(({ image, href, tooltip }, index) => (
          <Tooltip title={tooltip ? tooltip : ""}>
            <div key={index}>
              <img
                onClick={() => navigateTo(href)}
                style={{ cursor: href ? "pointer" : "default" }}
                width={settings?.carouselConfigurator?.width || 100}
                height={settings?.carouselConfigurator?.height || 70}
                src={image}
                alt={`Logo ${index + 1}`}
              />
            </div>
          </Tooltip>
        ))}
      </Slider>
    </CarouselContainer>
  );
};
