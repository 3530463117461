import { createContext, useContext, useEffect, useState } from "react";
import apiRequest from "../../../../../../../api/apiRequest";
import urls from "../../../../../../../api/urls";
import { toast } from "react-toastify";

export const GroupsContext = createContext(null);

export const GroupsProvider = ({ children, clubId }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (clubId) {
      fetchGroups();
    }
  }, [clubId]);

  const fetchGroups = async (searchText = "") => {
    let url = `${urls.groups}?clubId=${clubId}`;
    if (searchText) {
      url += `&name=${searchText}`;
    }

    const response = await apiRequest({
      method: "GET",
      url,
    });

    setGroups(response.data);
  };

  const updateGroup = async (id, group) => {
    const response = await apiRequest({
      method: "PUT",
      url: `${urls.groups}/${id}`,
      data: {
        name: group.name,
        user_ids: group.userIds,
      },
    });
    const newGroups = [...groups];
    const index = groups.findIndex(opt => opt.id === id);
    newGroups[index] = response;
    setGroups(newGroups);
    toast.success("Group updated!");
  };

  const updateGroups = async newGroups => {
    await apiRequest({
      method: "PUT",
      url: `${urls.groups}/batch`,
      data: {
        groups: newGroups,
      },
    });
  };

  const createGroup = async ({ name, clubId }) => {
    const response = await apiRequest({
      method: "POST",
      url: urls.groups,
      data: {
        name,
        clubId,
      },
    });
    setGroups([...groups, response]);
    toast.success("Group created!");
  };

  return (
    <GroupsContext.Provider
      value={{ groups, setGroups, createGroup, updateGroup, updateGroups, fetchGroups }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

export const useGroups = () => useContext(GroupsContext);
