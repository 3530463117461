import React, { useContext, useState } from 'react';
import { AuthLandingScreen } from 'pages/ManageAuth/components/AuthLandingScreen/index';
import AuthContext from '../../../../contexts/AuthContext';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Box, Typography, TextField, Grid, InputLabel, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPasswordSchema } from '../../../../validations/schemas';
import { toast } from 'react-toastify';
import { ROUTES } from 'constants/routes';

export const ResetPassword = () => {
  const { isAuthenticated, resetPassword } = useContext(AuthContext);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), []);
  }

  const query = useQuery();
  const code = query.get('code') ? query.get('code') : null;
  const email = query.get('email') ? query.get('email') : null;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: zodResolver(resetPasswordSchema) });

  const onSubmitHandler = async (values) => {
    try {
      setIsLoading(true);
      if (!email || !code) {
        toast.error('Please click on the link from email received');
        return;
      }
      await resetPassword(email, code, values.password, values.confirmPassword);
      toast.success('Password reset was successful');
      history.push(ROUTES.SIGN_IN);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <AuthLandingScreen height='1070px'>
      <>
        <Box textAlign='center' mt={4}>
          <Typography fontWeight={900} fontSize={{ xs: 20, md: 56 }} color={theme.palette.secondary.main}>
            Reset Password
          </Typography>
        </Box>
        <Grid px={5} mt={12} spacing={2} container justifyContent={{ xs: 'center', md: 'start' }}>
          <Grid item xs={12}>
            <InputLabel>Password</InputLabel>
            <TextField
              fullWidth
              type='password'
              placeholder='Enter New Password'
              margin='normal'
              error={!!errors['password']}
              helperText={errors['password'] ? errors['password'].message : ''}
              {...register('password')}
              // onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Confirm Password</InputLabel>
            <TextField
              fullWidth
              type='password'
              placeholder='Enter Confirm Password'
              margin='normal'
              error={!!errors['confirmPassword']}
              helperText={errors['confirmPassword'] ? errors['confirmPassword'].message : ''}
              {...register('confirmPassword')}
              // onKeyPress={handleKeyPress}
            />
          </Grid>

          <Grid item md={12} xs={12} textAlign='center' mt={2}>
            <Button
              size='large'
              variant='contained'
              disabled={isLoading ? true : false}
              sx={{
                p: 2,
                width: 250,
                borderRadius: 50,
                bgcolor: 'secondary.main',
                color: '#fff',
              }}
              onClick={handleSubmit(onSubmitHandler)}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  handleSubmit(onSubmitHandler);
                }
              }}
            >
              {isLoading ? <CircularProgress size='1.7em' color='primary' /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </>
    </AuthLandingScreen>
  );
};

export default ResetPassword;
