import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import SelectFamilies from "../SelectFamilies";
import DeleteModal from "../DeleteModal";
import { GroupsProvider } from "../SelectFamilies/GroupsProvider";

export const Families = props => {
  const [familyToDelete, setFamilyToDelete] = React.useState(null);

  const [families, setFamilies] = React.useState([]);

  useEffect(() => {
    if (props.families && props.families.length !== families.length) {
      setFamilies(props.families);
    }
  }, [props.families]);

  useEffect(() => {
    if (props.onChange) props.onChange(families);
  }, [families]);

  const removeFamily = () => {
    if (familyToDelete) {
      setFamilies(families.filter(f => f !== familyToDelete));
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box mb={5} mt={7}>
          <Grid container>
            <Grid md={5}>
              <Typography variant="h4" fontWeight="bold">
                Families Following this Group:
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <GroupsProvider clubId={props.clubId}>
        <SelectFamilies clubId={props.clubId} families={families} setFamilies={setFamilies} />
      </GroupsProvider>

      <DeleteModal
        title="Delete Family"
        text="Are you sure you want to delete the
        Thomson Family from this section?"
        setIsDeleteModal={setFamilyToDelete}
        isDeleteModal={familyToDelete}
        onConfirm={() => removeFamily()}
        onCancel={() => setFamilyToDelete(null)}
      />
    </React.Fragment>
  );
};

export default Families;
