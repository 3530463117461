import { Roles, useAnyRoles } from "../../utils/Roles";

const GuardRoles = {
  FamilyGuard: [
    Roles.thinkAdmin,
    Roles.serviceAdmin,
    Roles.verifiedFamily,
    Roles.staff,
    Roles.club,
  ],
  AlumniGuard: [Roles.thinkAdmin, Roles.serviceAdmin, Roles.alumni, Roles.staff, Roles.club],
  GraduateGuard: [
    Roles.thinkAdmin,
    Roles.serviceAdmin,
    Roles.verifiedFamily,
    Roles.staff,
    Roles.club,
    Roles.graduates,
  ],
};

export const useRouteRoles = () => {
  const FamilyGuard = useAnyRoles(GuardRoles.FamilyGuard);
  const AlumniGuard = useAnyRoles(GuardRoles.AlumniGuard);
  const GraduateGuard = useAnyRoles(GuardRoles.GraduateGuard);

  return { FamilyGuard, AlumniGuard, GraduateGuard };
};
