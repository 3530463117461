import React, { useState } from "react";
import { GraduatesHeader } from "./components";
import { toast } from "react-toastify";
import apiRequest from "api/apiRequest";
import { GraduatesList } from "./components/GraduatesList";
import ConfigurationContext from "contexts/ConfigurationContext";
import { SearchInput } from "../../components/SearchBar/SearchInput";
import { PillFilter } from "../../components/Filters/PillFilter";
import { AlphabeticFilter } from "../../components/Filters/AlphabeticFilter";
import { Pagination } from "../../components/Pagination/Pagination";

export const ManageGraduates = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [graduates, setGraduates] = React.useState(null);
  const [classes, setClasses] = React.useState([]);
  const [links, setLinks] = React.useState([]);

  const [filter, setFilter] = useState({
    classYear: null,
    query: "",
    prefix: null,
  });

  const { configurations } = React.useContext(ConfigurationContext);

  React.useEffect(() => {
    const fetchClasses = async () => {
      const resp = await apiRequest({
        method: "get",
        url: `/programs/${configurations?.id}/class-years/graduates`,
      });
      setClasses(resp.data);
    };
    fetchClasses();
  }, []);

  const fetchGraduates = async (pageNumber = 1) => {
    try {
      setIsLoading(true);
      const { classYear, query, prefix } = filter;

      const resp = await apiRequest({
        method: "get",
        url: `/graduates?page=${pageNumber}${classYear ? `&filter[attending_school_since][is]=${classYear}` : ""}${
          prefix ? `&filter[first_name][prefix]=${prefix}` : ""
        }${query !== "" ? `&search[query]=${encodeURIComponent(query)}` : ""}`,
      });

      setGraduates(resp.data);
      setLinks(resp.links);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGraduates();
  }, [filter]);

  return (
    <React.Fragment>
      <GraduatesHeader />
      <SearchInput
        onSearch={query => setFilter({ ...filter, query })}
        placeholder="Search by graduate name, subject, club"
      />
      <PillFilter
        label="Select Class"
        selectedPill={filter.classYear}
        setSelectedPill={classYear => setFilter({ ...filter, classYear })}
        pills={classes}
      />
      <AlphabeticFilter onSearch={prefix => setFilter({ ...filter, prefix })} />
      <GraduatesList graduates={graduates} isLoading={isLoading} />
      {links.length > 3 && <Pagination links={links} fetch={fetchGraduates} />}
    </React.Fragment>
  );
};
