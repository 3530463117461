import React, { useState, useEffect } from "react";
import { familyNetworkAlphabetsData } from "constants";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import COLORS from "constants/colors";
import Divider from "@mui/material/Divider";
import Container from "@mui/system/Container";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import FamiliesTable from "./components/FamiliesTable";

import apiRequest from "api/apiRequest";
import Button from "@mui/material/Button";
import { CreateGroupModal } from "./components/CreateGroupModal";
import { useTheme } from "@mui/material/styles";
import { useGroups } from "./GroupsProvider";
import { EditGroupModal } from "./components/EditGroupModal";
import urls from "../../../../../../../api/urls";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";

export const SelectFamilies = props => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [removeFamilies, setRemoveFamilies] = useState([]);
  const { updateGroups, groups } = useGroups();

  useEffect(() => {
    doSearch();
  }, [search, currentPage]);

  const doSearch = () => {
    apiRequest({
      method: "get",
      url:
        "/families?per_page=10&searchKey=" +
        search +
        "&page=" +
        currentPage +
        "&offset=" +
        (currentPage - 1) * 10,
    }).then(res => {
      setSearchResults(res.data || []);
      setCurrentPage(res.current_page);
      setTotalPages(res.last_page);
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSubmit = async () => {
    const { clubId, families } = props;
    await apiRequest({
      method: "PATCH",
      url: `${urls.clubs}/${clubId}`,
      data: {
        id: clubId,
        families,
      },
    });
    const newGroups = getUpdatedGroups();
    await updateGroups(newGroups);

    toast.success("Club updated!");
  };

  const getUpdatedGroups = () => {
    return groups.map(group => {
      const filteredUsers = group.users.filter(user => !removeFamilies.includes(user.id));

      return {
        ...group,
        users: filteredUsers,
      };
    });
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Paper
          sx={{
            borderRadius: "5px",
            p: 5,
          }}
          elevation={0}
        >
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h6">Manage Followers</Typography>
            </Grid>
            <Grid display="flex" justifyContent="flex-end" item xs={12} sm={12} md={6}>
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    borderRadius: 50,
                    padding: 2,
                    width: 220,
                    color: "white",
                  }}
                  onClick={() => setEditGroupOpen(true)}
                >
                  Edit Group
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    borderRadius: 50,
                    padding: 2,
                    width: 220,
                    color: "white",
                  }}
                  onClick={() => setOpen(true)}
                >
                  Create Group
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3} justifyContent="space-between" display="flex">
            {familyNetworkAlphabetsData.map(item => (
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={16} color="primary" fontWeight="bold">
                  <span style={{ cursor: "pointer" }} onClick={() => setSearch(item)}>
                    {item}
                  </span>
                </Typography>
                <Divider
                  style={{ width: "100%" }}
                  variant="middle"
                  flexItem
                  orientation="vertical"
                />
              </Box>
            ))}
          </Box>

          <Box>
            <Box
              component="form"
              mt={5}
              sx={{
                display: "flex",
                bgcolor: COLORS.white.light,
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon sx={{ color: COLORS.blue.secondary }} />
              </IconButton>
              <InputBase
                onChange={e => setSearch(e.target.value)}
                value={search}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by school name"
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FamiliesTable
              removeFamilies={removeFamilies}
              setRemoveFamilies={setRemoveFamilies}
              families={props.families}
              setFamilies={props.setFamilies}
              searchResults={searchResults.slice(0, 10)}
            />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
            </div>
            <Box display="flex" justifyContent="flex-end">
              <Button
                style={{ width: "150px", height: "45px", background: theme.palette.primary.main }}
                className="MuiButton-profile"
                variant="contained"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Paper>
        <CreateGroupModal clubId={props.clubId} open={open} setOpen={setOpen} />
        <EditGroupModal open={editGroupOpen} setOpen={setEditGroupOpen} />
      </Container>
    </React.Fragment>
  );
};
export default SelectFamilies;
