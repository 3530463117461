import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Hero from "./components/Hero";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Families from "./components/Families";
import AddStaff from "./components/AddStaff";
import UpComingEvents from "./components/UpComingEvents";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Announcements from "./components/Announcements";
import AuthContext from "contexts/AuthContext";
import apiRequest from "../../../../../api/apiRequest";

import { useHistory } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import { FilesUpload } from "./components/FilesUpload/FilesUpload";

export const ClubsEditor = () => {
  const params = useParams();
  const history = useHistory();
  const navigate = path => {
    return history.push({
      pathname: path,
    });
  };
  const theme = useTheme();

  const [club, setClub] = useState({});
  const [clubs, setClubs] = useState([]);
  const [events, setEvents] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [members, setMembers] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [families, setFamilies] = useState([]);
  const [files, setFiles] = useState([]);

  const [gallery, setGallery] = useState([]);

  const { user } = React.useContext(AuthContext);

  useEffect(() => {
    if (params.clubId) {
      //Load existing club data
      apiRequest({
        method: "get",
        url: "/clubs/" + params.clubId,
      }).then(res => {
        if (!canEditClub(res.data)) {
          navigate("/hub");
        }
        setClub(res.data);
        setEvents(res.data.events);
        setLeaders(res.data.leaders);
        setFiles(res.data.files);
        setFamilies(res.data.families);
        setGallery(res.data.gallery);
      });
    }

    const canEditClub = club => {
      let result = false;
      if (!user) {
        return false;
      }
      club.members.forEach(member => {
        if (member.user_id === user.id) {
          result = true;
        }
      });
      return result;
    };

    //Load list of clubs this user can edit for dropdown selection
    apiRequest({
      method: "get",
      url: "/clubs?currentUser=1",
    }).then(res => {
      let c = res.data;
      //filter to only clubs they can edit
      c = c.filter(club => {
        return canEditClub(club);
      });
      setClubs(c);
    });
  }, []);

  const updateClub = club => {
    setClub(club);
  };

  const save = () => {
    const data = {
      ...club,
      events,
      leaders,
      members,
      meetings,
      gallery,
      families,
      files,
    };
    apiRequest({
      method: "put",
      url: "/clubs/" + params.clubId,
      data,
    }).then(res => {
      navigate("/hub");
    });
  };

  const handleRemoveFile = index => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles([...newFiles]);
  };

  return (
    <React.Fragment>
      <Hero club={club} onClubUpdate={club => updateClub(club)} clubs={clubs} />
      <About
        club={club}
        onClubUpdate={club => updateClub(club)}
        onMeetingsUpdate={meetings => setMeetings(meetings)}
      />
      <AddStaff clubId={club.id} leaders={leaders} onChange={leaders => setLeaders(leaders)} />
      <FilesUpload
        onRemoveFile={handleRemoveFile}
        files={files}
        onChange={file => setFiles([...files, file])}
      />
      <UpComingEvents clubId={club.id} events={events} onChange={events => setEvents(events)} />
      <Announcements club={club} onChange={club => updateClub(club)} />
      <Families
        clubId={club?.id}
        families={families}
        onChange={families => setFamilies(families)}
      />
      <Gallery club={club} images={gallery} onChange={images => setGallery(images)} />
      <Box mt={3} mb={15} display="flex" justifyContent="flex-end">
        <Button
          style={{ width: "233px", height: "60px", background: theme.palette.primary.main }}
          className="MuiButton-profile"
          variant="contained"
          onClick={save}
        >
          Save Changes
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ClubsEditor;
