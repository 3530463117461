import Box from "@mui/system/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { PdfViewer } from "./PdfViewer";
import React from "react";
import "./FileUpload.css";

export const PdfCard = ({ url, onRemove, sxBox, sxCard }) => {
  const cardStyle = {
    maxWidth: 345,
    height: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };

  // Dynamically adjust the PDF page size here if necessary
  const pageWidth = 340;
  const pageScale = 0.7;

  return (
    <Box sx={{ position: "relative", ...sxBox }}>
      <Card sx={{ ...cardStyle, ...sxCard }}>
        <CardContent>
          <PdfViewer file={url} pageWidth={pageWidth * pageScale} />
        </CardContent>
      </Card>
      {onRemove && (
        <img className="remove-pdf" src="/assets/icons/cancel-icon.svg" alt="" onClick={onRemove} />
      )}
    </Box>
  );
};
