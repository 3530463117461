import { usePaginationApi } from 'hooks/usePaginationApi';

const useOutcomeArticles = (props) => {
  const articles = usePaginationApi({ url: '/outcome-articles' });

  function getArticleImage(article) {
    if (article.contentMedia) {
      return article.contentMedia?.url;
    }

    return 'assets/images/noImage.jpg';
  }

  return {
    ...articles,
    getArticleImage,
  };
};

export default useOutcomeArticles;
