import React from 'react';
// import COLORS from "constants/colors";
// import { ARTICLE_DETAIL_TEXT } from "constants/tips";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Avatar from "@mui/material/Avatar";
import Container from '@mui/system/Container';
// import Typography from "@mui/material/Typography";

export const Hero = ({ image, content, title }) => {
  return (
    <React.Fragment>
      <Box position={'relative'}>
        <Box
          sx={{
            left: '0%',
            top: '62%',
            zIndex: -1,
            position: 'absolute',
          }}
        >
          <img src='assets/images/background-circle-half.svg' alt='' />
        </Box>
        <Container maxWidth='xl'>
          <Grid container>
            <Grid md={2}></Grid>
            <Grid md={10} sm={12} mt={5} xs={12}>
              <Box position='relative' mx={'7%'}>
                <Box
                  sx={{
                    left: '-15%',
                    top: '15%',
                    zIndex: -1,
                    position: 'absolute',
                  }}
                >
                  <img src='assets/images/background-circle-hide.svg' alt='' />
                </Box>
                <Box
                  sx={{
                    right: '5%',
                    top: '-5%',
                    zIndex: -1,
                    position: 'absolute',
                  }}
                >
                  <img src='assets/images/background-circle.svg' alt='' />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '50%',
                  }}
                >
                  <Box
                    component='img'
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/noImage.jpg`;
                    }}
                    alt={title ? title : 'No Title'}
                    src={image}
                  />
                </Box>
                <Box my={7}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Box>
              </Box>
            </Grid>

            {/* <Box my={5} display={{md:"flex",sm:'flex',xs:'none'}} justifyContent="center">
              <Typography>
                <img alt="" src="assets/images/article-detail-graph.svg" />
              </Typography>
            </Box>

            <Box my={7}>
              <Typography>{ARTICLE_DETAIL_TEXT.TEXT2}</Typography>
            </Box>

            <Box my={5} display={{md:"flex",sm:'none',xs:'none'}} justifyContent="center">
                <img alt="" src="assets/images/article-detail-chart.svg" />
            </Box>
            <hr
              style={{
                width: "40px",
                float: "left",
                marginBottom: "5px",
                height: "3px",
                background: COLORS.primary.main,
                border: "none",
              }}
            />
            <Box my={7}>
              <Typography variant="h5" fontWeight="bold">
                {ARTICLE_DETAIL_TEXT.TEXT3}
              </Typography>{" "}
              <hr
                style={{
                  width: "40px",
                  float: "left",
                  background: COLORS.primary.main,
                  height: "3px",
                  border: "none",
                }}
              />
            </Box>
            <Box my={7}>
              <Typography variant="body2">
                {ARTICLE_DETAIL_TEXT.TEXT4}
              </Typography>
            </Box>
            <Box my={{lg:5,md:5,sm:0,xs:0}} display="flex" alignItems={"center"}>
              <Avatar
                alt=""
                src="assets/images/article-detail-author.svg"
                sx={{ width: 70, height: 70 }}
              />
              <Box ml={2}>
                <Typography variant="body2" fontWeight={"bold"}>
                  René Diekstra
                </Typography>
                <Typography width={300} fontSize={10}>
                  {ARTICLE_DETAIL_TEXT.TEXT5}
                </Typography>
              </Box>
            </Box> */}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Hero;
