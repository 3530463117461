import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AuthContext from "../../../../contexts/AuthContext";
import apiRequest from "../../../../api/apiRequest";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

export const FollowButton = ({ club }) => {
  const useStyles = makeStyles(theme => ({
    FollowButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const history = useHistory();
  const classes = useStyles();
  const { isAuthenticated, user, logout } = React.useContext(AuthContext);
  const [isMember, setIsMember] = React.useState(false);

  useEffect(() => {
    if (!club) return;
    apiRequest({
      method: "get",
      url: "/clubs/" + club.id + "/family",
    }).then(response => {
      setIsMember(response.data);
    });
  }, [club]);

  const toggleJoin = () => {
    if (isMember) {
      //catch error below
      apiRequest({
        method: "delete",
        url: "/clubs/" + club.id + "/family",
      }).then(response => {
        setIsMember(response.data);
      });
    } else {
      apiRequest({
        method: "post",
        url: "/clubs/" + club.id + "/family",
      }).then(
        response => {
          setIsMember(response.data);
        },
        err => {
          alert(err);
        }
      );
    }
  };

  const handleFollow = () => {
    if (
      user &&
      user.roles?.find(r => ["Verified Family", "Club Leader", "Staff"].indexOf(r.name) !== -1)
    ) {
      return toggleJoin();
    } else {
      history.push(`${ROUTES.SIGN_IN}?redirect=${window.location.pathname}`);
    }
  };

  return (
    <Box sx={{ justifyContent: "center", display: "flex" }}>
      {club.name && (
        // user.roles?.find(r => ["Verified Family", "Club Leader", "Staff"].indexOf(r.name) !== -1) ? (
        <Grid container>
          <Grid item textAlign={"center"} width={"100%"} height={"100%"}>
            <Box
              sx={{
                width: "100%",
                "@media (max-width: 1024px)": {
                  "@-moz-document url-prefix()": {
                    display: "block",
                    marginTop: "250px",
                  },
                },
              }}
            >
              <Button
                role="button"
                variant="contained"
                className={classes.FollowButton}
                sx={{
                  fontWeight: "bold",
                  fontSize: 16,
                  width: "190px",
                  height: "39px",
                  borderRadius: "50px",
                }}
                onClick={handleFollow}
              >
                {!isMember ? "Follow" : "Unfollow"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
