import React from 'react';
import styled from 'styled-components';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import COLORS from 'constants/colors';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

export const SchoolTable = (props) => {
  const { setSchool } = props || {};

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'white',
      color: 'black',
      border: 'none',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      border: 'none',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: COLORS.white.secondary,
      border: 'none',
    },
  }));

  return (
    <TableContainer sx={{ height: '400px', overflowX: 'scroll' }}>
      <Table sx={{ minWidth: 700 }} aria-label='customized table'>
        {props.filteredSchools.length === 0 ? (
          <p>No schools found</p>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell>School Name</StyledTableCell>
                {/* <StyledTableCell></StyledTableCell> */}
                <StyledTableCell>State</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.filteredSchools.map((data, index) => (
                <StyledTableRow key={index} sx={{ cursor: 'pointer' }} onClick={() => setSchool(data.id)}>
                  <StyledTableCell>{data.outcomeTitle}</StyledTableCell>
                  {/* <StyledTableCell>
                    <img src={data.url} alt='' height='15px' />
                  </StyledTableCell> */}
                  <StyledTableCell>{data.outcomeState}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default SchoolTable;
