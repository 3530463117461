import { Redirect, Route, Switch } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { ROUTES } from "../../constants/routes";

export const RouteGuard = ({ component: Component, canActivate, redirect = "/", ...rest }) => {
  const { user } = useAuthContext();
  const canAccess = useCanActivate(canActivate);

  const toRedirect = !!user ? ROUTES.NOT_FOUND : ROUTES.SIGN_IN;

  if (!canAccess) {
    return <Redirect to={toRedirect} />;
  }

  return <Route {...rest} component={Component} />;
};

export const SwitchGuard = ({ canActivate, redirect = "/", children }) => {
  const { user } = useAuthContext();
  const canAccess = useCanActivate(canActivate);

  const toRedirect = !!user ? redirect : ROUTES.SIGN_IN;

  if (!canAccess) {
    return <Redirect to={toRedirect} />;
  }

  return <Switch>{children}</Switch>;
};

const useCanActivate = canActivate => {
  const checkCanActivate = () => {
    if (typeof canActivate === "function") {
      return canActivate();
    }

    if (Array.isArray(canActivate)) {
      return canActivate.every(func => (typeof func === "function" ? func() : func));
    }

    return Boolean(canActivate);
  };

  return checkCanActivate(canActivate);
};
