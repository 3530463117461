import React from "react";
import ClubCard from "../ClubCard";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";

export const CardSection = ({ club, showEdit }) => {

  const history = useHistory();


  return (
    <Grid item sm={12} md={4} style={{position:'relative'}}>
      <ClubCard club={club} />
      {showEdit ? (
        <Button
          sx={{ width: "auto", padding: '.6em', position:'absolute', bottom:'32px', right:'1em' }}
          className="MuiButton-profile"
          variant="outlined"
          onClick={() => history.push("/hub-editor/" + club.id)}
        >
          Edit
        </Button>
      ) : null}
    </Grid>
  );
};
