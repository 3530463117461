import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import urls from 'api/urls';
import { useHistory } from 'react-router-dom';
//imports
// import { COLORS } from "constants/colors";

export const FamilyClubCard = (props) => {
  const { image, title, subTitle, description, sx, club } = props;
  const history = useHistory();
  const navigate = (path) => {
    return history.push({
      pathname: path,
    });
  };

  return (
    <React.Fragment>
      <Box sx={sx} mt={2} style={{backgroundColor:'#fff'}} mx={1}>
        <Box width={{ xs: '100%', md: '100%' }}>
          <img src={(image) ? image.path : "/assets/images/noImage.jpg"} alt='' width='100%' style={{height:'250px',objectFit:'cover'}} />
        </Box>
        <Box display='flex' flexDirection='column' ml={{ md: 2, xs: 2 }} mt={{ md: 2, xs: 2 }}>
          <Typography fontSize={{ md: 22 }} fontWeight={900} align='left'>
            {title}
          </Typography>
          <Typography fontSize={{ md: 18 }} align='left'>
            {subTitle}
          </Typography>
        </Box>
        <Box mt={{ md: 4 }} ml={{ md: 2, xs: 2 }}>
          <Typography
            warp
            align='left'
            lineHeight={2}
            fontSize={{ md: 15 }}
            width={{ xs: 320, md: 370 }}
            fontWeight={400}
          >
            {description}
          </Typography>
        </Box>
        <Box
          display='flex'
          mt={{ md: 2 }}
          alignItems='center'
          ml={{ md: 2, xs: 2 }}
          paddingY={{ md: 2, xs: 2 }}
          justifyContent='space-between'
        >
          <Button
            variant='contained'
            sx={{
              height: 40,
              width: 110,
              borderRadius: 60,
              // bgcolor: props.buttonColor.primary.main,
              textTransform: 'capitalize',
            }}
            onClick={() => navigate('/hub-page/' + props.club?.id)}
          >
            Learn More
          </Button>
          <Box display='flex' alignItems='center' sx={{ paddingRight: 4 }}>
            <IconButton>
              <img src='assets/icons/usersIcon.svg' alt='' />
            </IconButton>
            <Typography ml={1}>{JSON.stringify(club.familyCount)}</Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FamilyClubCard;
