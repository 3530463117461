import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material//IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { COLORS } from "constants/colors";
import { ROUTES } from "constants/routes";
import { familyNetworkAlphabetsData } from "constants";
import { ambassadorsCardData } from "constants/content";
import { AmbassadorCard } from "./../AmbassadorCard/index";

export const FamilyAmbassadorsDetails = () => {
  return (
    <Box mb={10}>
      <Box sx={{ position: "relative" }}>
        <Container>
          <Box sx={{ position: "absolute", top: 10, left: "5%", zIndex: -1 }}>
            <img src={"assets/icons/EllipseFull.svg"} alt="" />
          </Box>
        </Container>
      </Box>
      <Container maxWidth="false" disableGutters>
        <Box justifyContent="center" display="flex">
          <Box
            component="form"
            width={1200}
            sx={{
              p: 2,
              mt: 8,
              display: "flex",
              bgcolor: "white",
              alignItems: "center",
              borderRadius: "26px",
              boxShadow: "0px 4px 10px 7px rgba(0, 0, 0, 0.05)",
            }}
          >
            <IconButton sx={{ p: "10px" }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by family, name, grade, clubs, etc..."
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <img src={"assets/icons/filterIcon.svg"} alt="" />
              </IconButton>
              <Typography ml={1} color="gray">
                My Group
              </Typography>
              <IconButton
                color="primary"
                sx={{ p: "10px", ml: 1 }}
                aria-label="directions"
              >
                <img src={"assets/icons/arrowDown.svg"} alt="arrow" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box justifyContent="center" display="flex">
          <Grid item container md={10} xs={12} justifyContent="center">
            {familyNetworkAlphabetsData.map((item) => (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  fontSize={16}
                  color="primary"
                  ml={2}
                  mt={{ xs: 2, md: 8 }}
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Box>
        <Container>
          <Box>
            <Typography
              fontSize={{ md: 40 }}
              fontWeight={700}
              color={COLORS.black.dark}
              mt={{ xs: 2 }}
            >
              It Takes a Village
            </Typography>
            <Typography mt={{ xs: 2, md: 3 }} width={{ md: 1045 }} wrap>
              Better connected families improve student experience, academic
              outcomes and school safety. And for parents, it’s always nice to
              put a face with a name.
            </Typography>
            <Grid
              item
              spacing={4}
              container
              mt={{ md: 3, xs: 2 }}
              justifyContent="center"
            >
              {ambassadorsCardData.map((item, index) => (
                <Grid item md={4}>
                  <Link to={ROUTES.FAMILY_AMBASSADORS_PROFILE_DETAILS}>
                    <AmbassadorCard
                      image={item.profile_image?.path}
                      grade={item.grade}
                      userName={item.userName}
                      cartoons={item.cartons}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default FamilyAmbassadorsDetails;
