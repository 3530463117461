import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { BackgroundCircle } from "components/Global/BackgroundCircle";
import useOutcomeArticles from "pages/ManageOutcomes/hooks/OutcomeArticles";
import Section from "components/Layout/components/Section";
import { theme } from "uikit/theme";
import "./outcome-article.css";

export const OutcomeArticleSection = () => {
  const articleData = useOutcomeArticles();
  const history = useHistory();

  const navigate = (article) => {
    return history.push({
      pathname: `/article/${article?.articleUrl.split("/").pop()}`,
    });
  };

  return (
    <Section loading={articleData?.loading}>
      <Box className="outcome-article-section" position="relative">
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            mb={{ xs: 2, md: 0 }}
          >
            <Box pl="4em">
              <Typography fontWeight="bold" variant="h4">
                Success Stories
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            className="outcome-article-container"
            spacing={3}
            pt="2em"
          >
            {(articleData?.data || []).map((data) => (
              <Grid
                item
                onClick={() => navigate(data)}
                className="outcome-article-item"
                md={4}
                xs={6}
              >
                <img
                  src={articleData.getArticleImage(data)}
                  alt=""
                  width="100%"
                  className="outcome-article-image"
                />
                <Box className="outcome-article-description">
                  <Typography
                    fontWeight="bold"
                    sx={{
                      fontSize: { xs: 14, md: 28 },
                      mt: { xs: 1, md: 2 },
                    }}
                  >
                    {data.articleTitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: 10, md: 14 },
                      mt: 1,
                    }}
                  >
                    {data.contentDescription}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
        <BackgroundCircle
          top="1%"
          right="1%"
          zIndex="-1"
          styles={{ image: { width: 220, height: 220 } }}
          color={theme.palette.primary.main}
        />
        <BackgroundCircle
          bottom="-6%"
          left="20%"
          zIndex="-1"
          styles={{ image: { width: 340, height: 340 } }}
          color={theme.palette.primary.main}
          strokeWidth={20}
        />
      </Box>
    </Section>
  );
};

export default OutcomeArticleSection;
