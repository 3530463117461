import React from 'react';
import Box from '@mui/system/Box';
import Container from '@mui/system/Container';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';

import { Typography, Grid, Fab } from '@mui/material';
import EducationRow from './EducationRow';

export const Education = ({ formData, updateFormData }) => {
  const addNewEducation = () => {
    const newEducations = [
      ...formData.educations,
      { degree: '', outcome_id: null, ourId: Number(moment().format('x')) },
    ];
    updateFormData('educations', newEducations);
  };

  const removeEducation = (ourId) => {
    const newEducations = formData.educations.filter((e) => e.ourId !== ourId);
    updateFormData('educations', newEducations);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            Education
          </Typography>
        </Box>

        {formData?.educations?.map((ed) => {
          return (
            <EducationRow
              education={ed}
              formData={formData}
              updateFormData={updateFormData}
              removeEducation={removeEducation}
            />
          );
        })}

        <Grid item md={6} xs={12} sm={12}>
          <Box display='flex' alignItems='center' onClick={addNewEducation} sx={{ cursor: 'pointer' }}>
            <Fab color='secondary'>
              <AddIcon style={{ color: '#fff' }} />
            </Fab>
            <Typography ml={3} variant='body1' fontWeight='bold'>
              Add new education
            </Typography>
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Education;
