import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ArticleCard from '../ArticleCard';
import { useHistory } from 'react-router-dom';

export const ArticleMore = ({ articleContent }) => {
  const history = useHistory();

  const navigate = (article) => {
    return history.push({
      pathname: `/article/${article?.articleUrl.split('/').pop()}`,
    });
  };

  return (
    <>
      <Container maxWidth='false'>
        <Grid container spacing={3} mt={{ md: 5, sm: 10, xs: 5 }}>
          {articleContent.map((article, idx) => (
            <Grid xs={12} item md={4} key={idx}>
              <Box onClick={() => navigate(article)}>
                <ArticleCard
                  height={300}
                  image={article.featuredImageUrl}
                  fallbackImage={'assets/images/noImage.jpg'}
                  primaryText={article.articleTitle}
                  secondaryText={
                    article.contentDescription
                      ? article.contentDescription
                      : 'lorem ipsum sit amet. lorem ipsum sit amet'
                  }
                  article={article}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default ArticleMore;
