import React from 'react';

export const ManageTermsOfUse = () => {
  return (
    <div>
      <div
        name='termly-embed'
        data-id='7d967b8a-4f50-44f0-b635-378385015957
'
        data-type='iframe'
      ></div>
      {(function (d, s, id) {
        var js,
          tjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://app.termly.io/embed-policy.min.js';
        tjs.parentNode.insertBefore(js, tjs);
      })(document, 'script', 'termly-jssdk')}
    </div>
  );
};

export default ManageTermsOfUse;
