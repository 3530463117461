import React from "react";
import Box from "@mui/material/Box";
import ProfileDetails from "./ProfileDetails";
import { ProfileDetailsAbout } from "./ProfileDetailsAbout";
import { ProfileDetailsActivities } from "./ProfileDetailsActivities";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import { ProfileDetailsQuote } from "./ProfileDetailsQuote";
import { SchoolActivities } from "./SchoolActivities";
import { ProfessionalExperience } from "./ProfessionalExperience";
import { ProfileEducation } from "./ProfileEducation";

export const ProfilePage = ({
  profile,
  withLocation,
  withEducation = true,
  withEducationSection = false,
}) => {
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  return (
    <Box p={{ xs: 2 }}>
      <ProfileDetails withLocation={withLocation} withEducation={withEducation} profile={profile} />
      <SchoolActivities profile={profile} />
      <ProfessionalExperience profile={profile} />
      {withEducationSection && profile.educations.length > 0 && (
        <ProfileEducation profile={profile} />
      )}
      <ProfileDetailsAbout profile={profile} />
      {isFeatureEnabled("family_activities") && <ProfileDetailsActivities profile={profile} />}
      <ProfileDetailsQuote profile={profile} />
    </Box>
  );
};
