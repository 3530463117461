import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import EnrollButton from "../../../../components/EnrollButton";
import { LogosScroller } from "../../../../components/LogosScroller/LogosScroller";

export const Enroll = () => {
  const { configurations } = useContext(ConfigurationContext);

  return (
    <Grid sx={{ textAlign: "center", marginTop: 4 }}>
      <Box paddingY={10}>
        <Typography variant="h4" fontWeight="bold">
          Experience the {configurations.schoolShortName ? configurations.schoolShortName : ""}{" "}
          Difference
        </Typography>
        <EnrollButton topSpacing={4} />
        <br />
        <LogosScroller />
      </Box>
    </Grid>
  );
};

export default Enroll;
