import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export const NetworkHeader = ({ schoolName, subTitle, schoolType }) => {
  const theme = useTheme();

  return (
    <Box>
      <Container maxWidth="false" disableGutters>
        <Grid item container>
          <Grid item xs={12} mt={10}>
            <Typography
              wrap
              ml={{ xs: 5, md: 15, sm: 4 }}
              variant="h2"
              fontFamily="Proxima-Nova-Black"
              color={theme.palette.secondary.main}
              textAlign="center"
            >
              It all started at {schoolName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography color="#131416" fontSize={{ md: 40 }} fontWeight={700} mt={5}>
                {schoolType}
              </Typography>
              <Typography>{subTitle}</Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
