import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import COLORS from "constants/colors";
import Typography from "@mui/material/Typography";
import Meeting from "./../Meeting";
import useTheme from "@mui/material/styles/useTheme";

const AddMeeting = (props) => {
  const { setIsAddMeeting } = props || {};
  const [data, setData] = React.useState({});
  const [meetings, setMeetings] = React.useState(props.meetings || []);
  const theme = useTheme();

  useEffect(() => {
    if (props.meetings) {
      setMeetings(props.meetings);
    }
  }, [props.meetings])

  const confirm = () => {
    setIsAddMeeting(false);
    if (props.onConfirm) props.onConfirm(data);
  };

  const removeMeeting = (index) => {
    let newMeetings = [...meetings];
    newMeetings.splice(index, 1);
    if (props.setMeetings) {
      props.setMeetings(newMeetings);
    }
  }

  const updateMeeting = (meeting, index) => {
    let newMeetings = [...meetings];
    newMeetings[index] = meeting;
    if (props.setMeetings) {
      props.setMeetings(newMeetings);
    }
  }

  const addMeeting = () => {
    let newMeetings = [...props.meetings];
    newMeetings.push({
      number_of_students: "",
      student_grades: "",
      meeting_day: "",
      meeting_hours: "",
    });
    if (props.setMeetings) {
      props.setMeetings(newMeetings);
    }
  }

  return (
    <React.Fragment>
      <Paper
        sx={{
          borderRadius: "5px",
          p: 5,
          borderTop: `5px solid ${theme.palette.primary.main}`,
          position: "relative",
        }}
        elevation={0}
      >

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add a new meeting</Typography>
        </Box>
        {((meetings && meetings.length) ? props.meetings : []).map((meeting, i) => (
        <Box mt={3} style={{position:'relative'}} key={i}>
          <Meeting meeting={meeting} onMeetingUpdate={(m) => updateMeeting(m, i)} />
            <img
            src="/assets/icons/cancel-icon.svg"
            alt=""
            width="25px"
            onClick={() => removeMeeting(i)}
            style={{ cursor: "pointer",'position':'absolute',right:'0',top:'60%' }}
          />
        </Box>))}

        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
              variant='outlined'
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: '100px',
              }}
            onClick={() => addMeeting()}
          >
            New Meeting
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default AddMeeting;

