import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import SearchInputTextField from '../SearchInputTextField';
import { useTheme } from '@mui/material/styles';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const FamilyNetworkCard = () => {
  const theme = useTheme();
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);

  return (
    <Box
      height={700}
      className="header-image-overlay"
      sx={{
        maxWidth: '700vh',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${configurations?.family_hero_image?.path})`,
        position: 'relative',
      }}
    >
      {isFeatureEnabled('family_search') && <SearchInputTextField colorIcon={theme.palette.primary.main} />}
      <Container maxWidth='false' disableGutters sx={{position:'absolute'}}>
        <Grid item container>
          <Grid item md={5} sm={8} xs={12} mt={{ xs: 20, md: 20, sm: 20 }}>
            <Typography
              wrap
              ml={{ xs: 5, md: 15, sm: 4 }}
              variant='h2'
              color={theme.palette.common.white}
              fontWeight='bolder'
            >
              {/* {configurations.schoolName ? configurations.schoolName : ''} Family Network. */}
              {configurations.family_header_copy}
            </Typography>
            <Box
              ml={{ xs: 5, md: 15, sm: 4 }}
              mt={2}
              sx={{ display: 'flex', flexWrap: 'wrap' }}
              width={{ xs: 300, md: 'auto', sm: 350 }}
            >
              <Typography color={theme.palette.common.white} variant='body2' fontSize={{ xs: 18, md: 18, sm: 18 }}>
                {/* A home for families, not just boys. */}
                {configurations.family_subheader_copy}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FamilyNetworkCard;
