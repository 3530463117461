import React from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { useHistory } from 'react-router-dom';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const TipsCard = (props) => {
  const { height, isMainCard, image, article } = props || {};
  const history = useHistory();
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  const navigate = () => {
    if (article !== undefined)
      return history.push({
        pathname: `/article/${article.articleUrl.split('/').pop()}`,
      });
  };

  return (
    <React.Fragment>
      <Card
        onClick={navigate}
        sx={{
          height: 'auto',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <CardMedia
          image={article ? article.featuredImageUrl : image}
          component='img'
          height={height}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = image;
          }}
          alt={article.articleTitle ? article.articleTitle : 'No Title'}
        />
        <Paper elevation={0} className='MuiPaper-card-layer' />
        <Typography
          variant={isMainCard ? 'h5' : 'body2'}
          left={'5%'}
          bottom='5%'
          width={'60%'}
          zIndex={''}
          position={'absolute'}
          sx={{ color: 'white' }}
          fontWeight={isMainCard ? 'bold' : ''}
        >
          {article ? article.articleTitle : 'No Title'}
        </Typography>
        {isFeatureEnabled('article_favorite_icon') && (
          <FavoriteIcon
            fontSize='large'
            sx={{
              top: '5%',
              right: '5%',
              zIndex: '999',
              color: 'white',
              position: 'absolute',
            }}
          />
        )}
      </Card>
    </React.Fragment>
  );
};

export default TipsCard;
