import { Box, Typography, useMediaQuery } from "@mui/material";
import { BackgroundCircle } from "../../../../components/Global/BackgroundCircle";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Slider from "react-slick";
import { PdfCard } from "../../../../core/PdfViewer/PdfCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PdfDialog } from "../../../../core/PdfViewer/PdfDialog";
import "./FilesListing.css";

const Arrow = ({ className, style, onClick, children }) => {
  return (
    <Box className={className} style={{ ...style, right: 10 }}>
      <IconButton onClick={onClick}>{children}</IconButton>
    </Box>
  );
};

export const FilesListing = ({ files }) => {
  const theme = useTheme();
  const [openFile, setOpenFile] = useState(null);
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    nextArrow: (
      <Arrow>
        <ArrowForwardIcon />
      </Arrow>
    ),
    prevArrow: (
      <Arrow>
        <ArrowBackIcon />
      </Arrow>
    ),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const handleSlideClick = url => {
    mobileDevice ? window.open(url, "_blank").focus() : setOpenFile(url);
  };

  return (
    <Container component="section" id="clubFiles" maxWidth="xl" className="club-page-leaders">
      <Typography variant="h5" color="#131416" fontSize={{ md: 30 }} fontWeight={700} mb={"20px"}>
        Files
      </Typography>

      <div style={{ position: "relative" }}>
        <Box style={{ position: "absolute", right: "5%", top: "600px" }}>
          <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={20} />
        </Box>
      </div>

      <Box mb={4}>
        {files?.length > 0 && (
          <Slider {...settings} className="files-listing">
            {files.map(file => (
              <Box width={{ xs: "100%", md: "100%" }} onClick={() => handleSlideClick(file.url)}>
                <PdfCard sxBox={{ margin: "8px", cursor: "pointer" }} url={file.url} />
              </Box>
            ))}
          </Slider>
        )}
      </Box>

      <PdfDialog open={openFile} handleClose={() => setOpenFile(null)} url={openFile} />
    </Container>
  );
};
