import { sortListByValue } from "../utils/Sort";

export const SchoolActivitiesList = sortListByValue([
  { key: "arts", value: "Arts" },
  { key: "band", value: "Band" },
  { key: "baseball", value: "Baseball" },
  { key: "basketball", value: "Basketball" },
  { key: "boy_scouts", value: "Boy Scouts" },
  { key: "cheerleading", value: "Cheerleading" },
  { key: "choir_chorus", value: "Choir/Chorus" },
  { key: "computer_science", value: "Computer Science" },
  { key: "creative_writing", value: "Creative Writing" },
  { key: "cross_country", value: "Cross Country" },
  { key: "debate_team", value: "Debate Team" },
  { key: "environmental_club", value: "Environmental Club" },
  { key: "film_club", value: "Film Club" },
  { key: "football", value: "Football" },
  { key: "girl_scouts", value: "Girl Scouts" },
  { key: "golf", value: "Golf" },
  { key: "gymnastics", value: "Gymnastics" },
  { key: "key_club", value: "Key Club" },
  { key: "lacrosse", value: "Lacrosse" },
  { key: "math_club", value: "Math Club" },
  { key: "mock_trial", value: "Mock Trial" },
  { key: "model_united_nations", value: "Model United Nations" },
  { key: "national_honor_society", value: "National Honor Society (NHS)" },
  { key: "orchestra", value: "Orchestra" },
  { key: "photography_club", value: "Photography Club" },
  { key: "robotics", value: "Robotics" },
  { key: "science_club", value: "Science Club" },
  { key: "soccer", value: "Soccer" },
  { key: "softball", value: "Softball" },
  { key: "student_government", value: "Student Government" },
  { key: "student_newspaper", value: "Student Newspaper" },
  { key: "swimming", value: "Swimming" },
  { key: "tennis", value: "Tennis" },
  { key: "theater_drama", value: "Theater/Drama" },
  { key: "track_and_field", value: "Track and Field" },
  { key: "volleyball", value: "Volleyball" },
  { key: "wrestling", value: "Wrestling" },
  { key: "yearbook_committee", value: "Yearbook Committee" },
]);

export const VolunteerActivitiesList = sortListByValue([
  { key: "arts", value: "Arts" },
  { key: "assist_teachers", value: "Assist Teachers" },
  { key: "athletics", value: "Athletics" },
  { key: "chaperone", value: "Chaperone" },
  { key: "fundraising", value: "Fundraising" },
  { key: "guest_speaker", value: "Guest Speaker" },
  { key: "internships", value: "Internships" },
  { key: "mentor", value: "Mentor" },
  { key: "pta_pto", value: "PTA/PTO" },
  { key: "special_projects", value: "Special Projects" },
  { key: "tutor", value: "Tutor" },
]);
