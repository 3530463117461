import React, { useEffect } from "react";
import ImageUpload from "components/ImageUpload";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const UpComingEvents = props => {
  const [events, setEvents] = React.useState(props.events ?? []);
  const theme = useTheme();

  useEffect(() => {
    if (events.length === 0 && props.events?.length > 0) {
      setEvents(props.events);
    }
  }, [props.events]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(events);
    }
  }, [events]);

  const removeEvent = event => {
    const updatedEvents = events.filter(e => e !== event);
    setEvents(updatedEvents);
  };

  const addEvent = () => {
    const event = {
      name: "",
      location: "",
      event_date: new Date(),
      time: "",
    };
    setEvents(prevEvents => {
      return [...prevEvents, event];
    });
  };

  const updateEvent = (event, updates) => {
    const updatedEvent = { ...event, ...updates };
    const eventIndex = events.findIndex(ev => ev === event);
    const updatedEvents = [
      ...events.slice(0, eventIndex),
      updatedEvent,
      ...events.slice(eventIndex + 1),
    ];
    setEvents(updatedEvents);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box mb={5} mt={7}>
          <Typography variant="h4" fontWeight="bold">
            Upcoming Events
          </Typography>
        </Box>
        {events.map((event, i) => (
          <Grid container spacing={5} mb={4} key={i}>
            <Grid item md={4} sm={12} xs={12}>
              <ImageUpload
                image={event.image}
                ownerType="Club"
                ownerId={props.clubId}
                title="Add a representative image of your club."
                icon="/assets/icons/modalFileIcon.svg"
                title1="Drop your file(s) here or"
                title2="browse"
                bgColor="#F2F2F2"
                height={"300px"}
                padding={5}
                onChange={image => (image ? updateEvent(event, { image, image_id: image.id }) : [])}
                onDeleteImage={() => updateEvent(event, { image: null, image_id: null })}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Grid item container spacing={3}>
                <Grid item md={5} sm={6} xs={12}>
                  <InputLabel className="MuiInputLabel-Profile-label" shrink>
                    Event Name
                  </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="filled"
                      required
                      value={event.name}
                      onChange={e => updateEvent(event, { name: e.target.value })}
                      id="event-name"
                      name="event-name"
                      sx={{ mb: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <InputLabel className="MuiInputLabel-Profile-label" shrink>
                    Location
                  </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="filled"
                      required
                      value={event.location}
                      onChange={e => updateEvent(event, { location: e.target.value })}
                      id="location"
                      name="location"
                      sx={{ mb: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <InputLabel className="MuiInputLabel-Profile-label" shrink>
                    Event Date
                  </InputLabel>
                  <FormControl>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="outlined"
                      required
                      value={event.event_date}
                      onChange={e => updateEvent(event, { event_date: e.target.value })}
                      type="date"
                      id="event-date"
                      name="event-date"
                      sx={{ mb: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="MuiInputLabel-Profile-label" shrink>
                    Event Time
                  </InputLabel>
                  <FormControl>
                    <TextField
                      InputProps={{ disableUnderline: "false" }}
                      variant="outlined"
                      type="text"
                      required
                      value={event.time}
                      onChange={e => updateEvent(event, { time: e.target.value })}
                      id="event-time"
                      name="event-time"
                      sx={{ mb: 0 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={() => removeEvent(event)}>
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" ml={"35%"} mt={events.length === 0 ? 10 : -10}>
            <Fab
              color="primary"
              onClick={() => addEvent()}
              sx={{
                backgroundColor: theme.palette.primary.main,
                "&:hover": { backgroundColor: theme.palette.secondary.main },
              }}
            >
              <AddIcon />
            </Fab>
            <Typography ml={1} variant="body2" fontWeight="bold" color={COLORS.primary.main}>
              Add a new event
            </Typography>
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default UpComingEvents;
