import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { COUNTRIES, STATES } from "../../../constants/countries";

export const LocationProfile = ({ formData, updateFormData }) => {
  const updateLocation = (key, value) => {
    const newLocation = { ...formData.location, [key]: value };
    updateFormData("location", { ...newLocation });
  };

  return (
    <>
      <Grid item md={4} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          City
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: false }}
            variant="filled"
            hiddenLabel
            fullWidth
            id="city"
            name="city"
            className="family-input-fields"
            sx={{ mb: 2 }}
            value={formData.location.city}
            onChange={e => updateLocation("city", e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          State
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            sx={{ mb: 2 }}
            select
            value={formData?.location?.subdivision}
            onChange={e => updateLocation("subdivision", e.target.value)}
          >
            <MenuItem key="empty_value">
              <em>Select state</em>
            </MenuItem>
            {STATES &&
              STATES.map(st => (
                <MenuItem key={st.abbreviation} value={st.abbreviation}>
                  {st.name}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <InputLabel className="MuiInputLabel-Profile-label" shrink>
          Country
        </InputLabel>
        <FormControl fullWidth>
          <TextField
            sx={{ mb: 2 }}
            select
            value={formData?.location?.country}
            onChange={e => updateLocation("country", e.target.value)}
          >
            <MenuItem key="empty_value">
              <em>Select country</em>
            </MenuItem>
            {COUNTRIES &&
              COUNTRIES.map(st => (
                <MenuItem key={st.code} value={st.code}>
                  {st.name}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Grid>
    </>
  );
};
