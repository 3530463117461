import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Typography from '@mui/material/Typography';
import ConfigurationContext from 'contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';

export const RealAnswers = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ position: 'relative' }} display={{ xs: 'block', md: 'none' }}>
        <Box sx={{ position: 'absolute', top: 190, right: 0 }}>
          <img src='assets/icons/EllipseHalf.png' alt='' />
          <Box
            sx={{
              position: 'absolute',
              top: 1100,
              right: 400,
              transform: 'rotate(180deg)',
            }}
          >
            <img src='assets/icons/EllipseHalf.png' alt='' />
          </Box>
        </Box>
      </Box>
      <Grid container>
        {configurations?.qa_header_copy && configurations?.qa_subheader_copy ? (
          <Grid item container md={12}>
            <Grid item md={8} p={{ xs: 2, md: 10 }}>
              {configurations?.qa_header_copy && (
                <Box p={{ xs: 1, md: 0 }}>
                  <Typography variant='h2'>
                    {configurations?.qa_header_copy}
                  </Typography>
                </Box>
              )}
              {configurations?.qa_subheader_copy && (
                <Box p={{ xs: 1, md: 0 }} mt={{ md: 4, xs: 0 }}>
                  <Typography wrap fontSize={{ md: 32 }} fontWeight={700} width={{ xs: 300, md: 800 }}>
                    {configurations?.qa_subheader_copy}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item md={4} p={{ md: 10, xs: 0 }} display={{ md: 'block', xs: 'none' }}>
              <Box display='flex' justifyContent='flex-end'>
                <svg xmlns='http://www.w3.org/2000/svg' width='216' height='216' viewBox='0 0 216 216' fill='none'>
                  <circle
                    cx='108'
                    cy='108'
                    r='88'
                    stroke={theme.palette.secondary.main}
                    strokeOpacity='0.1'
                    strokeWidth='40'
                  />
                </svg>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Box>
  );
};
export default RealAnswers;
