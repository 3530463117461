import React from "react";
import Container from "@mui/system/Container";
import Box from "@mui/system/Box";
import { Divider, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import COLORS from "../../../constants/colors";
import { MessageForm } from "./MessageForm";

export const NewMessageTemplate = ({
  isUserProfile,
  children,
  isModal,
  setNewMessage,
  toggleSelectAll,
  selectedMembers,
  club,
}) => {
  return (
    <Container>
      <Box alignItems="center" display="flex" justifyContent="space-between" height="50px">
        {isModal ? (
          ""
        ) : (
          <Typography variant="h5" fontWeight="bold">
            Messages
          </Typography>
        )}
        {!isUserProfile && (
          <Button
            sx={{ width: "153px", height: "45px" }}
            className="MuiButton-profile"
            variant="contained"
          >
            New Message
          </Button>
        )}
      </Box>
      {!isUserProfile && <Box mt={5}>chat</Box>}
      {isUserProfile && (
        <Box mt={5}>
          <Paper sx={{ borderRadius: "26px", p: 3 }} elevation={4}>
            <Box display="flex" alignItems="center" height="20px">
              <ArrowCircleLeftOutlinedIcon
                fontSize="large"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => setNewMessage(false)}
              />
              <Typography ml={3} variant="h6" fontWeight="bold">
                New Message
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <div style={{ position: "absolute", right: "5em", top: "6.5em" }}>
              <Button style={{ color: "#FFFFFF" }} variant="contained" onClick={toggleSelectAll}>
                Select All
              </Button>
            </div>
            <Grid container height="450px" marginTop={"15px"}>
              <Grid item md={1}>
                <Box>
                  <Typography variant="body1" p={1}>
                    To
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} position="relative">
                <Box borderRadius={"5px"} bgcolor={COLORS.white.secondary} width="100%">
                  {children}
                </Box>
                <MessageForm
                  setNewMessage={setNewMessage}
                  club={club}
                  selectedMembers={selectedMembers}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </Container>
  );
};
