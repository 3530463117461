import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const AmbassadorsProfileActivities = (props) => {
  if (!props.profile.activities || props.profile.activities.length === 0) {
    return null;
  }
  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid item container md={10}>
          <Typography fontSize={{ md: 40 }} fontWeight={700} mt={4}>
            Activites
          </Typography>
          <Grid item container md={12} mt={4}>
            {props.profile.activities.map((item) => (
              <>
                <Grid item md={1.5} xs={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <img src={item.icon?.path} alt="" height="50px" />
                    <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2}>
                      {item.name}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AmbassadorsProfileActivities;
