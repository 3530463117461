import { createContext, useState } from 'react';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';
import { getPrime } from '../helpers/get_prime';

const ArticlesContext = createContext();

export const ArticlesProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [prime] = useState(getPrime(100, 1000));

  const fetchArticles = async ({ category, gradeBand, searchKey, page, limit }) => {
    try {
      if (page === 1) setArticles([]);
      const url = `${urls.articles}?limit=${limit}&${category === 0 ? '' : `&categoryIds=${category}`}${
        gradeBand === 0 ? '' : `&gradeBandIds=${gradeBand}`
      }&searchKey=${encodeURIComponent(searchKey.toLowerCase())}&page=${page}&random_sort_seed=${prime}&assigned=1`;
      const response = await apiRequest({
        method: 'get',
        url,
      });
      if (page === 1) {
        setArticles(response.data);
      } else {
        const articlesOld = [...articles];
        const newArticles = [
          ...articlesOld.slice(0, articles.length - 2),
          ...response.data,
          articles[articles.length - 2],
          articles[articles.length - 1],
        ];
        setArticles(newArticles);
      }
      setCount(Number(response.total));
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ArticlesContext.Provider
      value={{
        articles,
        error,
        fetchArticles,
        count,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

export default ArticlesContext;
