import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useDropzone } from "react-dropzone";
import { Grid, Typography } from "@mui/material";
import { ProgressBar } from "components/ProgressBar";
import { toast } from "react-toastify";
import apiRequest from "api/apiRequest";

export const ImageUpload = props => {
  const { icon, title1, title2, bgColor, title, height, padding, onDeleteImage } = props || {};
  const [image, setImage] = useState(null);
  const [data, setData] = useState(props.image ? props.image : null);
  const [showProgress, setShowProgress] = useState(false);
  const imageRef = useRef();

  const fileUploader = () => {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      if (imageRef) {
        reset();
        imageRef.current.click();
      }
    }
  };

  useEffect(() => {
    if (props.image && !data) {
      setData(props.image);
    }
  }, [props.image, data]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
  }, [data]);

  const reset = async () => {
    await deleteImage();
    setImage(null);
    setData(null);
  };

  const updateImage = async ({ ownerId, imageFile, imageId, ownerType }) => {
    try {
      const formData = new FormData();
      formData.append("name", "image");
      formData.append("image", imageFile);
      formData.append("owner_id", ownerId);
      formData.append("owner_type", ownerType);
      const url = image && imageId ? `images/${imageId}` : "images";
      return await apiRequest({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch {}
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      uploadImage(acceptedFiles[0]);
    },
    [props]
  );

  const { getRootProps } = useDropzone({
    onDrop,
  });

  const uploadImage = async file => {
    if (!file.type.toLowerCase().startsWith("image")) {
      toast.error("Please provide an image file!");
      return;
    }
    if (file.size > 20 * 1024 * 1024) {
      toast.error("Image size must be less than 20 MB");
      return;
    }
    setShowProgress(true);
    let res = await updateImage({
      ownerId: props.ownerId,
      imageFile: file,
      imageId: props.image ? props.image.id : null,
      ownerType: props.ownerType,
    });
    setImage(file);
    setData(res);
    setShowProgress(false);
    if (props.clearOnChange) {
      setTimeout(() => {
        reset();
      }, 100);
    }
  };

  const deleteImage = async () => {
    if (data) {
      const url = `images/${data.id}`;
      await apiRequest({
        method: "DELETE",
        url,
      });
      setData(null);
      setImage(null);
      onDeleteImage();
    }
  };

  const handleImageChange = async e => {
    if (e.target.files[0]) {
      await uploadImage(e.target.files[0]);
    }
  };

  const getDisplayImage = () => {
    if (data && data.id) {
      return data.path;
    }
    if (image) {
      return URL.createObjectURL(image);
    }
    return "";
  };

  return (
    <Box>
      <Box p={padding} bgcolor={bgColor} sx={{ width: "100%" }} onClick={() => fileUploader()}>
        <Grid
          {...getRootProps()}
          md={12}
          sm={12}
          xs={12}
          sx={{
            border: "1px dashed #A3A3A3",
            height: { height },
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {!image && !data ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img id="img" src={icon} alt="" width={"15%"} height={"15%"} />
              <Grid
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography textAlign="center" htmlFor="input" id="label" mt={1} variant="body2">
                    {title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography htmlFor="input" id="label" variant="body2">
                    {title1}{" "}
                    <label
                      htmlFor="fileInput"
                      onClick={fileUploader}
                      style={{ color: "#2E75FF", cursor: "pointer" }}
                    >
                      {title2}
                    </label>
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ) : (
            <img src={getDisplayImage()} alt="profile" style={ImageStyle} onClick={() => reset()} />
          )}
          <input
            type="file"
            id="fileInput"
            className="fileElem"
            accept="image/*"
            ref={imageRef}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </Grid>
        {showProgress ? (
          <Box sx={{ width: "100%", marginTop: "30px" }}>
            <ProgressBar value={Math.floor(Math.random() * (100 - 20 + 1)) + 20} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
export default ImageUpload;

const ImageStyle = {
  width: "auto",
  maxWidth: "100%",
  height: "100%",
  maxHeight: "300px",
  objectFit: "contain",
  display: "flex",
  borderRadius: "10px",
};
