import React from "react";
import ConfigurationContext from "contexts/ConfigurationContext";
import { NetworkHeader } from "../../../../components/Header/NetworkHeader";

export const GraduatesHeader = ({ query, setQuery, onSearch }) => {
  const { configurations } = React.useContext(ConfigurationContext);

  const subTitle = `
      Use the ${configurations?.schoolShortName}
      Graduate Gallery to stay connected with your classmates and remain an active member
      of the ${configurations?.schoolName} community!`;

  return (
    <NetworkHeader
      schoolName={configurations?.schoolName}
      subTitle={subTitle}
      schoolType={"Graduate Gallery"}
    />
  );
};

export default GraduatesHeader;
