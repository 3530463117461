import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useGroups } from "../GroupsProvider";

export const EditGroupModal = ({ open, setOpen }) => {
  const { groups, updateGroup } = useGroups();
  const [selectedGroup, setSelectedGroup] = useState();
  const [name, setName] = useState("");
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    if (selectedGroup) {
      setName(selectedGroup.name);
      setUserIds(selectedGroup.users.map(user => user.id));
    }
  }, [selectedGroup, selectedGroup?.name]);

  const toggleUserIds = e => {
    const userId = Number(e.target.value);
    if (userIds.includes(userId)) {
      const index = userIds.indexOf(userId);
      const newArray = [...userIds];
      newArray.splice(index, 1);
      setUserIds(newArray);
    } else {
      setUserIds([...userIds, userId]);
    }
  };

  const handleSubmit = async () => {
    await updateGroup(selectedGroup.id, { name, userIds });
    setSelectedGroup(undefined);
    setName("");
    setUserIds([]);
    setOpen(false);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={() => setOpen(false)}>
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent sx={{ mt: 3 }}>
        <Box>
          <Autocomplete
            options={groups}
            getOptionLabel={option => option.name}
            onChange={(e, value) => setSelectedGroup(value)}
            renderInput={params => (
              <TextField
                {...params}
                name="name"
                size="small"
                hiddenLabel
                variant="filled"
                color="secondary"
                placeholder="Search group"
                sx={{ mb: 2 }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: "false",
                }}
              />
            )}
          />
        </Box>
        {selectedGroup && (
          <>
            <Grid item md={6} xs={12} sm={6}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Group Name:
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={name}
                  onChange={e => setName(e.target.value)}
                  size="small"
                  InputProps={{ disableUnderline: "false" }}
                  hiddenLabel
                  variant="filled"
                  fullWidth
                  color="secondary"
                  placeholder="Team A..."
                  sx={{ mb: 2 }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <InputLabel className="MuiInputLabel-Profile-label" shrink>
                Users:
              </InputLabel>
              <FormGroup fullWidth>
                {selectedGroup?.users
                  .filter(user => user.id !== selectedGroup.creator.id)
                  .map(user => (
                    <Grid item md={6} display="flex" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={userIds.includes(user.id)}
                        color="primary"
                        value={user.id}
                        onChange={toggleUserIds}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {user.first_name} {user.last_name}
                      </Typography>
                    </Grid>
                  ))}
              </FormGroup>
            </Grid>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                onClick={handleSubmit}
                size="large"
                sx={{ p: 8, height: 40 }}
                className="MuiButton-profile"
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
