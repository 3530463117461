import React from "react";
import { getElementSize } from "../utils/numToMeasurement";
import "./Div.css";

const getStyles = (style, { overrideHeight, overrideWidth }) => {
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    height,
    width,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
  } = style;

  const generatedStyles = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: justifyContent,
    flexDirection: flexDirection,
    alignItems: alignItems,
    background: background,
    color: color,
    boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
    flex: fillSpace === "yes" ? 1 : "unset",
    padding: getElementSize(padding),
    borderRadius: getElementSize(borderRadius),
    margin: getElementSize(margin),
  };
  if (overrideHeight) {
    generatedStyles["height"] = getElementSize(height);
    generatedStyles["maxHeight"] = getElementSize(maxHeight);
    generatedStyles["minHeight"] = getElementSize(minHeight);
  }
  if (overrideWidth) {
    generatedStyles["width"] = getElementSize(width);
    generatedStyles["maxWidth"] = getElementSize(maxWidth);
    generatedStyles["minWidth"] = getElementSize(minWidth);
  }

  return generatedStyles;
};

export const Container = ({ children, ...props }) => {
  return (
    <div
      {...props}
      style={getStyles(props.style, {
        overrideHeight: props.overrideHeight,
        overrideWidth: props.overrideWidth,
      })}
    >
      {children}
    </div>
  );
};

export const Row = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={"grid"}
      style={getStyles(props.style, {
        overrideHeight: props.overrideHeight,
        overrideWidth: props.overrideWidth,
      })}
    >
      {children}
    </div>
  );
};
export const Col = ({ children, lg, ...props }) => {
  const styles = getStyles(props.style, {
    overrideHeight: props.overrideHeight,
    overrideWidth: props.overrideWidth,
  });

  delete styles.width;

  return (
    <div {...props} className={`colz-${lg}-12`} style={styles}>
      {children}
    </div>
  );
};
