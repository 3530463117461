export const ambassadorsCardData = [
  {
    id: 1,
    image: 'assets/images/cardImage.jpg',
    grade: '10th Grade',
    grade2: '9th Grade',
    userName: 'Thompson',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
  {
    id: 2,
    image: 'assets/images/cardImage2.jpg',
    grade: '10th Grade',
    grade2: '9th Grade',
    userName: 'Robinson',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
  {
    id: 3,
    image: 'assets/images/cardImage3.jpg',
    grade: '10th Grade',
    grade2: '9th Grade',
    userName: 'King',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
  {
    id: 4,
    image: 'assets/images/cardImage4.jpg',
    grade: '10th Grade',
    grade2: '9th Grade',
    userName: 'Testing',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
  {
    id: 5,
    image: 'assets/images/cardImage5.jpg',
    grade: '11th Grade',
    userName: 'Ramirez',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
  {
    id: 6,
    image: 'assets/images/cardImage5.jpg',
    grade: '12th Grade',
    userName: 'Sanchez',
    cartons: 'assets/icons/threeCartoons.jpg',
  },
];

export const leadersCardData = [
  {
    id: 1,
    image: 'assets/images/cardImage.jpg',
    userName: 'Jane Anderson',
    subject: 'English',
  },
  {
    id: 2,
    image: 'assets/images/cardImage.jpg',
    userName: 'Mark Jonas',
    subject: 'Mathematics',
  },
  {
    id: 3,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
  {
    id: 4,
    image: 'assets/images/cardImage.jpg',
    userName: 'Angela Davis',
    subject: 'Biology',
  },
  {
    id: 5,
    image: 'assets/images/cardImage.jpg',
    userName: 'Jennifer Martin',
    subject: 'English',
  },
  {
    id: 6,
    image: 'assets/images/cardImage.jpg',
    userName: 'Amanda Moore',
    subject: 'Pricipal',
  },
  {
    id: 7,
    image: 'assets/images/cardImage.jpg',
    userName: 'Robart Thomas',
    subject: 'Literature',
  },
  {
    id: 8,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
  {
    id: 9,
    image: 'assets/images/cardImage.jpg',
    userName: 'William Rodriguez',
    subject: 'Algebra & Statistics',
  },
  {
    id: 10,
    image: 'assets/images/cardImage.jpg',
    userName: 'Christopher',
    subject: 'Geometry',
  },
  {
    id: 11,
    image: 'assets/images/cardImage.jpg',
    userName: 'Nancy Lopz',
    subject: 'Counselor',
  },
  {
    id: 12,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
  {
    id: 13,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
  {
    id: 14,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
  {
    id: 15,
    image: 'assets/images/cardImage.jpg',
    userName: 'Thompson',
    subject: 'English',
  },
];

export const clubHubCardData = [
  {
    image: '/assets/images/club-hub-1.png',
    title: 'Book Club',
    info: 'A great way to motivate kids to read, get them talking about books, and have them share creative ideas.',
  },
  {
    image: '/assets/images/club-hub-2.png',
    title: 'Choir Club',
    info: 'Designed for students to apply musical skills as they continue to create and experience music.',
  },
  {
    image: '/assets/images/club-hub-3.png',
    title: 'Dance Club',
    info: 'Learn dance styles from different cultural backgrounds such Cumbia, Banda, Salsa, Modern and Hip Hop.',
  },
  {
    image: '/assets/images/club-hub-4.png',
    title: 'Orchestra Club',
    info: 'We develop bonds that really help students get through a challenging part of their lives.',
  },
  {
    image: '/assets/images/club-hub-5.png',
    title: 'Chess Club',
    info: 'The  scholastic  chess  club  combines  educational  and  social  activities good for mind .',
  },
  {
    image: '/assets/images/club-hub-6.png',
    title: 'Photography Club',
    info: 'Learn new skills by understanding and learning about what makes a good photograph',
  },
];

export const familyClubCardData = [
  {
    id: 1,
    image: '/assets/images/clubcard2.svg',
    title: 'Parent Mentor Program',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
    sx: '',
  },
  {
    id: 2,
    image: '/assets/images/clubcard2.svg',
    title: 'Communication at Home',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
  {
    id: 3,
    image: '/assets/images/clubcard2.svg',
    title: 'Book Club',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
  {
    id: 4,
    image: '/assets/images/clubcard2.svg',
    title: 'Book Club',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
];

export const familyNetworkAlphabetsData = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const ambassadorsActivitiesData = [
  { name: 'SWIMMING', icon: '/assets/icons/Frame.svg' },
  { name: 'BASKETBALL', icon: '/assets/icons/Frame-1.svg' },
  { name: 'RUNNING', icon: '/assets/icons/Frame-2.svg' },
  { name: 'PING PONG', icon: '/assets/icons/Frame-3.svg' },
  { name: 'YOGA', icon: '/assets/icons/Frame-4.svg' },
  { name: 'HIKING', icon: '/assets/icons/Frame-5.svg' },
  { name: 'ICE SKATING', icon: '/assets/icons/Frame-6.svg' },
  { name: 'TENNIS', icon: '/assets/icons/Frame-7.svg' },
];

export const ambassadorsProfileAblumsData = [
  {
    id: 1,
    image1: '/assets/images/albumPic1.svg',
    image2: '/assets/images/albumPic2.svg',
    image3: '/assets/images/albumPic9.svg',
    title: 'Summer 2019',
  },
  {
    id: 2,
    image1: '/assets/images/albumPic1.svg',
    image2: '/assets/images/albumPic3.svg',
    image3: '/assets/images/albumPic9.svg',
    title: 'Disneyland',
  },
  {
    id: 3,
    image1: '/assets/images/albumPic1.svg',
    image2: '/assets/images/albumPic4.svg',
    image3: '/assets/images/albumPic9.svg',
    title: 'Roadtrip 2021',
  },
  {
    id: 4,
    image1: '/assets/images/albumPic1.svg',
    image2: '/assets/images/albumPic5.svg',
    image3: '/assets/images/albumPic9.svg',
    title: 'Summer BBQ',
  },
];

export const ambassadorsAboutUsData = [
  {
    id: 1,
    icon: '/assets/icons/Vector-4.svg',
    icon2: '/assets/icons/Vector-6.svg',
    title: 'Our Favorite Book to Read',
    title2: 'Our Favorite Book to Read',
    subTitle: 'To Kill a Mockingbird',
    subTitle2: 'Luca’s',
  },
  {
    id: 1,
    icon: '/assets/icons/Vector-7.svg',
    title: 'Our Favorite Foodto Cook together',
    icon2: '/assets/icons/Vector-2.svg',
    title2: 'Our Favorite Book to Read',
    subTitle: 'Burger',
    subTitle2: 'Thanksgiving',
  },
  {
    id: 1,
    icon: '/assets/icons/Vector-5.svg',
    title: 'Our Favorite Sport to practice Together',
    icon2: '/assets/icons/Vector-1.svg',
    title2: 'Our Favorite Book to Read',
    subTitle: 'Kayaking',
    subTitle2: 'Camping and Kayaking',
  },
  {
    id: 1,
    icon: '/assets/icons/Vector-4.svg',
    title: 'Our Favorite Vacation Place',
    icon2: '/assets/icons/Vector-3.svg',
    title2: 'Our Favorite Book to Read',
    subTitle: 'Cape Code',
    subTitle2: 'Back to The Future',
  },
];

export const QARealAnswersData = [
  {
    id: 1,
    question: 'How would you describe the school culture?',
    selected: false,
  },
  {
    id: 2,
    question: 'How would you describe your classes and teachers?',
    selected: false,
  },
  {
    id: 3,
    question: 'What has been your favorite classroom experience?',
    selected: false,
  },
  {
    id: 4,
    question: 'How easy or difficult was it to make friends?',
    selected: true,
  },
  {
    id: 5,
    question: 'How would you describe your academic experience?',
    selected: false,
  },
  {
    id: 6,
    question: 'How would you describe your academic experience?',
    selected: false,
  },
  {
    id: 7,
    question: 'How would you describe your academic experience?',
    selected: false,
  },
  {
    id: 8,
    question: 'How would you describe your academic experience?',
    selected: false,
  },
  {
    id: 9,
    question: 'How would you describe your academic experience?',
    selected: false,
  },
];

export const leadersActivitiesData = [
  { name: 'SWIMMING', icon: '/assets/icons/Frame.svg' },
  { name: 'BASKETBALL', icon: '/assets/icons/Frame-1.svg' },
  { name: 'RUNNING', icon: '/assets/icons/Frame-2.svg' },
  { name: 'PING PONG', icon: '/assets/icons/Frame-3.svg' },
  { name: 'YOGA', icon: '/assets/icons/Frame-4.svg' },
  { name: 'HIKING', icon: '/assets/icons/Frame-5.svg' },
  { name: 'ICE SKATING', icon: '/assets/icons/Frame-6.svg' },
  { name: 'TENNIS', icon: '/assets/icons/Frame-7.svg' },
];

export const leadersAboutUsData = [
  {
    id: 1,
    icon: '/assets/icons/Vector-4.svg',
    icon2: '/assets/icons/Vector-6.svg',
    title: 'Favorite Book',
    title2: 'Favorite Book',
    subTitle: 'To Kill a Mockingbird',
    subTitle2: 'Luca’s',
  },
  {
    id: 2,
    icon: '/assets/icons/Vector-7.svg',
    title: 'Favorite Food',
    icon2: '/assets/icons/Vector-2.svg',
    title2: 'Favorite Book',
    subTitle: 'Burger',
    subTitle2: 'Thanksgiving',
  },
  {
    id: 3,
    icon: '/assets/icons/Vector-5.svg',
    title: 'Favorite Sport',
    icon2: '/assets/icons/Vector-1.svg',
    title2: 'Our Favorite Book to Read',
    subTitle: 'Kayaking',
    subTitle2: 'Camping and Kayaking',
  },
  {
    id: 4,
    icon: '/assets/icons/Vector-4.svg',
    title: 'Favorite School memory',
    icon2: '/assets/icons/Vector-3.svg',
    title2: 'Our Favorite Book to Read',
    subTitle: 'Cape Code',
    subTitle2: 'Back to The Future',
  },
];

export const leaderClubCardData = [
  {
    id: 1,
    image: '/assets/images/clubcard2.svg',
    title: 'Parent Mentor Program',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
    sx: '',
  },
  {
    id: 2,
    image: '/assets/images/clubcard2.svg',
    title: 'Communication at Home',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
  {
    id: 3,
    image: '/assets/images/clubcard2.svg',
    title: 'Book Club',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
  {
    id: 4,
    image: '/assets/images/clubcard2.svg',
    title: 'Book Club',
    subTitle: 'with Leah Davis and Sarah Garcia',
    description:
      'Our staff will help you teach you new methods to make the school transition an easy process for your child.',
  },
];
