import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Container, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ClubFamilyCard } from "pages/ManageClubHub/components/ClubFamilyCard";

import apiRequest from "../../../../api/apiRequest";
import { SectionCarousel } from "../../../../components/SectionCarousel/SectionCarousel";

export const FamiliesCardsSection = props => {
  const [families, setFamilies] = useState([]);

  const fetchFamilies = async (clubId, offset) => {
    try {
      // Fetch 4 records to check the existence of the next page
      const response = await apiRequest({
        method: "get",
        url: `clubs/${clubId}/family?list=1&offset=${offset}&limit=4`,
      });
      setFamilies(response.data.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch families", error);
      return [];
    }
  };

  useEffect(() => {
    if (props.club && props.club.id) {
      fetchFamilies(props.club.id, 0);
    }
  }, [props.club]);

  if (families.length === 0) {
    return null;
  }

  return (
    <div id="families">
      <SectionCarousel
        handleNext={offset => fetchFamilies(props.club.id, offset)}
        handlePrevious={offset => fetchFamilies(props.club.id, offset)}
        canGoForward={families.length >= 3}
        sectionTitle="Followers"
        count={families.length}
      >
        {families.map((family, index) => (
          <Grid item xs={12} md={4} key={index}>
            <ClubFamilyCard family={family} />
          </Grid>
        ))}
      </SectionCarousel>
    </div>
  );
};
