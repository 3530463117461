import React from "react";
import styled from "styled-components";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import COLORS from "constants/colors";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { Checkbox } from "@mui/material";
import { UserGroupCell } from "./UserGroupCell";

export const FamiliesTable = props => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
      border: "none",
      fontWeight: "bold",
      fontSize: "18px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      border: "none",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.white.secondary,
      border: "none",
    },
  }));

  const hasFamily = family => {
    const familyExists = props.families.find(f => f.id === family.id);
    return !!familyExists;
  };

  const addFamily = family => {
    props.setFamilies([...props.families, family]);

    if (props.removeFamilies.includes(family.head_user_id)) {
      const newState = [...props.removeFamilies];
      newState.splice(newState.indexOf(family.head_user_id), 1);
      props.setRemoveFamilies(newState);
    }
  };

  const removeFamily = family => {
    const { removeFamilies, setRemoveFamilies } = props;

    const newFamilies = props.families.filter(f => f.id !== family.id);
    props.setFamilies(newFamilies);

    if (!removeFamilies.includes(family.head_user_id)) {
      setRemoveFamilies([...removeFamilies, family.head_user_id]);
    }
  };

  const toggleFamily = family => {
    if (hasFamily(family)) {
      removeFamily(family);
    } else {
      addFamily(family);
    }
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Followers</StyledTableCell>
            <StyledTableCell>Family Name</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Family Members</StyledTableCell>
            <StyledTableCell>Group</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.searchResults.map((data, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {" "}
                <Checkbox
                  size="small"
                  checked={hasFamily(data)}
                  onClick={() => toggleFamily(data)}
                  color="primary"
                />
              </StyledTableCell>
              <StyledTableCell>{data.last_name}</StyledTableCell>
              <StyledTableCell>
                <img src={data.cartoons} alt="" />
              </StyledTableCell>
              <StyledTableCell>{data.member_names.join(" ")}</StyledTableCell>
              {hasFamily(data) ? (
                <StyledTableCell>
                  <UserGroupCell
                    isFollowing={hasFamily(data)}
                    key={data.id}
                    user={data?.user}
                    userId={data.head_user_id}
                  />
                </StyledTableCell>
              ) : (
                <StyledTableCell />
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FamiliesTable;
