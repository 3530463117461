import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import React from "react";
import Container from "@mui/system/Container";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

export const NotFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "calc(100vh - 591px)" }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 2, // Add padding if necessary
        }}
      >
        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            Sorry, this content is not available at this time.
          </Typography>
          <Typography variant="body1">
            Please click{" "}
            <Link to={ROUTES.SIGN_IN} underline="hover">
              here
            </Link>{" "}
            to log in to an account with the appropriate permission to access this content.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            If you are looking for something else, please click{" "}
            <Link to="/" underline="hover">
              here
            </Link>{" "}
            to return to the homepage to continue browsing.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};
