import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import BottomHeading from 'pages/ManageStaffDirectory/BottomHeading';
import { leadersActivitiesData } from 'constants';

export const LeaderProfileCard = (props) => {
  return (
    <Grid item md={12}>
      <Box display='flex' justifyContent='center'>
        <Grid item container md={12} style={{ marginTop: '20px' }}>
          <Grid item>
            <Typography color='#131416' fontSize={{ md: 40 }} fontWeight={700}>
              About Me
            </Typography>
            <Typography mt={2} justifyContent='right'>
              Born and raised in Brooklyn, NY. Jane found her passion for
              teaching when she was in high school and especially enjoys
              teaching English. Teaching English is a great opportunity since
              she’s able to work with students and their schedules! Having lived
              in Germany for the past few years, and majoring in English and
              German at the College of William & Mary, she can communicate
              comfortably in both languages and loves the challenges that
              language brings! Jane is also a trained yoga teacher, writer, and
              is passionate about exploring new countries and cultures.
              <br />
              <br />
              <br /> Jane is currently doing an MBA in International Management,
              Logistics and Consulting, and hopes to finish in Spring 2022. When
              she isn’t working, she enjoys growing and harvesting her own
              vegetables, cooking, meeting people, getting to know new cultures,
              and learning new things.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        style={{ marginTop: '100px' }}
      >
        <Grid item container md={12}>
          <Grid item>
            <Typography color='#131416' fontSize={{ md: 40 }} fontWeight={700}>
              My Commitment to Your Child{' '}
            </Typography>
            <Typography mt={2} justifyContent='right'>
              I will strive to motivate each individual, involve him or her in
              learning, and understand how to teach everyone, not simply aim to
              teach the average student. I will also be an advocate for your
              student as an individual, ensuring that they have all the
              resources they need to succeed
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container maxWidth='xl'>
          <Grid item container md={12}>
            <Typography fontSize={{ md: 40 }} fontWeight={700} mt={4}>
              Activites we like
            </Typography>
            <Grid item container md={12} mt={4}>
              {leadersActivitiesData.map((item) => (
                <>
                  <Grid item md={1.5} xs={6}>
                    <Box
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                      justifyContent='space-between'
                      minHeight='100px'
                    >
 <img src={item.icon} alt='' />
                      <Typography fontSize={{ md: 18 }} fontWeight={600} >
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: 300,
          height: 240,
          background: '#ffff',
          boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Card
          sx={{
            height: '300px',
            width: '500px',
            position: 'relative',
            marginTop: '100px',
          }}
        >
          <CardMedia
            image={'/assets/images/cardImage.jpg'}
            component='img'
            height={'300px'}
            alt='green iguana'
          />
          <PlayCircleFilledIcon
            fontSize='large'
            sx={{
              top: '45%',
              left: '45%',
              zIndex: '999',
              color: 'white',
              position: 'absolute',
            }}
          />
        </Card>
      </Box>
      <BottomHeading />
      <Grid container sx={{ bgcolor: '#F2F2F2' }}></Grid>
    </Grid>
  );
};

export default LeaderProfileCard;
