import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import ConfigurationContext from "contexts/ConfigurationContext";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { MessagesTab } from "components/Global/Messages";
import LanguageIcon from "@mui/icons-material/Language";
import { BackgroundCircle } from "components/Global/BackgroundCircle";

import { COLORS } from "constants/colors";

export const StaffProfileDetails = ({ profile, type }) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const theme = useTheme();
  const { configurations, schools, isFeatureEnabled } =
    useContext(ConfigurationContext);
  const linkedIn = profile?.staff_profile?.social_media_accounts?.find(
    (sm) => sm.provider.toLowerCase().trim() === "linkedin"
  );
  const facebook = profile?.staff_profile?.social_media_accounts?.find(
    (sm) => sm.provider.toLowerCase().trim() === "facebook"
  );
  const twitter = profile?.staff_profile?.social_media_accounts?.find(
    (sm) => sm.provider.toLowerCase().trim() === "twitter"
  );
  const websiteUrl = profile?.staff_profile?.personal_website_url;

  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Box sx={{ position: "relative",width:'100%' }} display={{ xs: "none", md: "flex" }}>
          <Box style={{ position: "absolute", left: "-100px", top: "250px" }}>
            <BackgroundCircle
              color={theme.palette.primary.main}
            />
          </Box>
          <Box style={{ position: "absolute", left: "105%", top: "250px" }}>
            <BackgroundCircle
              color={theme.palette.primary.main}
            />
          </Box>
        </Box>
        <Grid item container md={10} sm={12} mt={10}>

          {type === "ambassador" && (
            <Box sx={{ position: "relative" }}>
              <Box sx={{ position: "absolute" }}>
                <img src="/assets/images/tag.png" alt="" />
              </Box>
              <Box sx={{ position: "absolute", top: 5, left: 15 }}>
                <Typography
                  fontSize={{ md: 17 }}
                  fontWeight={600}
                  color={COLORS.white.main}
                ></Typography>
              </Box>
            </Box>
          )}

          <Grid item md={4} sm={6} xs={12}>
            <Box
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.03)" }}
              style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
              }}>
              <img
                src={
                  profile?.profile_image?.path
                    ? profile?.profile_image?.path
                    : configurations?.default_user_profile_image?.path ||
                      "/assets/images/family-profile.svg"
                }
                style={{
                  objectFit:'contain',
                  width:'320px',
                  height:'280px',
                }}
                alt={profile.full_name}
                title={profile.full_name}
                width="100%"
                height="auto"
              />
            </Box>
          </Grid>

          <Grid
            item
            container
            md={8}
            sm={6}
            justifyContent="center"
            paddingX={5}
            mt={4}
          >
            <Grid item md={6}>
              {profile?.last_name && (
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  {profile.full_name}
                </Typography>
              )}
              {profile?.title && (
                <Typography fontSize={{ md: 18 }} fontWeight={600}>
                  {profile.title}
                </Typography>
              )}
              <Box>
                <Typography
                  fontSize={{ md: 18 }}
                  fontWeight={600}
                  mt={2}
                  width={260}
                  wrap
                >
                  {/* {profile?.head?.first_name || ''} */}
                  {profile?.members &&
                    profile.members.map(
                      (mem, i) =>
                        `${mem.first_name}${
                          i < profile.members.length - 1 ? ", " : ""
                        }`
                    )}
                </Typography>

                <Typography
                  fontSize={{ md: 18 }}
                  fontWeight={600}
                  mt={2}
                  width={260}
                  wrap
                >
                  {profile?.work_email &&
                  profile?.staff_profile?.is_work_email_visible ? (
                    <a
                      href={`mailto:${profile?.work_email}`}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        textDecoration: "none",
                      }}
                    >
                      {profile.work_email}
                    </a>
                  ) : (
                    ""
                  )}
                  {profile?.staff_profile?.secondary_email &&
                  profile?.staff_profile?.is_secondary_email_visible ? (
                    <>
                      <br />
                      <a
                        href={`mailto:${profile.secondary_email}`}
                        style={{
                          color: "rgba(0, 0, 0, 0.87)",
                          textDecoration: "none",
                        }}
                      >
                        {profile.secondary_email}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {profile?.attending_school_since &&
                  configurations?.schoolShortName
                    ? `Member of the ${configurations.schoolShortName} community since ${profile.attending_school_since}`
                    : ""}
                </Typography>
                {profile?.staff_profile?.educations?.map((ed) => {
                  const foundSchool = schools.find(
                    (s) => s.id === ed.outcome_id
                  );
                  if (!foundSchool) return <React.Fragment></React.Fragment>;
                  return (
                    <>
                      <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                        {ed.degree} - {foundSchool.outcomeTitle}
                      </Typography>
                      {foundSchool?.url && (
                        <Box mt={2}>
                          <img
                            src={foundSchool?.url}
                            alt={foundSchool?.outcomeTitle}
                            height="30px"
                          />
                        </Box>
                      )}
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: 2, md: 0 }}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isFeatureEnabled("family_message") ? (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: 50,
                    padding: 2,
                    width: 220,
                    color: "white",
                  }}
                  onClick={() => setIsMessageModalOpen(true)}
                >
                  Message
                </Button>
              ) : (
                ""
              )}
              <Box display="flex" justifyContent="flex-end" marginX={2} mt={2}>
                {facebook ? (
                  <a
                    href={
                      facebook.url.toLowerCase().startsWith("http")
                        ? facebook.url
                        : `//${facebook?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <FacebookIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}

                {twitter ? (
                  <a
                    href={
                      twitter.url.toLowerCase().startsWith("http")
                        ? twitter.url
                        : `//${twitter?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <TwitterIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}

                {linkedIn ? (
                  <a
                    href={
                      linkedIn.url.toLowerCase().startsWith("http")
                        ? linkedIn.url
                        : `//${linkedIn?.url}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <LinkedInIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}

                {websiteUrl ? (
                  <a
                    href={
                      websiteUrl.toLowerCase().startsWith("http")
                        ? websiteUrl
                        : `//${websiteUrl}`
                    }
                    target="blank"
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: "relative" }}>
                        <LanguageIcon color="primary" fontSize="large" />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ position: "relative" }} display={{ xs: "none", md: "flex" }}>
          <Box style={{ position: "absolute", left: "-70px", top: "500px" }}>
            <BackgroundCircle
              color={theme.palette.primary.main}
            />
          </Box>
          <Box style={{ position: "absolute", left: "95vw", top: "250px" }}>
            <BackgroundCircle
              color={theme.palette.primary.main}
            />
          </Box>
        </Box>

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby="Messages"
        aria-describedby="Messages"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // boxShadow: 24,
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <MessagesTab
            isNew={true}
            isModal={true}
            members={profile ? [profile] : []}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default StaffProfileDetails;
