import React, { useEffect, useState } from "react";
import apiRequest from "api/apiRequest";
import { useParams } from "react-router-dom";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import { ProfilePage } from "../../../components/ProfileDetails/ProfilePage";

export const GraduateDetails = () => {
  const { graduateId } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiRequest({
      url: `/graduates/${graduateId}`,
    }).then(resp => {
      setProfile(resp.data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isLoading ? <LoadingIndicator /> : <ProfilePage profile={profile} />}
    </React.Fragment>
  );
};

export default GraduateDetails;
