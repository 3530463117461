import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BackgroundCircle } from "components/Global/BackgroundCircle";
import { ClubPageMeetings } from "./ClubPageMeetings";

export const ClubPageCover = props => {
  const theme = useTheme();
  let club = props.club || {};

  const getClubMeetings = () => {
    if (!club) {
      return [];
    }

    const meetings = [...(club?.meetings || [])];
    if (
      club?.num_students ||
      club?.meeting_times ||
      club?.start_time ||
      club?.gradeBands?.length > 0
    ) {
      meetings.push({
        number_of_students: club.num_students,
        meeting_day: club.meeting_times,
        meeting_hours: club.start_time,
        student_grades: club?.gradeBands?.map(band => band?.gradeBand?.grade_band).join(", "),
      });
    }

    return meetings;
  };

  if (!club) {
    return [];
  }

  return (
    <Grid container>
      <Grid item sx={{ position: "relative" }} width={"100%"} height={"100%"}>
        <Box style={{ position: "absolute", right: "10%", top: "-20%" }}>
          <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={20} />
        </Box>
        <Box style={{ position: "absolute", left: "-5%", top: "50%" }}>
          <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={20} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            zIndex: "-999",
            left: "-10%",
            bottom: "40%",
          }}
          display={{ xs: "none", sm: "block", md: "block" }}
        ></Box>

        <Box
          elevation={0}
          className="club-cover-container"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box width="100%">
            <img
              className="club-cover-image"
              alt={club.clubName}
              title={club.clubName}
              src={club.image ? club.image?.path : "/assets/images/noImage.jpg"}
            />
            <section id="about">
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "28px",
                  lineHeight: "28px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                About Us
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                width={{ sm: "85%", md: "80%", lg: "70%" }}
              >
                {club.about}
              </Typography>
            </section>

            {club.requirements && (
              <>
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "900",
                    fontSize: "28px",
                    lineHeight: "28px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Requirements
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  width={{ sm: "85%", md: "80%", lg: "70%" }}
                >
                  {club.requirements}
                </Typography>
              </>
            )}

            {getClubMeetings().length > 0 && <ClubPageMeetings meetings={getClubMeetings()} />}

            {club.benefits ? (
              <Box id="benefits" mt={2}>
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "900",
                    fontSize: "28px",
                    lineHeight: "28px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Benefits of Participating
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  width={{ sm: "85%", md: "80%", lg: "70%" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: club.benefits }}></div>
                </Typography>
              </Box>
            ) : (
              []
            )}
            <br />
            <br />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
