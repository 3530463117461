import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ConfigurationContext from "contexts/ConfigurationContext";

export const Card = ({ image, imageSubTitle, title, outcome_id }) => {
  const imgRef = useRef(null);
  const { configurations, schools } = React.useContext(ConfigurationContext);
  const [school, setSchool] = useState(null);
  const [imageWidth, setImageWidth] = useState(0);

  React.useEffect(() => {
    if (outcome_id) {
      setSchool(schools?.find(s => s.id === outcome_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcome_id]);

  React.useEffect(() => {
    if (imgRef) {
      setImageWidth(imgRef?.current?.clientWidth);
    }
  }, [imgRef.current, imgRef?.current?.clientWidth]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: imageSubTitle ? "320px" : "300px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: imageSubTitle ? "320px" : "300px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            ref={imgRef}
            src={
              image
                ? image
                : configurations?.default_user_profile_image?.path ||
                  "/assets/images/family-profile.svg"
            }
            alt={title}
            width="auto"
            height={`${imageSubTitle ? "300px" : "100%"}`}
          />
          {imageSubTitle && (
            <Box
              sx={{
                background: configurations.color1,
                width: imageWidth,
                textAlign: "center",
                color: "#fff",
              }}
            >
              {imageSubTitle}
            </Box>
          )}
        </Box>
        {school && (
          <div
            style={{
              background: "rgba(255,255,255,0.6)",
              position: "absolute",
              top: "230px",
              width: "100%",
              height: "70px",
              textAlign: "center",
            }}
          >
            <img
              src={school?.url}
              style={{
                width: "auto",
                height: "50px",
                margin: "10px",
                maxWidth: "75%",
                objectFit: "contain",
              }}
              alt={school?.outcomeTitle}
            />
          </div>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" p={4} alignItems="center">
        <Typography sx={{ fontFamily: "Proxima-Nova-Black" }} fontSize={22}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
