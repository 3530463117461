import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";

// import { ambassadorsProfileAblumsData } from "constants/content";

export const FamilyProfileAlbum = () => {
  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid item container md={10}>
          <Typography
            fontSize={{ md: 40 }}
            fontWeight={700}
            mt={10}
            p={{ xs: 4, md: 0 }}
          >
            {/* Albums */}
          </Typography>
        </Grid>
      </Grid>
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid item container md={10}>
          {/* <Grid container maxWidth="xl" spacing={2} p={2}>
            {ambassadorsProfileAblumsData.map((item, index) => (
              <>
                <Grid
                  item
                  container
                  md={3}
                  xs={6}
                  direction="column"
                  key={index}
                >
                  <Paper
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      ml: 2,
                      p: 2,
                      boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <Grid item md={6}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        height={{ xs: "auto", md: 250 }}
                      >
                        <img
                          src={item.image1}
                          alt=""
                          width="100%"
                          height="auto"
                        />
                        <img
                          src={item.image2}
                          alt=""
                          width="100%"
                          height="auto"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box height={{ xs: "auto", md: 250 }}>
                        <img
                          src={item.image3}
                          alt=""
                          width="100%"
                          height="auto"
                        />
                      </Box>
                    </Grid>
                  </Paper>
                  <Grid container justifyContent={"center"}>
                    <Grid
                      item
                      md={5}
                      ml={2}
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                    >
                      <Typography fontSize={{ md: 18 }} fontWeight={400}>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FamilyProfileAlbum;
